import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SidebarLink } from './Home.styles';
import useLogout from '../utils/useLogout';
import { BiSolidVideos } from "react-icons/bi";
import { IoLogOutSharp } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { FaBookOpenReader, FaSquareWhatsapp } from "react-icons/fa6";
import { TbTrident } from "react-icons/tb";

import { ToggleButton } from '../ToggleButton';

import { CgProfile } from "react-icons/cg";
import { GiWallet, GiNigeria } from "react-icons/gi";
import { FaWallet } from "react-icons/fa6";
import { RiMovieFill, RiEnglishInput } from "react-icons/ri";
import { API_URL } from '../config';
import { IoMdAnalytics } from "react-icons/io";
import { MdOutlineFindInPage } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { MdCampaign } from "react-icons/md";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { GiThink } from "react-icons/gi";



const Menu = () => {
  const navigate = useNavigate();
  const logoutUser = useLogout();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = await getValidToken(navigate, false); 
      if (token) {
        setIsAuthenticated(true);
      try {
        const response = await axios.get(`${API_URL}/User`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response?.data || error.message);
      } finally {
        setIsLoading(false); // Ensure loading state is cleared
      }
    } else {
      setIsLoading(false); // Ensure loading state is cleared if no token
    }

    };

    fetchData();
  }, [navigate]);

    //const dispatch = useDispatch();

    const handleLogout = () => {
        logoutUser();
        setIsAuthenticated(false);
      };


    return (
        <div>
          {isAuthenticated ? (
          <>
            <SidebarLink component={Link} to="/user-details">
              <CgProfile style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
              <h3>Profile</h3>
            </SidebarLink>
            <SidebarLink component={Link} to="/My-wallet">
              <GiWallet style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
              <h3>Wallet</h3>
            </SidebarLink>
            <SidebarLink component={Link} to="/My-Videos">
              <BiSolidVideos style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
              <h3>My Videos</h3>
            </SidebarLink>
            <SidebarLink component={Link} to="/vip/section/new">
                  <TbTrident style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
                  <h3>Vip Section New</h3> 
                </SidebarLink>
            <SidebarLink component={Link} to="/kasuwa/section">
                  <BsCart4 style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
                  <h3>Kasuwa Section</h3> 
            </SidebarLink> 
            <SidebarLink component={Link} to="/create/ad">
                <MdCampaign style={{ color: 'Green', fontSize: '24px', fontWeight: '500' }} />
                <h3>Create Ads</h3> 
            </SidebarLink> 
            <SidebarLink component={Link} to="/manage/ads">
                <HiClipboardDocumentList style={{ color: 'orange', fontSize: '24px', fontWeight: '500' }} />
                <h3>Manage Ads</h3> 
             </SidebarLink>       
           {/* <SidebarLink component={Link} to="/purchase/post/ticket">
                <TbTrident style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
                <h3>Purchase Ticket</h3> 
            </SidebarLink> } */}  
            {userData.role_id === 2 && (
              <SidebarLink component={Link} to="/become/creator/form">
              <FaBookOpenReader style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
              <h3>Become Creator</h3> 
            </SidebarLink>
            )}    
            <SidebarLink component={Link} to="/">
              <GiNigeria style={{ color: 'green', fontSize: '24px', fontWeight: '500' }} />
              <h3>Hausa</h3> 
            </SidebarLink>
            <SidebarLink component={Link} to="/">
              <RiEnglishInput style={{ color: 'orange', fontSize: '24px', fontWeight: '500' }} />
              <h3>English</h3> 
            </SidebarLink>
            
            {/* Render links based on user role */}
            {(userData.role_id === 1 || userData.role_id === 3 || userData.role_id === 33 || userData.role_id === 7) && (
              <>  
                <SidebarLink component={Link} to="/upload">
                  <FaUpload style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
                  <h3>Upload New Post</h3>
                </SidebarLink>
                <SidebarLink component={Link} to="/earnings">
                  <FaWallet style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
                  <h3>Earnings</h3>
                </SidebarLink>
                <SidebarLink component={Link} to="/My-Posts">
                  <RiMovieFill style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
                  <h3>My Posts</h3> 
                </SidebarLink>
                <SidebarLink component={Link} to="/Analytics">
                  <IoMdAnalytics style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
                  <h3>Analytics</h3> 
                </SidebarLink>
                <SidebarLink component={Link} to="search/post/ticket">
                  <MdOutlineFindInPage  style={{ color: 'blue', fontSize: '24px', fontWeight: '500' }} />
                  <h3>Search Ticket</h3> 
                </SidebarLink>
                <SidebarLink component={Link} to="/lissafin/rayuwa">
              <GiThink style={{ color: 'orange', fontSize: '24px', fontWeight: '500' }} />
              <h3>Lissafin Rayuwa</h3>
            </SidebarLink>
              </>
            )}

            {/* <SidebarLink component={Link} to="/lissafin/rayuwa">
              <GiThink style={{ color: 'orange', fontSize: '24px', fontWeight: '500' }} />
              <h3>Lissafin Rayuwa</h3> 
            </SidebarLink> */}

            <SidebarLink component={Link} to="/contactUs">
              <FaSquareWhatsapp style={{ color: 'green', fontSize: '24px', fontWeight: '500' }} />
              <h3>Contact Us</h3> 
            </SidebarLink>

            {/* Logout link */}
            <SidebarLink onClick={handleLogout}>
              <IoLogOutSharp style={{ color: 'red', fontSize: '24px', fontWeight: '500' }} />
              <h3>Logout</h3>
            </SidebarLink>
          </>
        ) : (
          <>
            <SidebarLink component={Link} to="/login">
              <RiMovieFill style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
              <h3>Login / Register</h3> 
            </SidebarLink>
          </>
        )}


          {/* <SidebarLink href="/admin/sales">
          <FcSalesPerformance style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Sales</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/analytics">
          <IoMdAnalytics style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Analytics</h3>
          </SidebarLink>
          {/* <ToggleButton onClick={toggleTheme}>
            {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}s
          </ToggleButton> 
          <SidebarLink component={Link} to="/">
          <IoIosSend style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Send Notification</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/settings">
          <IoMdSettings style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Settings</h3>
          </SidebarLink> */}

           
        </div>
    );
};

export default Menu;
