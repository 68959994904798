import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useTranslation } from 'react-i18next';
import PostCard from './PostCard';
import { useNavigate } from 'react-router-dom';
import { MdCancel, MdAdd } from 'react-icons/md';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import Spinner from './Spinner';
import {PostsContainer} from './PostCard.styles';


import { PaginationWrapper,
  Pagination,
  PageNumber,
  PaginationButton } from './Home.styles';

const UserPurchasedContentLists = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchVisible, setSearchVisible] = useState(false);
  const { t } = useTranslation();
  const [loadingStates, setLoadingStates] = useState({});
  const [expirationStates, setExpirationStates] = useState({});
  const postsListRef = useRef(null);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchPurchasedPosts = async () => {
  //     const token = await getValidToken(navigate, true);
  //     if (token) {
  //     try {
  //       const config = {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         params: { searchQuery, currentPage, perPage },
  //       };
  //       const response = await axios.get(`${API_URL}/all/posts/purchased/user`, config);
  //       const { totalCount, totalPages, posts } = response.data;
  //       setTotalCount(totalCount);
  //       setPosts(posts);
  //       setTotalPages(totalPages);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.error('Error fetching posts:', error);
  //     }
  //   }
  //   };
  //   fetchPurchasedPosts();
  // }, [searchQuery, currentPage, perPage, navigate]);

  useEffect(() => {
    const fetchPurchasedPosts = async () => {
      const token = await getValidToken(navigate, true);
      if (token) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { searchQuery, currentPage, perPage },
          };
          const response = await axios.get(`${API_URL}/all/posts/purchased/user`, config);
          const { totalCount, totalPages, posts } = response.data;
  
          // Filter out duplicate posts based on postId
          setPosts(prevPosts => {
            const newPosts = posts.filter(post => 
              !prevPosts.some(prevPost => prevPost.postId === post.postId)
            );
            return [...prevPosts, ...newPosts];
          });
  
          setTotalCount(totalCount);
          setTotalPages(totalPages);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching posts:', error);
        }
      }
    };
  
    fetchPurchasedPosts();
  }, [searchQuery, currentPage, perPage, navigate]);  

  // check if the purchased of is expired
  useEffect(() => {
    const checkPostPurchaseExpire = async (postId) => {
      const token = await getValidToken(navigate, false);
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/purchase/check/expire/${postId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          });

          const { purchasedResult, resetDateValid } = response.data;
          const isPostExpired = purchasedResult && !resetDateValid;

          setExpirationStates((prev) => ({ ...prev, [postId]: isPostExpired }));
          setLoadingStates((prev) => ({ ...prev, [postId]: false }));
        } catch (error) {
          console.error('Error checking post purchase:', error.response?.data || error.message);
          setLoadingStates((prev) => ({ ...prev, [postId]: false }));
        }
      } else {
        setLoadingStates((prev) => ({ ...prev, [postId]: false }));
      }
    };

    posts.forEach((post) => {
      if (!loadingStates[post.id] && !expirationStates.hasOwnProperty(post.id)) {
        setLoadingStates((prev) => ({ ...prev, [post.id]: true }));
        checkPostPurchaseExpire(post.id);
      }
    });
  }, [posts, navigate, expirationStates, loadingStates]);

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }


  const handleSearch = () => {
    setCurrentPage(1);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };


  const handleGoBack = () => {
    navigate('/');
  };

  const scrollToTop = () => {
    postsListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

return (
  <MainContainer>
      <Title>
        <StyledH1>Purchased Posts</StyledH1>
        <StyledH2>Total: {totalCount}</StyledH2>
        <CancelIcon>
          <StyledAddButton onClick={handleGoBack}>
            <MdCancel style={{ color: 'red', fontSize: '24px' }} />
          </StyledAddButton>
        </CancelIcon>
      </Title>

      {searchVisible && (
        <InputBox>
          <StyledInput
            label="Search"
            value={searchQuery}
            placeholder="Search purchased post..."
            onChange={(e) => setSearchQuery(e.target.value)}
            variant="outlined"
          />
          <Icon>
            <FaSearch variant="contained" style={{ color: 'black', fontSize: '24px' }} onClick={handleSearch} />
          </Icon>
        </InputBox>
      )}

      <CenterSearchAndCreateButton>
        <AddButton onClick={() => navigate('/')}>
          <MdAdd style={{ color: 'white', fontSize: '24px', fontWeight: '500' }} /> Buy New Post
        </AddButton>
        <AddButton onClick={() => setSearchVisible(!searchVisible)}>
          {searchVisible ? 'Hide Search' : 'Show Search'}
        </AddButton>
      </CenterSearchAndCreateButton>

      <PostsContainer ref={postsListRef}>
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <PostCard key={`${post.id}-${index}`} post={post} />
          ))
        ) : (
          <NoPostsMessage>{t('No posts available. You can go and buy a new one!')}</NoPostsMessage>
        )}
      </PostsContainer>

      <PaginationWrapper>
        <Pagination>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <PageNumber 
            key={index + 1} 
            onClick={() => handlePageClick(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}>
              {index + 1}
            </PageNumber>
          ))}
          <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </PaginationButton>
        </Pagination>
        </PaginationWrapper>
  </MainContainer>
);

};

const MainContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
`;


const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const StyledH2 = styled.h2`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
`;

const CancelIcon = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAddButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Icon = styled.div`
  cursor: pointer;
`;

const CenterSearchAndCreateButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const NoPostsMessage = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  margin-top: 20px;
`;

export {MainContainer, NoPostsMessage, Title, StyledH1, StyledH2, CancelIcon, StyledAddButton, SpinnerContainer};


export default UserPurchasedContentLists;
