import React from 'react';
import styled from 'styled-components';

const AvatarContainer = styled.div`
  width: 40px;  /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  margin-right: 0.7rem;
`;

const ProfileImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const Avatar = ({ username = '', profilePicture, avatarColor, width, height }) => {

    const firstLetter = username ? username.charAt(0).toUpperCase() : '';

  

  return (
    <AvatarContainer style={{ backgroundColor: avatarColor }} width={width} height={height}>
      {profilePicture ? (
        <ProfileImage src={profilePicture} alt="Profile" width={width} height={height}/>
      ) : (
        <div>{firstLetter}</div>
      )}
    </AvatarContainer>
  );
};

export default Avatar;
