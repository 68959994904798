// Example React component to fetch and display subscriptions
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaSearch, FaFilter } from 'react-icons/fa';
import { PaginationWrapper,
    Pagination,
    PageNumber,
    PaginationButton } from './Home.styles';

const SubscriptionsAnalytics = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [activeSubscribers, setActiveSubscribers] = useState(0);
    const [inactiveSubscribers, setInactiveSubscribers] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [filter, setFilter] = useState('all');
    const [status, setStatus] = useState('all');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [customStartDate, setCustomStartDate] = useState(new Date());
    const [customEndDate, setCustomEndDate] = useState(new Date());
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const subscribersListRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSubscriptions();
    }, [filter, status, searchQuery, customStartDate, customEndDate, currentPage, limit]);

    const formatCustomDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const fetchSubscriptions = async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            };
            const params = {
                filter,
                search: searchQuery,
                customStartDate: filter === 'custom' ? formatCustomDate(customStartDate) : undefined,
                customEndDate: filter === 'custom' ? formatCustomDate(customEndDate) : undefined,
                currentPage,
                limit,
                status
            }
            const response = await axios.get(`${API_URL}/subscriptions/analytics/user`, { ...config, params });
            setSubscriptions(response.data.subscriptions);
            setTotalEarnings(response.data.totalEarnings);
            setActiveSubscribers(response.data.activeSubscribers);
            setInactiveSubscribers(response.data.inactiveSubscribers);
            setTotalPages(response.data.totalPages); 
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        }
    }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };

    const scrollToTop = () => {
        subscribersListRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handlePageClick = (page) => {
        setCurrentPage(page);
        scrollToTop();
      };

      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          scrollToTop();
        }
      };
    
      const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scrollToTop();
        }
      };
      
      const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);
    
        const today = new Date();
        let newStartDate, newEndDate;
    
        switch (selectedFilter) {
            case 'yesterday':
                newStartDate = new Date(today);
                newStartDate.setDate(newStartDate.getDate() - 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today);
                newEndDate.setDate(newEndDate.getDate() - 1);
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'last-week':
                newStartDate = new Date(today);
                newStartDate.setDate(newStartDate.getDate() - 7);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = today;
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'last-month':
                newStartDate = new Date(today);
                newStartDate.setMonth(newStartDate.getMonth() - 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = today;
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'this-month':
                newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = today;
                newEndDate.setHours(23, 59, 59, 999);
                break;
                case 'this-week':
                    const dayOfWeek = today.getDay(); // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
                    
                    // Adjust the start of the week based on the desired start day (e.g., Monday or Sunday)
                    // Here, assuming the week starts on Monday
                    const startOfWeek = new Date(today);
                    startOfWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust if Sunday
                    startOfWeek.setHours(0, 0, 0, 0);
                    
                    // End of the week is 6 days after the start of the week
                    const endOfWeek = new Date(startOfWeek);
                    endOfWeek.setDate(startOfWeek.getDate() + 6);
                    endOfWeek.setHours(23, 59, 59, 999);
                
                    newStartDate = startOfWeek;
                    newEndDate = endOfWeek;
                    break;
                
            case 'this-year':
                newStartDate = new Date(today.getFullYear(), 0, 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = today;
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'last-year':
                newStartDate = new Date(today.getFullYear() - 1, 0, 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today.getFullYear() - 1, 11, 31);
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'custom':
                // newStartDate = customStartDate;
                // newEndDate = customEndDate;
                newStartDate = new Date(customStartDate);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(customEndDate);
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'today':
            default:
                newStartDate = new Date(today);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today);
                newEndDate.setHours(23, 59, 59, 999);
                break;
        }
    
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleCustomStartDateChange = (date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        setCustomStartDate(newDate);
        setStartDate(newDate);
        if (customEndDate && newDate > customEndDate) {
            const newEndDate = new Date(newDate);
            newEndDate.setHours(23, 59, 59, 999);
            setCustomEndDate(newEndDate);
            setEndDate(newEndDate);
        }
    };
    
    const handleCustomEndDateChange = (date) => {
        const newDate = new Date(date);
        newDate.setHours(23, 59, 59, 999);
        setCustomEndDate(newDate);
        setEndDate(newDate);
        if (customStartDate && newDate < customStartDate) {
            const newStartDate = new Date(newDate);
            newStartDate.setHours(0, 0, 0, 0);
            setCustomStartDate(newStartDate);
            setStartDate(newStartDate);
        }
    };

    const formatEarnings = (earnings) => {
        if (isNaN(parseFloat(earnings)) || !isFinite(earnings)) {
            return 'Invalid earnings';
        }
        earnings = parseFloat(earnings);
        if (isNaN(earnings) || !isFinite(earnings)) {
            return 'Invalid earnings';
        }
        let earningsStr = earnings.toFixed(2);
        const parts = earningsStr.split('.');
        let wholePart = parts[0];
        const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
        const regex = /\B(?=(\d{3})+(?!\d))/g;
        wholePart = wholePart.replace(regex, ',');
        return wholePart + decimalPart;
    };

    const formattedEarnings = formatEarnings(totalEarnings);
    

    return (
        <Container>
            <Title>Subscriptions</Title>

            <InsightsContainer>
                <div><span>N{formattedEarnings}</span><h4>Total Earnings</h4></div>
                <div><span>{activeSubscribers}</span><h4>Active Subscribers</h4></div>
                <div><span>{inactiveSubscribers}</span><h4>Inactive Subscribers</h4></div>
            </InsightsContainer>

            <Filters>
                <SelectDiv>
                <StatusSelect value={filter} onChange={handleFilterChange}>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="this-week">This Week</option>
                    <option value="this-month">This Month</option>
                    <option value="last-week">Last Week</option>
                    <option value="last-month">Last Month</option>
                    <option value="this-year">This Year</option>
                    <option value="last-year">Last Year</option>
                    <option value="custom">Custom</option>
                    <option value="all">All</option>
                    
                </StatusSelect>
                <StatusSelect value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="all">All</option>
                    <option value="active">Active</option>
                    <option value="expired">Expired</option>
                </StatusSelect>
                {filter === 'custom' && (
                    <DatePickerDiv>
                        <label>Start Date:</label>
                        <DatePicker selected={customStartDate} onChange={handleCustomStartDateChange} />
                        <label>End Date:</label>
                        <DatePicker selected={customEndDate} onChange={handleCustomEndDateChange} />
                    </DatePickerDiv>
                )}
                </SelectDiv>

                <SearchContainerDiv>    
                {showSearch && (
                    <SearchInput
                        type="text"
                        placeholder="Search by username or phone number"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                )}
                
                </SearchContainerDiv>
                <SearchToggle onClick={() => setShowSearch(!showSearch)}>
                    <FaSearch />
                </SearchToggle>
            </Filters>


            <SubscriptionList ref={subscribersListRef}>
                {subscriptions.map(sub => (
                    <SubscriptionCard key={sub.id}>
                        <LeftSide>
                            <Username>{sub.user_username} subscribed to {sub.author_username}</Username>
                            <Validity>{sub.status === 'active' ? 'Active' : 'Expired'}</Validity>
                        </LeftSide>
                        <RightSide>
                            <DateInfo>Started on: <br /> {formatDate(sub.start_date)}</DateInfo>
                            <DateInfo>Expired on: <br /> {formatDate(sub.end_date)}</DateInfo>
                            <Status status={sub.status}>{sub.status}</Status>
                        </RightSide>
                    </SubscriptionCard>
                ))}
            </SubscriptionList>

            <PaginationWrapper>
                <Pagination>
                <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
                </PaginationButton>
                {Array.from({ length: totalPages }, (_, index) => (
                    <PageNumber 
                    key={index + 1} 
                    onClick={() => handlePageClick(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}>
                    {index + 1}
                    </PageNumber>
                ))}
                <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
                </PaginationButton>
                </Pagination>
            </PaginationWrapper>
        </Container>
    );
};

const Container = styled.div`
    padding: 20px;
    background-color: ${props => props.theme.colors.background2};
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
`;

const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
`;

const SelectDiv = styled.div`
    
`;

const DatePickerDiv = styled.div`
    font-size: 16px;
`;

const SearchContainerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-contentL center;
    width: 100%;
`; 

const SearchToggle = styled.button`
    background: none;
    border: none;
    color: ${props => props.theme.colors.text};
    cursor: pointer;
    font-size: 1.5em;
    margin-right: 10px;
`;

const SearchInput = styled.input`
    padding: 5px;
    width: 100%;
    font-size: 16px;
    margin-right: 10px;
`;

const InsightsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.6rem;
  margin-bottom: 10px;

  > div {
    padding: ${props => props.theme.padding.card};
    border-radius: ${props => props.theme.borderRadius.card};
    margin-top: 1rem;
    box-shadow: ${props => props.theme.boxShadow};
    transition: all 300ms ease;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items to center horizontally */
    text-align: center; /* Align text content to center */

    &:hover {
      box-shadow: none;
      border: 2px solid green;
    }

    &:active {
      box-shadow: none;
      border: 6px solid green;
    }

    span {
      background-color: ${props => props.theme.colors.primary};
      padding: 0.5rem;
      border-radius: 10px;
      color: ${props => props.theme.colors.white};
      font-size: 1.5rem; /* Adjusted icon size */
      margin-bottom: 10px;
    }

    .middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h3 {
      margin: 1rem 0 0.6rem;
      font-size: 1.5rem;
      color: ${props => props.theme.colors.text};
    }

    h1 {
      color: ${props => props.theme.colors.text};
    }

    small {
      display: block;
      margin-top: 1.3rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Two items side by side on mobile */

    h3 {
      font-size: .8rem;
    }

    h1 {
      font-size: 1rem;
    }
  }
`;

const FilterButton = styled.button`
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
`;

const StatusSelect = styled.select`
    padding: 5px;
    cursor: pointer;
`;

const Summary = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
`;

const SummaryItem = styled.h2`
    margin: 0;
`;

const SubscriptionList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const SubscriptionCard = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.background};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Username = styled.div`
    font-size: 1.2em;
    font-weight: bold;
`;

const Validity = styled.div`
    font-size: 1em;
    color: #555;
`;

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
`;

const DateInfo = styled.div`
    font-size: 1em;
    color: ${props => props.theme.colors.text};
`;

const Status = styled.div`
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    width: 100%;
    background-color: ${props => (props.status === 'active' ? 'green' : 'red')};
    padding: 5px 10px;
    border-radius: 5px;
`;

export default SubscriptionsAnalytics;
