import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorMessageModal from './ErrorMessageModal';
import Button from './Button';

const Container = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.colors.background2};
  border-radius: 10px;
`;

const Title = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text};

  div {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
  }

   p {
    color: ${(props) => props.theme.colors.text};
    }

  h4 {
    margin: 5px 0px;
    color: ${(props) => props.theme.colors.text};
    }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  boreder-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.background};  
  padding: 10px;
`;

const Tab = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  color: ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.primary};
  &:hover {
    background: #0056b3;
  }
`;

const InputSection = styled.div`
  margin-top: 20px;
`;

const Label = styled.label`
  display: block;
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
`;

const Input = styled.input`
  padding: 8px;
  width: 100%;
  margin-bottom: 15px;
  background: ${(props) => props.theme.colors.background2};
  color: ${(props) => props.theme.colors.text};
  font-size: 16px;
`;

const H4Div = styled.div`
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
`;


const GenerateVirtualAccounts = ({userFullName, userPhone, fetchReceipt, fetchAccounts}) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [fullName, setFullName] = useState(userFullName);
  const [phone, setPhone] = useState(userPhone);  
  const [showStatic, setShowStatic] = useState(false);
  const [showDynamic, setShowDynamic] = useState(false);
  const [withBvn, setWithBvn] = useState('');
  const [withNin, setWithNin] = useState('');
  const [type, setType] = useState(null);
  const [bvn, setBvn] = useState('');
  const [nin, setNin] = useState('');
  const [dob, setDob] = useState('');
  const [amount, setAmount] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleGenerate = async () => {
    setIsLoadingSubmit(true);
  // Validate input for NIN and BVN
  if (nin && nin.length < 11) return setError('NIN must be at least 11 characters long.');
  if (bvn && bvn.length < 11) return setError('BVN must be at least 11 characters long.');

  // Fetch token
  const token = await getValidToken(navigate, true);
  if (!token) return setError('Invalid token. Please login again.');
  

  try {
    const data = type === 'static' 
      ? { bvn, nin, fullName, dob, phone, type } 
      : { amount, type };

    // Post request to generate the account
    const response = await axios.post(`${API_URL}/generate-account`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // On success
    setSuccess(`Account generated: ${response.data.message}`);
    fetchAccounts();
    fetchReceipt();
    setTimeout(() => {
      setSuccess('');
    }, 5000);

  } catch (error) {
    console.error('Error generating account:', error);
    
    // Check if there is an error response from the backend
    if (error.response && error.response.data && error.response.data.message) {
      // Set the error from the backend
      setError(error.response.data.message);
    } else {
      // Default error message if no specific error from the backend
      setError('Error generating account. Please try again.');
    }
    // Clear error after 4 seconds
    setTimeout(() => {
      setError('');
    }, 4000);
  } finally {
    setIsLoadingSubmit(false);
  }
};


  const handleStaticAccountTab = () => {
    setShowStatic(true);
    setType('static'); 
    setShowDynamic(false);
  }

  const handleDyanmicAccountTab = () => {
    setShowDynamic(true);
    setType('dynamic'); 
    setShowStatic(false);
  }

  const handleWithBvnTab = () => {
    setWithBvn(true);
    setNin(''); 
    setWithNin(false);
  }

  const handleWithNinTab = () => {
    setWithNin(true);
    setBvn(''); 
    setWithBvn(false);
  }



  return (
    <Container>
      <Title>{t('Generate Virtual Accounts')}</Title>

      {!showStatic && !showDynamic && (
        <InfoDiv>
          <div>
          <h4><b>Static Accounts:</b></h4> <p>{t('permanent accounts for automatic wallet funding, requires BVN.')}</p>
          </div>
          <div>
          <h4><b>Dynamic Account:</b></h4> <p>{t('one-time account for a specific amount, fund your wallet instantly.')}</p>
          </div>
        </InfoDiv>
      )}

      <Tabs>
        <Tab onClick={handleStaticAccountTab}>Static Account</Tab>
        <Tab onClick={handleDyanmicAccountTab}>Dynamic Account</Tab>
      </Tabs>

      {showStatic && (
        <InputSection>
        <H4Div>
          <h4>{t('Generate Static Account')}</h4>
        </H4Div>  

        <Tabs>
        <Tab onClick={handleWithBvnTab}>{t('With BVN')}</Tab>
        <Tab onClick={handleWithNinTab}>{t('With NIN')}</Tab>
        </Tabs>

          {withBvn && (
             <>
            <Label>{t('BVN Number')}</Label>
            <Input 
              type='text' 
              placeholder={t(`Enter BVN Number...`)} 
              value={bvn} 
              onChange={(e) => setBvn(e.target.value)}
              required 
            />
            <Label>{t('Date Of Birth')}</Label>
            <Input 
              type='date'  
              value={dob} 
              onChange={(e) => setDob(e.target.value)}
              required 
            />
            <Label>{t('Full Name On Bvn')}</Label>
            <Input 
              type='text' 
              placeholder={t(`Enter Your Full Name...`)} 
              value={fullName} 
              onChange={(e) => setFullName(e.target.value)}
              required 
            />
            <Label>{t('Legal Phone')}</Label>
            <Input 
              type='text' 
              placeholder={`${t('Your phone number')}`} 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)}
              required 
            />
           </>
          )}

          {withNin && (
             <>
             <Label>{t('NIN Number')}</Label>
                <Input 
                type='text' 
                placeholder={t(`Enter NIN Number...`)} 
                value={nin} 
                onChange={(e) => setNin(e.target.value)}
                required 
                />
                <Label>{t('Date Of Birth')}</Label>
                <Input 
                type='date'  
                value={dob} 
                onChange={(e) => setDob(e.target.value)} 
                />
                <Label>{t('Full Name On Nin')}</Label>
                <Input 
                type='text' 
                placeholder={t(`Enter Your Full Name...`)}
                value={fullName} 
                onChange={(e) => setFullName(e.target.value)}
                required 
                />
                <Label>{t('Legal Phone')}</Label>
                <Input 
                type='text' 
                placeholder={`${t('Your phone number')}`}
                value={phone} 
                onChange={(e) => setPhone(e.target.value)}
                required 
                />
             </>
          )}

          {success && (
                  <p style={{color: 'green'}}>{success}</p>
                )}
          
          <Button onClick={() => handleGenerate('static')} isLoading={isLoadingSubmit} disabled={isLoadingSubmit} isP={true} loginSuccess={success}>{t('Generate Accounts')}</Button>
        </InputSection>
      )}

      {showDynamic && (
        <InputSection>
        <H4Div>
        <h4>{t('Generate Dynamic Account')}</h4>
        </H4Div>
        <p>{t('This Section is under maintenance, for now you can only generate Static Accounts.')}</p>

          {/* <h4>Generate Dynamic Account</h4>
          <Label>Amount</Label>
          <Input 
            type='text' 
            placeholder='Enter amount' 
            value={amount} 
            onChange={(e) => setAmount(e.target.value)} 
          />
          <Button onClick={() => handleGenerate('dynamic')} isLoading={isLoadingSubmit} disabled={isLoadingSubmit} isP={true}>Generate Account</Button> */}
        </InputSection>
      )}

    {error && (
      <ErrorMessageModal errorMessage={error} onClose={() => {setError('')}}/>
    )} 
    </Container>
  );
};

export default GenerateVirtualAccounts;
