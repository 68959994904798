import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from '../../theme';
import { API_URL, baseURL } from '../../config';
import { useNavigate } from 'react-router-dom';
import { getValidToken } from '../../utils/auth';
import SubscriptionsAnalyticsForAdmin from './SubscriptionsAnalyticsForAdmin';
import { logout } from '../../reducers/actions';
import { useDispatch } from 'react-redux';
import { FaUsers } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { MdOutlinePostAdd, MdRateReview, MdOutlineFindInPage } from "react-icons/md";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { BiMoneyWithdraw, BiSolidDashboard } from "react-icons/bi";
import { FcSalesPerformance } from "react-icons/fc";
import { IoMdAnalytics, IoIosSend, IoMdSettings, IoMdCloseCircle } from "react-icons/io";
import { IoLogOutSharp } from "react-icons/io5";
import { FaFolder, FaRegMoneyBillAlt, FaBars, FaUpload, FaWpforms } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GiWallet } from "react-icons/gi";
import { MdCampaign } from "react-icons/md";
import { HiClipboardDocumentList } from "react-icons/hi2";
import DatePicker from 'react-datepicker';

import logo from '../../assets/ArewaVip.png';


import { Container, Aside, Top, Logo, Close, Sidebar, SidebarLink, Main, DateWrapper, InsightsContainer, DropdownMenu,
  MenuIcon, FilterDiv, DatePickerDiv } from './MainDashboardStyles';

import { ToggleButton } from '../../ToggleButton';
import Analytics from './Analytics';

const Dashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const isMobile = window.innerWidth <= 768; 
  console.log('isMobile:', isMobile);

  const [isOpen, setIsOpen] = useState(true);
  const sidebarRef = useRef(null);
  const [counts, setCounts] = useState({
    totalUsers: 0,
    totalPosts: 0,
    totalTransactions: 0,
    homepageViews: 0,
    pendingReceipts: 0,
    approvedReceipts: 0,
    totalTransactionSuccess: 0,
    totalTransactionFailed: 0,
    totalEarnings: 0,
    totalUsersWallet: 0,
    totalTopUp: 0
  });
  const [filter, setFilter] = useState('today');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    fetchCounts();
  }, [filter, startDate, endDate]);

  const fetchCounts = async () => {
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        };
        const params = {
          filter,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        };
        const res = await axios.get(`${API_URL}/dashboard-counts`, { ...config, params });
        setCounts(res.data);
      } catch (error) {
        console.error('Error fetching dashboard counts:', error);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768; 

      if (isMobile && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false); // Close sidebar if click is outside the sidebar and it's a mobile device
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Check screen size on mount and update isOpen accordingly
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsOpen(window.innerWidth > 768); // Show sidebar on desktop
    };

    // Set isOpen to false if screen width is less than or equal to 768px (mobile)
    setIsOpen(window.innerWidth > 768);

    // Add event listener for screen resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);
  
  const toggleSidebar = () => {
    console.log('Before state update - isOpen:', isOpen);
  setIsOpen((prevOpen) => !prevOpen);
  console.log('After state update - isOpen:', !isOpen);
    //console.log('isOpen:', isOpen);
  };

  // const toggleDropdown = () => {
  //   setShowDropdown(prevState => !prevState);
  // };

  const dispatch = useDispatch();

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme === 'dark') {
      setIsDarkMode(true);
    }
    setLoading(false);
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  useEffect(() => {
    if (!loading) {
      localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }
  }, [isDarkMode, loading]);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or animation
  }

  

  const handleLogout = () => {
    // Dispatch the logout action to clear authentication state in Redux
    dispatch(logout());

    // Remove the JWT token cookie
    document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Redirect to the login page or any other appropriate page
    window.location.replace('/admin'); // Use window.location.replace for full page reload
  };

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilter(selectedFilter);

    const today = new Date();
    let newStartDate, newEndDate;

    switch (selectedFilter) {
      case 'yesterday':
        newStartDate = new Date(today);
        newStartDate.setDate(newStartDate.getDate() - 1);
        newEndDate = new Date(newStartDate);
        break;
      case 'last-week':
        newStartDate = new Date(today);
        newStartDate.setDate(newStartDate.getDate() - 7);
        newEndDate = today;
        break;
      case 'last-month':
        newStartDate = new Date(today);
        newStartDate.setMonth(newStartDate.getMonth() - 1);
        newEndDate = today;
        break;
      case 'custom':
        newStartDate = customStartDate;
        newEndDate = customEndDate;
        break;
      default:
        newStartDate = today;
        newEndDate = today;
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleCustomStartDateChange = (date) => {
    setCustomStartDate(date);
    setStartDate(date);
    if (customEndDate && date > customEndDate) {
      setCustomEndDate(date);
      setEndDate(date);
    }
  };

  const handleCustomEndDateChange = (date) => {
    setCustomEndDate(date);
    setEndDate(date);
    if (customStartDate && date < customStartDate) {
      setCustomStartDate(date);
      setStartDate(date);
    }
  };

  // Format wallet balance with commas
const formatWallet = (wallet) => {
  // Check if wallet is a valid number or string that can be converted to a number
  if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
    return '***';
  }

  // Convert earnings to number and ensure it's a valid number
  wallet = parseFloat(wallet);
  if (isNaN(wallet) || !isFinite(wallet)) {
    return 'Invalid wallet';
  }

  // Convert earnings to string with 2 decimal places
  let walletStr = wallet.toFixed(2);

  // Split wallet into whole and decimal parts
  const parts = walletStr.split('.');
  let wholePart = parts[0];
  const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  // Add commas for thousands, millions, etc.
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  wholePart = wholePart.replace(regex, ',');

  // Return formatted earnings
  return wholePart + decimalPart;
};

  

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
    <Container>
    
    
    <MenuIcon onClick={toggleSidebar}>
      <BiSolidDashboard style={{ fontSize: '24px', fontWeight: '500' }} />
    </MenuIcon>
 

       <Aside>
      {/* <MenuIcon onClick={toggleDropdown}>
          <FaBars />
        </MenuIcon>  */}
        
        <Sidebar style={{ display: isOpen ? 'flex' : 'none' }} ref={sidebarRef}>
        
        {/* <DropdownMenu open={showDropdown}> */}
        <Top>
          <Logo>
          <img src={logo} alt="Logo" />
            {/* <h2>Admin<span className="danger"> Menu</span></h2> */}
          </Logo>
          {/* <ToggleButton onClick={toggleTheme}>
            {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          </ToggleButton> */} 
          <Close id="close-btn" onClick={() => setIsOpen(false)}>
          <IoMdCloseCircle style={{ fontSize: '34px', fontWeight: '500' }} />
          </Close>
          
        </Top>

          <SidebarLink component={Link} to="/admin/users">
          <FaUsers style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Users</h3>
          </SidebarLink>
          {/* Other SidebarLinks */}
          <SidebarLink component={Link} to="/admin/transactions">
          <GrTransaction style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Transactions</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/posts">
          <MdOutlinePostAdd style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Posts</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/post">
          <FaUpload style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Upload New Post</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/credit">
          <BsCreditCard2FrontFill style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Credit User</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/posts/review/requests">
          <MdRateReview style={{ color: 'purple', fontSize: '24px', fontWeight: '500' }} />
            <h3>Posts Review Requests</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/foreign/transfer/receipts">
          <FaMoneyBillTransfer style={{ color: 'green', fontSize: '24px', fontWeight: '500' }} />
            <h3>Foreign Transfer Receipts</h3>
          </SidebarLink>
          
          <SidebarLink component={Link} to="/admin/withdrawals-requests">
          <BiMoneyWithdraw style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Withdrawal Request</h3> 
          </SidebarLink>
          <SidebarLink href="/admin/sales">
          <FcSalesPerformance style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Sales</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/analyticsAdmin">
          <IoMdAnalytics style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Analytics</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/search/post/ticket/admin">
          <IoMdAnalytics style={{ color: 'blue', fontSize: '24px', fontWeight: '500' }} />
            <h3>Search Ticket</h3>
          </SidebarLink> 
          <SidebarLink component={Link} to="/admin/create/ad">
          <MdCampaign style={{ color: 'green', fontSize: '24px', fontWeight: '500' }} />
            <h3>Create Ads</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/manage/ads/admin">
          <HiClipboardDocumentList style={{ color: 'green', fontSize: '24px', fontWeight: '500' }} />
            <h3>Manage Ads</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/manage/ads/requests">
          <HiClipboardDocumentList style={{ color: 'green', fontSize: '24px', fontWeight: '500' }} />
            <h3>Ads Requests</h3>
          </SidebarLink>
          <ToggleButton onClick={toggleTheme}>
            {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          </ToggleButton>
          <SidebarLink component={Link} to="/admin/become/creator/requests">
          <FaWpforms style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Creator Requests</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/">
          <IoIosSend style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Send Notification</h3>
          </SidebarLink>
          <SidebarLink component={Link} to="/admin/settings">
          <IoMdSettings style={{ color: 'black', fontSize: '24px', fontWeight: '500' }} />
            <h3>Settings</h3>
          </SidebarLink>
          <SidebarLink onClick={handleLogout}>
          <IoLogOutSharp style={{ color: 'red', fontSize: '24px', fontWeight: '500' }} />
            <h3>Logout</h3>
          </SidebarLink>
          {/* </DropdownMenu>   */}
        </Sidebar>
      </Aside>
   
    

      <Main>
      <h1>Dashboard</h1>

      <FilterDiv>
        <label>Filter:</label>
        <select onChange={handleFilterChange} value={filter}>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="last-week">Last Week</option>
          <option value="last-month">Last Month</option>
          <option value="custom">Custom</option>
        </select>

        {filter === 'custom' && (
          <DatePickerDiv>
            <label>Start Date:</label>
            <DatePicker selected={customStartDate} onChange={handleCustomStartDateChange} />
            <label>End Date:</label>
            <DatePicker selected={customEndDate} onChange={handleCustomEndDateChange} />
          </DatePickerDiv>
        )}
      </FilterDiv>

      <InsightsContainer className="insights">
        <div className="sales">
        <IoMdAnalytics style={{ color: 'green', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
              <h1>₦{formatWallet(counts?.totalEarnings)}</h1>
              <h3>Total Earnings</h3>
            </div>
          </div>
        </div>

        <div className="expenses">
        <FcSalesPerformance style={{ color: 'yellow', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
              <h1>{counts.homepageViews}</h1>
              <h3>Total Visits</h3>
            </div>
          </div>
        </div>

        <div className="income">
        <BiMoneyWithdraw style={{ color: 'black', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
             <h1>₦{formatWallet(counts?.totalTopUp)}</h1>
              <h3>Total TopUp</h3>
            </div>
          </div>
        </div>

        <div className="sales">
        <Link to="/admin/users" className="expenses" style={{ textDecoration: 'none' }}>
        <FaPeopleGroup style={{ color: 'blue', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
            <h1>{counts.totalUsers}</h1>
              <h3>Total Users</h3>
            </div>
          </div>
          </Link>
        </div>

        <div className="sales">
        <FaRegMoneyBillAlt style={{ color: 'blue', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
            <h1>₦{formatWallet(counts?.totalUsersWallet)}</h1>
              <h3>Users Wallet</h3>
            </div>
          </div>
        </div>

        <div className="income">
        <BiMoneyWithdraw style={{ color: 'black', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
             <h1>{counts.pendingReceipts}</h1>
              <h3>PendingReceipts</h3>
            </div>
          </div>
        </div>

        <div className="income">
        <BiMoneyWithdraw style={{ color: 'black', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
             <h1>{counts.approvedReceipts}</h1>
              <h3>Approved Receipts</h3>
            </div>
          </div>
        </div>
        

        <div className="expenses">
        <FaFolder style={{ color: 'yellow', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
            <h1>{counts.totalPosts}</h1>
              <h3>Total Posts</h3>
            </div>
          </div>
        </div>

        <div className="income">
        <FaRegMoneyBillAlt style={{ color: 'green', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
              <h1>{counts.totalTransactionSuccess}</h1>
              <h3>Success Transaction</h3>
            </div>
          </div>
        </div>

        <div className="income">
        <FaRegMoneyBillAlt style={{ color: 'green', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
              <h1>{counts.totalTransactionFailed}</h1>
              <h3>Failed Transaction</h3>
            </div>
          </div>
        </div>

        <div className="income">
        <FaRegMoneyBillAlt style={{ color: 'green', fontSize: '40px', fontWeight: '500' }} />
          <div className="middle">
            <div className="left">
              <h1>{counts.totalTransactions}</h1>
              <h3>Total Transaction</h3>
            </div>
          </div>
        </div>

        <div>
        <Link to="/admin/analyticsAdmin" className="expenses" style={{ textDecoration: 'none' }}>  
        <BiMoneyWithdraw style={{ color: 'black', fontSize: '40px', fontWeight: '500' }} />
          <div>
            <div>
              <h1>Analytics</h1>
              <h3>Show All</h3>
            </div>
          </div>
          </Link>
        </div>
      </InsightsContainer>

      {/* <Analytics /> */}
    </Main> 
  
      
  
    </Container>
    </ThemeProvider>
  );
};

export default Dashboard;
