import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { API_URL, baseURL } from '../../config';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

const ModalContainer = styled.div`
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 1000;
    // width: 80%;
    // max-width: 600px;
    // background: ${props => props.theme.colors.background2};
    // border: 1px solid ${props => props.theme.colors.border};
    // border-radius: 8px;
    // padding: 20px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
     overflow-y: auto;
`;

const FormGroup = styled.div`
    margin: 15px 0px;
`;

const FormGroupUser = styled.div`
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.colors.text};
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.text};
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: ${props => props.theme.colors.background}; 
  color: ${props => props.theme.colors.text};
`;

// const Textarea = styled.textarea`
//   width: 100%;
//   padding: 8px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
//   resize: vertical;
// `;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background}; 
    color: ${props => props.theme.colors.text};
    font-size: 16px;
    resize: vertical;
`;

const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    background-color: ${props => props.theme.colors.primary};
    color: white;
    width: 100%;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
        background-color: ${props => props.theme.colors.primaryHover};
    }
`;

const DeleteButton = styled(Button)`
    background-color: red;
    &:hover {
        background-color: darkred;
    }
`;

const ImagesContainer = styled.div`
  margin-bottom: 20px;
`;

const AdRequestDetails = ({ ad, onClose, onCloseDropdown, refresh }) => {
    const [status, setStatus] = useState(ad.status);
    const [activeStatus, setActiveStatus] = useState(ad.active_status);
    const [message, setMessage] = useState(ad.message);
    const [accessType, setAccessType] = useState(ad.access_type);
    const [videoLink, setVideoLink] = useState(ad.video_link);
    const [description, setDescription] = useState(ad.description);
    const [adsCategoryId, setAdsCategoryId] = useState(ad.ads_category_id);
    const [adsType, setAdsType] = useState(ad.ads_type);
    const [phone, setPhone] = useState(ad.phone);
    const [state, setState] = useState(ad.state);
    const [localGovernment, setLocalGovernment] = useState(ad.local_government);
    const [area, setArea] = useState(ad.area);
    const [address, setAddress] = useState(ad.address);
    const [price, setPrice] = useState(ad.price);
    const [isNegotiable, setIsNegotiable] = useState(ad.isNegotiable);
    const [videoFile, setVideoFile] = useState(null); // If handling video uploads
    const [isUpdating, setIsUpdating] = useState(false);

    const navigate = useNavigate();

    // Convert the images string to an array
    const imagesArray = ad.images ? ad.images.split(',') : [];

    const handleUpdate = async () => {
        setIsUpdating(true);
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                };                
                const formData = new FormData();
                formData.append('id', ad.id);
                formData.append('active_status', activeStatus);
                formData.append('status', status);
                formData.append('message', message);
                formData.append('access_type', accessType);
                formData.append('video_link', videoLink);
                formData.append('description', description);
                formData.append('ads_category_id', adsCategoryId);
                formData.append('ads_type', adsType);
                formData.append('phone', phone);
                formData.append('state', state);
                formData.append('local_government', localGovernment);
                formData.append('area', area);
                formData.append('address', address);
                formData.append('price', price);
                formData.append('isNegotiable', isNegotiable);

                // If handling video file uploads
                if (videoFile) {
                    formData.append('video', videoFile);
                }

                const response = await axios.put(`${API_URL}/admin/ads/updateStatus`, formData, config);
                refresh();
                onClose();
                onCloseDropdown();
            } catch (error) {
                console.error('Error updating ad:', error);
            } finally {
                setIsUpdating(false);
            }
        }
    };

    const handleDelete = async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                await axios.delete(`${API_URL}/admin/ad/delete/${ad.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                refresh();
                onClose();
            } catch (error) {
                console.error('Error deleting ad:', error);
            }
        }
    };

    const handleFileChange = (e) => {
        setVideoFile(e.target.files[0]);
    };

    return (
        <>
            <ModalContainer>
                <h2>Ad Details</h2>
                <FormGroup>
                    <Label>Title</Label>
                    <p>{ad.title}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Username of the owner</Label>
                    <p>{ad.owner_username}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Email of the owner</Label>
                    <p>{ad.owner_email}</p>
                </FormGroup>
                <FormGroup>
                    <Label>Phone of the owner</Label>
                    <p>{ad.owner_phone}</p>
                </FormGroup>
                <FormGroup>
                    <img src={`${baseURL}${ad.thumbnail}`} alt='Receipt' width={'100%'} height={'100%'} />
                </FormGroup>
                <FormGroup>
                    <ImagesContainer>
                        <Carousel showThumbs={false} infiniteLoop autoPlay>
                            {imagesArray.map((image, index) => (
                                <div key={index}>
                                    <img key={index} src={`${baseURL}${image}`} alt="Ad" />
                                </div>
                            ))}
                        </Carousel>
                    </ImagesContainer>
                </FormGroup>
                <FormGroup>
                    <Label>Video Link (Optional)</Label>
                    <Input
                        type="url"
                        name="video_link"
                        value={videoLink}
                        onChange={(e) => setVideoLink(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Textarea
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        rows="4"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Ad Category</Label>
                    <Select
                        name="ads_category_id"
                        value={adsCategoryId}
                        onChange={(e) => setAdsCategoryId(e.target.value)}
                        required
                    >
                        <option value="1">Electronics</option>
                        <option value="2">Vehicles</option>
                        <option value="3">Real Estate</option>
                        <option value="4">Services</option>
                        <option value="5">Jobs</option>
                        <option value="6">Furniture</option>
                        <option value="7">Fashion</option>
                        <option value="8">Health & Beauty</option>
                        <option value="9">Sports & Outdoors</option>
                        <option value="10">Pets</option>
                        <option value="11">Books & Media</option>
                        <option value="12">Home & Garden</option>
                        <option value="13">Toys & Games</option>
                        <option value="14">Food & Beverages</option>
                        <option value="15">Automotive</option>
                    </Select>
                </FormGroup>
                <FormGroup>
                    <Label>Access Type</Label>
                    <Select value={accessType} onChange={(e) => setAccessType(e.target.value)}>
                        <option value="1-week">1 Week</option>
                        <option value="2-weeks">2 Weeks</option>
                        <option value="3-weeks">3 Weeks</option>
                        <option value="1-month">1 Month</option>
                        <option value="2-months">2 Months</option>
                        <option value="3-months">3 Months</option>
                        <option value="4-months">4 Months</option>
                        <option value="5-months">5 Months</option>
                        <option value="6-months">6 Months</option>
                        <option value="1-year">1 Year</option>
                    </Select>
                </FormGroup>
                <FormGroup>
                    <Label>Ads Type</Label>
                    <Select
                        name="ads_type"
                        value={adsType}
                        onChange={(e) => setAdsType(e.target.value)}
                        required
                    >
                        <option value="mainApp">Main App</option>
                        <option value="kasuwaSection">Kasuwa Section</option>
                        <option value="player">Player</option>
                        <option value="navigating">Navigating</option>
                    </Select>
                </FormGroup>
                {adsType === 'player' && (
                    <FormGroup>
                        <Label>Video (Optional)</Label>
                        <Input
                            type="file"
                            name="video"
                            accept="video/*"
                            onChange={handleFileChange}
                            style={{ display: adsType === 'player' ? 'block' : 'none' }}
                        />
                    </FormGroup>
                )}
                <FormGroup>
                    <Label>Contact Phone</Label>
                    <Input
                        type="tel"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label>State</Label>
                    <Input
                        type="text"
                        name="state"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Local Government</Label>
                    <Input
                        type="text"
                        name="local_government"
                        value={localGovernment}
                        onChange={(e) => setLocalGovernment(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Area</Label>
                    <Input
                        type="text"
                        name="area"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Address</Label>
                    <Input
                        type="text"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Price</Label>
                    <div>
                        <div style={{gap: '20px', display: 'flex', margin: '10px'}}>
                        <div>
                        <input
                            type="radio"
                            name="priceOption"
                            value="price"
                            checked={!isNegotiable}
                            onChange={() => setIsNegotiable(false)}
                        /> Price
                        </div>
                        <div>
                        <input
                            type="radio"
                            name="priceOption"
                            value="isNegotiable"
                            checked={isNegotiable}
                            onChange={() => setIsNegotiable(true)}
                            /> Negotiable
                        </div>
                        </div>
                        <Input
                            type="number"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                            style={{ display: !isNegotiable ? 'block' : 'none' }}
                        />
                    </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Amount To Spend</Label>
                            <Input 
                                value={ad.amount_to_spend}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Status</Label>
                            <Select value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="public">Public</option>
                                <option value="private">Private</option>
                                <option value="scheduled">Scheduled</option>
                                <option value="pending">Pending</option>
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label>Approval Status</Label>
                            <Select value={activeStatus} onChange={(e) => setActiveStatus(e.target.value)}>
                                <option value="active">Approve</option>
                                <option value="inactive">UnApprove</option>
                                <option value="waiting">Waiting</option>
                                <option value="rejected">Rejected</option>
                                <option value="blocked">Blocked</option>
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label>Reason Message</Label>
                            <Textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                rows="3"
                            />
                        </FormGroup>
                        <ButtonsDiv>
                            <Button onClick={handleUpdate} disabled={isUpdating}>
                                {isUpdating ? 'Updating...' : 'Update Ad'}
                            </Button>
                            <DeleteButton onClick={handleDelete}>
                                Delete Ad
                            </DeleteButton>
                            <Button onClick={onClose}>Close</Button>
                        </ButtonsDiv>
                    </ModalContainer>
                </>
            );
        };
        
export default AdRequestDetails;
        

