import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { API_URL, baseURL } from '../../config';
import { getValidToken } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import EditPost from './EditPost'; 

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Post = styled.div`
  background-color: ${props => props.theme.colors.background2};
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const Thumbnail = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
`;

const PostTitle = styled.h3`
  margin-top: 15px;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.text};
`;

const PostContent = styled.p`
  color: ${props => props.theme.colors.text};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start; //flex-end;
  margin-top: 15px;
`;

const Button = styled.button`
  //margin-left: 10px;
  padding: 10px 10px;
  gap: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:first-child {
    background-color: #28a745;

    &:hover {
      background-color: #218838;
    }
  }

  &:nth-child(2) {
    background-color: #ffc107;

    &:hover {
      background-color: #e0a800;
    }
  }

  &:last-child {
    background-color: #dc3545;

    &:hover {
      background-color: #c82333;
    }
  }
`;

const PostsReviewRequests = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        };
        const response = await axios.get(`${API_URL}/review-requests`, config);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    }
  };

  const updateStatus = async (postId, status) => {
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        };
        await axios.put(`${API_URL}/update-status/${postId}`, { active_status: status }, config);
        fetchPosts(); // Refresh the list after updating
      } catch (error) {
        console.error('Error updating post status:', error);
      }
    }
  };

  const openEditPost = (postId) => {
    navigate(`/admin/posts/edit-post/${postId}`);
  };

  return (
    <Container>
      <h2>Posts Review Requests</h2>
      {posts.length === 0 ? (
        <p>No posts to review.</p>
      ) : (
        posts.map((post) => (
          <Post key={post.id} onClick={() => openEditPost(post.id)}>
            <Thumbnail src={`${baseURL}${post.thumbnail}`} alt='Thumbnail' />
            <PostTitle>{post.title}</PostTitle>
            {/* <PostContent>{post.content}</PostContent> */}
            <ButtonGroup>
              <Button onClick={(e) => { e.stopPropagation(); updateStatus(post.id, 'active'); }}>Approve</Button>
              <Button onClick={(e) => { e.stopPropagation(); updateStatus(post.id, 'inactive'); }}>Pending</Button>
              <Button onClick={(e) => { e.stopPropagation(); updateStatus(post.id, 'waiting'); }}>Waiting</Button>
            </ButtonGroup>
          </Post>
        ))
      )}
    </Container>
  );
};

export default PostsReviewRequests;

