import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; // Adjust the import as necessary
import { API_URL } from '../../config'; // Adjust the import as necessary
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingIcon, SuccessIcon, ErrorIcon } from './styles';

const FormField = styled.div`
    margin-bottom: 20px;
`;


const Label = styled.label`
    display: block;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
`;

const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const Styledhr = styled.hr`
    margin: 15px 0;
    color: ${props => props.theme.colors.text};
`;

export {FormField, Label, Input, TextArea, Select, ButtonsDiv, Button, Styledhr};


const RequestForm = ({ request, onClose, refresh, refreshCounts }) => {
    const [isloading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(request.status);
    const [message, setMessage] = useState(request.message || '');
    const [reason, setReason] = useState(request.reason || '');
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleUpdateStatus = async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                await axios.post(`${API_URL}/author/request/updateStatus`, {
                    id: request.id,
                    status,
                    message,
                }, config);

                setMessage('');
                setError('');
                

                setLoginSuccess(true);
                setIsLoading(false);

            setTimeout(() => {
                setLoginSuccess(false);
                onClose();
                refresh();
                refreshCounts();
            }, 2000); 
            } catch (error) {
                console.error('Error updating request:', error);
                setError(error.message);
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            }
        }
    };

    
    return (
        <div>
            <FormField>
                <Label>Username</Label>
                <Input type="text" value={request.username} readOnly />
            </FormField>
            <FormField>
                <Label>Phone</Label>
                <Input type="text" value={request.phone} readOnly />
            </FormField>
            <FormField>
                <Label>Email</Label>
                <Input type="text" value={request.email} readOnly />
            </FormField>
            <FormField>
                <Label>Author Type</Label>
                <Input type="text" value={request.author_type} readOnly />
            </FormField>
            <FormField>
                <Label>Youtube Channel</Label>
                <Input type="text" value={request.youtube_channel} readOnly />
            </FormField>
            <FormField>
                <Label>Social Media Handle</Label>
                <Input type="text" value={request.social_media_handle} readOnly />
            </FormField>
            <FormField>
                    <Label>Reason</Label>
                    <TextArea rows="5" value={reason} onChange={e => setReason(e.target.value)} />
                </FormField>
            <FormField>
                <Label>Bank Name</Label>
                <Input type="text" value={request.main_bank_name} readOnly /> 
            </FormField>
            <FormField>
                <Label>Account Number</Label>
                <Input type="text" value={request.main_ac_no} readOnly />
            </FormField>
            <FormField>
                <Label>Account Name</Label>
                <Input type="text" value={request.main_ac_name} readOnly />
            </FormField>      
            <FormField>
                <Label>Message</Label>
                <TextArea rows="5" value={message} onChange={e => setMessage(e.target.value)} />
            </FormField>
            <FormField>
                <Label>Status</Label>
                <Select value={status} onChange={e => setStatus(e.target.value)}>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                    <option value="approved">Approved</option>
                </Select>
            </FormField>
            <ButtonsDiv>
            <Button onClick={handleUpdateStatus} disabled={isloading || loginSuccess}>
                
                {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Update Status'}
                {isloading && <LoadingIcon />}
            </Button>
            <Button onClick={onClose} style={{ marginTop: '10px', background: 'red' }}>Close</Button>
            </ButtonsDiv>
        </div>
    );
};

export default RequestForm;
