import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { baseURL, API_URL } from '../config';
import { Link, useNavigate } from 'react-router-dom';
import UpdateAdsForm from './UpdateAdsForm';
import RenewAdsForm from './RenewAdsForm';

import { MdCancel, MdAdd, MdClose } from 'react-icons/md';
import { FaSearch } from 'react-icons/fa';
import { IoMdOptions } from "react-icons/io";
import styled, { keyframes } from 'styled-components';
import Spinner from './Spinner';

const BgBig = styled.div`
  background-color: ${(props) => props.theme.colors.background};
`;

const MainContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const StyledH2 = styled.h2`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
`;

const CancelIcon = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAddButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Icon = styled.div`
  cursor: pointer;
`;

const CenterSearchAndCreateButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const NoPostsMessage = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  margin-top: 20px;
`;

const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background2};
  @media (max-width: 768px) {
    //flex-direction: column;
    align-items: center;
  }
`;

const ThumbnailDiv = styled.div`
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
`;

const PostDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;

const PostTitle = styled.h3`
  font-size: 20px;
  margin: 0;
  color: ${(props) => props.theme.colors.text};
`;

const PostAnalysis = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  p {
    margin: 0;
  }
  time {
    font-size: 14px;
    color: ${(props) => props.theme.colors.subText};
  }
`;

const StatusDiv = styled.div`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) => {
    if (props.status === 'public') return '#d4edda';
    if (props.status === 'private') return '#fff3cd';
    if (props.status === 'pending') return '#f8d7da';
    return '#f0f0f0';
  }};
  color: ${(props) => {
    if (props.status === 'public') return '#155724';
    if (props.status === 'private') return '#856404';
    if (props.status === 'pending') return '#721c24';
    return '#333';
  }};
`;

const ActiveStatusAndEllipsesDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const AdminActiveStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) => {
    if (props.adminStatus === 'active') return '#d4edda';
    if (props.adminStatus === 'inactive') return '#fff3cd';
    if (props.adminStatus === 'blocked') return '#f8d7da';
    return '#f0f0f0';
  }};
  color: ${(props) => {
    if (props.adminStatus === 'active') return '#155724';
    if (props.adminStatus === 'inactive') return '#856404';
    if (props.adminStatus === 'blocked') return '#721c24';
    return '#333';
  }};
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledP = styled.p`
  color: ${(props) => props.theme.colors.text};
`;

const StyledTime = styled.time`
  color: ${(props) => props.theme.colors.text};
`;

const Ellipses = styled.div`
  cursor: pointer;
  font-size: 24px;
  font-weight: 800;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: #007bff;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  background: white;
  width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.colors.dark};
  padding: 10px;
`;

const CloseIcon = styled.div`
  cursor: pointer;
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: #ff0000;
  }
`;

const DropdownOption = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
`;

const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: ${props => (props.postIsActive ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 6px;
    height: 6px;
  }
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

// const ManageAds = () => {
//     const [isLoading, setIsLoading] = useState(true);
//     const [ads, setAds] = useState([]);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     const [perPage] = useState(50);
//     const [AdId, setAdId] = useState(null);
//     const [totalCount, setTotalCount] = useState(0);
//     const [dropdownAdId, setDropdownAdId] = useState(null);
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const dropdownRef = useRef(null);
//     const [searchVisible, setSearchVisible] = useState(false);
//     const [editAd, setEditAd] = useState(false);
//     const [renewAd, setRenewAd] = useState(false);
  
//     const navigate = useNavigate();
  
//     useEffect(() => {
//       const fetchPosts = async () => {
//         const token = await getValidToken(navigate, true); 
//         if (token) {
//           try {
//             const config = {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//               withCredentials: true,
//               params: { searchQuery, currentPage, perPage },
//             };
//             const response = await axios.get(`${API_URL}/my/ads/all`, config);
//             const { totalCount, ads } = response.data;
//             setTotalCount(totalCount);
//             setAds(ads);
//             setIsLoading(false);
//           } catch (error) {
//             setIsLoading(false);
//             console.error('Error fetching posts:', error);
//           }
//         }
//       };
//       fetchPosts();
//     }, [searchQuery, currentPage, perPage, navigate]);
  
//     const handleDropdownClick = (adId) => {
//       if (dropdownAdId === adId && isDropdownOpen) {
//         setIsDropdownOpen(false);
//         setDropdownAdId(null);
//       } else {
//         setIsDropdownOpen(true);
//         setDropdownAdId(adId);
//       }
//     };
  
//     const handleCloseDropdown = () => {
//       setDropdownAdId(null);
//       setIsDropdownOpen(false);
//     };
  
//     const handleEditAd = (AdId) => {
//       setDropdownAdId(null);
//       setIsDropdownOpen(false);
//       setAdId(AdId);
//       setEditAd(true);
//       setRenewAd(false);
//     };
  
//     const handleRenewAd = (AdId) => {
//       setDropdownAdId(null);
//       setIsDropdownOpen(false);
//       setAdId(AdId);
//       setEditAd(false);
//       setRenewAd(true);
//     };
  
//     const handleDelete = async (AdId) => {
//       const token = await getValidToken(navigate, true); 
//       if (token) {
//         if (window.confirm('Are you sure you want to delete this post?')) {
//           try {
//             await axios.delete(`${API_URL}/ads/${AdId}`, {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//               withCredentials: true,
//             }); 
//             setAds(ads.filter(ad => ad.id !== AdId)); 
//             setIsDropdownOpen(false);
//           } catch (error) {
//             setIsDropdownOpen(false);
//             console.error('Error deleting ad:', error);
//           }
//         }
//       }
//     };
  
//     const handleUpdateAdStatus = (AdId) => {
//       setDropdownAdId(null);
//       setIsDropdownOpen(false);
//       // Handle post status update
//     };
  
//     if (isLoading) {
//       return (
//         <MainContainer>
//           <Spinner />
//         </MainContainer>
//       );
//     }
  
//     const handleCloseEdit = () => {
//       setAdId(null);
//       setEditAd(false);
//       setRenewAd(false);
//     };
  
//     const handleSearch = () => {
//       setCurrentPage(1);
//     };
  
//     const handleSearchVisible = () => {
//       setSearchQuery('');
//       setSearchVisible(!searchVisible);
//     };
  
//     const scrollToTop = () => {
//       window.scrollTo({ top: 0, behavior: 'smooth' });
//     };
  
//     const handleNextPage = () => {
//       scrollToTop();
//       setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(totalCount / perPage)));
//     };
  
//     const handlePreviousPage = () => {
//       scrollToTop();
//       setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//     };
  
//     const handlePageClick = (pageNumber) => {
//       scrollToTop();
//       setCurrentPage(pageNumber);
//     };
  
//     const handleNewAd = () => {
//       navigate('/create/ad');
//     };
  
//     const handleGoBack = () => {
//       navigate('/');
//     };
  
//     const formatTimeAgo = (createdDate, scheduledAt = null) => {
//       const currentDate = new Date();
  
//       if (scheduledAt) {
//         const scheduledDate = new Date(scheduledAt);
//         const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
//         if (scheduledDate > currentDate) {
//           return `Will be posted on ${scheduledDate.toLocaleString('en-US', options)}`;
//         }
//       }
  
//       const timeDifference = currentDate - new Date(createdDate);
//       const secondsDifference = Math.floor(timeDifference / 1000);
//       const minutesDifference = Math.floor(secondsDifference / 60);
//       const hoursDifference = Math.floor(minutesDifference / 60);
//       const daysDifference = Math.floor(hoursDifference / 24);
//       const monthsDifference = Math.floor(daysDifference / 30);
//       const yearsDifference = Math.floor(monthsDifference / 12);
  
//       if (yearsDifference > 0) {
//         return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
//       } else if (monthsDifference > 0) {
//         return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
//       } else if (daysDifference > 0) {
//         return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
//       } else if (hoursDifference > 0) {
//         return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
//       } else if (minutesDifference > 0) {
//         return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
//       } else {
//         return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
//       }
//     };
  
//     const getDisplayStatus = (status, scheduledAt) => {
//       const now = new Date();
//       const scheduledDate = new Date(scheduledAt);
  
//       if (status === 'scheduled' && scheduledDate <= now) {
//         return 'public';
//       }
  
//       return status;
//     };
  
//     return (
//       <BgBig>
//         <Container>
//           <Title>
//             <StyledH1>My Ads</StyledH1>
//             <StyledH2>Total: {totalCount}</StyledH2>
//             <CancelIcon>
//               <StyledAddButton onClick={handleGoBack}>
//                 <MdCancel style={{ color: 'black', fontSize: '24px' }} />
//               </StyledAddButton>
//             </CancelIcon>
//           </Title>
  
//           {searchVisible && (
//             <InputBox>
//               <StyledInput
//                 label="Search"
//                 value={searchQuery}
//                 placeholder="Search..."
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 variant="outlined"
//               />
//               <Icon>
//                 <FaSearch variant="contained" style={{ color: 'black', fontSize: '24px' }} onClick={handleSearch} />
//               </Icon>
//             </InputBox>
//           )}
//           <CenterSearchAndCreateButton>
//             <AddButton onClick={handleNewAd}>
//               <MdAdd style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }} />
//               Create Ad
//             </AddButton>
//             <AddButton onClick={handleSearchVisible}>
//               <FaSearch style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }} />
//               {searchVisible ? 'Close Search' : 'Search'}
//             </AddButton>
//           </CenterSearchAndCreateButton>
  
//           {ads.length === 0 ? (
//             <NoPostsMessage>No ads to display</NoPostsMessage>
//           ) : editAd ? (
//             <UpdateAdsForm AdId={AdId} onClose={handleCloseEdit} />
//           ) : renewAd ? (
//             <RenewAdsForm AdId={AdId} onClose={handleCloseEdit} />
//           ) : (
//             ads.map((ad) => (
//               <Card key={ad.id}>
//                 <ThumbnailDiv>
//                   <img src={`${baseURL}/media/${ad.thumbnail}`} alt={ad.title} />
//                 </ThumbnailDiv>
  
//                 <PostDetailsDiv>
//                   <PostTitle>{ad.title}</PostTitle>
//                   <PostAnalysis>
//                     <StyledP>Analysis: {ad.description}</StyledP>
//                     <StatusDiv>
//                       <StyledP>Status: {getDisplayStatus(ad.status, ad.scheduledAt)}</StyledP>
//                       <StyledTime>{formatTimeAgo(ad.createdAt, ad.scheduledAt)}</StyledTime>
//                     </StatusDiv>
//                   </PostAnalysis>
//                 </PostDetailsDiv>
  
//                 <ActiveStatusAndEllipsesDiv>
//                   <AdminActiveStatusDiv>
//                     {ad.audio && <AudioIndicator />}
//                   </AdminActiveStatusDiv>
//                   <Ellipses>
//                     <IoMdOptions onClick={() => handleDropdownClick(ad.id)} />
//                   </Ellipses>
  
//                   {isDropdownOpen && dropdownAdId === ad.id && (
//                     <>
//                       <Overlay onClick={handleCloseDropdown} />
//                       <DropdownMenu ref={dropdownRef}>
//                         <DropdownHeader>
//                           <CloseIcon>
//                             <MdClose onClick={handleCloseDropdown} />
//                           </CloseIcon>
//                         </DropdownHeader>
  
//                         <DropdownOption onClick={() => handleEditAd(ad.id)}>Edit</DropdownOption>
//                         <DropdownOption onClick={() => handleRenewAd(ad.id)}>Renew</DropdownOption>
//                         <DropdownOption onClick={() => handleUpdateAdStatus(ad.id)}>
//                           {ad.status === 'active' ? 'Deactivate' : 'Activate'}
//                         </DropdownOption>
//                         <DropdownOption onClick={() => handleDelete(ad.id)}>Delete</DropdownOption>
//                       </DropdownMenu>
//                     </>
//                   )}
//                 </ActiveStatusAndEllipsesDiv>
//               </Card>
//             ))
//           )}
  
//           {totalCount > perPage && (
//             <PaginationWrapper>
//               <Pagination>
//                 <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
//                   Previous
//                 </PaginationButton>
//                 {[...Array(Math.ceil(totalCount / perPage)).keys()].map((_, index) => (
//                   <PageNumber
//                     key={index}
//                     onClick={() => handlePageClick(index + 1)}
//                     active={index + 1 === currentPage}
//                   >
//                     {index + 1}
//                   </PageNumber>
//                 ))}
//                 <PaginationButton
//                   onClick={handleNextPage}
//                   disabled={currentPage === Math.ceil(totalCount / perPage)}
//                 >
//                   Next
//                 </PaginationButton>
//               </Pagination>
//             </PaginationWrapper>
//           )}
//         </Container>
//       </BgBig>
//     );
//   };
  
//   export default ManageAds;



const ManageAds = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [ads, setAds] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(50);
  const [AdId, setAdId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownAdId, setDropdownAdId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [editAd, setEditAd] = useState(false);
  const [renewAd, setRenewAd] = useState(false);
  const [deletingIsLoading, setDeletingIsLoading] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    const fetchAds = async () => {
      const token = await getValidToken(navigate, true); 
      if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
          params: { searchQuery, currentPage, perPage },
        };
        const response = await axios.get(`${API_URL}/my/ads/all`, config);
        const { totalCount, ads } = response.data;
        setTotalCount(totalCount);
        setAds(ads);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching posts:', error);
      }
    }
    };
    fetchAds();
  }, [searchQuery, currentPage, perPage, navigate]);

  const handleDelete = async (AdId) => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    if (window.confirm('Are you sure you want to delete this post?')) {
      setDeletingIsLoading(true);
      try {
        await axios.delete(`${API_URL}/ads/${AdId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }); 
        setAds(ads.filter(ad => ad.id !== AdId)); 
        setIsDropdownOpen(false);
      } catch (error) {
        setIsDropdownOpen(false);
        console.error('Error deleting ad:', error);
      } finally {
        setDeletingIsLoading(false)
      }
    }
  }
  };

  const handleDropdownClick = (adId) => {
    if (dropdownAdId === adId && isDropdownOpen) {
      setIsDropdownOpen(false);
      setDropdownAdId(null);
    } else {
      setIsDropdownOpen(true);
      setDropdownAdId(adId);
    }
  };

  const handleCloseDropdown = () => {
    setDropdownAdId(null);
    setIsDropdownOpen(false);
  };

  const handleUpdateAdStatus = (AdId) => {
    setDropdownAdId(null);
    setIsDropdownOpen(false);
    // Handle post status update
  };


  if (isLoading) {
    return (
      <MainContainer>
        <Spinner />
      </MainContainer>
    );
  }

  const handleEditAd = (adId) => {
    setDropdownAdId(null);
    setIsDropdownOpen(false);
    setEditAd(true);
    setRenewAd(false);
    navigate(`/ad/update/${adId}`, { state: { adId } });
  };
  
  const handleRenewAd = (adId) => {
    setDropdownAdId(null);
    setIsDropdownOpen(false);
    setAdId(adId);
    setEditAd(false);
    setRenewAd(true);
  };

  const handleCloseEdit = () => {
    setAdId(null);
    setEditAd(false);
    setRenewAd(false);
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  const handleSearchVisible = () => {
    setSearchQuery('');
    setSearchVisible(!searchVisible)
  }


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNextPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(totalCount / perPage)));
  };

  const handlePreviousPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    scrollToTop();
    setCurrentPage(pageNumber);
  };

  const handleNewAd = () => {
    navigate('/create/ad');
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const formatTimeAgo = (createdDate, scheduledAt = null) => {
    const currentDate = new Date();
  
    // Check if the post is scheduled for the future
  if (scheduledAt) {
    const scheduledDate = new Date(scheduledAt);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    if (scheduledDate > currentDate) {
      return `Will be posted on ${scheduledDate.toLocaleString('en-US', options)}`;
    }
  }

    const timeDifference = currentDate - new Date(createdDate);
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(monthsDifference / 12);
  
    if (yearsDifference > 0) {
      return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
    } else if (monthsDifference > 0) {
      return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
    } else if (daysDifference > 0) {
      return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
    }
  };
  

  const getDisplayStatus = (status, scheduledAt) => {
    const now = new Date();
    const scheduledDate = new Date(scheduledAt);
  
    // If the post is scheduled and the scheduled date is in the past
    if (status === 'scheduled' && scheduledDate <= now) {
      return 'public';
    }
  
    // Otherwise return the original status
    return status;
  };

  // Function to check if the ad is expired
const isAdExpired = (endDate) => {
  if (!endDate) {
    // If endDate is null or undefined, the ad is not expired
    return false;
  }

  const currentTime = new Date();
  return new Date(endDate) < currentTime;
};

  
  return (
    <BgBig>
      <Container>
        <Title>
          <StyledH1>My Ads</StyledH1>
          <StyledH2>Total: {totalCount}</StyledH2>
          <CancelIcon>
            <StyledAddButton onClick={handleGoBack}>
              <MdCancel style={{ color: 'black', fontSize: '24px' }} />
            </StyledAddButton>
          </CancelIcon>
        </Title>

        {searchVisible && (
          <InputBox>
            <StyledInput
              label="Search"
              value={searchQuery}
              placeholder="Search..."
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
            />
            <Icon>
              <FaSearch variant="contained" style={{ color: 'black', fontSize: '24px' }} onClick={handleSearch} />
            </Icon>
          </InputBox>
        )}
        <CenterSearchAndCreateButton>
          <AddButton onClick={handleNewAd}>
            <MdAdd style={{ color: 'white', fontSize: '24px', fontWeight: '500' }} /> New Ad
          </AddButton>
          <AddButton onClick={handleSearchVisible}>
            {searchVisible ? 'Hide Search' : 'Show Search'}
          </AddButton>
        </CenterSearchAndCreateButton>

        {ads.length === 0 ? (
          <NoPostsMessage>No Ads available. Create a new one!</NoPostsMessage>
        ) : (
          ads.map((ad) => {
            const expired = isAdExpired(ad.end_date);

            return (
              <Card key={ad.id}>
                <ThumbnailDiv onClick={() => handleEditAd(ad.id)}>
                  {ad.thumbnail && <img src={`${baseURL}${ad.thumbnail}`} alt="Thumbnail" />}
                </ThumbnailDiv>

                <PostDetailsDiv>
                  <PostTitle onClick={() => handleEditAd(ad.id)}>{ad.title}</PostTitle>

                  <PostAnalysis>
                    <StyledP>Price: N{ad.price}</StyledP>
                    {expired ? (
                      <StatusDiv style={{ backgroundColor: 'red', color: 'white' }}>
                        Expired
                      </StatusDiv>
                    ) : (
                      <StatusDiv status={getDisplayStatus(ad.status, ad.scheduled_at)}>
                        {getDisplayStatus(ad.status, ad.scheduled_at)}
                      </StatusDiv>
                    )}
                  </PostAnalysis>
                  <PostAnalysis>
                    <StyledP>Views: {ad.view_count}</StyledP>
                    <StyledTime>{formatTimeAgo(ad.created_at, ad.scheduled_at)}</StyledTime>
                  </PostAnalysis>
                  <PostAnalysis>
                    <StyledP>Contact Views: {ad.contact_views}</StyledP>
                    <StyledTime>Chats: {ad.chats_views}</StyledTime>
                  </PostAnalysis>
                  <ActiveStatusAndEllipsesDiv>
                    {expired ? (
                      <AdminActiveStatusDiv onClick={() => handleRenewAd(ad.id)} style={{ backgroundColor: 'red', color: 'white' }}>
                        Renew Ad
                      </AdminActiveStatusDiv>
                    ) : (
                      <AdminActiveStatusDiv adminStatus={ad.active_status}>
                        <p>{ad.active_status}</p>
                        {ad.active_status === 'active' && <AudioIndicator postIsActive={ad.active_status} />}
                      </AdminActiveStatusDiv>
                    )}

                    <Ellipses onClick={() => handleDropdownClick(ad.id)}>
                      <IoMdOptions />
                    </Ellipses>
                  </ActiveStatusAndEllipsesDiv>
                </PostDetailsDiv>

                {renewAd && (
                  <>
                    <ModalBackdrop onClick={handleCloseEdit} />
                    <FormModelDiv>
                      <RenewAdsForm adId={ad.id} />
                    </FormModelDiv>
                  </>
                )}

                <Actions ref={dropdownRef}>
                  {isDropdownOpen && dropdownAdId === ad.id ? (
                    <DropdownMenu onClick={(e) => e.stopPropagation()}>
                      <DropdownHeader>
                        <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
                      </DropdownHeader>
                      <DropdownOption onClick={() => handleEditAd(ad.id)}>Edit</DropdownOption>
                      {expired && (
                      <DropdownOption onClick={() => handleRenewAd(ad.id)}>Renew</DropdownOption> 
                      )}
                      <DropdownOption onClick={() => handleDelete(ad.id)}>Delete</DropdownOption>
                      <DropdownOption onClick={() => handleUpdateAdStatus(ad.id)}>Close</DropdownOption>
                    </DropdownMenu>
                  ) : null}
                </Actions>
                {deletingIsLoading && (
                  <b><p style={{alignItems: 'center', display: 'flex', justifyContent: 'center', margin: '10px'}}>Deleting...<Spinner /></p></b>
                )}
              </Card>
            );
          })
        )}

        {editAd && (
          <>
            <ModalBackdrop onClick={handleCloseEdit} />
            <FormModelDiv>
              <UpdateAdsForm />
            </FormModelDiv>
          </>
        )}

        <PaginationWrapper>
          <Pagination>
            <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </PaginationButton>
            {Array.from({ length: Math.ceil(totalCount / perPage) }, (_, index) => (
              <PageNumber
                key={index + 1}
                onClick={() => handlePageClick(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </PageNumber>
            ))}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / perPage)}>
              Next
            </PaginationButton>
          </Pagination>
        </PaginationWrapper>
      </Container>
    </BgBig>
  );
};

//   return (
//     <BgBig>
//       <Container>
//         <Title>
//           <StyledH1>My Ads</StyledH1>
//           <StyledH2>Total: {totalCount}</StyledH2>
//           <CancelIcon>
//             <StyledAddButton onClick={handleGoBack}>
//               <MdCancel style={{ color: 'black', fontSize: '24px' }} />
//             </StyledAddButton>
//           </CancelIcon>
//         </Title>

//         {searchVisible && (
//           <InputBox>
//             <StyledInput
//               label="Search"
//               value={searchQuery}
//               placeholder="Search..."
//               onChange={(e) => setSearchQuery(e.target.value)}
//               variant="outlined"
//             />
//             <Icon>
//               <FaSearch variant="contained" style={{ color: 'black', fontSize: '24px' }} onClick={handleSearch} />
//             </Icon>
//           </InputBox>
//         )}
//         <CenterSearchAndCreateButton>
//         <AddButton onClick={handleNewAd}>
//             <MdAdd style={{ color: 'white', fontSize: '24px', fontWeight: '500' }} /> New Ad
//           </AddButton>
//           <AddButton onClick={handleSearchVisible}>
//             {searchVisible ? 'Hide Search' : 'Show Search'}
//           </AddButton>
//         </CenterSearchAndCreateButton>

//         {ads.length === 0 ? (
//           <NoPostsMessage>No Ads available. Create a new one!</NoPostsMessage>
//         ) : (
//           ads.map((ad) => (
//             <Card key={ad.id}>
              
//                 <ThumbnailDiv onClick={() => handleEditAd(ad.id)}>
//                   {ad.thumbnail && <img src={`${baseURL}${ad.thumbnail}`} alt="Thumbnail" />}
//                 </ThumbnailDiv>

//               <PostDetailsDiv>
                
//                 <PostTitle onClick={() => handleEditAd(ad.id)}>{ad.title}</PostTitle>

//                 <PostAnalysis>
//                   <StyledP>Price: N{ad.price}</StyledP>
//                   {isAdExpired ? (
//                     <StatusDiv style={(backgroundColor: 'red')}>
//                       Expired
//                     </StatusDiv>
//                   ) : (
//                     <StatusDiv status={getDisplayStatus(ad.status, ad.scheduled_at)}>{getDisplayStatus(ad.status, ad.scheduled_at)}</StatusDiv> 
//                   )}
//                   {/* <StyledP>{ad.contact_views}</StyledP> */}
//                 </PostAnalysis>
//                 <PostAnalysis>
//                 <StyledP>Views: {ad.view_count}</StyledP>   
//                 <StyledTime>{formatTimeAgo(ad.created_at, ad.scheduled_at)}</StyledTime> 
//                 </PostAnalysis>
//                 <ActiveStatusAndEllipsesDiv>
//                   {isAdExpired ? (
//                     <AdminActiveStatusDiv onClick={() => handleRenewAd(ad.id)} style={(backgroundColor: 'red')}>
//                       Renew Ad
//                     </AdminActiveStatusDiv>
//                   ) : (
//                     <AdminActiveStatusDiv adminStatus={ad.active_status}>
//                     <p>{ad.active_status}</p> 
//                     {ad.active_status === 'active' && <AudioIndicator postIsActive={ad.active_status}/>}
//                   </AdminActiveStatusDiv>
//                   )}
                  
//                   <Ellipses onClick={() => handleDropdownClick(ad.id)}>
//                     <IoMdOptions />
//                   </Ellipses>
//                 </ActiveStatusAndEllipsesDiv>
//               </PostDetailsDiv>

//               {renewAd && (
//                 <>
//                   <ModalBackdrop onClick={handleCloseEdit} />
//                   <FormModelDiv>
//                     <RenewAdsForm adId={ad.id}/>
//                   </FormModelDiv>
//                 </>
//               )}

//               <Actions ref={dropdownRef}>
//                 {isDropdownOpen && dropdownAdId === ad.id ? (
//                   <DropdownMenu onClick={(e) => e.stopPropagation()}>
//                     <DropdownHeader>
//                       <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
//                     </DropdownHeader>
//                     <DropdownOption onClick={() => handleEditAd(ad.id)}>Edit</DropdownOption>
//                     <DropdownOption onClick={() => handleRenewAd(ad.id)}>Renew</DropdownOption>
//                     <DropdownOption onClick={() => handleDelete(ad.id)}>Delete</DropdownOption>
//                     <DropdownOption onClick={() => handleUpdateAdStatus(ad.id)}>Update Status</DropdownOption>
//                   </DropdownMenu>
//                 ) : null}
//               </Actions>
//             </Card>
//           ))
//         )}

//         {editAd && (
//           <>
//             <ModalBackdrop onClick={handleCloseEdit} />
//             <FormModelDiv>
//               {/* Pass adId as a prop or retrieve it from state */}
//               <UpdateAdsForm />
//             </FormModelDiv>
//           </>
//         )}

        

//         {/* {isDropdownOpen && <ModalBackdrop onClick={handleCloseEdit} />} */}

//       <PaginationWrapper>
//         <Pagination>
//           <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
//             Previous
//           </PaginationButton>
//           {Array.from({ length: Math.ceil(totalCount / perPage) }, (_, index) => (
//             <PageNumber
//               key={index + 1}
//               onClick={() => handlePageClick(index + 1)}
//               className={currentPage === index + 1 ? 'active' : ''}
//             >
//               {index + 1}
//             </PageNumber>
//           ))}
//           <PaginationButton onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / perPage)}>
//             Next
//           </PaginationButton>
//         </Pagination>
//       </PaginationWrapper>

//       </Container>
//       </BgBig>
//   );
// };

export default ManageAds;
