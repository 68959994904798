import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import styled, { keyframes, css } from 'styled-components';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeDown, FaForward, FaBackward, FaExpand } from 'react-icons/fa';
import Logo from '../assets/ArewaTv.png';

// Keyframes for rotation animation
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const AudioPlayerDiv = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 233px;
  background-color: #000000;
  border-radius: 10px;
  border: 2px solid ${props => props.theme.colors.text};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  gap: 15px;

  background-image: url(${props => props.backgroundImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    height: 320px;
  }

  @media (min-width: 1242px) {
    height: 420px;
  }
`;

const ControlsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
`;

const SliderAndDurationDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 5px 0;
`;

const Slider = styled.input`
  width: 100%;
  margin: 0 10px;
  appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-moz-range-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-ms-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const PlayPauseSeekDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
`;

const VolumeSpeedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  margin: 0 20px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const VolumeControl = styled.input`
  -webkit-appearance: none;
  width: 120px;
  height: 5px;
  background: ${props => props.theme.colors.primary};
  border-radius: 5px;
  outline: none;
  margin: 10px 10px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 50%;
    cursor: pointer;
  }
`;

const SpeedControl = styled.select`
  background: none;
  border: 1px solid ${props => props.theme.colors.text};
  color: #fff;
  font-size: 14px;
  margin: 10px 0;
  padding: 5px;
  cursor: pointer;
`;

const TimeDisplay = styled.span`
  color: white;
  margin: 0 10px;
`;

const LogoDiv = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 20;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TopRightLogo = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const LoadingLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 10;
  animation: ${rotate} 2s linear infinite;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const SpinningThumbnail = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  animation: ${props => (props.isplaying ? css`${rotate} 5s linear infinite` : 'none')};
  margin-bottom: 20px; /* Added margin for spacing */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AudioPlayer = ({ audioUrl, thumbnail }) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [logoVisible, setLogoVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const logoTimeoutRef = useRef(null);

  const handlePlayPause = () => {
    setPlaying(prev => !prev);
  };

  const showLogo = () => {
    setLogoVisible(true);
    clearTimeout(logoTimeoutRef.current);
    logoTimeoutRef.current = setTimeout(() => {
      setLogoVisible(false);
    }, 5000);
  };

  const handleProgress = (state) => {
    setProgress(state.played);
    const playedSeconds = state.playedSeconds;

    if (playedSeconds > 0 && playedSeconds < 5) {
      showLogo();
    }
    if (playedSeconds > duration * 0.5 && playedSeconds < duration * 0.5 + 5) {
      showLogo();
    }
    if (playedSeconds > duration * 0.8 && playedSeconds < duration * 0.8 + 5) {
      showLogo();
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleSeekChange = (e) => {
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleSpeedChange = (e) => {
    setPlaybackRate(parseFloat(e.target.value));
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <AudioPlayerDiv backgroundImage={thumbnail}>
      <ReactPlayer
        ref={playerRef}
        url={audioUrl}
        playing={playing}
        volume={volume}
        controls={false}
        playbackRate={playbackRate}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onBuffer={() => setLoading(true)}
        onBufferEnd={() => setLoading(false)}
        width="100px"
        height="1px"
      />

    <TopRightLogo visible={logoVisible}>
          <img src={Logo} alt="Logo" />
        </TopRightLogo>
        {!playing && (
          <LogoDiv onClick={handlePlayPause}>
            <img src={Logo} alt="Play Logo" />
          </LogoDiv>
        )}
        {loading && (
          <LoadingLogo visible={loading}>
            <img src={Logo} alt="Loading" />
          </LoadingLogo>
        )}

      <ControlsDiv>
        

        <SpinningThumbnail isplaying={playing}>
          <img src={thumbnail} alt="Thumbnail" />
        </SpinningThumbnail>

        <SliderAndDurationDiv>
          <TimeDisplay>{formatTime(progress * duration)}</TimeDisplay>
          <Slider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={progress}
            onChange={handleSeekChange}
          />
          <TimeDisplay>{formatTime(duration)}</TimeDisplay>
        </SliderAndDurationDiv>

        <PlayPauseSeekDiv>
          <ControlButton onClick={() => playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)}>
            <FaBackward />
          </ControlButton>
          <ControlButton onClick={handlePlayPause}>
            {playing ? <FaPause /> : <FaPlay />}
          </ControlButton>
          <ControlButton onClick={() => playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)}>
            <FaForward />
          </ControlButton>
        </PlayPauseSeekDiv>

        <VolumeSpeedDiv>
          <VolumeControl
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
          <SpeedControl value={playbackRate} onChange={handleSpeedChange}>
            <option value="0.5">0.5x</option>
            <option value="1">1x</option>
            <option value="1.5">1.5x</option>
            <option value="2">2x</option>
          </SpeedControl>
        </VolumeSpeedDiv>
      </ControlsDiv>
    </AudioPlayerDiv>
  );
};
export default AudioPlayer;



// import React, { useState, useRef, useEffect } from 'react';
// import styled from 'styled-components';
// import WaveSurfer from 'wavesurfer.js';

// const Container = styled.div`
//   position: relative;
//   width: 100%;
//   max-width: 600px;
//   margin: auto;
//   background: url(${props => props.thumbnail}) no-repeat center center/cover;
//   border-radius: 10px;
//   overflow: hidden;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const Controls = styled.div`
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   background: rgba(0, 0, 0, 0.7);
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px;
// `;

// const PlayButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
//   &:focus {
//     outline: none;
//   }
// `;

// const DownloadButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
//   &:focus {
//     outline: none;
//   }
// `;

// const Slider = styled.input`
//   width: 100%;
//   -webkit-appearance: none;
//   background: #ddd;
//   height: 5px;
//   border-radius: 5px;
//   outline: none;
//   &::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     appearance: none;
//     width: 15px;
//     height: 15px;
//     border-radius: 50%;
//     background: #4CAF50;
//     cursor: pointer;
//   }
//   &::-moz-range-thumb {
//     width: 15px;
//     height: 15px;
//     border-radius: 50%;
//     background: #4CAF50;
//     cursor: pointer;
//   }
// `;

// const Waveform = styled.div`
//   width: 100%;
//   height: 100px;
//   background: rgba(255, 255, 255, 0.3);
// `;

// const AudioPlayer = ({ audioPath, thumbnail }) => {
//   const [playing, setPlaying] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const waveformRef = useRef(null);
//   const waveSurferRef = useRef(null);

//   useEffect(() => {
//     console.log("Initializing WaveSurfer");

//     if (audioPath && waveformRef.current) {
//       waveSurferRef.current = WaveSurfer.create({
//         container: waveformRef.current,
//         waveColor: 'violet',
//         progressColor: 'purple',
//         barWidth: 2,
//         height: 100,
//       });

//       waveSurferRef.current.load(audioPath);

//       waveSurferRef.current.on('audioprocess', () => {
//         const currentTime = waveSurferRef.current.getCurrentTime();
//         const duration = waveSurferRef.current.getDuration();
//         setProgress((currentTime / duration) * 100);
//       });

//       waveSurferRef.current.on('finish', () => {
//         setPlaying(false);
//         setProgress(0);
//       });

//       return () => {
//         if (waveSurferRef.current) {
//           console.log("Destroying WaveSurfer");
//           waveSurferRef.current.destroy();
//           waveSurferRef.current = null;
//         }
//       };
//     }
//   }, [audioPath]);

//   const handlePlayPause = () => {
//     if (waveSurferRef.current) {
//       if (waveSurferRef.current.isPlaying()) {
//         waveSurferRef.current.pause();
//         setPlaying(false);
//       } else {
//         waveSurferRef.current.play();
//         setPlaying(true);
//       }
//     }
//   };

//   const handleSeek = (e) => {
//     const seekTime = (e.target.value / 100) * waveSurferRef.current.getDuration();
//     waveSurferRef.current.seekTo(seekTime / waveSurferRef.current.getDuration());
//     setProgress((seekTime / waveSurferRef.current.getDuration()) * 100);
//   };

//   const handleDownload = async () => {
//     try {
//       const response = await fetch(audioPath);
//       if (!response.ok) throw new Error('Network response was not ok');
//       const blob = await response.blob();
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement('a');
//       a.style.display = 'none';
//       a.href = url;
//       a.download = audioPath.split('/').pop();
//       document.body.appendChild(a);
//       a.click();
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error('Failed to download file:', error);
//     }
//   };

//   return (
//     <Container thumbnail={thumbnail}>
//       <Waveform ref={waveformRef} />
//       <Controls>
//         <PlayButton onClick={handlePlayPause}>
//           {playing ? 'Pause' : 'Play'}
//         </PlayButton>
//         <Slider
//           type="range"
//           min="0"
//           max="100"
//           value={progress}
//           onChange={handleSeek}
//         />
//         <DownloadButton onClick={handleDownload}>Download</DownloadButton>
//       </Controls>
//     </Container>
//   );
// };

// export default AudioPlayer;
