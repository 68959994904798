import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 0.9rem;
  padding: 10px;
  margin-bottom: 25px;
  background-color: ${props => props.theme.colors.background};

  > div {
    transition: all 300ms ease;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &:hover {
      box-shadow: none;
      border: 2px solid green;
    }

    &:active {
      box-shadow: none;
      border: 6px solid green;
    }

    span {
      background-color: ${props => props.theme.colors.primary};
      padding: 0.5rem;
      border-radius: 50%;
      color: ${props => props.theme.colors.white};
      font-size: 2rem;
    }

    h3 {
      margin: 1rem 0 0.6rem;
      font-size: 1.5rem;
      color: ${props => props.theme.colors.text};
    }

    h1 {
      color: ${props => props.theme.colors.text};
    }

    small {
      display: block;
      margin-top: 1.3rem;
    }
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #0004;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusted for smaller screens */

    h3 {
      font-size: 1rem;
    }

    h1 {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Single column for smaller screens */

    h3 {
      font-size: 0.9rem;
    }

    h1 {
      font-size: 1rem;
    }
  }

  @media (max-width: 290px) {
    grid-template-columns: 1fr; /* Single column for very small screens */
    padding: 5px; /* Adjust padding for small screens */

    h3 {
      font-size: 0.7rem;
    }

    h1 {
      font-size: 0.8rem;
    }
  }
`;



const CardContainer = styled.div`
  width: 100%;
  max-width: 350px;
  height: 100%;
  overflow-y: auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 5px 0px #888;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  background-color: ${props => props.theme.colors.background};
  
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  
  @media (max-width: 290px) {
    max-width: 100%;
    height: auto;
    grid-template-rows: auto 1fr;
    padding: 10px;
  }
`;


// const ImageContainer = styled.div`
//   position: relative;
//   overflow: hidden;
//   height: 200px;
//   background-color: #000; /* Background color for remaining space */
//   margin: auto;

//   @media (max-width: 768px) {
//     height: 150px;
//   }

//   @media (max-width: 480px) {
//     height: 120px;
//   }

//   @media (max-width: 290px) {
//     height: 100px;
//   }
// `;

// const Thumbnail = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: contain; /* Ensure the image covers the entire container */
//   object-position: center; /* Center the image within the container */
//   position: absolute;
//   top: 0;
//   left: 0;
//   cursor: pointer;
// `;

 const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 200px;
  background-color: #000; /* Background color for remaining space */
  margin: auto;

  &.landscape img {
    object-fit: stretch;
  }

  &.portrait img {
    object-fit: contain;
  }

  &.square img {
    object-fit: contain;
  }

  @media (max-width: 768px) {
    height: 150px;
  }

  @media (max-width: 480px) {
    height: 120px;
  }

  @media (max-width: 290px) {
    height: 100px;
  }
`;

 const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;



const TextContainer = styled.div`
  padding: 5px;
  flex: 1;

  @media (max-width: 768px) {
    padding: 5px;
  }

  @media (max-width: 480px) {
    padding: 5px;
  }

  @media (max-width: 290px) {
    padding: 5px;
  }
`;


const ProfilePicture = styled.img`
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 290px) {
    width: 25px;
    height: 25px;
  }
`;


const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.text};
`;

const Title = styled.h4`
  margin-bottom: 5px;
  font-size: 1.6rem;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }

  @media (max-width: 290px) {
    font-size: 1rem;
  }
`;


const InfoParagraph = styled.div`
  margin-bottom: 5px;
  font-size: 0.7rem;
  font-weight: bold;
  display: flex;
  gap: 5px;
  color: ${props => props.theme.colors.text};
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 0.6rem;
    margin-bottom: 4px;
    gap: 4px;
  }

  @media (max-width: 480px) {
    font-size: 0.5rem;
    margin-bottom: 3px;
    gap: 3px;
  }

  @media (max-width: 290px) {
    font-size: 0.4rem;
    margin-bottom: 2px;
    gap: 2px;
  }
`;

const PriceAndBuy = styled.div`
  width: 100%;
  margin-bottom: 5px; /* Corrected typo */
  font-size: 0.7rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
  color: black;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 0.6rem;
    gap: 8px;
  }

  @media (max-width: 480px) {
    font-size: 0.5rem;
    gap: 6px;
  }

  @media (max-width: 290px) {
    font-size: 0.4rem;
    gap: 4px;
  }
`;

const AuthorDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.4rem 0;
  color: ${props => props.theme.colors.text};

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.text};

  // @media (max-width: 768px) {
  //   align-items: flex-start;
  // }

  // @media (max-width: 480px) {
  //   font-size: 0.8em;
  // }
`;

const AuthorLink = styled(Link)`
  font-size: 0.7rem;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.colors.text}; 
  margin-right: 10px; /* Add spacing between author name and time */
  font-weight: bold; /* Make the author name bold */

  @media (max-width: 768px) {
    font-size: 0.6rem;
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    font-size: 0.5rem;
    margin-right: 6px;
  }

  @media (max-width: 290px) {
    font-size: 0.4rem;
    margin-right: 4px;
  }
`;


const DescriptionParagraph = styled.p`
  line-height: 1.4rem;

  @media (max-width: 768px) {
    line-height: 1.2rem;
  }

  @media (max-width: 480px) {
    line-height: 1rem;
  }

  @media (max-width: 290px) {
    line-height: 0.9rem;
  }
`;

const StyledPrice = styled.div`
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.7rem;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 0.6rem;
  }

  @media (max-width: 290px) {
    padding: 4px;
    font-size: 0.5rem;
  }
`;

const StyledBuy = styled.div`
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.7rem;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 0.6rem;
  }

  @media (max-width: 290px) {
    padding: 4px;
    font-size: 0.5rem;
  }
`;

const StyledPlay = styled.div`
  width: 100%;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.danger};
  color: ${props => props.theme.colors.text};

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.7rem;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 0.6rem;
  }

  @media (max-width: 290px) {
    padding: 4px;
    font-size: 0.5rem;
  }
`;

const AuthorProfilePicDiv = styled.div`
  width: 100px;
  height: 100px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 290px) {
    width: 50px;
    height: 50px;
  }
`;

const AuthorProfilePic = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
`;

export { 
  PostsContainer, 
  CardContainer, 
  StyledLink, 
  ImageContainer, 
  Thumbnail, 
  TextContainer, 
  Title, 
  InfoParagraph, 
  ProfilePicture, 
  AuthorLink, 
  DescriptionParagraph, 
  PriceAndBuy, 
  StyledPrice,
  StyledBuy,
  StyledPlay,
  AuthorDetailsDiv,
  AuthorInfo,
  AuthorProfilePic,
  AuthorProfilePicDiv
 };