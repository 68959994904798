import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL, baseURL } from '../config';
import { useNavigate, useParams } from 'react-router-dom';
import { getValidToken, getUserIdFromToken } from '../utils/auth';
import { IoMdCloseCircle } from "react-icons/io";
import { IoCheckmarkDoneSharp, IoCheckmarkSharp } from "react-icons/io5";

// Styled Components
const ChatContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const ChatHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 75px;
  padding: 15px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1002;
`;

const AdDetails = styled.div`
  display: flex;
  align-items: center;
`;

const AdThumbnail = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const ChatMessages = styled.div`
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  margin: 75px 0px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
`;

const MessageBubble = styled.div`
  padding: 10px 15px;
  background-color: ${({ isSender, theme }) => (isSender ? theme.colors.background2 : theme.colors.primary)};
  color: ${({ isSender }) => (isSender ? '#000' : '#fff')};
  border-radius: 20px;
  margin-bottom: 10px;
  align-self: ${({ isSender }) => (isSender ? 'flex-end' : 'flex-start')};
  max-width: 70%;
  position: relative;
`;

const MessageContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  time {
    font-size: 12px;
    color: #999;
    margin-left: 10px;
  }
`;

const MessageStatus = styled.div`
  position: absolute;
  bottom: -25px;
  right: 10px;
  display: flex;
  align-items: center;
`;

const ChatInputContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 15px;
  background-color: ${(props) => props.theme.colors.primary};
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const SendButton = styled.button`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const OnlineIndicator = styled.p`
  margin-left: 10px;
  color: green;
`;

const OfflineIndicator = styled.p`
  margin-left: 10px;
  color: red;
`;

const LastSeenIndicator = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: #999;
`;



// Chat Component
const Chat = ({ ad, onClose }) => {
  const { id: adId } = useParams();
  const [userId, setUserId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [authorUsername, setAuthorUsername] = useState(null);
  const [authorId, setAuthorId] = useState(null);
  const [otherId, setOtherId] = useState(null);
  const [adThumbnail, setAdThumbnail] = useState(null);
  const [adTitle, setAdTitle] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState({});
  const [isInactive, setIsInactive] = useState(false);
  const inactivityTimeout = 30000; // 30 seconds
  const [newMessage, setNewMessage] = useState('');
  const [error, setError] = useState('');
  const chatMessagesRef = useRef(null);
  const navigate = useNavigate();

  const fetchMessages = useCallback(async () => {
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/chat/messages`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
          params: { adId }
        });

        setError('');
        setMessages(response.data.messages);
        setAuthorUsername(response.data.authorUsername);
        setAuthorId(response.data.adAuthorId);
        setOtherId(response.data.otherUserId);
        setAdTitle(response.data.adTitle);
        setAdThumbnail(response.data.adThumbnail);
        scrollToBottom();
        markMessagesAsSeen(response.data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
        setError('Failed to load messages. Please try again later.');
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    }
  }, [adId, navigate]);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsInactive(true);
      }, inactivityTimeout);
      setIsInactive(false);
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    // Fetch data initially
    fetchMessages();

    // Setup inactivity timer
    resetTimer();

    // Listen for user activity events
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [fetchMessages]);

  useEffect(() => {
    if (isInactive) {
      // Fetch data again if user is inactive
      fetchMessages();
      setIsInactive(false); // Reset inactivity state after refetch
    }
  }, [isInactive, fetchMessages]);

  // Fetch the online status of the author and all users
  const fetchOnlineStatus = useCallback(async () => {
    try {
      const token = await getValidToken(navigate, true);
      const response = await axios.get(`${API_URL}/online-status`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
        params: { userIds: [authorId, otherId] } // Pass authorId to the endpoint
      });
      setOnlineStatus(response.data);
    } catch (error) {
      console.error('Error fetching online status:', error);
    }
  }, [authorId, navigate]);
  
  useEffect(() => {
    fetchOnlineStatus();
  
    const interval = setInterval(() => {
      fetchOnlineStatus();
    }, 30000); // Check every 30 seconds
  
    return () => clearInterval(interval);
  }, [fetchOnlineStatus]);

  useEffect(() => {
    const fetchUserId = async () => {
      const id = await getUserIdFromToken(navigate);
      setUserId(id);
      scrollToBottom();
    };
    fetchUserId();
  }, [navigate]);

  const markMessagesAsSeen = useCallback(async (messages) => {
    if (!userId) return;

    const unseenMessages = messages.filter(
      (msg) => msg.receiver_id === userId && msg.seen === 0
    );

    if (unseenMessages.length > 0) {
      try {
        const token = await getValidToken(navigate, true);
        await axios.post(
          `${API_URL}/chat/mark-as-seen`,
          {
            adId: adId,
            messageIds: unseenMessages.map(msg => msg.id).filter(id => id !== null),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
      } catch (error) {
        console.error('Error marking messages as seen:', error);
      }
    }
  }, [adId, navigate, userId]);

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:5000');
  
    socket.onopen = () => {
      console.log('WebSocket connection established');
    };
  
    socket.onmessage = (event) => {
      const { adId: receivedAdId, message } = JSON.parse(event.data);
      console.log('Message from server:', receivedAdId, message);
  
      // Check if the received message's adId matches the current adId
      if (receivedAdId === adId) {
        setMessages(prevMessages => [...prevMessages, message]);
        scrollToBottom();
      }
    };
  
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  
    return () => {
      socket.close();
    };
  }, [adId]);

  const scrollToBottom = () => {
    chatMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async () => {
    const token = await getValidToken(navigate, true);
    if (token && newMessage.trim()) {
      try {
        const response = await axios.post(`${API_URL}/chat/send`, {
          adId: adId,
          message: newMessage
        }, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });

        setMessages([...messages, response.data]);
        setNewMessage('');
        scrollToBottom();
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  // Utility function to format time
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours % 12 || 12}:${minutes < 10 ? `0${minutes}` : minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
};

return (
  <ChatContainer>
    <ChatHeader>
      <AdDetails>
        <AdThumbnail src={`${baseURL}${adThumbnail}`} alt='Ad Thumbnail' />
        <div>
          <p>
            Chat with {authorUsername}
            {authorId !== userId && (
              onlineStatus[authorId]?.status ? (
                <OnlineIndicator><b>Online</b></OnlineIndicator>
              ) : (
                <OfflineIndicator>
                  <b>Offline</b>
                  <LastSeenIndicator>
                    <b>Last seen: </b>{formatTimestamp(onlineStatus[authorId]?.lastSeen)}
                  </LastSeenIndicator>
                </OfflineIndicator>
              )
            )}
          </p>
          <span>{adTitle}</span>
        </div>
      </AdDetails>
      <IoMdCloseCircle onClick={() => {navigate(`/kasuwa/section`)}} size={30} color="#fff" />
    </ChatHeader>
    
    <ChatMessages>
      {messages.map((message, index) => {
        const isSender = message.sender_id === userId;
        const otherUserId = isSender ? message.receiver_id : message.sender_id;
        const otherUsername = isSender ? 'You' : message.sender_username;
        const otherUserStatus = onlineStatus[otherUserId];

        return (
          <MessageBubble key={message.id} isSender={isSender}>
            <div styles={{display: 'flex'}}>
              <div>
              <b>{otherUsername}</b>
              </div>
              <div>
              {!isSender && (
                otherUserStatus?.status ? (
                  <OnlineIndicator><b>Online</b></OnlineIndicator>
                ) : (
                  <OfflineIndicator>
                    <b>Offline</b>
                    <LastSeenIndicator>
                      <b>Last seen: </b>{formatTimestamp(otherUserStatus?.lastSeen)}
                    </LastSeenIndicator>
                  </OfflineIndicator>
                )
              )}
              </div>
            </div>

            <MessageContent>
              <span>{message.message}</span>
              <time>{formatTimestamp(message.timestamp)}</time>
            </MessageContent>

            <MessageStatus>
              {/* Show double ticks if the message has been seen by the receiver */}
              {isSender && (
                message?.seen === 1 ? <IoCheckmarkDoneSharp color="blue" /> : <IoCheckmarkSharp />
              )}
            </MessageStatus>
          </MessageBubble>
        );
      })}
      {error && <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>{error}</b></p>}
      <div ref={chatMessagesRef} style={{ height: '10px' }} />
    </ChatMessages>
    
    <ChatInputContainer>
      <ChatInput
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type your message..."
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleSendMessage();
        }}
      />
      <SendButton onClick={handleSendMessage}>Send</SendButton>
    </ChatInputContainer>
  </ChatContainer>
);

};

export default Chat;