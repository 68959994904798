import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { API_URL, baseURL } from '../../config';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import useDebounce from '../../utils/useDebounce';
import AdRequestDetails from './AdRequestDetails';
import { MdCancel, MdAdd, MdClose } from 'react-icons/md';
import { FaSearch } from 'react-icons/fa';
import { IoMdOptions } from "react-icons/io";

import { PaginationWrapper, Pagination, PageNumber, PaginationButton, FormContainer, FormTitle, Tabs, Tab, SearchInput, RequestList, RequestItem, FormModelDiv, ModalBackdrop } from './BecomeCreatorFormRequests';
import { Card, ThumbnailDiv, PostDetailsDiv, PostTitle, AudioIndicator, ActiveStatusAndEllipsesDiv, Actions, AdminActiveStatusDiv, StatusDiv, StyledP, 
        StyledTime, Ellipses, DropdownHeader, DropdownMenu, DropdownOption, CloseIcon, PostAnalysis, MainContainer } from '../ProducerAllPosts';


const ManageAdsRequests = () => {
    const [adsRequests, setAdsRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedReceipt, setSelectedReceipt] = useState(null); 
    const [showDetails, setShowDetails] = useState(false); 
    const [tab, setTab] = useState('pending');
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [counts, setCounts] = useState({ allAds: 0, approvedAds: 0, pendingAds: 0, waitingAds: 0, rejectedAds: 0 });
    const [dropdownPostId, setDropdownPostId] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const ListRef = useRef(null);
    const navigate = useNavigate();

    const debouncedSearch = useDebounce(search, 800);

    const fetchCounts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/ads/counts`, {
                    params: { search: debouncedSearch, tab },
                    ...config
                });
                setCounts(response.data);
            } catch (error) {
                console.error('Error fetching request counts:', error);
            }
        }
    }, [debouncedSearch, tab, navigate]);

    const fetchAds = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/ads`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setAdsRequests(response.data.ads || []);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching receipts:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentPage, navigate, debouncedSearch, tab]);

    useEffect(() => {
        fetchCounts();
        fetchAds();
    }, [currentPage, debouncedSearch, tab, navigate, fetchCounts, fetchAds]);

    const handleDelete = async (adId) => {
        const token = await getValidToken(navigate, true); 
          if (token) {
        if (window.confirm('Are you sure you want to delete this ad?')) {
          try {
            await axios.delete(`${API_URL}/admin/ad/delete/${adId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            }); 
            setAdsRequests(adsRequests.filter(ad => ad.id !== adId)); // Update the ads state
            setIsDropdownOpen(false);
          } catch (error) {
            setIsDropdownOpen(false);
            console.error('Error deleting ad:', error);
          }
        }
      }
      };

    const handleDropdownClick = (adId) => {
        if (dropdownPostId === adId && isDropdownOpen) {
          setIsDropdownOpen(false);
          setDropdownPostId(null);
        } else {
          setIsDropdownOpen(true);
          setDropdownPostId(adId);
        }
      };

      const handleUpdatePostStatus = (adId) => {
        setDropdownPostId(null);
        setIsDropdownOpen(false);
      };
    
      const handleCloseDropdown = () => {
        setDropdownPostId(null);
        setIsDropdownOpen(false);
      };

    const handleSelectReceipt = (receipt) => {
        setSelectedReceipt(receipt);
        setShowDetails(true);
    };

    const handleCloseDetails = () => {
        setSelectedReceipt(null);
        setShowDetails(false);
        setIsDropdownOpen(false);
    };

    const handleTabChange = (selectedTab) => {
        setTab(selectedTab);
        setCurrentPage(1); 
        fetchAds(); 
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const scrollToTop = () => {
        ListRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handlePageClick = (page) => {
        setCurrentPage(page);
        scrollToTop();
    };
  
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            scrollToTop();
        }
    };
  
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollToTop();
        }
    };
    
    const filteredAdsRequests = Array.isArray(adsRequests) 
    ? (tab === 'all' ? adsRequests : adsRequests.filter(req => req.status === tab)) 
    : [];

    console.log('Filtered Receipts:', filteredAdsRequests);
    console.log('Current Tab:', tab);

    const handleSelectChange = (event) => {
        handleTabChange(event.target.value);
    };

    const formatTimeAgo = (createdDate, scheduledAt = null) => {
        const currentDate = new Date();
      
        // Check if the post is scheduled for the future
      if (scheduledAt) {
        const scheduledDate = new Date(scheduledAt);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        if (scheduledDate > currentDate) {
          return `Will be posted on ${scheduledDate.toLocaleString('en-US', options)}`;
        }
      }
    
      
        const timeDifference = currentDate - new Date(createdDate);
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(monthsDifference / 12);
      
        if (yearsDifference > 0) {
          return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
        } else if (monthsDifference > 0) {
          return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
        } else if (daysDifference > 0) {
          return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
        } else if (hoursDifference > 0) {
          return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
        } else if (minutesDifference > 0) {
          return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
        } else {
          return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
        }
      };
      
    
      const getDisplayStatus = (status, scheduledAt) => {
        const now = new Date();
        const scheduledDate = new Date(scheduledAt);
      
        // If the post is scheduled and the scheduled date is in the past
        if (status === 'scheduled' && scheduledDate <= now) {
          return 'public';
        }
      
        // Otherwise return the original status
        return status;
      };

    if (isLoading) {
        return (
        <MainContainer>
            <Spinner />
        </MainContainer>
        )
    };

    return (
        <FormContainer>
        <FormTitle>Ads Management</FormTitle>
        <Tabs>
            <Tab active={tab === 'pending'} onClick={() => handleTabChange('pending')}>
            <p>Pending</p> <p>({counts.pendingAds})</p>
            </Tab>
            <Tab active={tab === 'waiting'} onClick={() => handleTabChange('waiting')}>
            <p>Waiting</p> <p>({counts.waitingAds})</p>
            </Tab>

            <select value={tab} onChange={handleSelectChange} style={{ marginLeft: '10px' }}>
                <option value="all">All ({counts.allAds})</option>
                <option value="all">All Requests ({counts.allAds})</option>
                <option value="approved">Approved ({counts.approvedAds})</option>
                <option value="pending">Pending ({counts.pendingAds})</option>
                <option value="waiting">Waiting ({counts.waitingAds})</option>
                <option value="rejected">Rejected ({counts.rejectedAds})</option>
            </select>
        </Tabs>
        <SearchInput
            type="text"
            placeholder="Search by username or phone"
            value={search}
            onChange={handleSearchChange}
        />
        <RequestList ref={ListRef}>
            {filteredAdsRequests.map(ad => (
                <Card key={ad.id}>
                <ThumbnailDiv onClick={() => handleSelectReceipt(ad)}>
                    {ad.thumbnail && <img src={`${baseURL}${ad.thumbnail}`} alt="Thumbnail" />}
                </ThumbnailDiv>
                <PostDetailsDiv>
                    <PostTitle>{ad.title}</PostTitle>
                <PostAnalysis>
                    <StyledP>N{ad.price}</StyledP>
                    <StatusDiv status={getDisplayStatus(ad.status, ad.scheduled_at)}>{getDisplayStatus(ad.status, ad.scheduled_at)}</StatusDiv>
                    <StyledP>{ad.views}</StyledP>
                    <StyledTime>{formatTimeAgo(ad.created_at, ad.scheduled_at)}</StyledTime>
                </PostAnalysis>
                <ActiveStatusAndEllipsesDiv>
                    <AdminActiveStatusDiv adminStatus={ad.active_status}>
                    <p>{ad.active_status}</p> 
                    {ad.active_status === 'active' && <AudioIndicator postIsActive={ad.active_status}/>}
                    </AdminActiveStatusDiv>
                    <StyledP>by {ad.owner_username}</StyledP>
                    <Ellipses onClick={() => handleDropdownClick(ad.id)}>
                    <IoMdOptions />
                    </Ellipses>
                </ActiveStatusAndEllipsesDiv>
                </PostDetailsDiv>
                <Actions ref={dropdownRef}>
                {isDropdownOpen && dropdownPostId === ad.id ? (
                    <DropdownMenu onClick={(e) => e.stopPropagation()}>
                    <DropdownHeader>
                        <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
                    </DropdownHeader>
                    <DropdownOption onClick={() => handleSelectReceipt(ad)}>Edit</DropdownOption>
                    <DropdownOption onClick={() => handleDelete(ad.id)}>Delete</DropdownOption>
                    <DropdownOption onClick={() => handleUpdatePostStatus(ad.id)}>Update Status</DropdownOption>
                    </DropdownMenu>
                ) : null}
                </Actions>
                </Card>
            ))}
        </RequestList>
        {totalPages > 1 && (
            <PaginationWrapper>
            <Pagination>
                <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                </PaginationButton>
                {Array.from({ length: totalPages }, (_, index) => (
                    <PageNumber
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </PageNumber>
                ))}
                <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                </PaginationButton>
            </Pagination>
        </PaginationWrapper>
        )}
        {showDetails && selectedReceipt && (
            <>
            <ModalBackdrop onClick={handleCloseDetails} />
            <FormModelDiv>
            <AdRequestDetails
                        ad={selectedReceipt}
                        onClose={handleCloseDetails}
                        onCloseDropdown={handleCloseDropdown}
                        refresh={fetchAds}
                    />
            </FormModelDiv>        
            </>        
        )}
        </FormContainer>
    );
};

export default ManageAdsRequests;

