import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { IoMdCloseCircle } from "react-icons/io";
import Spinner from './Spinner';

const NotificationList = styled.div`
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  padding: 10px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationCard = styled.div`
  padding: 20px;
  margin: 10px 0;
  background-color: ${(props) => props.theme.colors.background2};
  border-left: ${({ read }) => (read ? 'none' : '4px solid #007bff')};
  color: ${(props) => props.theme.colors.text};
  cursor: ${({ read }) => (read ? 'default' : 'pointer')};
  transition: background-color 0.3s;

  &:hover {
    //background-color: ${({ read }) => (read ? 'green' : '#e0e0e0')};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

const CloseButton = styled.button`
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const NotificationComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      const token = await getValidToken(navigate, false); 
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/notifications`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            withCredentials: true
          });
          setNotifications(response.data.notifications);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        } finally {
          setIsLoading(false); // Ensure loading state is cleared
        }
      } else {
        setIsLoading(false); // Ensure loading state is cleared if no token
      } 
    };

    fetchNotifications();
  }, [navigate]);

  const markAsRead = async (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );

    const token = await getValidToken(navigate, false); 
      if (token) {
    try {
      await axios.put(`${API_URL}/notifications/${id}/read`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  }
  };

  if(isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return (
    <NotificationList>
    <Header>
      <div><h3>Notifications</h3></div>
      <CloseButton onClick={() => {navigate('/')}}><IoMdCloseCircle size='24' color='black' /></CloseButton>
    </Header>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          read={notification.read}
          onClick={() => !notification.read && markAsRead(notification.id)}
        >
          {notification.message}
        </NotificationCard>
      ))}
    </NotificationList>
  );
};

export default NotificationComponent;
