import styled, { keyframes } from 'styled-components';
import {Link} from 'react-router-dom';
import { FaEllipsisH, FaCheck, FaTimes, FaPhone, FaLock  } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.borderRadius.card};
`;

const LoadingIcon = styled(FaEllipsisH)`
  animation: spin 1.4s infinite linear;
  margin-left: 5px; 

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-left: 3px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-left: 2px;
  }
`;

const SuccessIcon = styled(FaCheck)`
  color: green;
  margin-left: 5px; 

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-left: 3px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-left: 2px;
  }
`;

const ErrorIcon = styled(FaTimes)`
  color: red;
  margin-left: 5px; 

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-left: 3px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-left: 2px;
  }
`;

const SuccessMessage = styled.p`
  color: green;
  background-color: black;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 15px;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 4px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 3px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  background-color: white;
  max-width: 355px;
  margin: 0 auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 4px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 3px;
  }
`;

const Spinner = styled.div`
  
`;

const Logo = styled.img`
  position: relative;
  width: 100px; 
  height: 100px; 
  border-radius: 50%; 
  border: 2px solid ${props => props.theme.colors.text};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const TitleContainer = styled.div` 
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const Title = styled.h1`
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const Card = styled.div`
  background-color: ${props => props.theme.colors.background};
  margin: auto;
  //margin-top: 15px;
  //margin-bottom: 15px;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  align-items: center;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  max-width: 355px;
  //width: 100%;
  color: ${props => props.theme.colors.text};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  h1 {
    color: black;
  }

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 300px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    max-width: 300px;
  }
`; 

// const Card = styled.div`
//   background-color: ${props => props.theme.colors.background};
//   margin: auto;
//   backdrop-filter: blur(20px);
//   box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
//   align-items: center;
//   border-radius: 20px;
//   border: 2px solid rgba(255, 255, 255, 0.5);
//   max-width: 355px;
//   color: ${props => props.theme.colors.text};
//   padding: 20px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   position: relative;

//   h1 {
//     color: black;
//   }

//   @media (max-width: 480px) {
//     /* Mobile portrait */
//     padding: 10px;
//     max-width: 300px;
//   }

//   @media (min-width: 480px) and (max-width: 640px) {
//     /* Mobile landscape */
//     padding: 15px;
//     max-width: 320px;
//   }

//   @media (min-width: 640px) and (max-width: 800px) {
//     /* Tablet portrait */
//     padding: 15px;
//     max-width: 600px;
//   }

//   @media (min-width: 800px) and (max-width: 1024px) {
//     /* Tablet landscape */
//     padding: 20px;
//     max-width: 800px;
//   }

//   @media (min-width: 1024px) and (max-width: 1280px) {
//     /* Desktop standard */
//     padding: 20px;
//     max-width: 1000px;
//   }

//   @media (min-width: 1280px) and (max-width: 1920px) {
//     /* Desktop large */
//     padding: 20px;
//     max-width: 1200px;
//   }

//   @media (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px) {
//     /* High-density screens */
//     padding: 20px;
//     max-width: 1000px;
//   }
// `;

const Close = styled.div`
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.colors.danger};
  cursor: pointer;

  @media (max-width: 768px) {
    top: 8px;
    right: 8px;
  }

  @media (max-width: 480px) {
    top: 6px;
    right: 6px;
  }
`;

const CloseIcon = styled(IoMdCloseCircle)`
  font-size: 34px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;


const InputBox = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 355px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  margin-bottom: 25px;

  &:nth-child input:valid ~ span,
  &:nth-child input:focus ~ span {
    background: #00dfc4;
    color: ${props => props.theme.colors.text};
    border-radius: 2px;
  }

  input:valid ~ span,
  input:focus ~ span {
    color: ${props => props.theme.colors.text};
    transform: translateX(10px) translateY(-14px);
    font-size: 15px;
    padding: 0 10px;
    background: #32a852;
    border-left: 1px solid #00dfc4;
    border-right: 1px solid #00dfc4;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    max-width: 300px;
    height: 45px;
  }

  @media (max-width: 480px) {
    max-width: 250px;
    height: 40px;
  }
`;

const Label = styled.span`
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.theme.colors.text};
  transition: 0.3s ease;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 8px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 6px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  background: transparent;
  border-radius: 5px;
  outline: none;
  color: ${props => props.theme.colors.text};
  font-size: 20px;
  font-weight: 400;
  transition: 0.3s;
  overflow: hidden;

  &&:placeholder {
    color: ${props => props.theme.colors.primary};
  }

  &:valid,
  &:focus {
    border: 2px solid #00dfc4;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 16px;
  }
`;

const Icon = styled.i`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  overflow: hidden;

  @media (max-width: 768px) {
    right: 8px;
  }

  @media (max-width: 480px) {
    right: 6px;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  margin-top: 10px;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    font-size: 1em;
    padding: 6px 12px;
  }
`;

const PlayAudioButton = styled.button`
  height: 28px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  padding: 7px;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.white};
  font-weight: 500;

  @media (max-width: 768px) {
    height: 24px;
    padding: 7px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    height: 30px;
    padding: 7px;
  }
`;

const PauseAudioButton = styled.button`
  height: 28px;
  background-color: ${props => props.theme.colors.danger};
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.text};
  font-weight: 500;

  @media (max-width: 768px) {
    height: 24px;
    padding: 4px;
  }

  @media (max-width: 480px) {
    height: 20px;
    padding: 3px;
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  font-weight: 300;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }

  @media (max-width: 480px) {
    font-size: 0.8em;
  }
`;

const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.danger};
  border-radius: 50%;
  display: ${props => (props.showVideoModal ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 6px;
    height: 6px;
  }
`;


const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    background-color: #000; /* Background color for remaining space */
    margin: auto;
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain; /* Fit the image within the container */
    object-position: center; /* Center the image within the container */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
`;

const AuthorDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  color: ${props => props.theme.colors.text};

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  // @media (max-width: 768px) {
  //   flex-direction: column;
  //   align-items: flex-start;

  //   > div {
  //     flex-direction: column;
  //     align-items: flex-start;
  //     gap: 0.5rem;
  //   }
  // }

  // @media (max-width: 480px) {
  //   margin: 0.5rem 0;

  //   > div {
  //     gap: 0.25rem;
  //   }
  // }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.text};

  // @media (max-width: 768px) {
  //   align-items: flex-start;
  // }

  // @media (max-width: 480px) {
  //   font-size: 0.8em;
  // }
`;

const PhoneIcon = styled(FaPhone)`
  color: black;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const LockIcon = styled(FaLock)`
  color: black;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;


export { Container, SuccessMessage, ErrorMessage, ImageContainer, Thumbnail, AudioIndicator, PlayAudioButton, PauseAudioButton, Close, CloseIcon, LoadingIcon, SuccessIcon, ErrorIcon, Spinner, Logo, TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, StyledButton, StyledLink,
  AuthorDetailsDiv, ProfileImage, AuthorInfo, PhoneIcon, LockIcon
 };
