import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight  } from "react-icons/fa";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getValidToken } from '../utils/auth';
import { API_URL } from '../config';
import Button from './Button';
import Spinner from './Spinner';
import {SpinnerContainer} from './PostDetails.Styles';

import Countries from '../utils/Countries';
import States from '../utils/States.json';
import LocalGovernments from '../utils/LocalGovernments.json';
import Wards from '../utils/Wards.json';
import PopularAreas from '../utils/PopularAreas.json';

const FormContainer = styled.div`
  position: relative;
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.background2};
  color: ${props => props.theme.colors.text};
`;

const FormTitle = styled.h2`
  color: ${props => props.theme.colors.text};
  text-align: center;
  margin-bottom: 20px;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: ${props => props.theme.colors.background}; 
  color: ${props => props.theme.colors.text};
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: ${props => props.theme.colors.background}; 
  color: ${props => props.theme.colors.text};
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
`;

const MoveButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const ProgressDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px; 10px;
`;

const ProgressBar = styled.div`
  height: 20px;
  width: ${props => props.progress}%;
  background-color: ${props => (props.progress === 100 ? '#4caf50' : '#76c7c0')};
  transition: width 0.4s ease;
`;

const ProgressText = styled.span`
  display: flex;
  width: 25%;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
`;

export { FormContainer, FormTitle, FormField, Label, Input, Textarea, Select, PreviewContainer, PreviewImage,
  Image, RemoveButton, MoveButton, SubmitButton
};

const CreateAdForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    phone: '',
    address: '',
    ads_category_id: 1,
    state: '',
    local_government: '',
    ward: '',
    area: '',
    amount_to_spend: 'Free',
    ads_type: 'kasuwaSection',
    access_type: '1-week',
    video_link: '',
    price: '0.00',
    isNegotiable: false,
    condition: 'new',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [fetchDataisLoading, setFetchDataIsLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isFirstAd, setIsFirstAd] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadedBytes, setLoadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = await getValidToken(navigate, true);
      if (token) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${API_URL}/user/ads/check`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          // Handle the response
          if (response.data) {
            const { ads, hasAds, phone: userPhone, email: userEmail } = response.data;

            if (ads && ads.id) {
              setIsFirstAd(false);
            }

            // Update the phone in formData
            setFormData(prevState => ({
              ...prevState,
              phone: userPhone || ''
            }));
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUserData();
  }, [navigate]); 

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:8080');

    ws.onopen = () => {
      console.log('WebSocket connection established on:', ws);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.progress !== undefined) {
        setUploadProgress(data.progress);
        setLoadedBytes(data.uploadedBytes);
        setTotalBytes(data.totalBytes);
      }
    };    

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => ws.close();
  }, []);

  const calculateAmountToSpend = useCallback (() => {
    const accessPrices = {
      '1-week': 1500,
      '2-weeks': 3000,
      '3-weeks': 4500,
      '1-month': 5000,
      '2-months': 10000,
      '3-months': 15000,
      '4-months': 20000,
      '5-months': 25000,
      '6-months': 30000,
      '1-year': 60000,
    };

    const adTypePrices = {
      mainApp: 2000,
      kasuwaSection: 3000,
      player: 5000,
      navigate: 10000,
    };

    const accessPrice = accessPrices[formData.access_type] || 0;
    const adTypePrice = adTypePrices[formData.ads_type] || 0;

    const totalAmount = accessPrice + adTypePrice;
    setFormData((prev) => ({
      ...prev,
      amount_to_spend: totalAmount.toString(),
    }));
  }, [formData.access_type, formData.ads_type]);

  useEffect(() => {
    if (!isFirstAd) {
      calculateAmountToSpend();
    }
  }, [formData.access_type, formData.ads_type, isFirstAd, calculateAmountToSpend]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
  
    if (name === 'thumbnail') {
      setThumbnail(files[0]);
    } else if (name === 'video') {
      setVideo(files[0]);
    } else if (name === 'pictures') {
      const newPictures = Array.from(files);
      setPictures((prevPictures) => {
        const updatedPictures = [...prevPictures, ...newPictures].slice(0, 10);
        const newPreviews = updatedPictures.map((file) => URL.createObjectURL(file));
  
        // Revoke old URLs to avoid memory leaks
        previewImages.forEach((url) => URL.revokeObjectURL(url));
        setPreviewImages(newPreviews);
  
        return updatedPictures;
      });
    }
  };

  const moveImageLeft = (index) => {
    if (index > 0) {
      const reorderedPictures = Array.from(pictures);
      const temp = reorderedPictures[index - 1];
      reorderedPictures[index - 1] = reorderedPictures[index];
      reorderedPictures[index] = temp;

      setPictures(reorderedPictures);
      setPreviewImages(reorderedPictures.map((file) => URL.createObjectURL(file)));
    }
  };

  const moveImageRight = (index) => {
    if (index < pictures.length - 1) {
      const reorderedPictures = Array.from(pictures);
      const temp = reorderedPictures[index + 1];
      reorderedPictures[index + 1] = reorderedPictures[index];
      reorderedPictures[index] = temp;

      setPictures(reorderedPictures);
      setPreviewImages(reorderedPictures.map((file) => URL.createObjectURL(file)));
    }
  };

  const handleRemoveImage = (index) => {
    setPictures((prevPictures) => {
      const updatedPictures = prevPictures.filter((_, i) => i !== index);
  
      // Revoke the URL of the removed image
      URL.revokeObjectURL(previewImages[index]);
  
      setPreviewImages(updatedPictures.map((file) => URL.createObjectURL(file)));
      return updatedPictures;
    });
  };

  const handleOnDragEnd = (result) => {
    console.log('Drag result:', result); // Debugging line
    if (!result.destination) return;
    const reorderedPictures = Array.from(pictures);
    const [moved] = reorderedPictures.splice(result.source.index, 1);
    reorderedPictures.splice(result.destination.index, 0, moved);

    setPictures(reorderedPictures);
    setPreviewImages(reorderedPictures.map((file) => URL.createObjectURL(file)));
  };
  

  const handlePriceOptionChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      isNegotiable: value === 'negotiable',
      price: value === 'negotiable' ? '' : prev.price,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setUploadProgress(0);
    setLoadedBytes(0);
    setTotalBytes(0);

    // Validate required fields
    if (!formData.title || !formData.ads_category_id) {
      setError('Please fill in all required fields.');
      setTimeout(() => setError(''), 5000);
      setIsLoading(false);
      return;
  }

    const token = await getValidToken(navigate, true);
    if (token) {
      setIsLoading(true);
    try {
      const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data', 
                },
                onUploadProgress: (progressEvent) => {
                    const loaded = progressEvent.loaded;
                    const total = progressEvent.total;
                    const progress = Math.round((loaded / total) * 100);

                    setUploadProgress(progress);
                    setLoadedBytes(loaded);
                    setTotalBytes(total);
                },
            };
      
  
      const formDataObj = new FormData();
  
      // Append form fields
      for (const [key, value] of Object.entries(formData)) {
        formDataObj.append(key, value);
      }
  
      // Append files
      if (thumbnail) formDataObj.append('thumbnail', thumbnail);
      if (video) formDataObj.append('video', video);
      if (pictures.length > 0) {
        pictures.forEach((picture) => formDataObj.append('pictures', picture));
      }
  
      const response = await axios.post(`${API_URL}/ads`, formDataObj, config);
  
      setError('');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(true);
        navigate('/manage/ads');
      }, 5000); 
    } catch (error) {
      console.error('Error creating ad:', error);
      
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An unexpected error occurred.');
      }
  
      setTimeout(() => {
        setUploadProgress(0);
        setLoadedBytes(0);
        setTotalBytes(0);
        setSuccess(false);
        setError('');
      }, 7000);
    } finally {
      setIsLoading(false);
    }
  }
  };

  if (fetchDataisLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }
  

  return (
    <FormContainer>
      <FormTitle>Create New Ad</FormTitle>
      <form onSubmit={handleSubmit}>
      <FormField>
          <Label>Thumbnail</Label>
          {thumbnail && (
            <FormField>
            <PreviewImage>
              <Image src={URL.createObjectURL(thumbnail)} alt="Thumbnail" />
            </PreviewImage>
            </FormField>
          )}
          <Input
            type="file"
            name="thumbnail"
            accept="image/*"
            onChange={handleFileChange}
          />
        </FormField>
        
      <FormField>
          <Label>Pictures (Up to 10)</Label>
          <PreviewContainer>
        {previewImages.map((image, index) => (
          <PreviewImage key={`${image}-${index}`}>
            <Image src={image} alt={`Preview ${index}`} />

            {index > 0 && (
              <MoveButton
                style={{ left: '-25px' }}
                onClick={() => moveImageLeft(index)}
              >
                <FaArrowAltCircleRight  color={'black'}/>
              </MoveButton>
            )}
            {index < previewImages.length - 1 && (
              <MoveButton
                style={{ right: '-25px' }}
                onClick={() => moveImageRight(index)}
              >
                <FaArrowAltCircleLeft color={'black'} />
              </MoveButton>
            )}
            <RemoveButton onClick={() => handleRemoveImage(index)}>
              <IoMdCloseCircle color='white' />
            </RemoveButton>
          </PreviewImage>
        ))}
      </PreviewContainer>

          <Input
            type="file"
            name="pictures"
            accept="image/*"
            multiple
            onChange={handleFileChange}
          />
        </FormField>

        <FormField>
          <Label>Video Link (Optional)</Label>
          <Input
            type="url"
            name="video_link"
            value={formData.video_link}
            onChange={handleInputChange}
          />
        </FormField>
        <FormField>
          <Label>Title</Label>
          <Input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
        </FormField>
        <FormField>
          <Label>Ads Category</Label>
          <Select
            name="ads_category_id"
            value={formData.ads_category_id}
            onChange={handleInputChange}
            required
          >
            <option value="1">Electronics</option>
            <option value="2">Vehicles</option>
            <option value="3">Real Estate</option>
            <option value="4">Services</option>
            <option value="5">Jobs</option>
            <option value="6">Furniture</option>
            <option value="7">Fashion</option>
            <option value="8">Health & Beauty</option>
            <option value="9">Sports & Outdoors</option>
            <option value="10">Pets</option>
            <option value="11">Books & Media</option>
            <option value="12">Home & Garden</option>
            <option value="13">Toys & Games</option>
            <option value="14">Food & Beverages</option>
            <option value="15">Automotive</option>
          </Select>
        </FormField>

        <FormField>
          <label htmlFor="condition">Condition</label>
          <div style={{ gap: '20px', display: 'flex', margin: '10px' }}>
            <div>
              <input
                type="radio"
                id="condition-new"
                name="condition"
                value="new"
                checked={formData.condition === 'new'}
                onChange={handleInputChange}
              />
              <label htmlFor="condition-new">New</label>
            </div>

            <div>
              <input
                type="radio"
                id="condition-used"
                name="condition"
                value="used"
                checked={formData.condition === 'used'}
                onChange={handleInputChange}
              />
              <label htmlFor="condition-used">Used</label>
            </div>
          </div>
        </FormField>
          
        <FormField>
          <Label>Description</Label>
          <Textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
            rows="4"
          />
        </FormField>
        <FormField>
          <Label>Price</Label>
          <div>

          <div style={{gap: '20px', display: 'flex', margin: '10px'}}>

          <div>
            <input
              type="radio"
              name="priceOption"
              value="price"
              checked={!formData.isNegotiable}
              onChange={handlePriceOptionChange}
            />
            <label>Price</label>
            </div>

            <div>
            <input
              type="radio"
              name="priceOption"
              value="negotiable"
              checked={formData.isNegotiable}
              onChange={handlePriceOptionChange}
            />
            <label>Negotiable</label>
            </div>

          </div>    
          </div>
          {!formData.isNegotiable && (
            <Input
              type="tel"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              required={!formData.isNegotiable}
              step="0.01"
            />
          )}
        </FormField>
        
        <FormField>
          <Label>State</Label>
          <Select 
            id="state"
            name="state" 
            value={formData.state} 
            onChange={handleInputChange}
            >
          <option value="">--Choose State--</option>
          {States.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </Select>
        </FormField>

        {formData.state && PopularAreas[formData.state] && (
          <>
          <FormField>
            <Label>Choose Nearest to your location</Label>
            <Select 
              id="area" 
              name="area"
              value={formData.area} 
              onChange={handleInputChange}

              >
              <option value="">--Choose Area--</option>
              {PopularAreas[formData.state].map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
              <option value="Whole">Whole {formData.state}</option>
            </Select>
            </FormField>
          </>
        )}

        {formData.state && LocalGovernments[formData.state] && (
          <>
        <FormField>
          <Label>Local Government</Label>
          <Select 
            id="lga" 
            name="local_government"
            value={formData.local_government} 
            onChange={handleInputChange}>
              <option value="">--Choose Local Government--</option>
              {LocalGovernments[formData.state].map((lga, index) => (
                <option key={index} value={lga}>
                  {lga}
                </option>
              ))}
            </Select>
        </FormField>
        </>
      )}

      {formData.local_government && Wards[formData.state] && Wards[formData.state][formData.local_government] && (
          <>
        <FormField>
          <Label>Select Ward</Label>
          <Select 
            id="ward" 
            name="ward"
            value={formData.ward} 
            onChange={handleInputChange}>
              <option value="">--Choose Ward--</option>
              {Wards[formData.state][formData.local_government].map((ward, index) => (
                <option key={index} value={ward}>
                  {ward}
                </option>
              ))}
            </Select>
        </FormField>
        </>
      )}

        <FormField>
          <Label>Address</Label>
          <Input
            type="text"
            name="address"
            placeholder='Address in details'
            value={formData.address}
            onChange={handleInputChange}
            required
          />
        </FormField>
        <FormField>
          <Label>Contact Phone</Label>
          <Input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
            disabled
          />
        </FormField>
        <FormField>
          <Label>Access Type</Label>
          <Select
            name="access_type"
            value={formData.access_type}
            onChange={handleInputChange}
            required
          >
            <option value="1-week">1 Week {!isFirstAd && '- ₦1500'}</option>
            <option value="2-weeks">2 Weeks {!isFirstAd && '- ₦3000'}</option>
            <option value="3-weeks">3 Weeks {!isFirstAd && '- ₦4500'}</option>
            <option value="1-month">1 Month {!isFirstAd && '- ₦5000'}</option>
            <option value="2-months">2 Months {!isFirstAd && '- ₦10000'}</option>
            <option value="3-months">3 Months {!isFirstAd && '- ₦15000'}</option>
            <option value="4-months">4 Months {!isFirstAd && '- ₦20000'}</option>
            <option value="5-months">5 Months {!isFirstAd && '- ₦25000'}</option>
            <option value="6-months">6 Months {!isFirstAd && '- ₦30000'}</option>
            <option value="1-year">1 Year - {!isFirstAd && '- ₦60000'}</option>
          </Select>
        </FormField>
        <FormField>
          <Label>Ads Type</Label>
          <Select
            name="ads_type"
            value={formData.ads_type}
            onChange={handleInputChange}
            required
          >
            <option value="mainApp">Main App {!isFirstAd && '- ₦2000'}</option>
            <option value="kasuwaSection">Kasuwa Section {!isFirstAd && '- ₦3000'}</option>
            <option value="player">Player {!isFirstAd && '- ₦5000'}</option>
            <option value="navigating">Navigating {!isFirstAd && '- ₦10000'}</option>
          </Select>
        </FormField>
        {formData.ads_type === 'player' && (
          <FormField>
            <Label>Video (Optional)</Label>
            <Input
              type="file"
              name="video"
              accept="video/*"
              onChange={handleFileChange}
              style={{ display: formData.ads_type === 'player' ? 'block' : 'none' }}
            />
          </FormField>
        )}
        <FormField>
          <Label>Amount to Spend</Label>
          <Input
            type="text"
            name="amount_to_spend"
            value={isFirstAd ? 'Free' : formData.amount_to_spend}
            readOnly
          />
        </FormField>
  
        {error && (
          <FormField><b><p style={{color: 'red'}}>{error}</p></b></FormField>
        )}

      {uploadProgress > 0 && (
        <FormField>
          <ProgressDiv>
            <ProgressBarContainer>
              <ProgressBar progress={uploadProgress} />
            </ProgressBarContainer>
            <ProgressText>
              {((loadedBytes / 1024 / 1024).toFixed(2))}/{(totalBytes / 1024 / 1024).toFixed(2)} mb
            </ProgressText>
          </ProgressDiv>
          </FormField>
        )}
    
        
        <FormField>
        <Button 
          onClick={handleSubmit} 
           isLoading={isLoading} 
           loginSuccess={success} 
           errorMessage={error} 
           uploadProgress={uploadProgress}
           disabled={isLoading || success}
           isP={true}
        >
          Create Ad
        </Button>
      </FormField>
      </form>
    </FormContainer>
  );
};

export default CreateAdForm;
