import React, { useEffect, useState, useRef } from 'react';
import { MdClose } from 'react-icons/md';
import { FaFilter } from 'react-icons/fa';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Chart from './Chart';
import MapChart from './MapChart';
import SubscriptionsAnalytics from './SubscriptionsAnalytics';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { API_URL } from '../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SpinnerContainer, ImageContainer, Thumbnail, Span, BrandLogo, StyledHandIcon, Heading1, Heading2, StyledLink, StyledButtonK, ContentContainer } from './PostDetails.Styles';
import { Close, CloseIcon } from './PublicStyles';
import { PaginationWrapper,
    Pagination,
    PageNumber,
    PaginationButton } from './Home.styles';

const Analytics = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({ views: [], sells: 0, follows: 0, earnings: 0, posts: [] });
    const [allTimeData, setAllTimeData] = useState({ views: [], sells: 0, follows: 0, earnings: 0 });
    const [filter, setFilter] = useState('today');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [customStartDate, setCustomStartDate] = useState(new Date());
    const [customEndDate, setCustomEndDate] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const postsPerPage = 10;
    const postsListRef = useRef(null);

    useEffect(() => {
        fetchAnalyticsData();
    }, [filter, startDate, endDate]);

    useEffect(() => {
        fetchAllTimeData();
    }, []);

    const fetchAnalyticsData = async () => {
        const token = await getValidToken(navigate, false);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const params = {
                    filter,
                    startDate: filter === 'all' ? undefined : startDate.toISOString().split('T')[0],
                    endDate: filter === 'all' ? undefined : endDate.toISOString().split('T')[0],
                    page: currentPage,
                    limit: postsPerPage,
                };
                const res = await axios.get(`${API_URL}/analytics/user`, { ...config, params });
                setData(res.data);
                setTotalPages(res.data.totalPages); 
            } catch (error) {
                console.error('Error fetching analytics data:', error);
            }
        }
    };

    const fetchAllTimeData = async () => {
        const token = await getValidToken(navigate, false);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const res = await axios.get(`${API_URL}/analytics/all-time`, config);
                setAllTimeData(res.data);
            } catch (error) {
                console.error('Error fetching all-time analytics data:', error);
            }
        }
    };

    // Utility function to get views by type and sum the counts
    const getViewsByType = (views, type) => {
        return views
            .filter(v => v.view_type === type)
            .reduce((total, view) => total + view.count, 0);
    };

    // Utility function to get views by post ID and type
    const getPostViewsByType = (views, postId, type) => {
        return views
            .filter(v => v.post_id === postId && v.view_type === type)
            .reduce((total, view) => total + view.count, 0);
    };

    // Utility function to get combined views by post ID
    const getPostTotalViews = (views, postId) => {
        return views
            .filter(v => v.post_id === postId)
            .reduce((total, view) => total + view.count, 0);
    };

    // Utility function to calculate total views
    const calculateTotalViews = (views) => {
        return views.reduce((total, view) => total + view.count, 0);
    };

    // Utility function to handle unknown countries
    const countViewsWithUnknownCountry = (views, type) => {
        return views
            .filter(v => v.view_type === type && (v.country === 'unknown' || !v.country))
            .reduce((total, view) => total + view.count, 0);
    };

    const getPostSells = (postId) => {
        const post = data.posts.find(post => post.id === postId);
        return post ? post.sells : 0;
    };
    
    const getPostEarnings = (postId) => {
        const post = data.posts.find(post => post.id === postId);
        return post ? post.earnings : 0;
    };
    

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);

        const today = new Date();
        let newStartDate, newEndDate;

        switch (selectedFilter) {
            case 'yesterday':
                newStartDate = new Date(today);
                newStartDate.setDate(newStartDate.getDate() - 1);
                newEndDate = new Date(newStartDate);
                break;
            case 'last-week':
                newStartDate = new Date(today);
                newStartDate.setDate(newStartDate.getDate() - 7);
                newEndDate = today;
                break;
            case 'last-month':
                newStartDate = new Date(today);
                newStartDate.setMonth(newStartDate.getMonth() - 1);
                newEndDate = today;
                break;
            case 'this-month':
                newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
                newEndDate = today;
                break;
            case 'today':
                newStartDate = today;
                newEndDate = today;
                break;
            case 'custom':
                newStartDate = customStartDate;
                newEndDate = customEndDate;
                break;
            default:
                newStartDate = today;
                newEndDate = today;
                break;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleCustomStartDateChange = (date) => {
        setCustomStartDate(date);
        setStartDate(date);
        if (customEndDate && date > customEndDate) {
            setCustomEndDate(date);
            setEndDate(date);
        }
    };

    const handleCustomEndDateChange = (date) => {
        setCustomEndDate(date);
        setEndDate(date);
        if (customStartDate && date < customStartDate) {
            setCustomStartDate(date);
            setStartDate(date);
        }
    };

    const formatEarnings = (earnings) => {
        if (isNaN(parseFloat(earnings)) || !isFinite(earnings)) {
            return 'Invalid earnings';
        }
        earnings = parseFloat(earnings);
        if (isNaN(earnings) || !isFinite(earnings)) {
            return 'Invalid earnings';
        }
        let earningsStr = earnings.toFixed(2);
        const parts = earningsStr.split('.');
        let wholePart = parts[0];
        const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
        const regex = /\B(?=(\d{3})+(?!\d))/g;
        wholePart = wholePart.replace(regex, ',');
        return wholePart + decimalPart;
    };

    const formattedEarnings = formatEarnings(data?.earnings);

    const handleCloseIcon = () => {
        navigate('/');
    };

    useEffect(() => {
        fetchAnalyticsData();
    }, [currentPage]);

    const scrollToTop = () => {
        postsListRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handlePageClick = (page) => {
        setCurrentPage(page);
        scrollToTop();
      };

      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          scrollToTop();
        }
      };
    
      const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scrollToTop();
        }
      };  

    return (
        <>
            <Header>
                <BrandLogo onClick={() => navigate('/')}>
                    <h2>Arewa <Span>Gida</Span></h2>
                </BrandLogo>
                <Close onClick={handleCloseIcon}>
                    <CloseIcon />
                </Close>
            </Header>

            <Container>
                
            <Title>Analytics</Title>

            <FilterDiv>
                <label><FaFilter /> Filter:</label>
                <select onChange={handleFilterChange} value={filter}>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="last-week">Last Week</option>
                    <option value="last-month">Last Month</option>
                    <option value="custom">Custom</option>
                    <option value="all">All</option>
                </select>

                {filter === 'custom' && (
                    <DatePickerDiv>
                        <label>Start Date:</label>
                        <DatePicker selected={customStartDate} onChange={handleCustomStartDateChange} />
                        <label>End Date:</label>
                        <DatePicker selected={customEndDate} onChange={handleCustomEndDateChange} />
                    </DatePickerDiv>
                )}
            </FilterDiv>

            <h2>{filter.charAt(0).toUpperCase() + filter.slice(1)} Data</h2>
            <InsightsContainer>
                <div><span>{calculateTotalViews(data.views)}</span> <h4>Total Views</h4></div>
                <div><span>{getViewsByType(data.views, 'trailer')}</span> <h4>Trailer Views</h4></div>
                <div><span>{getViewsByType(data.views, 'main')}</span> <h4>Main Views</h4></div>
                <div><span>{data.sells}</span> <h4>Total Sells</h4></div>
                <div><span>₦{formattedEarnings}</span> <h4>Total Earnings</h4></div>
                <div><span>{data.follows}</span> <h4>Total Follow</h4></div>
            </InsightsContainer>

            <DataDivTitle>Posts Lists</DataDivTitle>
            <PostListsDiv ref={postsListRef}>
            {data.posts && data.posts.map(post => (
                    <PostCard key={post.id}>
                        <PostTitleDiv>
                        <h4>{post.title}</h4>
                        </PostTitleDiv>
                        <PostDetailsDiv>
                        <p>Trailer Views: <span>{getPostViewsByType(data.views, post.id, 'trailer') + countViewsWithUnknownCountry(data.views, 'trailer')}</span></p>
                        <p>Main Views: <span>{getPostViewsByType(data.views, post.id, 'main') + countViewsWithUnknownCountry(data.views, 'main')}</span></p>
                        <p>Total Views: <span>{getPostTotalViews(data.views, post.id)}</span></p>
                        <p>Sells: <span>{getPostSells(post.id)}</span></p>
                        <p>Earnings: <span>₦{getPostEarnings(post.id)}</span></p>
                        </PostDetailsDiv>
                    </PostCard>
                ))}

            <PaginationWrapper>
                <Pagination>
                <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
                </PaginationButton>
                {Array.from({ length: totalPages }, (_, index) => (
                    <PageNumber 
                    key={index + 1} 
                    onClick={() => handlePageClick(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}>
                    {index + 1}
                    </PageNumber>
                ))}
                <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
                </PaginationButton>
                </Pagination>
            </PaginationWrapper>
            </PostListsDiv>

            <DataDivTitle>Subscribers</DataDivTitle>    
            <SubscriptionsAnalytics />

            <DataDivTitle>Chart</DataDivTitle>    
            <Chart data={data} filter={filter} />

            <DataDivTitle>Map</DataDivTitle>
            <MapChart data={data} filter={filter}/>
        </Container>
        </>
    );
};

const Container = styled.div`
    padding: 20px;
    padding-top: 50px;
    min-height: 100vh;
    color: ${props => props.theme.colors.text};
    background-color: ${props => props.theme.colors.background};
    // border-radius: ${props => props.theme.borderRadius.card};
`;

const InsightsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.6rem;
  margin-bottom: 10px;

  > div {
    padding: ${props => props.theme.padding.card};
    border-radius: ${props => props.theme.borderRadius.card};
    margin-top: 1rem;
    box-shadow: ${props => props.theme.boxShadow};
    transition: all 300ms ease;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items to center horizontally */
    text-align: center; /* Align text content to center */

    &:hover {
      box-shadow: none;
      border: 2px solid green;
    }

    &:active {
      box-shadow: none;
      border: 6px solid green;
    }

    span {
      background-color: ${props => props.theme.colors.primary};
      padding: 0.5rem;
      border-radius: 10px;
      color: ${props => props.theme.colors.white};
      font-size: 1.5rem; /* Adjusted icon size */
      margin-bottom: 10px;
    }

    .middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h3 {
      margin: 1rem 0 0.6rem;
      font-size: 1.5rem;
      color: ${props => props.theme.colors.text};
    }

    h1 {
      color: ${props => props.theme.colors.text};
    }

    small {
      display: block;
      margin-top: 1.3rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Two items side by side on mobile */

    h3 {
      font-size: .8rem;
    }

    h1 {
      font-size: 1rem;
    }
  }
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  //left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 10px; /* Add padding for some space around the content */
  //z-index: 27;
`;

const Title = styled.h2`
    margin-top: 10px;
`;

const DataDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    div {
        flex: 1 1 45%;
        margin: 10px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        text-align: center;
        span {
            display: block;
            font-size: 2em;
        }
    }

    @media (max-width: 600px) {
        div {
            flex: 1 1 100%;
        }
    }
`;

const DatePickerDiv = styled.div`

`;

const FilterDiv = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    label {
        margin-right: 10px;
    }
    select {
        padding: 5px; 
    }
`;

const ChartDiv = styled.div`
    margin: 20px 0;
`;

const PostListsDiv = styled.div`
    //display: flex;
    //flex-direction: column;
    align-items: center;
    //margin: 40px 0; // Top and Bottom 40px
`;

const PostDiv = styled.div`
    margin-bottom: 10px;

    h4 {
        margin: 0;
    }

    p {
        margin: 5px 0 0 0;
    }
`;

const PostCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 10px;
    background-color: ${props => props.theme.colors.background2};
    color: ${props => props.theme.colors.text};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PostTitleDiv = styled.div`
    flex: 1;
    margin-right: 20px;

    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
    }

    h4 {
        margin: 0;
        color: ${props => props.theme.colors.text};
    }
`;

const PostDetailsDiv = styled.div`
    //flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
        margin: 0;
        color: ${props => props.theme.colors.text};
    }
    
    span {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.primary};
        padding: 0.3rem;
        border-radius: 10px;
        font-size: 1rem; /* Adjusted icon size */
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const DataDivTitle = styled.h2`
    font-weight: bold;
    margin: 40px 0; // Top and Bottom 40px
`;

export default Analytics;
