import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { API_URL } from '../../config';
import { getValidToken } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

const WithdrawalRequest = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = useState('pending');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [counts, setCounts] = useState({ pending: 0, waiting: 0, approved: 0, failed: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    fetchWithdrawals();
  }, [activeTab, currentPage, searchTerm]);

  const fetchWithdrawals = async () => {
    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        };
        const response = await axios.get(`${API_URL}/withdrawals/requests`, {
          params: {
            status: activeTab,
            page: currentPage,
            search: searchTerm,
          },
          ...config,
        });
        setWithdrawals(response.data.withdrawals || []);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching withdrawals:', error);
      }
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  const fetchCounts = async () => {
    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        };
        const response = await axios.get(`${API_URL}/withdrawals/requests/counts`, config);
        setCounts(response.data);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    }
  };

  const handleStatusUpdate = async (withdrawalId, status, message = '') => {
    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        };
        await axios.put(`${API_URL}/withdrawals/update/${withdrawalId}`, { status, message }, config);
        setSearchTerm('');
        fetchWithdrawals();
        fetchCounts();
      } catch (error) {
        console.error('Error updating status:', error);
      }
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredWithdrawals = withdrawals.filter(withdrawal => {
    if (activeTab === 'pending') return withdrawal.status === 'pending';
    if (activeTab === 'waiting') return withdrawal.status === 'waiting';
    if (activeTab === 'approved') return withdrawal.status === 'approved';
    if (activeTab === 'failed') return withdrawal.status === 'failed';
    return true;
  });

  return (
    <Container>
      <Header>
        <div>
          <h1>Arewa Kallo</h1>
        </div>
        <div>
          <MdClose onClick={() => { navigate('/dashboard') }} />
        </div>
      </Header>

      <Title>Withdrawal Requests</Title>

      <SearchBar>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </SearchBar>

      <Tabs>
        <Tab active={activeTab === 'pending'} onClick={() => handleTabChange('pending')}>
          New Requests <span>({counts.pending})</span>
        </Tab>
        <Tab active={activeTab === 'waiting'} onClick={() => handleTabChange('waiting')}>
          Waiting Requests <span>({counts.waiting})</span>
        </Tab>
        <Tab active={activeTab === 'approved'} onClick={() => handleTabChange('approved')}>
          Approved Requests <span>({counts.approved})</span>
        </Tab>
        <Tab active={activeTab === 'failed'} onClick={() => handleTabChange('failed')}>
          Failed Requests <span>({counts.failed})</span>
        </Tab>
      </Tabs>

      {filteredWithdrawals.map((withdrawal) => (
        <WithdrawalItemCard key={withdrawal.id}>
          <div>
            <strong>Amount: ${withdrawal.amount}</strong>
            <p>Producer: {withdrawal.producer_username}</p>
            <p>Producer Ac: {withdrawal.producer_account_number}</p>
            <p>Producer Bank name: {withdrawal.producer_bank_name}</p>
            <p>Producer Ac Name: {withdrawal.producer_account_name}</p>
            <p>Status: {withdrawal.status}</p>
            <p>Timestamp: {withdrawal.timestamps}</p>
          </div>
          <div>
            {withdrawal.status === 'pending' && (
              <>
                <Button bgColor="#28a745" hoverColor="#218838" onClick={() => handleStatusUpdate(withdrawal.transaction_id, 'approved')}>Approve</Button>
                <MessageInput
                  placeholder="Type message"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button bgColor="#ffc107" hoverColor="#e0a800" onClick={() => handleStatusUpdate(withdrawal.transaction_id, 'waiting', message)}>Waiting</Button>
                <Button bgColor="#dc3545" hoverColor="#c82333" onClick={() => handleStatusUpdate(withdrawal.transaction_id, 'failed')}>Reject</Button>
              </>
            )}
            <Button bgColor="#ffc107" hoverColor="#e0a800" onClick={() => handleStatusUpdate(withdrawal.id, 'pending')}>Reset to Pending</Button>
          </div>
        </WithdrawalItemCard>
      ))}

      <Pagination>
        <Button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </Button>
        <PageIndicator>Page {currentPage} of {totalPages}</PageIndicator>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </Button>
      </Pagination>
    </Container>
  );
};

export default WithdrawalRequest;

// Styled Components
const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin: 0 auto;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    margin: 0;
  }

  svg {
    cursor: pointer;
    font-size: 24px;
  }
`;

const SearchBar = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
`;


const Tabs = styled.div`
  display: flex;
  margin-bottom: 20px;
  max-width: 600px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  background: ${(props) => (props.active ? '#007bff' : '#f8f9fa')};
  color: ${(props) => (props.active ? '#fff' : '#000')};
  border: 1px solid #ddd;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:hover {
    background: ${(props) => (props.active ? '#0056b3' : '#e2e6ea')};
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.text};
  margin-bottom: 10px;
`;

const WithdrawalItemCard = styled.div`
  background-color: ${props => props.theme.colors.background2};
  color: ${props => props.theme.colors.text};
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 10px;

  div {
    flex: 1;
    color: ${props => props.theme.colors.text};

    strong {
      display: block;
      margin-bottom: 5px;
    }

    p {
      margin: 0 0 5px 0;
    }
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  background-color: ${(props) => props.bgColor};
  color: #fff;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }

  &:last-child {
    margin-right: 0;
  }
`;

const MessageInput = styled.input`
  margin: 10px 0;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PageIndicator = styled.span`
  margin: 0 10px;
`;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { API_URL } from '../../config';
// import { getValidToken } from '../../utils/auth';
// import { useNavigate } from 'react-router-dom';


// const Container = styled.div`
//   max-width: 800px;
//   margin: 20px auto;
// `;

// const WithdrawalItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 1px solid #ccc;
//   padding: 10px 0;

//   &:last-child {
//     border-bottom: none;
//   }
// `;

// const Button = styled.button`
//   padding: 5px 10px;
//   margin-left: 10px;
//   cursor: pointer;
//   background-color: ${(props) => props.bgColor || '#007bff'};
//   color: #fff;
//   border: none;
//   border-radius: 5px;

//   &:hover {
//     background-color: ${(props) => props.hoverColor || '#0056b3'};
//   }
// `;

// const WithdrawalRequest = () => {
//   const [withdrawals, setWithdrawals] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchWithdrawals();
//   }, []);

//   const fetchWithdrawals = async () => {
//     const token = await getValidToken(navigate, false);
//     if (token) {
//     try {
//       const config = {
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//         withCredentials: true,
//     };
//       const response = await axios.get(`${API_URL}/withdrawals/requests`, config);
//       setWithdrawals(response.data);
//     } catch (error) {
//       console.error('Error fetching withdrawals:', error);
//     }
//   }
//   };

//   const handleStatusUpdate = async (withdrawalId, status) => {
//     const token = await getValidToken(navigate, false);
//     if (token) {
//     try {
//       const config = {
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//         withCredentials: true,
//     };
//       await axios.put(`${API_URL}/withdrawals/update/${withdrawalId}`, { status }, config);
//       fetchWithdrawals();
//     } catch (error) {
//       console.error('Error updating status:', error);
//     }
//   }
//   };

//   return (
//     <Container>
//       <h2>Withdrawal Requests</h2>
//       {withdrawals.map((withdrawal) => (
//         <WithdrawalItem key={withdrawal.id}>
//           <div>
//             <strong>Amount: ${withdrawal.amount}</strong>
//             <p>Producer: {withdrawal.producer_username}</p>
//             <p>Status: {withdrawal.status}</p>
//             <p>Timestamp: {new Date(withdrawal.timestamps).toLocaleString()}</p>
//           </div>
//           <div>
//             {withdrawal.status === 'pending' && (
//               <>
//                 <Button bgColor="#28a745" hoverColor="#218838" onClick={() => handleStatusUpdate(withdrawal.transaction_id, 'approved')}>Approve</Button>
//                 <Button bgColor="#dc3545" hoverColor="#c82333" onClick={() => handleStatusUpdate(withdrawal.transaction_id, 'failed')}>Reject</Button>
//               </>
//             )}
//             <Button bgColor="#ffc107" hoverColor="#e0a800" onClick={() => handleStatusUpdate(withdrawal.id, 'pending')}>Reset to Pending</Button>
//           </div>
//         </WithdrawalItem>
//       ))}
//     </Container>
//   );
// };

// export default WithdrawalRequest;
