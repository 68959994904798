import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import Spinner from '../Spinner'
import styled from 'styled-components';


const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleLabel = styled.label`
  margin-right: 10px;
`;

const ToggleButton = styled.input`
  margin-right: 15px;
`;

const AdminPanel = () => {
  const [shuffled, setShuffled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  console.log('shuffled in fronent initially', shuffled);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get(`${API_URL}/public/check/shuffled/settings`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { shuffled_posts: initialShuffled } = response.data;
        setShuffled(initialShuffled);
        console.log('initialValue', initialShuffled);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching settings:', error.response?.data || error.message);
      }
    };

    fetchSettings();
  }, []);

  const handleToggle = async () => {
    const token = localStorage.getItem('jwtToken');
    try {
        console.log('Toggle value:', !shuffled); // Add this line
      await axios.post(
        `${API_URL}/admin/settings`,
        { shuffled: !shuffled }, // Toggle the value directly here
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT token in the request headers
          },
        }
      );
      setShuffled(!shuffled); // Toggle the state locally after successful request
    } catch (error) {
      console.error('Error updating settings:', error.response?.data || error.message);
    }
  };

  if (isLoading) {
    return (
      <Spinner />
    )
  }

  return (
    <ToggleContainer>
      <ToggleLabel>Shuffled Posts:</ToggleLabel>
      <ToggleButton type="checkbox" checked={shuffled} onChange={handleToggle} />
    </ToggleContainer>
  );
};

export default AdminPanel;
