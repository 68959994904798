import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import axios from 'axios';
import { Typography, TextField, Button, Grid, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { IoMdCloseCircle } from "react-icons/io";
import { Container, Card, Close, StyledButton, InputBox, StyledInput, Label, } from './EditUser.styles';
import { TitleContainer, Title } from './styles';
const EditUser = ({ userId, onClose }) => {
  const { id: urlId } = useParams();
  const navigate = useNavigate(); 

  const [userData, setUserData] = useState(null);
  const [updatedUserData, setUpdatedUserData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    role: '',
    active: false,
    password: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${API_URL}/users/${urlId}`, config);
        const fetchedUserData = response.data;
        console.log('Response:', response.data);
        setUserData(fetchedUserData);
        // Only allow changing email, phone, and password
        setUpdatedUserData({
          first_name: fetchedUserData.first_name,
          last_name: fetchedUserData.last_name,
          email: fetchedUserData.email,
          phone: fetchedUserData.phone,
          password: '',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data. Please try again later.');
      }
    };
    fetchUserData();
  }, [urlId]);

  if (!userData) {
    return <Typography variant="body2">Loading...</Typography>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('jwtToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.put(`${API_URL}/users/${urlId}`, updatedUserData, config);
      navigate('/admin/users');
    } catch (error) {
      console.error('Error saving user data:', error);
      setError('Error saving user data. Please try again later.');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const token = localStorage.getItem('jwtToken');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        await axios.delete(`${API_URL}/users/${urlId}`, config);
        navigate('/admin/users');
      } catch (error) {
        console.error('Error deleting user:', error);
        setError('Error deleting user. Please try again later.');
      }
    }
  };

  return (
    <Container>
    <Card>
      <Close onClick={() => navigate('/admin/users')}>
      <IoMdCloseCircle style={{ fontSize: '34px', fontWeight: '500' }} />
      </Close>
      <div>
      <TitleContainer>
        <Title>
          Edit User
        </Title>
        </TitleContainer>
        {error && <Typography variant="body2" color="error">{error}</Typography>}
        <form onSubmit={handleSave}>
          <InputBox>
          <StyledInput
            fullWidth
            type='text'
            variant="outlined"
            label="Username"
            value={userData.username} // Display the username (unclickable and unchangeable)
            margin="normal"
            required
            disabled
          />
          {/* <Label>Username</Label> */}
          </InputBox>

          <InputBox>
          <StyledInput
            fullWidth
            type='text'
            variant="outlined"
            label="First_Name"
            name="first_name"
            value={updatedUserData.first_name} 
            onChange={handleChange}
            margin="normal"
            required
          //  disabled
          />
          <Label>First_Name</Label>
          </InputBox>
          
          <InputBox>
          <StyledInput
            fullWidth
            type='text'
            variant="outlined"
            label="Last_Name"
            name="last_name"
            value={updatedUserData.last_name} 
            onChange={handleChange}
            margin="normal"
            required
          //  disabled
          />
          <Label>Last_Name</Label>
          </InputBox>

          <InputBox>
          <StyledInput
            fullWidth
            type='text'
            variant="outlined"
            label="Email"
            name="email"
            value={updatedUserData.email}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Label>Email</Label>
          </InputBox>

          <InputBox>
          <StyledInput
            fullWidth
            type='text'
            variant="outlined"
            label="Phone"
            name="phone"
            value={updatedUserData.phone}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Label>Phone Number</Label>
          </InputBox>

          <InputBox>
          <StyledInput
            fullWidth
            variant="outlined"
            label="New Password"
            name="password"
            type="password"
            value={updatedUserData.password}
            onChange={handleChange}
            placeholder="Password"
            margin="normal"
          />
          <Label>Password</Label>
          </InputBox>

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              value={userData.role} // Display the actual role based on fetched data
              onChange={handleChange}
              label="Role"
            >
              <MenuItem value="1">Admin</MenuItem>
              <MenuItem value="2">User</MenuItem>
              <MenuItem value="3">Producer</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Active</InputLabel>
            <Select
              name="active"
              value={userData.active ? '1' : '0'} // Display the actual active status based on fetched data
              onChange={handleChange}
              label="Active"
            >
              <MenuItem value="1">Approved</MenuItem>
              <MenuItem value="0">Pending</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
            <StyledButton variant="contained" color="primary" type="submit">
              Save
            </StyledButton>
            <StyledButton variant="contained" color="default" onClick={() => navigate('/admin/users')}>
              Cancel
            </StyledButton>
          </div>
        </form>
        <StyledButton variant="contained" color="secondary" onClick={handleDelete}>
          Delete User
        </StyledButton>
        </div>
    </Card>
    </Container>
  );
};

export default EditUser;
