import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaEye, FaEyeSlash, FaTimes, FaSave, FaSearch } from 'react-icons/fa';
import html2canvas from 'html2canvas';
import logo from '../assets/ArewaVip.png';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdDateRange, MdClose } from "react-icons/md";


import {LoadingIcon, SuccessIcon, ErrorIcon} from './PublicStyles';

const Earnings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submittedSuccessfull, setSubmittedSuccessfull] = useState(false);
  const [earnings, setEarnings] = useState(null);
  const [username, setUsername] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawError, setWithdrawError] = useState('');
  const [showWithdrawInput, setShowWithdrawInput] = useState(false);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [showBalance, setShowBalance] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const transactionsPerPage = 5;
  const transactionsListRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const { t } = useTranslation();

  console.log('Earnings', earnings)

  useEffect(() => {
    fetchEarnings();
    fetchWithdrawalHistory(currentPage, 5, startDate, endDate, searchTerm);
  }, [navigate, currentPage, 5, startDate, endDate, searchTerm]);

  const fetchEarnings = async () => {
    const token = await getValidToken(navigate, true);
      if (token) {
    try {
      const response = await axios.get(`${API_URL}/producer/earnings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      const { earnings, username } = response.data;
      setEarnings(earnings);
      setUsername(username);
    } catch (error) {
      console.error('Error fetching earnings:', error);
    }
  }
  };

  const fetchWithdrawalHistory = async (page = 1, limit = 5, startDate = null, endDate = null, searchTerm = '') => {
    const token = await getValidToken(navigate, true);
      if (token) {
    try {
      const response = await axios.get(`${API_URL}/withdrawals/user-requests`, {
          params: {
            page,
            limit,
            startDate,
            endDate,
            search: searchTerm,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
      setWithdrawalHistory(response.data.withdrawals);
      setTotalTransactions(response.data.total); // assuming the backend returns the total number of transactions
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
    }
  }
  };


  const handleWithdraw = async (e) => {
    e.preventDefault();
    const token = await getValidToken(navigate, true);
    if (token) {
      setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };
      const formattedWithdrawAmount = parseFloat(withdrawAmount).toFixed(2);
      const response = await axios.post(`${API_URL}/producer/withdraw`, { amount: formattedWithdrawAmount }, config);
      setLoading(false);
      setSubmittedSuccessfull(true);
      setMessage(response.data.message);
      setMessageType('success');
      fetchEarnings();
      setWithdrawAmount('');
      setWithdrawError('');
      setShowWithdrawInput(false);
      fetchWithdrawalHistory();
      setTimeout(() => {
        setMessage('');
        setSubmittedSuccessfull(false);
      }, 5000);
    } catch (error) {
      console.error('Error withdrawing:', error);
      setWithdrawError(error.response?.data?.error || 'An error occurred.');
      setMessageType('error');
      setLoading(false);
      setTimeout(() => {
        setWithdrawError('');
      }, 8000);
    }
  }
  };
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    fetchWithdrawalHistory(1, 5, startDate, endDate, e.target.value);
  };
  
  const handleStartDateChange = (date) => {
    setStartDate(date);
    fetchWithdrawalHistory(1, 5, date, endDate, searchTerm);
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
    fetchWithdrawalHistory(1, 5, startDate, date, searchTerm);
  };

  const handleChange = (e) => {
    setWithdrawAmount(e.target.value);
  };

  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance);
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleCloseModal = () => {
    setSelectedTransaction(null);
  };

  const handleSaveAsImage = () => {
    const transactionDetails = document.getElementById('transaction-details');

    // Hide the Save Button and Close Icon
    transactionDetails.classList.add('hide-save-and-close');

    html2canvas(transactionDetails).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'transaction-details.png';
      link.click();

      // Show the Save Button and Close Icon
      transactionDetails.classList.remove('hide-save-and-close');
    });
  };


  // Pagination
  const totalPages = Math.ceil(totalTransactions / transactionsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    transactionsListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
    if (datePickerVisible) setDatePickerVisible(false);
    setSearchTerm('');
  };

  const toggleDatePicker = () => {
    setDatePickerVisible(!datePickerVisible);
    if (searchVisible) setSearchVisible(false);
    setStartDate('');
    setEndDate('');
  };

  // Format earnings with commas
  const formatEarnings = (earnings) => {
    // Check if earnings is a valid number or string that can be converted to a number
    if (isNaN(parseFloat(earnings)) || !isFinite(earnings)) {
      return 'Invalid earnings';
    }
  
    // Convert earnings to number and ensure it's a valid number
    earnings = parseFloat(earnings);
    if (isNaN(earnings) || !isFinite(earnings)) {
      return 'Invalid earnings';
    }
  
    // Convert earnings to string with 2 decimal places
    let earningsStr = earnings.toFixed(2);
  
    // Split earnings into whole and decimal parts
    const parts = earningsStr.split('.');
    let wholePart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Add commas for thousands, millions, etc.
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    wholePart = wholePart.replace(regex, ',');
  
    // Return formatted earnings
    return wholePart + decimalPart;
  };
  
    // Format earnings with custom function
    const formattedEarnings = formatEarnings(earnings);

  return (
    <BgBig>
    <Container>
      <WalletCard>
        <WelcomeUserDiv>{username} Earnings</WelcomeUserDiv>
        <BalanceAndAddButtonDiv>
          <BalanceDiv>
            Earnings: {showBalance ? `N${formattedEarnings}` : '*********'}
            <button onClick={toggleBalanceVisibility}>
              {showBalance ? <FaEyeSlash /> : <FaEye />}
            </button>
          </BalanceDiv>
        </BalanceAndAddButtonDiv>
      </WalletCard>

      <hr />

      {message && <Message type={messageType}>{message}</Message>}

      {!showWithdrawInput && (
        <Button onClick={() => setShowWithdrawInput(true)}>Withdraw</Button>
      )}

      {showWithdrawInput && (
        <div style={{ marginTop: '20px' }}>
          {withdrawError && <ErrorMessage>{withdrawError}</ErrorMessage>}
          <StyledInput
            type="number"
            placeholder="Enter withdrawal amount"
            value={withdrawAmount}
            onChange={handleChange}
            required
          />
          <Label>Enter withdrawal amount</Label>
          {/* <StyledButton onClick={handleWithdraw}>Submit</StyledButton> */}
          <StyledButton onClick={handleWithdraw} type="submit" disabled={loading || submittedSuccessfull}>
          {submittedSuccessfull ? <SuccessIcon /> : withdrawError ? <ErrorIcon /> : `${t('submit')}`}
          {loading && <LoadingIcon />} 
        </StyledButton>
        </div>
      )}

      <TransactionDetailsDiv ref={transactionsListRef}>
        <WithdrawalHistoryh3>Withdrawal History</WithdrawalHistoryh3>

        <SearchAndDatePickerDiv>

        <DatePickerContainer>
          <Icon onClick={toggleDatePicker}>
            {datePickerVisible ? <MdClose /> : <MdDateRange />}
          </Icon>
          <DatePickerWrapper visible={datePickerVisible}>
            <DateLabel>Start Date:</DateLabel>
            <StyledDatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              isClearable
              dateFormat="yyyy/MM/dd"
              placeholderText="Select start date"
            />
            <DateLabel>End Date:</DateLabel>
            <StyledDatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              isClearable
              dateFormat="yyyy/MM/dd"
              placeholderText="Select end date"
            />
          </DatePickerWrapper>
        </DatePickerContainer>

          <SearchContainer>
          <SearchInput
            visible={searchVisible}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search..."
          />
          <Icon onClick={toggleSearch}>
            {searchVisible ? <FaTimes /> : <FaSearch />}
          </Icon>
        </SearchContainer>
        </SearchAndDatePickerDiv>


        {withdrawalHistory.map((withdrawal) => (
          <TransactionCard
            key={withdrawal.id}
            onClick={() => handleTransactionClick(withdrawal)}
            status={withdrawal.status}
          >
            <div>
              <span>{withdrawal.producer_username}</span>
              <span>Amount: N{withdrawal.amount}</span>
              <span>On: {new Date(withdrawal.timestamp).toLocaleString()}</span>
            </div>
            <StatusDiv status={withdrawal.status}>
              <span>{withdrawal.status}</span>
            </StatusDiv>
            <span>{withdrawal.transaction_type === 'debit' ? 'Debit' : 'Credit'}</span>
          </TransactionCard>
        ))}
        <PaginationWrapper>
        <Pagination>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <PageNumber 
            key={index + 1} 
            onClick={() => handlePageClick(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}>
              {index + 1}
            </PageNumber>
          ))}
          <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </PaginationButton>
        </Pagination>
        </PaginationWrapper>
      </TransactionDetailsDiv>

      {selectedTransaction && (
        <ModalOverlay>
          <ModalContent id="transaction-details">
            <CloseModalButton className="close-modal-button" onClick={handleCloseModal}>
              <FaTimes />
            </CloseModalButton>
            <Logo src={logo} alt="Company Logo" />
            <h2>Transaction Details</h2>
            <TransactionDetail>
              <span>Username:</span> {selectedTransaction.producer_username}
            </TransactionDetail>
            <TransactionDetail>
              <span>Producer Name:</span> {selectedTransaction.producer_username}
            </TransactionDetail>
            <TransactionDetail>
              <span>Amount:</span> N{selectedTransaction.amount}
            </TransactionDetail>
            <TransactionDetail>
              <span>Created At:</span> {new Date(selectedTransaction.timestamp).toLocaleString()}
            </TransactionDetail>
            <TransactionDetail>
              <span>Status:</span> {selectedTransaction.status}
            </TransactionDetail>
            <SaveButton className="save-button" onClick={handleSaveAsImage}>
              <FaSave /> Save as Image
            </SaveButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
    </BgBig>
  );
};

export default Earnings;

// Styled Components

const BgBig = styled.div`
  background-color: ${(props) => props.theme.colors.background};
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const WalletCard = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    padding: 15px;
  }
`;

const WelcomeUserDiv = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const BalanceAndAddButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BalanceDiv = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const SearchAndDatePickerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Icon = styled.div`
  cursor: pointer;
  font-size: 24px;
  margin-right: 8px;
`;

const SearchInput = styled.input`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  padding: 8px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #ccc;
  width: 300px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const DateLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  color: #333;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const StyledButton = styled(Button)`
  background-color: #28a745;
  width: 100%;
  &:hover {
    background-color: #218838;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const TransactionDetailsDiv = styled.div`
  margin-top: 20px;
  h3 {
    margin-bottom: 5px;
  }
`;

const WithdrawalHistoryh3 = styled.h3`
  color: ${props => props.theme.colors.text};
`;

const TransactionCard = styled.div`
  background-color: ${props => props.theme.colors.background2};
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${(props) => (
    props.status === 'approved' ?  '1px solid #c3e6cb' :
    props.status === 'pending' ? '1px solid #e9edc0' :
    props.status === 'failed' ? '1px solid #f5c6cb' : ' 1px solid #c3e6cb'
  )};
  cursor: pointer;
  div {
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.text};
  }
  &:hover {
    background-color: ${(props) => (
      props.status === 'approved' ? '#c3e6cb' :
      props.status === 'pending' ? '#e9edc0' :
      props.status === 'failed' ? '#f5c6cb' : '#c3e6cb'
    )};
    div {
      color: #333333;
    }
  }
`;

const StatusDiv = styled.div`
  margin-top: 10px;
  padding: 5px;
  border-radius: 10px;
  color: #333333;
  background: ${({ status }) => 
    status === 'approved' ? '#27d651' :
    status === 'pending' ? '#f5c91b' : 
    status === 'failed' ? '#fa5067' : 
    '#fff3cd'
  };
  span {
    color: #333333;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;
`;

const Pagination = styled.div`
  display: flex;
  gap: 5px;
  .active {
    color: #28a745;
    font-weight: bold;
  }
`;

const PageNumber = styled.div`
  padding: 8px 12px;
  margin: 0 3px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  margin: 0 3px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 20px;
`;

const TransactionDetail = styled.div`
  margin-bottom: 10px;
  span {
    font-weight: bold;
  }
`;

const SaveButton = styled(Button)`
  background-color: #28a745;
  &:hover {
    background-color: #218838;
  }
`;

const Message = styled.div`
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.type === 'success' ? '#d4edda' : '#f8d7da')};
  color: ${(props) => (props.type === 'success' ? '#155724' : '#721c24')};
`;