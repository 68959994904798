import React from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
`;

const TermsTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text};
`;

const TermsText = styled.p`
    margin-bottom: 10px;
    line-height: 1.6;
    color: ${props => props.theme.colors.text};
`;


const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const TermsAndConditionsSignup = ({ onClose, iAgree }) => {

    const handleIagree = () => {
        iAgree();
        onClose();
    }

    return (
        <TermsContainer>
            <TermsTitle>Terms and Conditions</TermsTitle>
            <TermsText>
                By signing up, you agree to the following terms and conditions:
            </TermsText>
            <TermsText>
                1. You can purchase, rent, or subscribe to channels and their content on our platform.
            </TermsText>
            <TermsText>
                2. Each purchase or subscription provides limited time access based on the purchased content, such as 1 month, 1 week, or 3 months. After this period, you will need to repurchase or resubscribe to continue access.
            </TermsText>
            <TermsText>
                3. There are no refunds after payment. Please make sure to review the content and be satisfied before making a payment.
            </TermsText>
            <TermsText>
                4. All payments are final. No refunds or exchanges are allowed.
            </TermsText>
            <TermsText>
                5. You must comply with all applicable laws and platform guidelines while accessing and using the content.
            </TermsText>
            
            <ButtonsDiv>
            <Button onClick={handleIagree}>
                I Agree 
            </Button>
            <Button onClick={onClose} style={{ background: 'red' }}>Close</Button>
            </ButtonsDiv>
        </TermsContainer>
    );
};

export default TermsAndConditionsSignup;