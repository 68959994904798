import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter, Routes, and Route
import { SoundProvider } from './SoundContext';
import AuthCheck from './utils/AuthCheck';
import UserAuthCheck from './utils/UserAuthCheck';
import GlobalStyles from './GlobalStyles';
//import { AxiosProvider } from './utils/AxiosProvider';
//import PrivateRoute from './components/PrivateRoute';
import ConsentBanner from './components/ConsentBanner';
import Home from './components/Home'; // Import Home component
import Register from './components/Register';
import Login from './components/Login';
//import ResetPasswordForm from './components/ResetPasswordForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import VerifyVerificationCode from './components/VerificationCodeForm';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import VerifyEmail from './components/VerifyEmail';
import Logout from './components/Logout';
import UserDetails from './components/UserDetails';
import AddFundModel from './components/AddFundModel';
import Dashboard from './components/Admin/Dashboard'; // Import Dashboard component
import Users from './components/Admin/Users'; // Import Users component
import Transactions from './components/Admin/Transactions'; // Import Transactions component
import Sales from './components/Admin/Sales'; // Import Sales component
import AnalyticsAdmin from './components/Admin/Analytics'; // Import Analytics component
import KasuwaSection from './components/KasuwaSection';
import BlogPostForm from './components/Admin/BlogPostForm';
import Posts from './components/Admin/Posts';
import PostDetailsModal from './components/Admin/PostDetailsModal';
import ProducerAllPosts from './components/ProducerAllPosts';
import Earnings from './components/Earnings';
import EditPost from './components/Admin/EditPost';
import EditPostForUsers from './components/EditPostForUsers';
import PostDetails from './components/PostDetails';
import AuthorDetails from './components/AuthorDetails';
import AdminLogin from './components/Admin/AdminLogin';
import EditUser from './components/Admin/EditUser';
import AddUser from './components/Admin/AddUser';
import UserPurchasedContentLists from './components/UserPurchasedContentLists';
import AdminWalletManagement from './components/Admin/AdminWalletManagement';
import AdminSettings from './components/Admin/AdminSettings';
import WithdrawalRequest from './components/Admin/WithdrawalRequest';
import CreateNewPost from './components/CreateNewPost';
import CreateAdForm from './components/CreateAdForm';
import UpdateAdsForm from './components/UpdateAdsForm';
import ManageAds from './components/ManageAds'; 
import AdDetails from './components/AdDetails';
import Chat from './components/Chat';
import ChatList from './components/ChatList';
import ManageAdsRequests from './components/Admin/ManageAdsRequests';
import AdRequestDetails from './components/Admin/AdRequestDetails';
import Analytics from './components/Analytics';
import PostTicketPurchase from './components/PostTicketPurchase';
import PostsReviewRequests from './components/Admin/PostsReviewRequests';
import BecomeCreatorFormRequests from './components/Admin/BecomeCreatorFormRequests';
import ForeignTransferReceipts from './components/Admin/ForeignTransferReceips';
import BecomeCreatorForm from './components/BecomeCreatorForm';
import ContactUs from './components/ContactUs';
import TicketsSearch from './components/TicketsSearch';
import Notifications from './components/Notifications';

import SabonTsari from './components/SabonTsari';
import LissafinRayuwa from './components/LissafinRayuwa';

//import Settings from './components/Admin/Settings'; // Import Settings component
//import PrivateRoute from './components/PrivateRoute';

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import UserWalletDetails from './components/UserWalletDetails';
import VipSectionNew from './components/VipSectionNew';

//import  ThemeToggle  from './ThemeToggle';

export const ThemeContext = createContext(); // Export ThemeContext

// const ThemeToggle = () => {
//   const { theme, toggleTheme } = useContext(ThemeContext);
  
//   return (
//     <button onClick={toggleTheme}>
//       {theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
//     </button>
//   );
// };



const App = () => {
  const [theme, setTheme] = useState(() => {
  // const toggleTheme = () => {
  //   setTheme(theme === 'light' ? 'dark' : 'light');
  return localStorage.getItem('theme') || 'light';
  });

  useEffect(() => {
    // Store the theme mode in local storage when it changes
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <Router>
    {/* <PrivateRoute> */}
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
    <SoundProvider>
    <GlobalStyles />  
      <div className="App">
        <ConsentBanner />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/verify-code" element={<VerifyVerificationCode />} />
          <Route path="/update-password" element={<UpdatePasswordForm />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/post/:postId" element={<PostDetails />} />
          <Route path="/author/:authorId" element={<AuthorDetails />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/lissafin/rayuwa" element={<LissafinRayuwa />} />


          <Route 
            path="/*" 
            element={
            <UserAuthCheck>
              <Routes>
                {/* <Route path="/post/:postId" element={<PostDetails />} /> */}
                <Route path="/user-details" element={<UserDetails />} />
                <Route path="/upload" element={<CreateNewPost />} />
                <Route path="/earnings" element={<Earnings />} />
                <Route path="/add-fund" element={<AddFundModel />} />
                <Route path="/My-Posts" element={<ProducerAllPosts />} />
                <Route path="My-Posts/edit/post/:id" element={<EditPostForUsers />} />
                <Route path="/My-wallet" element={<UserWalletDetails />} />
                <Route path="/My-Videos" element={<UserPurchasedContentLists />} />
                <Route path="/Analytics" element={<Analytics />} />
                <Route path="become/creator/form" element={<BecomeCreatorForm />} /> 
                <Route path="vip/section/new" element={<VipSectionNew />} />
                <Route path="kasuwa/section" element={<KasuwaSection />} /> 
                <Route path="purchase/post/ticket" element={<PostTicketPurchase />} />
                <Route path="search/post/ticket" element={<TicketsSearch />} />
                <Route path="create/ad" element={<CreateAdForm />} />
                <Route path="/ad/update/:id" element={<UpdateAdsForm />} />
                <Route path="manage/ads" element={<ManageAds />} />
                <Route path="ad/details/:id" element={<AdDetails />} />
                <Route path="chat/:id" element={<Chat />} /> 
                <Route path="chat/lists" element={<ChatList />} />
                <Route path="Notifications" element={<Notifications />} />           
              </Routes>
            </UserAuthCheck>
            }
            />

          <Route
            path="/admin/*"
            element={
              <AuthCheck>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="users/edit-user/:id" element={<EditUser />} />
                  <Route path="users/add-user" element={<AddUser />} />
                  <Route path="post" element={<BlogPostForm />} />
                  <Route path="credit" element={<AdminWalletManagement />} />  
                  <Route path="transactions" element={<Transactions />} />
                  <Route path="withdrawals-requests" element={<WithdrawalRequest />} />
                  <Route path="sales" element={<Sales />} />
                  <Route path="analyticsAdmin" element={<AnalyticsAdmin />} />
                  <Route path="posts" element={<Posts />} />
                  <Route path="settings" element={<AdminSettings />} />
                  <Route path="posts/edit-post/:id" element={<EditPost />} />
                  <Route path="posts/review/requests" element={<PostsReviewRequests />} />
                  <Route path="become/creator/requests" element={<BecomeCreatorFormRequests />} />
                  <Route path="foreign/transfer/receipts" element={<ForeignTransferReceipts />} />
                  <Route path="search/post/ticket/admin" element={<TicketsSearch />} />
                  <Route path="create/ad" element={<CreateAdForm />} />
                  <Route path="/ad/update/:id/admin" element={<UpdateAdsForm />} /> 
                  <Route path="manage/ads/requests" element={<ManageAdsRequests />} />
                  <Route path="adRequestDetailsModal" element={<AdRequestDetails />} />
                  <Route path="postDetailsModal" element={<PostDetailsModal />} />
                </Routes>
              </AuthCheck>
            }
          />

          {/* Redirect any unknown routes to Home */}
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </SoundProvider> 
    </ThemeContext.Provider>
    </ThemeProvider>
    {/* </PrivateRoute> */}
    </Router>
  );
};

export default App;
