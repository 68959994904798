import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import DOMPurify from 'dompurify';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import Spinner from './Spinner';
import CustomYouTubePlayer from './CustomYouTubePlayer';
import { LoadingIcon, SuccessIcon, ErrorIcon } from '../components/Admin/styles';
import { Container, Title, StyledH1, CancelIcon, StyledAddButton } from './ProducerAllPosts';
import { FormContainer, FormTitle, FormField, Input, Textarea, Select, PreviewContainer, PreviewImage,
  Image, RemoveButton, MoveButton, SubmitButton
} from './CreateAdForm';

 import { 
  Card, Close, InputBox, 
  StyledInput, Label, 
  TextArea, StyledReactQuill1Div, StyledReactQuill,
  FreeAndSellDiv, AccessAndResetDiv, CheckBoxlabel, 
  CheckBoxinput, PriceInput, SelectOption,
 } from '../components/Admin/BlogPostForm.Styles';

  import {SpinnerContainer} from './PostDetails.Styles';

import { IoMdCloseCircle } from "react-icons/io";
import styled from 'styled-components';

const ProgressDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px; 10px;
`;

const ProgressBar = styled.div`
  height: 20px;
  width: ${props => props.progress}%;
  background-color: ${props => (props.progress === 100 ? '#4caf50' : '#76c7c0')};
  transition: width 0.4s ease;
`;

const ProgressText = styled.span`
  display: flex;
  width: 25%;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
`;


const Option = styled.option`
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    gap: 8px;
    &:disabled {
        background-color: green;
        cursor: not-allowed;
    }
`;

const CreateNewPost = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [userData, setUserData] = useState('');
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [uploadType, setUploadType] = useState('');
  const [loadedBytes, setLoadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [videoLink, setVideoLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);
  const [trailerLink, setTrailerLink] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [price, setPrice] = useState('0.00'); 
  const [accessType, setAccessType] = useState('reset-date'); 
  const [scheduled, setScheduled] = useState('');
  const [statusAccessType, setStatusAccessType] = useState('public');
  const [accessDuration, setAccessDuration] = useState('lifetime'); 
  const [downloadable, setDownloadable] = useState('1');
  const [categoryId, setCategoryId] = useState(1);
  const [postType, setPostType] = useState('Free'); 
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);

  // Check if online
const isOnline = () => navigator.onLine;

// Add event listeners to detect network changes
window.addEventListener('online', () => {
  console.log('Network connection restored.');
  // Handle reconnection logic here
});

window.addEventListener('offline', () => {
  console.log('Network connection lost.');
  // Handle disconnection logic here
});

// Usage
if (!isOnline()) {
  alert("You are currently offline. Please check your internet connection.");
}


  useEffect(() => {
    const fetchData = async () => {
      const token = await getValidToken(navigate, true); 
      if (token) {
      try {
        const response = await axios.get(`${API_URL}/User`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response?.data || error.message);
      } finally {
        setIsLoading(false); 
      }
    } else {
      setIsLoading(false); 
    }

    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    let timer;
    if (!isValidLink) {
      setErrorMessageVisible(true);
      timer = setTimeout(() => {
        setErrorMessageVisible(false);
        setIsValidLink(true); 
      }, 4000);
    }

    return () => clearTimeout(timer); 
  }, [isValidLink]);

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:8080');

    ws.onopen = () => {
      console.log('WebSocket connection established on:', ws);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.progress !== undefined) {
        setUploadProgress(data.progress);
        setLoadedBytes(data.uploadedBytes);
        setTotalBytes(data.totalBytes);
      }
    };    

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => ws.close();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadIsLoading(true);
    setLoginSuccess(false);
    setErrorMessage('');
    setUploadProgress(0);
    setLoadedBytes(0);
    setTotalBytes(0);

    // Validate required fields
    if (!title || !categoryId) {
        setErrorMessage('Please fill in all required fields.');
        setTimeout(() => setErrorMessage(''), 5000);
        setUploadIsLoading(false);
        return;
    }

    // Sanitize inputs
    const sanitizedTitle = DOMPurify.sanitize(title);
    const sanitizedVideoLink = DOMPurify.sanitize(videoLink);
    const sanitizedDownloadLink = DOMPurify.sanitize(downloadLink);
    const sanitizedTrailerLink = DOMPurify.sanitize(trailerLink);
    const sanitizedDescription = DOMPurify.sanitize(description);
    const sanitizedContent = DOMPurify.sanitize(content);

    const token = await getValidToken(navigate, true); 
    if (token) {
        try {
            const formData = new FormData();
            formData.append('title', sanitizedTitle);
            formData.append('video_link', sanitizedVideoLink);
            formData.append('download_link', sanitizedDownloadLink);
            formData.append('trailer_link', sanitizedTrailerLink);
            formData.append('downloadable', downloadable);
            formData.append('description', sanitizedDescription);
            formData.append('content', sanitizedContent);
            formData.append('categoryId', categoryId);
            formData.append('accessType', accessType); 
            formData.append('accessDuration', accessType === 'reset-date' ? accessDuration : '0'); 
            formData.append('postType', postType);
            formData.append('status', statusAccessType);
            formData.append('scheduled', scheduled);

            if (postType === 'Sell') {
                formData.append('price', price);
            }
            if (thumbnail) {
                formData.append('thumbnail', thumbnail);
            }
            if (video) {
                formData.append('video', video);
            }
            if (audio) {
                formData.append('audio', audio);
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data', 
                },
                onUploadProgress: (progressEvent) => {
                    const loaded = progressEvent.loaded;
                    const total = progressEvent.total;
                    const progress = Math.round((loaded / total) * 100);

                    setUploadProgress(progress);
                    setLoadedBytes(loaded);
                    setTotalBytes(total);
                },
            };

            await axios.post(`${API_URL}/posts`, formData, config);

            setErrorMessage('');
            setLoginSuccess(true);
            setUploadIsLoading(false);

            setTimeout(() => {
                setLoginSuccess(false);
                navigate('/My-Posts');
            }, 4000); 
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.error);
                setUploadProgress(0);
                setLoadedBytes(0);
                setTotalBytes(0);
                setUploadIsLoading(false);
                setTimeout(() => {
                    setErrorMessage('');
                    setLoginSuccess(false);
                }, 5000);
            } else {
                console.error('Error creating post:', error);
            }
        } finally {
            setUploadIsLoading(false);
        }
    }
};

  // const imageHandler = () => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.click();
  
  //   input.onchange = async () => {
  //     const file = input.files[0];
  //     const formData = new FormData();
  //     formData.append('image', file);
  
  //     try {
  //       const response = await axiosInstance.post('/uploads', formData);
  //       const imageUrl = response.data.url; // Assuming backend responds with image URL
  //       const quill = quillRef.current.getEditor();
  //       const range = quill.getSelection(true);
  //       quill.insertEmbed(range.index, 'image', imageUrl);
  //     } catch (error) {
  //       console.error('Error uploading image:', error);
  //     }
  //   };
  // };

  // const modules = {
  //   toolbar: {
  //     container: [
  //       [{ header: [1, 2, false] }],
  //       ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //       [{ list: 'ordered' }, { list: 'bullet' }],
  //       ['link', 'image'], // Include the 'image' toolbar button
  //       ['clean'],
  //     ],
  //     handlers: {
  //       image: imageHandler, // Attach the image handler to the toolbar button
  //     },
  //   },
  // };

  const CharacterLimit = 75;
  const DescriptionCharacterLimit = 300;

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= CharacterLimit) {
      setTitle(inputValue);
    } else {
      setTitle(inputValue.slice(0, CharacterLimit)); 
    }
  };
  
  const handleTrailerChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= CharacterLimit) {
      setTrailerLink(inputValue);
    } else {
      setTrailerLink(inputValue.slice(0, CharacterLimit)); 
    }
  }; 

  const handleDownloadLinkChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= CharacterLimit) {
      setDownloadLink(inputValue);
    } else {
      setDownloadLink(inputValue.slice(0, CharacterLimit)); 
    }
  }; 

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    
    if (inputValue.length <= DescriptionCharacterLimit) {
      setDescription(inputValue); 
    } else {
      setDescription(inputValue.slice(0, DescriptionCharacterLimit)); 
    }
  };

  const handleVideoLinkChange = (e) => {
    const inputValue = e.target.value.trim(); 
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    const isValid = youtubeRegex.test(inputValue);

    setVideoLink(inputValue);
    setIsValidLink(isValid);
  };

  // Function to extract YouTube video ID from link
  const getYouTubeVideoId = (link) => {
    const videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return videoIdMatch ? videoIdMatch[1] : '';
  };
  const videoId = getYouTubeVideoId(videoLink);
  
  const handleScheduleChange = (e) => setScheduled(e.target.value);
  const handleStatusAccessTypeChange = (e) => setStatusAccessType(e.target.value);

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const handleUploadTypeChange = (e) => {
    const value = e.target.value;
    setUploadType(value);
    setVideoLink('');
    setVideo(null);
  };
  

  return (
    <FormContainer>
      <Title>
          <StyledH1>Create New Post</StyledH1>
          <CancelIcon>
            <StyledAddButton onClick={() => navigate('/')}>
              <IoMdCloseCircle style={{ color: 'black', fontSize: '30px' }} />
            </StyledAddButton>
          </CancelIcon>
        </Title>

    <form onSubmit={handleSubmit}>

    {/* Producer */}
    {userData.role_id === 3 && (
      <>
      <FormField>
      <InputBox>
        <StyledInput 
        type="text" 
        placeholder="Title" 
        value={title} 
        onChange={handleTitleChange}
        required 
        />
        <Label>Title</Label>
      </InputBox>
      </FormField>
  
      <FormField>
      <InputBox>
        <StyledInput 
        type="file" 
        accept="image/*" 
        onChange={(e) => setThumbnail(e.target.files[0])} 
        />
        <Label>Thumbnail</Label>
      </InputBox>
      </FormField>

      <FormField>
      <InputBox>
        <Select onChange={handleUploadTypeChange}>
        <Option value="">Choose Upload Type</Option>
          <Option value="Youtube">Youtube</Option>
          <Option value="Arewa">Arewa Upload</Option>
        </Select>
      </InputBox>
      </FormField>
      
      {uploadType === 'Youtube' && (
      <div>
        {!isValidLink && errorMessageVisible && (
          <p style={{ color: 'red', marginBottom: '10px' }}>Invalid YouTube link</p>
        )}
        <FormField>
        <InputBox>
          <StyledInput
            type="text"
            placeholder="Video Link"
            value={videoLink}
            onChange={handleVideoLinkChange}
          />
          <Label>Video Link</Label>
        </InputBox>
        </FormField>
        {videoLink && (
            <div>
              <FormField>
              <CustomYouTubePlayer videoId={videoId} />
              </FormField>
            </div>
          )}
        </div>
      )}
      
      {uploadType === 'Arewa' && (
        <FormField>
        <InputBox>
          <StyledInput
            type="file"
            accept="video/*"
            onChange={(e) => setVideo(e.target.files[0])}
          />
          <Label>Video</Label>
        </InputBox>
        </FormField>
      )}

    <FormField>
     <InputBox>
      <StyledInput
        type="text"
        placeholder="Trailer Link"
        value={trailerLink}
        onChange={handleTrailerChange}
      />
      <Label>Trailer Link</Label>
    </InputBox>
    </FormField>    
  
    <FormField>
    <InputBox>
      <StyledInput
        type="text"
        placeholder="Download Link"
        value={downloadLink}
        onChange={handleDownloadLinkChange}
      />
      <Label>Download Link</Label>
    </InputBox>
    </FormField>  
  
  
    <div>
    <FormField> 
    <p style={{ color: 'green', marginBottom: '5px' }}>{DescriptionCharacterLimit - description.length} characters remaining</p>
        <TextArea 
        placeholder="Description" 
        value={description} 
        onChange={handleDescriptionChange} 
        />
    </FormField>      
    </div>

    <FormField>    
    <FreeAndSellDiv>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Free" checked={postType === 'Free'} onChange={() => setPostType('Free')} />
          Free
        </CheckBoxlabel>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Sell" checked={postType === 'Sell'} onChange={() => setPostType('Sell')} />
          Sell
        </CheckBoxlabel>
        {postType === 'Sell' && (
          <PriceInput type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} />
        )}
      </FreeAndSellDiv>
      </FormField>  

      <FormField>    
      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                checked={downloadable}
              />
              Download
            </CheckBoxlabel>

            {accessType && (
            <Select style={{width: '17%'}} value={downloadable} onChange={(e) => setDownloadable(e.target.value)}>
              <SelectOption value="1">On</SelectOption>
              <SelectOption value="0">Off</SelectOption>
            </Select>
          )}

          </AccessAndResetDiv>
          </FormField>    

          <FormField>       
      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                value="reset-date"
                checked={accessType}
                onChange={() => setAccessType('reset-date')}
              />
              Reset Purchase
            </CheckBoxlabel>

            {accessType && (
            <InputBox>
            <Select value={accessDuration} onChange={(e) => setAccessDuration(e.target.value)}>
              <SelectOption value="lifetime">lifetime</SelectOption>
              <SelectOption value="1">1-Month</SelectOption>
              <SelectOption value="2">1-Week</SelectOption>
              <SelectOption value="3">3-Months</SelectOption>
            </Select>
            </InputBox>
          )}
          </AccessAndResetDiv>
          </FormField>    

      <FormField>
      <Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
        <SelectOption value="1">Fina Finai</SelectOption>
        <SelectOption value="2">Wakoki</SelectOption>
        <SelectOption value="3">Labarai</SelectOption>
        <SelectOption value="4">Koyarwa</SelectOption>
        <SelectOption value="5">Makaranta</SelectOption>
      </Select>
      </FormField>    

      <FormField>        
      <Select value={statusAccessType} onChange={handleStatusAccessTypeChange}>
        <SelectOption value="public">Public</SelectOption>
        <SelectOption value="private">Private</SelectOption>
        <SelectOption value="scheduled">Scheduled</SelectOption>
      </Select>
      </FormField>  
      {statusAccessType === 'scheduled' && (
        <FormField>  
        <input type="datetime-local" value={scheduled} onChange={handleScheduleChange} />
        </FormField>  
      )}

  {uploadProgress > 0 && (
    <ProgressDiv>
      <ProgressBarContainer>
        <ProgressBar progress={uploadProgress} />
      </ProgressBarContainer>
      <ProgressText>
        {((loadedBytes / 1024 / 1024).toFixed(2))}/{(totalBytes / 1024 / 1024).toFixed(2)} mb
      </ProgressText>
    </ProgressDiv>
  )}
  {errorMessage && (
    <FormField>  
        <p style={{ color: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{errorMessage}</p>
     </FormField>  
      )}
    </>  
    )}

    {/* Musicians */}
    {userData.role_id === 33 && (
      <>
    <FormField>  
    <InputBox>
      <StyledInput 
      type="text" 
      placeholder="Title" 
      value={title} 
      onChange={handleTitleChange}
      required 
      />
      <Label>Title</Label>
    </InputBox>
    </FormField>  

    <FormField>    
    <InputBox>
      <StyledInput 
      type="file" 
      accept="image/*" 
      onChange={(e) => setThumbnail(e.target.files[0])} 
      />
      <Label>Thumbnail</Label>
    </InputBox>
    </FormField> 

    <FormField>     
    <InputBox>
          <StyledInput
            type="file"  
            accept="audio/*"
            onChange={(e) => setAudio(e.target.files[0])}
          />
          <Label>Audio</Label>
    </InputBox>
    </FormField>  

    <FormField>    
    <InputBox>
    <StyledInput
      type="text"
      placeholder="Trailer Link"
      value={trailerLink}
      onChange={handleTrailerChange}
    />
    <Label>Trailer Link</Label>
  </InputBox>
  </FormField>  

  <div>
  <FormField>  
    <p style={{ color: 'green', marginBottom: '5px' }}>{DescriptionCharacterLimit - description.length} characters remaining</p>
        <TextArea 
        placeholder="Description" 
        value={description} 
        onChange={handleDescriptionChange} 
        />
  </FormField>        
    </div>  
  
    <FormField>  
      <FreeAndSellDiv>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Free" checked={postType === 'Free'} onChange={() => setPostType('Free')} />
          Free
        </CheckBoxlabel>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Sell" checked={postType === 'Sell'} onChange={() => setPostType('Sell')} />
          Sell
        </CheckBoxlabel>
        {postType === 'Sell' && (
          <PriceInput type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} />
        )}
      </FreeAndSellDiv>
      </FormField>  

      <FormField>    
      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                value="reset-date"
                checked={accessType === 'reset-date'}
                onChange={() => setAccessType('reset-date')}
              />
              Reset Date
            </CheckBoxlabel>

            {accessType === 'reset-date' && (
            <Select value={accessDuration} onChange={(e) => setAccessDuration(e.target.value)}>
              <SelectOption value="lifetime">lifetime</SelectOption>
              <SelectOption value="1">1-Month</SelectOption>
              <SelectOption value="2">1-Week</SelectOption>
              <SelectOption value="3">3-Months</SelectOption>
            </Select>
          )}

            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                value="lifetime"
                checked={accessType === 'lifetime'}
                onChange={() => setAccessType('lifetime')}
              />
              Lifetime
            </CheckBoxlabel>
          </AccessAndResetDiv>
          </FormField>    

      <FormField>
        <Label>Select Category</Label>  
      <Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
        <SelectOption value="2">Wakoki</SelectOption>
      </Select>
      </FormField>  

      <FormField>      
      <Select value={statusAccessType} onChange={handleStatusAccessTypeChange}>
        <SelectOption value="public">Public</SelectOption>
        <SelectOption value="private">Private</SelectOption>
        <SelectOption value="scheduled">Scheduled</SelectOption>
      </Select>
      </FormField>  
      {statusAccessType === 'scheduled' && (
        <FormField>  
        <Input type="datetime-local" value={scheduled} onChange={handleScheduleChange} />
        </FormField>  
      )}

    {uploadProgress > 0 && (
        <ProgressDiv>
          <ProgressBarContainer>
            <ProgressBar progress={uploadProgress} />
          </ProgressBarContainer>
          <ProgressText>
            {((loadedBytes / 1024 / 1024).toFixed(2))}/{(totalBytes / 1024 / 1024).toFixed(2)} mb
          </ProgressText>
        </ProgressDiv>
      )}
    </>  
    )}  

    {/* Blogger and Writers */}
    {(userData.role_id === 1 || userData.role_id === 7) && (
      <>
    <FormField>  
    <InputBox>
      <StyledInput 
      type="text" 
      placeholder="Title" 
      value={title} 
      onChange={handleTitleChange}
      required 
      />
      <Label>Title</Label>
    </InputBox>
    </FormField>  

    <FormField>    
    <InputBox>
      <StyledInput 
      type="file" 
      accept="image/*" 
      onChange={(e) => setThumbnail(e.target.files[0])} 
      />
      <Label>Thumbnail</Label>
    </InputBox>
    </FormField>  

  <FormField>      
  <InputBox>
    <StyledInput
      type="text"
      placeholder="Trailer Link"
      value={trailerLink}
      onChange={handleTrailerChange}
    />
    <Label>Trailer Link</Label>
  </InputBox>
  </FormField>  

  <div>
  <FormField>  
    <p style={{ color: 'green', marginBottom: '5px' }}>{DescriptionCharacterLimit - description.length} characters remaining</p>
      <TextArea 
      placeholder="Description" 
      value={description} 
      onChange={handleDescriptionChange} 
      />
  </FormField>      
  </div>    

  <FormField>      
  <StyledReactQuill1Div>    
      <StyledReactQuill 
      value={content}
      onChange={setContent}
      />
  </StyledReactQuill1Div>
  </FormField>    

  <FormField>  
      <FreeAndSellDiv>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Free" checked={postType === 'Free'} onChange={() => setPostType('Free')} />
          Free
        </CheckBoxlabel>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Sell" checked={postType === 'Sell'} onChange={() => setPostType('Sell')} />
          Sell
        </CheckBoxlabel>
        {postType === 'Sell' && (
          <PriceInput type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} />
        )}
      </FreeAndSellDiv>
  </FormField>      

  <FormField>  
      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                value="reset-date"
                checked={accessType === 'reset-date'}
                onChange={() => setAccessType('reset-date')}
              />
              Reset Date
            </CheckBoxlabel>

            {accessType === 'reset-date' && (
            <Select value={accessDuration} onChange={(e) => setAccessDuration(e.target.value)}>
              <SelectOption value="lifetime">lifetime</SelectOption>
              <SelectOption value="1">1-Month</SelectOption>
              <SelectOption value="2">1-Week</SelectOption>
              <SelectOption value="3">3-Months</SelectOption>
            </Select>
          )}

          </AccessAndResetDiv>
  </FormField>         

  <FormField>  
      <Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
        <SelectOption value="">Select Category</SelectOption>
        <SelectOption value="6">Novel</SelectOption>
        <SelectOption value="4">Koyarwa</SelectOption>
        <SelectOption value="5">Makaranta</SelectOption>
      </Select>
  </FormField>      

  <FormField>  
      <Select value={statusAccessType} onChange={handleStatusAccessTypeChange}>
        <SelectOption value="public">Public</SelectOption>
        <SelectOption value="private">Private</SelectOption>
        <SelectOption value="scheduled">Scheduled</SelectOption>
      </Select>
  </FormField>      
      {statusAccessType === 'scheduled' && (
        <FormField>  
        <input type="datetime-local" value={scheduled} onChange={handleScheduleChange} />
        </FormField>  
      )}

    {uploadProgress > 0 && (
      <FormField>
        <ProgressDiv>
          <ProgressBarContainer>
            <ProgressBar progress={uploadProgress} />
          </ProgressBarContainer>
          <ProgressText>
            {((loadedBytes / 1024 / 1024).toFixed(2))}/{(totalBytes / 1024 / 1024).toFixed(2)} mb
          </ProgressText>
        </ProgressDiv>
        </FormField>
      )}
    </>  
    )}

<FormField>  
      <Button onClick={handleSubmit} disabled={uploadIsLoading || loginSuccess}>
        {loginSuccess ? (
          <SuccessIcon color='#fff'/>
        ) : errorMessage ? (
          <ErrorIcon />
        ) : uploadIsLoading ? (
          <>
            Processing
            <LoadingIcon />
            <span>{uploadProgress}%</span>
          </>
        ) : (
          'Create Post'
        )}
      </Button>
</FormField>  
  
    </form>
    </FormContainer>
  );
};

export default CreateNewPost;
