import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsArrowUpSquareFill } from "react-icons/bs";

const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 50px;
  padding: 10px 10px;
  font-size: 16px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  z-index: 25;
`;

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ScrollToTopButton visible={visible} onClick={scrollToTop}>
      <BsArrowUpSquareFill />
    </ScrollToTopButton>
  );
};

export default ScrollToTop;
