// frontend/components/BlogPostForm.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // Assuming you're using Axios for HTTP requests
import { API_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
//import styled from 'styled-components';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

 import { 
  Container, Card, Close, InputBox, 
  StyledInput, Label, TextArea1, TitleContainer, Title, 
  TextArea, StyledReactQuill1Div, StyledReactQuill,
  FreeAndSellDiv, AccessAndResetDiv, CheckBoxlabel, 
  CheckBoxinput, PriceInput, Select, SelectOption,
  CreateButton, ErrorText, CharCount } from './BlogPostForm.Styles';

// import { Container, Card, Close, StyledButton, InputBox, StyledInput, Label, } from './EditUser.styles';
// import { TitleContainer, Title } from './styles';
import { IoMdCloseCircle } from "react-icons/io";

const BlogPostForm = () => {
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);
  const [trailerLink, setTrailerLink] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [price, setPrice] = useState(''); // State for price input
  const [accessType, setAccessType] = useState('lifetime'); // Default to reset-date
  const [accessDuration, setAccessDuration] = useState('1'); // Default to one month
  const [categoryId, setCategoryId] = useState('');
  const [postType, setPostType] = useState('Free'); // Default to Free
  const quillRef = useRef(null); // Ref for accessing Quill instance
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (!isValidLink) {
      // Show error message for 4 seconds
      setErrorMessageVisible(true);
      timer = setTimeout(() => {
        setErrorMessageVisible(false);
        setIsValidLink(true); // Reset validation status
      }, 4000);
    }

    return () => clearTimeout(timer); // Clear the timer when the component unmounts or re-renders
  }, [isValidLink]);

  const navigate = useNavigate();

  const CharacterLimit = 70;
  const DescriptionCharacterLimit = 300;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('video_link', videoLink);
      formData.append('download_link', downloadLink);
      formData.append('trailer_link', trailerLink);
      formData.append('description', description);
      formData.append('content', content);
      formData.append('categoryId', categoryId);
      formData.append('accessType', accessType); // Add accessType to the form data
      formData.append('accessDuration', accessType === 'reset-date' ? accessDuration : '0'); // Set access duration if 'reset-date' is selected
      formData.append('postType', postType);
      if (postType === 'Sell') {
        formData.append('price', price);
      }
      if (thumbnail) {
        formData.append('thumbnail', thumbnail);
      }

      const token = localStorage.getItem('jwtToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      await axios.post(`${API_URL}/posts`, formData, config);
      console.log('Post created successfully');
      navigate('/admin/posts');
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);
  
      try {
        const response = await axios.post('/uploads', formData);
        const imageUrl = response.data.url; // Assuming backend responds with image URL
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection(true);
        quill.insertEmbed(range.index, 'image', imageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'], // Include the 'image' toolbar button
        ['clean'],
      ],
      handlers: {
        image: imageHandler, // Attach the image handler to the toolbar button
      },
    },
  };

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= CharacterLimit) {
      setTitle(inputValue);
    } else {
      setTitle(inputValue.slice(0, CharacterLimit)); // Truncate the input value
    }
  }; 

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= CharacterLimit) {
      setDescription(inputValue);
    } else {
      setDescription(inputValue.slice(0, DescriptionCharacterLimit)); // Truncate the input value
    }
  };

  const handleVideoLinkChange = (e) => {
    const inputValue = e.target.value.trim(); // Remove leading/trailing spaces
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    const isValid = youtubeRegex.test(inputValue);

    setVideoLink(inputValue);
    setIsValidLink(isValid);
  };
  
  

  return (
    <Container>
    <Card>
      <Close onClick={() => navigate('/admin/posts')}>
      <IoMdCloseCircle style={{ fontSize: '34px', fontWeight: '500' }} />
      </Close>
      <div>
      <TitleContainer>
        <Title>
          Create New Post
        </Title>
        </TitleContainer>
        {/* {error && <h1 color="error">{error}</h1>} */}
    <form onSubmit={handleSubmit}>

    <InputBox>
      <StyledInput 
      type="text" 
      placeholder="Title" 
      value={title} 
      onChange={handleTitleChange}
      required 
      />
      <Label>Title</Label>
    </InputBox>

    <InputBox>
      <StyledInput 
      type="file" 
      accept="image/*" 
      onChange={(e) => setThumbnail(e.target.files[0])} 
      />
      <Label>Thumbnail</Label>
    </InputBox>

    <div>
      <InputBox>
        <StyledInput
          type="text"
          placeholder="Video Link"
          value={videoLink}
          onChange={handleVideoLinkChange}
        />
        <Label>Video Link</Label>
      </InputBox>
      {!isValidLink && errorMessageVisible && (
        <p style={{ color: 'red' }}>Invalid YouTube link</p>
      )}
    </div>

  <InputBox>
    <StyledInput
      type="text"
      placeholder="Download Link"
      value={downloadLink}
      onChange={(e) => setDownloadLink(e.target.value)}
    />
    <Label>Download Link</Label>
  </InputBox>

  <InputBox>
    <StyledInput
      type="text"
      placeholder="Trailer Link"
      value={trailerLink}
      onChange={(e) => setTrailerLink(e.target.value)}
    />
    <Label>Trailer Link</Label>
  </InputBox>

  <div>
      <TextArea 
      placeholder="Description" 
      value={description} 
      onChange={handleDescriptionChange} 
      />
      <p>{CharacterLimit - description.length} characters remaining</p>
  </div>    

  <StyledReactQuill1Div>    
      <StyledReactQuill 
    //  theme="snow"
    //  modules={modules}
      value={content}
      onChange={setContent}
    //  ref={quillRef} 
      />
  </StyledReactQuill1Div>  

      <FreeAndSellDiv>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Free" checked={postType === 'Free'} onChange={() => setPostType('Free')} />
          Free
        </CheckBoxlabel>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Sell" checked={postType === 'Sell'} onChange={() => setPostType('Sell')} />
          Sell
        </CheckBoxlabel>
        {postType === 'Sell' && (
          <PriceInput type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} />
        )}
      </FreeAndSellDiv>

      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                value="reset-date"
                checked={accessType === 'reset-date'}
                onChange={() => setAccessType('reset-date')}
              />
              Reset Date
            </CheckBoxlabel>

            {accessType === 'reset-date' && (
            <select value={accessDuration} onChange={(e) => setAccessDuration(e.target.value)}>
              <option value="1">1-Month</option>
              <option value="2">1-Week</option>
              <option value="3">3-Months</option>
              {/* Add more duration options if needed */}
            </select>
          )}

            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                value="lifetime"
                checked={accessType === 'lifetime'}
                onChange={() => setAccessType('lifetime')}
              />
              Lifetime
            </CheckBoxlabel>
          </AccessAndResetDiv>

      <Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
        <SelectOption value="">Select Category</SelectOption>
        <SelectOption value="1">Fina Finai</SelectOption>
        <SelectOption value="2">Wakoki</SelectOption>
        <SelectOption value="3">Kallo</SelectOption>
      </Select>

      <CreateButton type="submit">Create Post</CreateButton>
    </form>
    </div>
    </Card>
    </Container>
  );
};

export default BlogPostForm;
