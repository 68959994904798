import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { API_URL, baseURL } from '../../config';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from '../Spinner';
import useDebounce from '../../utils/useDebounce';
import PostDetailsModal from './PostDetailsModal';
import { MdClose } from 'react-icons/md';
import { IoMdOptions } from "react-icons/io";

import { PaginationWrapper, Pagination, PageNumber, PaginationButton, FormContainer, FormTitle, Tabs, Tab, SearchInput, RequestList, FormModelDiv, ModalBackdrop } from './BecomeCreatorFormRequests';
import { Card, ThumbnailDiv, PostDetailsDiv, PostTitle, AudioIndicator, ActiveStatusAndEllipsesDiv, Actions, AdminActiveStatusDiv, StatusDiv, StyledP, 
        StyledTime, Ellipses, DropdownHeader, DropdownMenu, DropdownOption, CloseIcon, PostAnalysis, MainContainer } from '../ProducerAllPosts';


const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPost, setSelectedPost] = useState(null); 
    const [showDetails, setShowDetails] = useState(false); 
    const [tab, setTab] = useState('inactive');
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [counts, setCounts] = useState({ allPosts: 0, activePosts: 0, inActivePosts: 0, waitinPosts: 0, rejectedPosts: 0, deletedAtPosts: 0 });
    const [dropdownPostId, setDropdownPostId] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const ListRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const modalOpen = location.pathname.includes('/admin/postDetailsModal');

    const debouncedSearch = useDebounce(search, 500);

    const fetchCounts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/posts/counts`, {
                    params: { search: debouncedSearch, tab },
                    ...config
                });
                setCounts(response.data);
            } catch (error) {
                console.error('Error fetching request counts:', error);
            }
        }
    }, [debouncedSearch, tab, navigate]);

    const fetchPosts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/posts`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setPosts(response.data.posts || []);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching receipts:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentPage, navigate, debouncedSearch, tab]);

    useEffect(() => {
        fetchCounts();
        fetchPosts();
    }, [currentPage, debouncedSearch, tab, navigate, fetchCounts, fetchPosts]);

    useEffect(() => {
      if (showDetails && selectedPost) {
          // When modal is opened, push a new history entry
          navigate('/admin/postDetailsModal'); // Adjust path as needed
      } else if (!showDetails && modalOpen) {
          // If modal is not supposed to be open, navigate back
          navigate('/admin/posts'); // Adjust path as needed
      }
  }, [showDetails, selectedPost, navigate, modalOpen]);

    const handleDelete = async (postId) => {
        const token = await getValidToken(navigate, true); 
          if (token) {
        if (window.confirm('Are you sure you want to delete this ad?')) {
          try {
            await axios.delete(`${API_URL}/admin/ad/delete/${postId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            }); 
            setPosts(posts.filter(post => post.id !== postId));
            setIsDropdownOpen(false);
          } catch (error) {
            setIsDropdownOpen(false);
            console.error('Error deleting ad:', error);
          }
        }
      }
      };

    const handleDropdownClick = (adId) => {
        if (dropdownPostId === adId && isDropdownOpen) {
          setIsDropdownOpen(false);
          setDropdownPostId(null);
        } else {
          setIsDropdownOpen(true);
          setDropdownPostId(adId);
        }
      };

      const handleUpdatePostStatus = (adId) => {
        setDropdownPostId(null);
        setIsDropdownOpen(false);
      };
    
      const handleCloseDropdown = () => {
        setDropdownPostId(null);
        setIsDropdownOpen(false);
      };

    const handleSelectPost = (post) => {
        //setSelectedPost(post);
        //setShowDetails(true);
        navigate(`edit-post/${post}`);
    };

    const handleCloseDetails = () => {
        setSelectedPost(null);
        setShowDetails(false);
        setIsDropdownOpen(false);
        navigate(-1);
    };

    const handleTabChange = (selectedTab) => {
        setTab(selectedTab);
        setCurrentPage(1); 
        fetchPosts(); 
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const scrollToTop = () => {
        ListRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handlePageClick = (page) => {
        setCurrentPage(page);
        scrollToTop();
    };
  
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            scrollToTop();
        }
    };
  
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollToTop();
        }
    };
    
    const filteredPosts = Array.isArray(posts) 
    ? (tab === 'inactive' || tab === 'deletedAt' ? posts : posts.filter(req => req.active_status === tab)) 
    : [];

    console.log('Filtered Receipts:', filteredPosts);
    console.log('Current Tab:', tab);

    const handleSelectChange = (event) => {
        handleTabChange(event.target.value);
    };

    const formatTimeAgo = (createdDate, scheduledAt = null) => {
        const currentDate = new Date();
      
        // Check if the post is scheduled for the future
      if (scheduledAt) {
        const scheduledDate = new Date(scheduledAt);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        if (scheduledDate > currentDate) {
          return `Will be posted on ${scheduledDate.toLocaleString('en-US', options)}`;
        }
      }
    
      
        const timeDifference = currentDate - new Date(createdDate);
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(monthsDifference / 12);
      
        if (yearsDifference > 0) {
          return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
        } else if (monthsDifference > 0) {
          return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
        } else if (daysDifference > 0) {
          return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
        } else if (hoursDifference > 0) {
          return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
        } else if (minutesDifference > 0) {
          return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
        } else {
          return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
        }
      };
      
    
      const getDisplayStatus = (status, scheduledAt) => {
        const now = new Date();
        const scheduledDate = new Date(scheduledAt);
      
        // If the post is scheduled and the scheduled date is in the past
        if (status === 'scheduled' && scheduledDate <= now) {
          return 'public';
        }
      
        // Otherwise return the original status
        return status;
      };

    if (isLoading) {
        return (
        <MainContainer>
            <Spinner />
        </MainContainer>
        )
    };

    return (
        <FormContainer>
        <FormTitle>Posts Management</FormTitle>
        <Tabs>
            <Tab active={tab === 'inactive'} onClick={() => handleTabChange('inactive')}>
            <p>Inactive</p> <p>({counts.inActivePosts})</p>
            </Tab>
            <Tab active={tab === 'waiting'} onClick={() => handleTabChange('waiting')}>
            <p>Waiting</p> <p>({counts.waitinPosts})</p>
            </Tab>

            <select value={tab} onChange={handleSelectChange} style={{ marginLeft: '10px' }}>
                <option value="">Filter</option>
                <option value="all">All Requests ({counts.allPosts})</option>
                <option value="active">Active ({counts.activePosts})</option>
                <option value="inactive">InActive ({counts.inActivePosts})</option>
                <option value="waiting">Waiting ({counts.waitinPosts})</option>
                <option value="rejected">Rejected ({counts.rejectedPosts})</option>
                <option value="deletedAt">Deleted ({counts.deletedAtPosts})</option>
            </select>
        </Tabs>
        <SearchInput
            type="text"
            placeholder="Search by Title or username or phone"
            value={search}
            onChange={handleSearchChange}
        />
        <RequestList ref={ListRef}>
            {filteredPosts.map(post => (
                <Card key={post.id}>
                <ThumbnailDiv onClick={() => handleSelectPost(post.id)}>
                    {post.thumbnail && <img src={`${baseURL}${post.thumbnail}`} alt="Thumbnail" />}
                </ThumbnailDiv>
                <PostDetailsDiv>
                    <PostTitle>{post.title}</PostTitle>
                <PostAnalysis>
                    <StyledP>N{post.price}</StyledP>
                    <StatusDiv status={getDisplayStatus(post.status, post.scheduled_at)}>{getDisplayStatus(post.status, post.scheduled_at)}</StatusDiv>
                    <StyledP>{post.views}</StyledP>
                    <StyledTime>{formatTimeAgo(post.created_at, post.scheduled_at)}</StyledTime>
                </PostAnalysis>
                <ActiveStatusAndEllipsesDiv>
                    <AdminActiveStatusDiv adminStatus={post.active_status}>
                    <p>{post.active_status}</p> 
                    {post.active_status === 'active' && <AudioIndicator postIsActive={post.active_status}/>}
                    </AdminActiveStatusDiv>
                    <StyledP>by {post.owner_username}</StyledP>
                    <Ellipses onClick={() => handleDropdownClick(post.id)}>
                    <IoMdOptions />
                    </Ellipses>
                </ActiveStatusAndEllipsesDiv>
                </PostDetailsDiv>
                <Actions ref={dropdownRef}>
                {isDropdownOpen && dropdownPostId === post.id ? (
                    <DropdownMenu onClick={(e) => e.stopPropagation()}>
                    <DropdownHeader>
                        <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
                    </DropdownHeader>
                    <DropdownOption onClick={() => handleSelectPost(post)}>Edit</DropdownOption>
                    <DropdownOption onClick={() => handleDelete(post.id)}>Delete</DropdownOption>
                    <DropdownOption onClick={() => handleUpdatePostStatus(post.id)}>Update Status</DropdownOption>
                    </DropdownMenu>
                ) : null}
                </Actions>
                </Card>
            ))}
        </RequestList>
        {totalPages > 1 && (
            <PaginationWrapper>
            <Pagination>
                <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                </PaginationButton>
                {Array.from({ length: totalPages }, (_, index) => (
                    <PageNumber
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </PageNumber>
                ))}
                <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                </PaginationButton>
            </Pagination>
        </PaginationWrapper>
        )}
        {showDetails && selectedPost && (
            <>
            <ModalBackdrop onClick={handleCloseDetails} />
            <FormModelDiv>
            <PostDetailsModal
                        post={selectedPost}
                        onClose={handleCloseDetails}
                        onCloseDropdown={handleCloseDropdown}
                        refresh={fetchPosts}
                    />
            </FormModelDiv>        
            </>        
        )}
        </FormContainer>
    );
};

export default Posts;


// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { getValidToken } from '../../utils/auth';
// import { API_UPLOADS_URL, API_URL } from '../../config';
// import { Link, useNavigate } from 'react-router-dom';
// import { FaSearch } from 'react-icons/fa';
// import { MdAdd } from 'react-icons/md';
// import {
//   MainContainer, Container, Title, InputBox, StyledInput,
//   Icon, Button, Card, ThumbnailDiv, PostDetailsDiv,
//   PostTitle, PostAnalysis, StatusDiv, Actions, Ellipses, DropdownMenu,
//   DropdownOption, Overlay, SearchToggleButton, CenterSearchAndCreateButton,
//   Spinner, NoPostsMessage
// } from './Posts.Styles';
// import {Close, CloseIcon} from '../PublicStyles';

// const Posts = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [posts, setPosts] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [perPage] = useState(50);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [dropdownPostId, setDropdownPostId] = useState(null);
//   const dropdownRef = useRef(null);
//   const [isSearchVisible, setIsSearchVisible] = useState(false);
//   const navigate = useNavigate();

//   const toggleSearchVisibility = () => {
//     setIsSearchVisible(!isSearchVisible);
//   };

//   useEffect(() => {
//     const fetchPosts = async () => {
//       const token = await getValidToken(navigate, true); 
//       if (token) {
//       try {
//         const config = {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//           withCredentials: true,
//           params: { searchQuery, currentPage, perPage },
//         };
//         const response = await axios.get(`${API_URL}/all/posts/admin`, config);
//         setPosts(response.data);
//         setIsLoading(false);
//       } catch (error) {
//         setIsLoading(false);
//         console.error('Error fetching posts:', error);
//       }
//     }
//     };
//     fetchPosts();
//   }, [searchQuery, currentPage, perPage, navigate]);

//   const handleDropdownClick = (postId, e) => {
//     e.stopPropagation();
//     if (dropdownPostId === postId) {
//       setIsDropdownOpen(!isDropdownOpen);
//     } else {
//       setDropdownPostId(postId);
//       setIsDropdownOpen(true);
//     }
//   };

//   const handleOutsideClick = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setIsDropdownOpen(false);
//       setDropdownPostId(null);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('mousedown', handleOutsideClick);
//     return () => {
//       document.removeEventListener('mousedown', handleOutsideClick);
//     };
//   }, []);

//   const handleEditPost = (postId) => {
//     navigate(`edit-post/${postId}`);
//   };

//   const handleDelete = async (postId) => {
//     const token = await getValidToken(navigate, true); 
//       if (token) {
//     if (window.confirm('Are you sure you want to delete this post?')) {
//       try {
//         const config = {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         };
//         await axios.delete(`${API_URL}/posts/${postId}`, config);
//         navigate('/admin/posts');
//       } catch (error) {
//         console.error('Error deleting post:', error);
//       }
//     }
//   }
//   };

//   const handleUpdatePostStatus = (postId) => {
//     console.log('handleUpdatePostStatus', postId);
//     // Handle post status update
//   };

//   const formatTimeAgo = (createdDate) => {
//     const currentDate = new Date();
//     const timeDifference = currentDate - new Date(createdDate);
//     const secondsDifference = Math.floor(timeDifference / 1000);
//     const minutesDifference = Math.floor(secondsDifference / 60);
//     const hoursDifference = Math.floor(minutesDifference / 60);
//     const daysDifference = Math.floor(hoursDifference / 24);
//     const monthsDifference = Math.floor(daysDifference / 30);
//     const yearsDifference = Math.floor(monthsDifference / 12);

//     if (yearsDifference > 0) {
//       return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
//     } else if (monthsDifference > 0) {
//       return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
//     } else if (daysDifference > 0) {
//       return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
//     } else if (hoursDifference > 0) {
//       return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
//     } else if (minutesDifference > 0) {
//       return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
//     } else {
//       return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
//     }
//   };

//   const handleMouseDown = (postId) => {
//     const startTime = Date.now();
//     const timeoutId = setTimeout(() => {
//       handleDropdownClick(postId, new Event('mousedown'));
//     }, 1000);

//     const handleMouseUp = (e) => {
//       clearTimeout(timeoutId);
//       if (Date.now() - startTime < 1000) {
//         handleEditPost(postId);
//       }
//       document.removeEventListener('mouseup', handleMouseUp);
//     };

//     document.addEventListener('mouseup', handleMouseUp);
//   };

//   const handleDropdownOptionClick = (callback, e) => {
//     e.stopPropagation();
//     callback();
//   };

//   if (isLoading) {
//     return (
//       <MainContainer>
//         <Spinner />
//       </MainContainer>
//     );
//   }

//   return (
//     <MainContainer>
//       <Container>
//         <Title>
//           <h1>Posts Management</h1>
//           <span>Total: {posts.length}</span>
//           <Close onClick={() => navigate('/admin/dashboard')}>
//             <CloseIcon />
//           </Close>
//         </Title>
//         {isSearchVisible && (
//           <InputBox>
//             <StyledInput
//               value={searchQuery}
//               placeholder="Search..."
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//             <Icon>
//               <FaSearch style={{ color: 'black', fontSize: '24px' }} onClick={() => setCurrentPage(1)} />
//             </Icon>
//           </InputBox>
//         )}
//         <CenterSearchAndCreateButton>
//           <Button onClick={() => navigate('/post')} style={{ display: isSearchVisible ? 'none' : 'flex' }}>
//             <MdAdd style={{ color: 'white', fontSize: '24px', fontWeight: '500' }} /> New Post
//           </Button>
//           <SearchToggleButton onClick={toggleSearchVisibility}>
//             <FaSearch style={{ color: 'white', fontSize: '24px' }} />
//           </SearchToggleButton>
//         </CenterSearchAndCreateButton>

//         {posts.length === 0 ? (
//           <NoPostsMessage>No posts available. Create a new one!</NoPostsMessage>
//         ) : (
//           posts.map((post) => (
//             <Card key={post.id} onMouseDown={() => handleMouseDown(post.id)}>
//               <ThumbnailDiv>
//                 <Link to={`edit-post/${post.id}`}>
//                   {post.thumbnail && <img src={`${API_UPLOADS_URL}${post.thumbnail}`} alt="Thumbnail" />}
//                 </Link>
//               </ThumbnailDiv>
//               <PostDetailsDiv>
//                 <PostTitle>
//                   <Link to={`edit-post/${post.id}`}>{post.title}</Link>
//                 </PostTitle>
//                 <PostAnalysis>
//                   <p>N{post.price}</p>
//                   <StatusDiv status={post.status}>{post.status}</StatusDiv>
//                   <p>{post.views}</p>
//                   <time>{formatTimeAgo(post.created_at)}</time>
//                   <p>{post.totalSold}</p>
//                 </PostAnalysis>
//                 <Actions>
//                   <Ellipses onClick={(e) => handleDropdownClick(post.id, e)}>...</Ellipses>
//                   {dropdownPostId === post.id && isDropdownOpen && (
//                     <DropdownMenu ref={dropdownRef} onMouseDown={(e) => e.stopPropagation()}>
//                       <DropdownOption onClick={(e) => handleDropdownOptionClick(() => handleEditPost(post.id), e)}>Edit</DropdownOption>
//                       <DropdownOption onClick={(e) => handleDropdownOptionClick(() => handleDelete(post.id), e)}>Delete</DropdownOption>
//                       <DropdownOption onClick={(e) => handleDropdownOptionClick(() => handleUpdatePostStatus(post.id), e)}>Update Status</DropdownOption>
//                     </DropdownMenu>
//                   )}
//                 </Actions>
//               </PostDetailsDiv>
//             </Card>
//           ))
//         )}
//         {isDropdownOpen && <Overlay />}
//       </Container>
//     </MainContainer>
//   );
// };

// export default Posts;
