import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; 
import { API_URL } from '../../config'; 
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingIcon, SuccessIcon, ErrorIcon } from './styles';

const FormField = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const UserEditForm = ({ user, onClose, refresh, refreshCounts }) => {
    const [userId, setUserId] = useState(user.id);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    //const [userType, setUserType] = useState(user.user_type);
    const [userWallet, setUserWallet] = useState(user.wallet);
    //const [pin, setPin] = useState(user.pin);
    const [active, setActive] = useState(user.active);
    const [wemaBankAc, setWemaBankAc] = useState(user.wema_bank_account);
    const [moniepointBankAc, setMoniepointBankAc] = useState(user.moniepoint_bank_account);
    const [sterlingBankAc, setSterlingBankAc] = useState(user.sterling_bank_account);
    const [fidelityBankAc, setFidelityBankAc] = useState(user.fidelity_bank_account);
    const [role, setRole] = useState(user.role_id);
    const [password, setPassword] = useState(null);
    const [referralCode, setRefarrelCode] = useState(user.referral_code);
    const [state, setState] = useState(user.state);
    const [lastActivity, setLastActivity] = useState(user.last_activity);
    const [createdAt, setCreatedAt] = useState(user.created_at);
    const [isLoading, setIsLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setLoginSuccess(false);
        setError('');
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                await axios.post(`${API_URL}/users/update`, { 
                    id: user.id, firstName, lastName, email, phone, 
                    userWallet, active, wemaBankAc,
                    moniepointBankAc, sterlingBankAc, fidelityBankAc,
                    referralCode, lastActivity, createdAt, state, role, password }, config);
    
                setError('');
                setLoginSuccess(true);
                setIsLoading(false);

                setTimeout(() => {
                    setLoginSuccess(false);
                    onClose();
                    refresh();
                    refreshCounts();
                }, 2000); 
            } catch (error) {
                console.error('Error updating user:', error);
                setError(error.message);
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            }
        }
    };

    const formatTimeAgo = (createdDate, scheduledAt = null) => {
        const currentDate = new Date();
      
        // Check if the post is scheduled for the future
      if (scheduledAt) {
        const scheduledDate = new Date(scheduledAt);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        if (scheduledDate > currentDate) {
          return `Will be posted on ${scheduledDate.toLocaleString('en-US', options)}`;
        }
      }
    
      
        const timeDifference = currentDate - new Date(createdDate);
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(monthsDifference / 12);
      
        if (yearsDifference > 0) {
          return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
        } else if (monthsDifference > 0) {
          return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
        } else if (daysDifference > 0) {
          return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
        } else if (hoursDifference > 0) {
          return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
        } else if (minutesDifference > 0) {
          return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
        } else {
          return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
        }
      };
    
      const formatDate = (dateString) => {
        const date = new Date(dateString);
      
        const pad = (num) => (num < 10 ? `0${num}` : num);
      
        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1); // Months are 0-based
        const year = date.getFullYear();
      
        let hours = date.getHours();
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
      
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
      
        return `${day}/${month}/${year} ${pad(hours)}:${minutes}:${seconds} ${ampm}`;
      };

      const formatWallet = (wallet) => {
        // Check if wallet is a valid number or string that can be converted to a number
        if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
          return '......'; //'Invalid earnings';
        }
    
        // Convert earnings to number and ensure it's a valid number
        wallet = parseFloat(wallet);
        if (isNaN(wallet) || !isFinite(wallet)) {
          return 'Invalid wallet';
        }
    
        // Convert earnings to string with 2 decimal places
        let walletStr = wallet.toFixed(2);
    
        // Split wallet into whole and decimal parts
        const parts = walletStr.split('.');
        let wholePart = parts[0];
        const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
    
        // Add commas for thousands, millions, etc.
        const regex = /\B(?=(\d{3})+(?!\d))/g;
        wholePart = wholePart.replace(regex, ',');
    
        // Return formatted earnings
        return wholePart + decimalPart;
      };

    return (
        <form onSubmit={handleSubmit}>
            <FormField>
                <Label>UserId</Label>
                <Input
                    type="text"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>First Name</Label>
                <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Last Name</Label>
                <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Email</Label>
                <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Phone Number</Label>
                <Input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </FormField>
{/*             <FormField>
                <Label>Pin</Label>
                <Input
                    type="tel"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                />
            </FormField> */}
            <FormField>
                <Label>Wallet</Label>
                <Input
                    type="text"
                    value={`₦${formatWallet(userWallet)}`}
                    onChange={(e) => setUserWallet(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Wema Bank Ac</Label>
                <Input
                    type="tel"
                    value={wemaBankAc}
                    onChange={(e) => setWemaBankAc(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Moniepoint Bank Ac</Label>
                <Input
                    type="tel"
                    value={moniepointBankAc}
                    onChange={(e) => setMoniepointBankAc(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Sterling Bank Ac</Label>
                <Input
                    type="tel"
                    value={sterlingBankAc}
                    onChange={(e) => setSterlingBankAc(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Fidelity Bank Ac</Label>
                <Input
                    type="tel"
                    value={fidelityBankAc}
                    onChange={(e) => setFidelityBankAc(e.target.value)}
                />
            </FormField>
{/*             <FormField>
                <Label>User Type</Label>
                <Select value={userType} onChange={(e) => setUserType(e.target.value)}>
                    <option value={'user'}>User</option>
                    <option value={'agent'}>Agent</option>
                    <option value={'vendor'}>Vendor</option>
                </Select>
            </FormField> */}
            <FormField>
                <Label>Role</Label>
                <Select value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value={1}>Admin</option>
                    <option value={2}>User</option>
                    <option value={3}>Customer Care</option>
                    <option value={4}>Agent</option>
                    <option value={5}>Vendor</option>
                </Select>
            </FormField>
            <FormField>
                <Label>Update Password</Label>
                <Input
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Referral Code</Label>
                <Input
                    type="tel"
                    value={referralCode}
                    onChange={(e) => setRefarrelCode(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>State</Label>
                <Input
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Last Activity, {formatTimeAgo(lastActivity)}</Label>
                <Input
                    type="text"
                    value={formatDate(lastActivity)}
                    onChange={(e) => setLastActivity(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Registration Date, {formatTimeAgo(createdAt)} </Label>
                <Input
                    type="text"
                    value={formatDate(createdAt)}
                    onChange={(e) => setCreatedAt(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label> Active Status</Label>
                <Select value={active} onChange={(e) => setActive(e.target.value)}>
                    <option value={1}>Active</option>
                    <option value={0}>In Active</option>
                    <option value={2}>Blocked</option>
                </Select>
            </FormField>

            <ButtonsDiv>
            <Button onClick={handleSubmit} disabled={isLoading || loginSuccess}>
                
                {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Update Status'}
                {isLoading && <LoadingIcon />}
            </Button>
            <Button onClick={onClose} style={{ marginTop: '10px', background: 'red' }}>Close</Button>
            </ButtonsDiv>
        </form>
    );
};

export default UserEditForm;
