import React, { useEffect, useState, useMemo } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import countriesGeoJSON from '../utils/countries.geojson'; // Ensure the correct path and filename
import styled from 'styled-components';

const MapChart = ({ data }) => {
  const [geoData, setGeoData] = useState(null);

  // Memoize the views array to prevent unnecessary re-renders
  const views = useMemo(() => Array.isArray(data?.views) ? data.views : [], [data]);

  const aggregatedViews = useMemo(() => {
    const aggregated = aggregateViewsByCountryAndType(views);
    console.log('Aggregated Views:', aggregated); // Debugging line
    return aggregated;
  }, [views]);

  useEffect(() => {
    // Fetch the GeoJSON data
    fetch(countriesGeoJSON)
      .then(response => response.json())
      .then(geoData => {
        // Enhance the countries GeoJSON with aggregated view data
        const enhancedGeoData = {
          ...geoData,
          features: geoData.features.map(feature => {
            const countryName = feature.properties.ADMIN.toLowerCase();
            const mainView = aggregatedViews[`${countryName}-main`] || { count: 0 };
            const trailerView = aggregatedViews[`${countryName}-trailer`] || { count: 0 };
            return {
              ...feature,
              properties: {
                ...feature.properties,
                mainCount: mainView.count,
                trailerCount: trailerView.count,
                highlight: mainView.count || trailerView.count,
              },
            };
          }),
        };

        setGeoData(enhancedGeoData);
      })
      .catch(error => {
        console.error('Error fetching GeoJSON data:', error);
      });
  }, [aggregatedViews]);

  // Define a style for highlighted countries
  const countryStyle = (feature) => {
    const { mainCount, trailerCount } = feature.properties;
    let fillColor = 'gray';
    if (mainCount > 0) fillColor = 'blue';
    if (trailerCount > 0) fillColor = 'green';

    return {
      fillColor,
      weight: 1,
      opacity: 1,
      color: 'white',
      fillOpacity: 0.7,
    };
  };

  if (!geoData) {
    return <div>Loading map data...</div>;
  }

  return (
    <>
      <StyledMapContainer center={[20, 0]} zoom={0} minZoom={0}
  maxZoom={10}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <GeoJSON data={geoData} style={countryStyle} />
      </StyledMapContainer>
      <div style={{ marginTop: '20px' }}>
        <Title>Main Views</Title>
        <ul>
          {Object.values(aggregatedViews).filter(view => view.view_type === 'main').map((view, index) => (
            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <img
            src={`/flags/${view.country.toLowerCase()}.png`}
            alt={''}
            style={{ width: '30px', marginRight: '10px' }}
            />
              <div>{view.country}: <Span>{view.count}</Span></div>
            </li>
          ))}
        </ul>
        
        <hr />
        
        <Title>Trailer Views</Title>
        <ul>
          {Object.values(aggregatedViews).filter(view => view.view_type === 'trailer').map((view, index) => (
            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <img
            src={`/flags/${view.country.toLowerCase()}.png`}
            alt={''}
            style={{ width: '30px', marginRight: '10px' }}
            />
              <div>{view.country}: <Span>{view.count}</Span></div>
            </li>
          ))}
        </ul>

        <hr />
        
        <Title>Unknown Countries</Title>
        <ul>
          {Object.values(aggregatedViews).filter(view => view.country === 'Unknown').map((view, index) => (
            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <img
            src={`/flags/${view.country.toLowerCase()}.png`}
            alt={''}
            style={{ width: '30px', marginRight: '10px' }}
            />
              <div>{view.country}: <Span>{view.count}</Span></div>
            </li>
          ))}
        </ul>
      </div>

    </>
  );
};

const aggregateViewsByCountryAndType = (views) => {
  return views.reduce((acc, view) => {
    const country = view.country.trim().toLowerCase() || 'unknown';
    const key = `${country}-${view.view_type}`;
    
    if (!acc[key]) {
      acc[key] = { country: country === 'unknown' ? 'Unknown Countries' : view.country, view_type: view.view_type, count: 0 };
    }
    
    acc[key].count += view.count;
    return acc;
  }, {});
};

const StyledMapContainer = styled(MapContainer)`
  height: 233px;
  width: 100%;
`;

const Span = styled.span`
  padding: 5px;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.primary};
  border-radius: 7px;
`;

const Title = styled.h3`
  margin: 10px;
  color: ${props => props.theme.colors.text};
`;

export default MapChart;

