import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

// const SkeletonCard = styled.div`
//   background-color: #f0f0f0; /* Placeholder color */
//   border-radius: 8px;
//   margin-bottom: 16px; /* Adjust as needed */
//   padding: 16px;
//   width: 350px;
//   height: 250px;
//   /* Add styles to match your card's appearance */
//   @media (min-width: 769px) {
//        width: 300px;
//      }
// `;

const SkeletonCard = styled.div`
  display: inline-block;
  // height: ${({ height }) => height || '20px'};
  // width: ${({ width }) => width || '100%'};
  width: 100% || 350px;
  height: 250px;
  background-color: ${(props) => props.theme.colors.SkeletonBackground}; || #f6f7f8;
  background-image: linear-gradient(
    to right,
    #c0f0cb 0%,
    #e8edea 20%,
    #f6f7f8 40%,
    #28a745 100%
  );
  background-repeat: no-repeat;
  background-size: 200px 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  animation: ${shimmer} 2s infinite;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 8px;
  margin-bottom: 16px;

  @media (min-width: 769px) {
            width: 300px;
          }
`;

const SkeletonLoader = ({ count }) => {
  const skeletons = [];

  for (let i = 0; i < count; i++) {
    skeletons.push(<SkeletonCard key={i} />);
  }

  return <>{skeletons}</>;
};

export default SkeletonLoader;
