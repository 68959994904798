import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {API_URL} from '../config'
import { useNavigate } from 'react-router-dom';


import { Container, Logo, LoadingIcon, SuccessIcon, ErrorIcon, TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, StyledButton } from './Admin/styles'; // Import Card and FormControl directly
import { FaPhone } from 'react-icons/fa';
import logoImg from '../assets/ArewaTv.png';

const VerificationCodeForm = ({ onVerificationSuccess }) => {
  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginSuccessfull, setLoginSuccessfull] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // Simulate page content rendering
    const timeout = setTimeout(() => {
      setScreenLoading(false); // Hide loading spinner after 2 seconds (adjust timing as needed)
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once after component mounts


  const [verificationCode, setVerificationCode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(false);

    const email = localStorage.getItem('resetEmail'); // Retrieve email from localStorage
    
    try {

      if (verificationCode.length < 6 || verificationCode.length > 6 ) {
        setLoading(false);
        setError('Verification Code must be at least 6 characters long.');

          // Reset error message after 4 seconds
      setTimeout(() => {
        setError(null);
      }, 4000);

        return;
      }

      const response = await axios.post(`${API_URL}/users/verify-code`, { email, verificationCode });

      if (response.status === 200) {
        console.log('Code verified successfully');
        setLoading(false);
        setLoginSuccessfull(true);

        // Save email and verification code to local storage
      localStorage.setItem('email', email);
      localStorage.setItem('verificationCode', verificationCode);

        setTimeout(() => {
          setLoginSuccessfull(false);
          navigate('/update-password'); // Navigate to update password form
      }, 2000); // Delay navigation for 2 seconds after showing success message

      } else {
        console.error('Error verifying code:', response.data || 'Unknown error');
        setLoading(false);
        setError(response.data);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
      }
    } catch (error) {
      console.error('Error verifying code:', error.response?.data || error.message);
      setLoading(false);
      setError(error.message);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
    }
  };  


  return (
    <Container>
     {screenLoading ? (
                <LoadingIcon />
              ) : (   
      <Card>
      <Logo src={logoImg} alt="Logo" />
      <TitleContainer>
          <Title>Enter 6 digit Code here</Title>
        </TitleContainer>
        {/* Display error or success message if login fails */}
        {loginSuccessfull && <p style={{ color: 'green', backgroundColor: 'black', padding: '5px' }}>Verification Code successful!</p>}
        {error && <p style={{ color: 'black' }}>{error}</p>}
    <form onSubmit={handleSubmit}>
    <InputBox>
            <Icon>
              <FaPhone style={{ color: 'black', fontSize: '24px' }} />
            </Icon>
        <StyledInput 
          type="text" 
          value={verificationCode} 
          onChange={(e) => setVerificationCode(e.target.value)} 
          />
          <Label>Verification Code:</Label>
    </InputBox>

    <StyledButton type="submit" disabled={loading || loginSuccessfull}>
          {loginSuccessfull ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Verify Code'}
          {loading && <LoadingIcon />}
        </StyledButton>
    </form>
    </Card>
      )} 
    </Container>
  );
};

export default VerificationCodeForm;
