import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const SkeletonWrapper = styled.div`
  display: inline-block;
  height: var(--height, 20px);
  width: var(--width, 100%);
  background-color: ${(props) => props.theme.colors.SkeletonBackground || '#f6f7f8'};
  background-image: linear-gradient(
    to right,
    #c0f0cb 0%,
    #e8edea 20%,
    #f6f7f8 40%,
    #28a745 100%
  );
  background-repeat: no-repeat;
  background-size: 200px 100%;
  position: relative;
  overflow: hidden;
  animation: ${shimmer} 1s infinite;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: var(--border-radius, 7px);
  margin: 0;
`;

export { SkeletonWrapper };