import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/youtube';
import styled, { keyframes } from 'styled-components';
import { FaPlay, FaPause, FaExpand, FaCompress, FaCog } from 'react-icons/fa';
import Logo from '../assets/ArewaTv.png';

const VideoPlayerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 233px;
  background-color: black;
  border-radius: 7px;
  border: 2px solid ${props => props.theme.colors.text};

  @media (min-width: 768px) {
    height: 320px;
  }

  @media (min-width: 1242px) {
    height: 420px;
  }
`;

const ControlsDiv = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px;
  transition: opacity 0.5s;
  opacity: ${props => props.hidden ? '0' : '1'};
  pointer-events: ${props => props.hidden ? 'none' : 'auto'};
`;

const Slider = styled.input`
  width: 100%;
  margin: 0 10px;
  appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-moz-range-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-ms-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const SettingsDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px;
  display: ${props => props.visible ? 'block' : 'none'};
`;

const TimeDisplay = styled.span`
  color: white;
  margin-left: 10px;
  margin-right: 10px;
`;

const LogoDiv = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10; // Ensure it is above the video

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TopRightLogo = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 20;
  transition: opacity 0.5s;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    animation: ${rotate} 2s linear infinite;
  }
`;





const CustomYouTubePlayer = ({ videoId }) => {
  const playerRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [logoVisible, setLogoVisible] = useState(false);
  const logoTimeoutRef = useRef(null);
  const [buffering, setBuffering] = useState(false);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current && playing) {
        const currentDuration = playerRef.current.getDuration();
        if (currentDuration !== duration) {
          setDuration(currentDuration);
        }

        const currentTime = playerRef.current.getCurrentTime();
        setProgress(currentTime / currentDuration);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [playing, duration]);

  const resetHideControlsTimeout = () => {
    clearTimeout(controlsTimeoutRef.current);

    if (playing) {
      controlsTimeoutRef.current = setTimeout(() => {
        setControlsVisible(false);
        setSettingsVisible(false);
      }, 5000);
    } else {
      setControlsVisible(true);
    }
  };
  
  useEffect(() => {
    resetHideControlsTimeout(); // Set timeout to hide controls when player state changes
  
    return () => clearTimeout(controlsTimeoutRef.current);
  }, [playing]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreenNow = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
      setIsFullScreen(!!isFullScreenNow);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);

  const handlePlayPause = () => {
    setPlaying((prev) => !prev);
    setControlsVisible(true);
  
    if (playing) {
      setTimeout(() => {
        if (progress === playerRef.current.getCurrentTime() / duration) {
          // Video is stuck, reload it
          playerRef.current.seekTo(progress, 'fraction');
        }
      }, 3000); // Adjust the delay as needed
    }
  };  

  const handleSeekChange = (e) => {
    const newProgress = parseFloat(e.target.value);
    playerRef.current.seekTo(newProgress, 'fraction');
    setProgress(newProgress);
  };

  const showLogo = () => {
    setLogoVisible(true);
    clearTimeout(logoTimeoutRef.current);
    logoTimeoutRef.current = setTimeout(() => {
      setLogoVisible(false);
    }, 5000);
  };

  const handleProgress = (state) => {
    if (!buffering) {
      setProgress(state.played);
    }
    const playedSeconds = state.playedSeconds;
    
    if (playedSeconds > 0 && playedSeconds < 5) {
      showLogo();
    }
    if (playedSeconds > duration * 0.5 && playedSeconds < duration * 0.5 + 5) {
      showLogo();
    }
    if (playedSeconds > duration * 0.8 && playedSeconds < duration * 0.8 + 5) {
      showLogo();
    }
  };
  

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handlePlay = () => {
    setPlaying(true);
    setLoading(false);
    setBuffering(false);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const toggleFullScreen = () => {
    const playerElement = playerRef.current.getInternalPlayer().getIframe();
    if (!isFullScreen) {
      if (playerElement.requestFullscreen) {
        playerElement.requestFullscreen();
      } else if (playerElement.mozRequestFullScreen) { /* Firefox */
        playerElement.mozRequestFullScreen();
      } else if (playerElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        playerElement.webkitRequestFullscreen();
      } else if (playerElement.msRequestFullscreen) { /* IE/Edge */
        playerElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreenNow = !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      );
      setIsFullScreen(isFullScreenNow);
    };
  
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setIsFullScreen(false);
      }
    });
  
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
      document.removeEventListener('keydown', () => {});
    };
  }, []);
  

  const toggleSettings = () => {
    setSettingsVisible(prev => !prev);
  };

  const handleQualityChange = (e) => {
    const quality = e.target.value;
    const player = playerRef.current.getInternalPlayer();
    player.setPlaybackQuality(quality);
  };

  const handleSpeedChange = (e) => {
    const speed = parseFloat(e.target.value);
    const player = playerRef.current.getInternalPlayer();
    player.setPlaybackRate(speed);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleInteraction = () => {
    setControlsVisible(true);
    setSettingsVisible(false); // Hide settings when interacting with controls
    resetHideControlsTimeout();
  };
  

const handleBuffering = (state) => {
  if (state && state.loaded !== undefined) {
    if (state.loaded < 1) {
      setLoading(true);
      setBuffering(true);
      // Optionally, you can implement a retry mechanism here
      retryLoading();
    } else {
      setLoading(false);
    }
  } else {
    setLoading(false); // or any appropriate fallback
  }
};

const retryLoading = () => {
  // Retry loading after a delay
  setTimeout(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(progress, 'fraction');
      setPlaying(true);
    }
  }, 3000); // Adjust the delay as needed
};

const handleError = (e) => {
  console.error("Video player error:", e);
  setLoading(true);
  // Retry logic or fallback UI
  retryLoading();
};


  return (
    <VideoPlayerDiv
      onMouseMove={handleInteraction}
      onMouseLeave={resetHideControlsTimeout}
      onTouchStart={handleInteraction}
      onClick={handleInteraction}
    >
      <ReactPlayer
        ref={playerRef}
        url={`https://www.youtube.com/watch?v=${videoId}`}
        playing={playing}
        controls={isIOS}  // Show native controls on iOS
        width='100%'
        height='100%'
        onProgress={handleProgress}
        onDuration={handleDuration}
        onPlay={handlePlay}
        onPause={handlePause}
        onBuffer={handleBuffering}
        onError={handleError}
        config={{
          youtube: {
            playerVars: {
              rel: 0,
              modestbranding: 1,
              playsinline: 1, // Important for iOS
            },
          },
        }}
      />
      <TopRightLogo visible={logoVisible}>
        <img src={Logo} alt="Logo" />
      </TopRightLogo>
      {!playing && (
        <LogoDiv onClick={handlePlayPause}>
          <img src={Logo} alt="Play Logo" />
        </LogoDiv>
      )}
      {loading && (
        <LoadingLogo>
          <img src={Logo} alt="Loading Logo" />
        </LoadingLogo>
      )}
      {!isIOS && (
        <>
          <ControlsDiv hidden={!controlsVisible}>
            <button onClick={handlePlayPause} aria-label={playing ? "Pause video" : "Play video"}>
              {playing ? <FaPause /> : <FaPlay />}
            </button>
            <TimeDisplay>{formatTime(progress * duration)}</TimeDisplay>
            <Slider
              type="range"
              min={0}
              max={1}
              step="any"
              value={progress}
              onChange={handleSeekChange}
            />
            <TimeDisplay>{formatTime(duration)}</TimeDisplay>
            <button style={{marginRight: '8px'}} onClick={toggleFullScreen} aria-label={isFullScreen ? "Full Screen" : "Exit Full Screen"}>
              {isFullScreen ? <FaCompress /> : <FaExpand />}
            </button>
            <button onClick={toggleSettings}>
              <FaCog />
            </button>
          </ControlsDiv>
          <SettingsDiv visible={settingsVisible}>
            <div>
              <label>Quality:</label>
              <select onChange={handleQualityChange}>
                <option value="small">360p</option>
                <option value="medium">480p</option>
                <option value="large">720p</option>
                <option value="hd720">HD 720p</option>
                <option value="hd1080">HD 1080p</option>
                <option value="highres">High Res</option>
              </select>
            </div>
            <div>
              <label>Speed:</label>
              <select onChange={handleSpeedChange}>
                <option value="0.25">0.25x</option>
                <option value="0.5">0.5x</option>
                <option value="0.75">0.75x</option>
                <option value="1" selected>1x</option>
                <option value="1.25">1.25x</option>
                <option value="1.5">1.5x</option>
                <option value="1.75">1.75x</option>
                <option value="2">2x</option>
              </select>
            </div>
          </SettingsDiv>
        </>
      )}
    </VideoPlayerDiv>
  );

  // return (
  //   <VideoPlayerDiv
  //     onMouseMove={handleInteraction}
  //     onMouseLeave={resetHideControlsTimeout}
  //     onTouchStart={handleInteraction}
  //     onClick={handleInteraction}
  //   >
  //     {isIOS ? (
  //       <iframe
  //       width="100%"
  //       height="100%"
  //       src={`https://www.youtube.com/embed/${videoId}?playsinline=1&modestbranding=1&rel=0&controls=1`}
  //       frameBorder="0"
  //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //       allowFullScreen
  //       title='video'
  //     ></iframe>
      
  //     ) : (
  //       <>
  //         <ReactPlayer
  //           ref={playerRef}
  //           url={`https://www.youtube.com/watch?v=${videoId}`}
  //           playing={playing}
  //           controls={false}  // Custom controls for non-iOS
  //           width="100%"
  //           height="100%"
  //           onProgress={handleProgress}
  //           onDuration={handleDuration}
  //           onPlay={handlePlay}
  //           onPause={handlePause}
  //           onBuffer={handleBuffering}
  //           onError={handleError}
  //           config={{
  //             youtube: {
  //               playerVars: {
  //                 rel: 0,
  //                 modestbranding: 1,
  //                 playsinline: 1, // Important for iOS
  //               },
  //             },
  //           }}
  //         />
  
  //         <TopRightLogo visible={logoVisible}>
  //           <img src={Logo} alt="Logo" />
  //         </TopRightLogo>
  
  //         {!playing && (
  //           <LogoDiv onClick={handlePlayPause}>
  //             <img src={Logo} alt="Play Logo" />
  //           </LogoDiv>
  //         )}
  
  //         {loading && (
  //           <LoadingLogo>
  //             <img src={Logo} alt="Loading Logo" />
  //           </LoadingLogo>
  //         )}
  
  //         <ControlsDiv hidden={!controlsVisible}>
  //           <button onClick={handlePlayPause} aria-label={playing ? "Pause video" : "Play video"}>
  //             {playing ? <FaPause /> : <FaPlay />}
  //           </button>
  //           <TimeDisplay>{formatTime(progress * duration)}</TimeDisplay>
  //           <Slider
  //             type="range"
  //             min={0}
  //             max={1}
  //             step="any"
  //             value={progress}
  //             onChange={handleSeekChange}
  //           />
  //           <TimeDisplay>{formatTime(duration)}</TimeDisplay>
  //           <button
  //             style={{ marginRight: '8px' }}
  //             onClick={toggleFullScreen}
  //             aria-label={isFullScreen ? "Full Screen" : "Exit Full Screen"}
  //           >
  //             {isFullScreen ? <FaCompress /> : <FaExpand />}
  //           </button>
  //           <button onClick={toggleSettings}>
  //             <FaCog />
  //           </button>
  //         </ControlsDiv>
  
  //         <SettingsDiv visible={settingsVisible}>
  //           <div>
  //             <label>Quality:</label>
  //             <select onChange={handleQualityChange}>
  //               <option value="small">360p</option>
  //               <option value="medium">480p</option>
  //               <option value="large">720p</option>
  //               <option value="hd720">HD 720p</option>
  //               <option value="hd1080">HD 1080p</option>
  //               <option value="highres">High Res</option>
  //             </select>
  //           </div>
  //           <div>
  //             <label>Speed:</label>
  //             <select onChange={handleSpeedChange} defaultValue="1">
  //               <option value="0.25">0.25x</option>
  //               <option value="0.5">0.5x</option>
  //               <option value="0.75">0.75x</option>
  //               <option value="1">1x</option>
  //               <option value="1.25">1.25x</option>
  //               <option value="1.5">1.5x</option>
  //               <option value="1.75">1.75x</option>
  //               <option value="2">2x</option>
  //             </select>
  //           </div>
  //         </SettingsDiv>
  //       </>
  //     )}
  //   </VideoPlayerDiv>
  // );
  
};

export default CustomYouTubePlayer;

// import React, { useState, useRef, useEffect } from 'react';
// import ReactPlayer from 'react-player/youtube';
// import styled, { keyframes } from 'styled-components';
// import { FaPlay, FaPause, FaExpand, FaCompress, FaCog } from 'react-icons/fa';
// import Logo from '../assets/ArewaTv.png';

// const VideoPlayerDiv = styled.div`
//   position: relative;
//   width: 100%;
//   height: 233px;
//   background-color: black;
//   border-radius: 7px;
//   border: 2px solid ${props => props.theme.colors.text};

//   @media (min-width: 768px) {
//     height: 320px;
//   }

//   @media (min-width: 1242px) {
//     height: 420px;
//   }
// `;

// const ControlsDiv = styled.div`
//   position: absolute;
//   bottom: 10px;
//   left: 10px;
//   right: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background: rgba(0, 0, 0, 0.5);
//   border-radius: 5px;
//   padding: 5px;
//   transition: opacity 0.5s;
//   opacity: ${props => props.hidden ? '0' : '1'};
//   pointer-events: ${props => props.hidden ? 'none' : 'auto'};
// `;

// const Slider = styled.input`
//   width: 100%;
//   margin: 0 10px;
//   appearance: none;
//   background: transparent;

//   &::-webkit-slider-runnable-track {
//     height: 5px;
//     background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
//     border-radius: 5px;
//   }

//   &::-moz-range-track {
//     height: 5px;
//     background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
//     border-radius: 5px;
//   }

//   &::-ms-track {
//     height: 5px;
//     background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
//     border-radius: 5px;
//   }

//   &::-webkit-slider-thumb {
//     appearance: none;
//     width: 15px;
//     height: 15px;
//     background: white;
//     border: 2px solid green;
//     border-radius: 50%;
//     cursor: pointer;
//   }

//   &::-moz-range-thumb {
//     width: 15px;
//     height: 15px;
//     background: white;
//     border: 2px solid green;
//     border-radius: 50%;
//     cursor: pointer;
//   }

//   &::-ms-thumb {
//     width: 15px;
//     height: 15px;
//     background: white;
//     border: 2px solid green;
//     border-radius: 50%;
//     cursor: pointer;
//   }
// `;

// const SettingsDiv = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: rgba(0, 0, 0, 0.5);
//   border-radius: 5px;
//   padding: 5px;
//   display: ${props => props.visible ? 'block' : 'none'};
// `;

// const TimeDisplay = styled.span`
//   color: white;
//   margin-left: 10px;
//   margin-right: 10px;
// `;

// const LogoDiv = styled.div`
//   position: absolute;
//   width: 50px;
//   height: 50px;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   cursor: pointer;
//   z-index: 10; // Ensure it is above the video

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//   }
// `;

// const TopRightLogo = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 15px;
//   width: 50px;
//   height: 50px;
//   cursor: pointer;
//   z-index: 10;
//   opacity: ${props => (props.visible ? 1 : 0)};
//   transition: opacity 0.5s;

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//   }
// `;

// const rotate = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

// const LoadingLogo = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 50px;
//   height: 50px;
//   z-index: 20;
//   //opacity: ${(props) => (props.visible ? 1 : 0)};
//   transition: opacity 0.5s;
//   animation: ${rotate} 2s linear infinite;

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//   }
// `;





// const CustomYouTubePlayer = ({ videoId }) => {
//   const playerRef = useRef(null);
//   const controlsTimeoutRef = useRef(null);
//   const [loading, setLoading] = useState(false);
//   const [playing, setPlaying] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [controlsVisible, setControlsVisible] = useState(true);
//   const [settingsVisible, setSettingsVisible] = useState(false);
//   const [isFullScreen, setIsFullScreen] = useState(false);
//   const [logoVisible, setLogoVisible] = useState(false);
//   const logoTimeoutRef = useRef(null);

//   const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (playerRef.current && playing) {
//         const currentDuration = playerRef.current.getDuration();
//         if (currentDuration !== duration) {
//           setDuration(currentDuration);
//         }
//       }
//     }, 1000);
  
//     return () => clearInterval(interval);
//   }, [playing, duration]);
  

//   const resetHideControlsTimeout = () => {
//     clearTimeout(controlsTimeoutRef.current);
  
//     if (playing) {
//       controlsTimeoutRef.current = setTimeout(() => {
//         setControlsVisible(false);
//         setSettingsVisible(false); // Hide settings when controls are hidden
//       }, 5000);
//     } else {
//       setControlsVisible(true);
//     }
//   };
  
//   useEffect(() => {
//     resetHideControlsTimeout(); // Set timeout to hide controls when player state changes
  
//     return () => clearTimeout(controlsTimeoutRef.current);
//   }, [playing]);

//   useEffect(() => {
//     const handleFullScreenChange = () => {
//       const isFullScreenNow = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
//       setIsFullScreen(!!isFullScreenNow);
//     };

//     document.addEventListener('fullscreenchange', handleFullScreenChange);
//     document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
//     document.addEventListener('mozfullscreenchange', handleFullScreenChange);
//     document.addEventListener('MSFullscreenChange', handleFullScreenChange);
//     return () => {
//       document.removeEventListener('fullscreenchange', handleFullScreenChange);
//       document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
//       document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
//       document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
//     };
//   }, []);

//   // const handlePlayPause = () => {
//   //   setPlaying(prev => !prev);
//   //   setControlsVisible(true);
//   // };

//   const handlePlayPause = () => {
//     setPlaying((prev) => !prev);
//     setControlsVisible(true);
  
//     if (playing) {
//       setTimeout(() => {
//         if (progress === playerRef.current.getCurrentTime() / duration) {
//           // Video is stuck, reload it
//           playerRef.current.seekTo(progress, 'fraction');
//         }
//       }, 3000); // Adjust the delay as needed
//     }
//   };  

//   const handleSeekChange = (e) => {
//     const newProgress = parseFloat(e.target.value);
//     setProgress(newProgress);
//     playerRef.current.seekTo(newProgress, 'fraction');
//   };

//   const showLogo = () => {
//     setLogoVisible(true);
//     clearTimeout(logoTimeoutRef.current);
//     logoTimeoutRef.current = setTimeout(() => {
//       setLogoVisible(false);
//     }, 5000);
//   };

//   const handleProgress = (state) => {
//     setProgress(state.played);
//     const playedSeconds = state.playedSeconds;
    
//     if (playedSeconds > 0 && playedSeconds < 5) {
//       showLogo();
//     }
//     if (playedSeconds > duration * 0.5 && playedSeconds < duration * 0.5 + 5) {
//       showLogo();
//     }
//     if (playedSeconds > duration * 0.8 && playedSeconds < duration * 0.8 + 5) {
//       showLogo();
//     }
//   };
  

//   const handleDuration = (duration) => {
//     setDuration(duration);
//   };

//   const handlePlay = () => {
//     setPlaying(true);
//   };

//   const handlePause = () => {
//     setPlaying(false);
//   };

//   const toggleFullScreen = () => {
//     const playerElement = playerRef.current.getInternalPlayer().getIframe();
//     if (!isFullScreen) {
//       if (playerElement.requestFullscreen) {
//         playerElement.requestFullscreen();
//       } else if (playerElement.mozRequestFullScreen) { /* Firefox */
//         playerElement.mozRequestFullScreen();
//       } else if (playerElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
//         playerElement.webkitRequestFullscreen();
//       } else if (playerElement.msRequestFullscreen) { /* IE/Edge */
//         playerElement.msRequestFullscreen();
//       }
//     } else {
//       if (document.exitFullscreen) {
//         document.exitFullscreen();
//       } else if (document.mozCancelFullScreen) { /* Firefox */
//         document.mozCancelFullScreen();
//       } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
//         document.webkitExitFullscreen();
//       } else if (document.msExitFullscreen) { /* IE/Edge */
//         document.msExitFullscreen();
//       }
//     }
//   };

//   const toggleSettings = () => {
//     setSettingsVisible(prev => !prev);
//   };

//   const handleQualityChange = (e) => {
//     const quality = e.target.value;
//     const player = playerRef.current.getInternalPlayer();
//     player.setPlaybackQuality(quality);
//   };

//   const handleSpeedChange = (e) => {
//     const speed = parseFloat(e.target.value);
//     const player = playerRef.current.getInternalPlayer();
//     player.setPlaybackRate(speed);
//   };

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secs = Math.floor(seconds % 60);
//     return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
//   };

//   // const handleInteraction = () => {
//   //   setControlsVisible(true);
//   //   resetHideControlsTimeout();
//   // };

//   const handleInteraction = () => {
//     setControlsVisible(true);
//     setSettingsVisible(false); // Hide settings when interacting with controls
//     resetHideControlsTimeout();
//   };
  

// //   const handleBuffering = (state) => {
// //   if (state && state.loaded !== undefined) {
// //     if (state.loaded < 1) {
// //       setLoading(true);
// //     } else {
// //       setLoading(false);
// //     }
// //   } else {
// //     // Handle the case where state.loaded is undefined
// //     setLoading(false); // or any appropriate fallback
// //   }
// // };

// const handleBuffering = (state) => {
//   if (state && state.loaded !== undefined) {
//     if (state.loaded < 1) {
//       setLoading(true);
//       // Optionally, you can implement a retry mechanism here
//       retryLoading();
//     } else {
//       setLoading(false);
//     }
//   } else {
//     setLoading(false); // or any appropriate fallback
//   }
// };

// const retryLoading = () => {
//   // Retry loading after a delay
//   setTimeout(() => {
//     if (playerRef.current) {
//       playerRef.current.seekTo(progress, 'fraction');
//       setPlaying(true);
//     }
//   }, 3000); // Adjust the delay as needed
// };

// const handleError = (e) => {
//   console.error("Video player error:", e);
//   setLoading(true);
//   // Retry logic or fallback UI
//   retryLoading();
// };

//   return (
//     <VideoPlayerDiv
//       onMouseMove={handleInteraction}
//       onMouseLeave={resetHideControlsTimeout}
//       onTouchStart={handleInteraction}
//       onClick={handleInteraction}
//     >
//       <ReactPlayer
//         ref={playerRef}
//         url={`https://www.youtube.com/watch?v=${videoId}`}
//         playing={playing}
//         controls={isIOS}  // Show native controls on iOS
//         width='100%'
//         height='100%'
//         onProgress={handleProgress}
//         onDuration={handleDuration}
//         onPlay={handlePlay}
//         onPause={handlePause}
//         onBuffer={handleBuffering}
//         onError={handleError}
//         config={{
//           youtube: {
//             playerVars: {
//               rel: 0,
//               modestbranding: 1,
//               playsinline: 1, // Important for iOS
//             },
//           },
//         }}
//       />
//       <TopRightLogo visible={logoVisible}>
//         <img src={Logo} alt="Logo" />
//       </TopRightLogo>
//       {!playing && (
//         <LogoDiv onClick={handlePlayPause}>
//           <img src={Logo} alt="Play Logo" />
//         </LogoDiv>
//       )}
//       {loading && (
//         <LoadingLogo>
//           <img src={Logo} alt="Loading Logo" />
//         </LoadingLogo>
//       )}
//       {!isIOS && (
//         <>
//           <ControlsDiv hidden={!controlsVisible}>
//             <button onClick={handlePlayPause} aria-label={playing ? "Pause video" : "Play video"}>
//               {playing ? <FaPause /> : <FaPlay />}
//             </button>
//             <TimeDisplay>{formatTime(progress * duration)}</TimeDisplay>
//             <Slider
//               type="range"
//               min={0}
//               max={1}
//               step="any"
//               value={progress}
//               onChange={handleSeekChange}
//             />
//             <TimeDisplay>{formatTime(duration)}</TimeDisplay>
//             <button style={{marginRight: '8px'}} onClick={toggleFullScreen} aria-label={isFullScreen ? "Full Screen" : "Exit Full Screen"}>
//               {isFullScreen ? <FaCompress /> : <FaExpand />}
//             </button>
//             <button onClick={toggleSettings}>
//               <FaCog />
//             </button>
//           </ControlsDiv>
//           <SettingsDiv visible={settingsVisible}>
//             <div>
//               <label>Quality:</label>
//               <select onChange={handleQualityChange}>
//                 <option value="small">360p</option>
//                 <option value="medium">480p</option>
//                 <option value="large">720p</option>
//                 <option value="hd720">HD 720p</option>
//                 <option value="hd1080">HD 1080p</option>
//                 <option value="highres">High Res</option>
//               </select>
//             </div>
//             <div>
//               <label>Speed:</label>
//               <select onChange={handleSpeedChange}>
//                 <option value="0.25">0.25x</option>
//                 <option value="0.5">0.5x</option>
//                 <option value="0.75">0.75x</option>
//                 <option value="1" selected>1x</option>
//                 <option value="1.25">1.25x</option>
//                 <option value="1.5">1.5x</option>
//                 <option value="1.75">1.75x</option>
//                 <option value="2">2x</option>
//               </select>
//             </div>
//           </SettingsDiv>
//         </>
//       )}
//     </VideoPlayerDiv>
//   );
// };

// export default CustomYouTubePlayer;


