import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import { Typography, TextField, Button, Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

import { IoMdCloseCircle } from "react-icons/io";
import { Container, Card, Close, ModalContainer, InputBox, StyledInput, Label, StyledButton } from './AddUserStyles';
import { TitleContainer, Title } from './styles';

const AddUser = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const [userData, setUserData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    role: '',
    active: false,
    password: '', // Placeholder for password
    // Add more fields as needed
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('jwtToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(`${API_URL}/register`, userData, config); // Use POST request for adding a new user, we can also use /users to add
      navigate('/admin/users') // Navigate back to the Users component after successful addition
    } catch (error) {
      console.error('Error saving user data:', error);
      setError('Error saving user data. Please try again later.');
    }
  };

  return (
    <Container>
      <Card>
        <Close onClick={() => navigate('/admin/users')}>
        <IoMdCloseCircle style={{ fontSize: '34px', fontWeight: '500' }} />
        </Close>
        <div className="modal">
        <TitleContainer>
        <Title>
          Add New User
        </Title>
        </TitleContainer>
          {error && <Typography variant="body2" color="error">{error}</Typography>}
          <form onSubmit={handleSave}>
            <InputBox>
            <StyledInput
              fullWidth
              type='text'
              variant="outlined"
              label="Username"
              name="username"
              value={userData.username}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Label>Username</Label>
            </InputBox>

            <InputBox>
            <StyledInput
              fullWidth
              type='text'
              variant="outlined"
              label="First Name"
              name="first_name"
              value={userData.first_name}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Label>First Name</Label>
            </InputBox>

            <InputBox>
            <StyledInput
              fullWidth
              type='text'
              variant="outlined"
              label="Last Name"
              name="last_name"
              value={userData.last_name}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Label>Last Name</Label>
            </InputBox>

            <InputBox>
            <StyledInput
              fullWidth
              type='email'
              variant="outlined"
              label="Email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Label>Email</Label>
            </InputBox>

            <InputBox>
            <StyledInput
              fullWidth
              type='text'
              variant="outlined"
              label="Phone"
              name="phone"
              value={userData.phone}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Label>Phone Number</Label>
            </InputBox>

            <InputBox>
            <StyledInput
              fullWidth
              variant="outlined"
              label="Role"
              name="role"
              value={userData.role}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Label>Role</Label>
            </InputBox>

            <InputBox>
            <StyledInput
              fullWidth
              type='password'
              variant="outlined"
              label="Password"
              name="password"
              placeholder="Password"
              value={userData.password}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Label>Password</Label>
            </InputBox>

            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <StyledButton variant="contained" color="primary" type="submit">Add User</StyledButton>
              </Grid>
              <Grid item>
                <StyledButton variant="contained" color="default" onClick={() => navigate('/admin/users')}>Cancel</StyledButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </Card>
    </Container>
  );
};

export default AddUser;
