import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const StyledReactQuill = styled(ReactQuill)`
  .ql-editor {
    color: ${props => props.theme.colors.text}; || #333; /* Change to your desired text color */
    font-size: 16px;
    font-weight: 500;
  }
`;

const TextArea1 = styled.textarea`
    background-color: #dddddd;
    color: #666666;
    padding: 1em; // 16px
    border-radius: 10px;
    border: 2px solid transparent;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    width: 100%;
    height: 100px;
    transition: all 0.2s;
    resize: none;
    margin-bottom: 10px;

    &:hover {
        cursor: pointer;
        background-color: #eeeeee;

    }

    &:focus {
        cursor: text;
        color: #333333;
        background-color: white;
        border-color: #333333;
    }
    
`;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background}; || #333;
  min-height: 100vh;
  padding: 20px;
`;

const Card = styled.div`
  background-color: ${props => props.theme.colors.background}; || #444;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 600px;
  color: ${props => props.theme.colors.text}; || #fff;
`;

const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: ${props => props.theme.colors.danger};
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.text}; || #fff;
`;

const InputBox2 = styled.div`
display: flex;
justify-content: center;
position: relative;
// width: 100%;
// height: 50px;
border: 1px solid ${(props) => props.theme.colors.text};
border-radius: 6px;
margin-bottom: 20px;

&:nth-child input:valid ~ span,
&:nth-child input:focus ~ span {
  background: #00dfc4;
  color: black;
  border-radius: 2px;
}

input:valid ~ span,
input:focus ~ span {
  color: black;
  transform: translateX(10px) translateY(-14px);
  font-size: 15px;
  padding: 0 5px;
  background: #32a852;
  border-left: 1px solid #00dfc4;
  border-right: 1px solid #00dfc4;
  border-radius: 5px;
}
`;

const InputBox = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #777;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.background}; || #555;
  color: ${props => props.theme.colors.text}; || #fff;
  ::placeholder {
    color: #aaa;
  }
`;

const Label2 = styled.span`
position: absolute;
left: 0;
padding: 0 5px;
pointer-events: none;
// font-size: 20px;
// font-weight: 400;
color: ${(props) => props.theme.colors.text};
transition: 0.3s ease;
`;

const Label = styled.label`
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: ${props => props.theme.colors.primary}; || #444;
  padding: 0 5px;
  color: ${props => props.theme.colors.text}; || #fff;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #777;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.background}; || #555;
  color: ${props => props.theme.colors.text}; || #fff;
  ::placeholder {
    color: #aaa;
  }
`;

const StyledReactQuill1Div = styled.div`
  margin-bottom: 20px;
`;

const FreeAndSellDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AccessAndResetDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const CheckBoxlabel = styled.label`
  color: ${props => props.theme.colors.text}; || #fff;
`;

const CheckBoxinput = styled.input`
  margin-right: 10px;
`;

const PriceInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #777;
  font-size: 16px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.background}; || #555;
  color: ${props => props.theme.colors.text}; || #fff;
  ::placeholder {
    color: #aaa;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #777;
  font-size: 14px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.background}; || #555;
  color: ${props => props.theme.colors.text}; || #fff;
  margin-bottom: 20px;
`;

const SelectOption = styled.option`
  background-color: ${props => props.theme.colors.background}; || #444;
  color: ${props => props.theme.colors.text}; || #fff;
  overflow: auto;
`;

const CreateButton = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.primary}; || #28a745;
  color: ${props => props.theme.colors.text}; || #fff;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const ErrorText = styled.p`
  color: red;
`;

const CharCount = styled.p`
  color: #aaa;
`;

export { 
  Container, Card, Close, InputBox, 
  StyledInput, Label, TextArea1, TitleContainer, Title, 
  TextArea, StyledReactQuill1Div, StyledReactQuill,
  FreeAndSellDiv, AccessAndResetDiv, CheckBoxlabel, 
  CheckBoxinput, PriceInput, Select, SelectOption,
  CreateButton, ErrorText, CharCount };