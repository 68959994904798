import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { API_URL, baseURL } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { MdCancel } from 'react-icons/md';
import 'react-quill/dist/quill.snow.css';
import Spinner from '../Spinner';
import CustomYouTubePlayer from '../CustomYouTubePlayer';
import VideoPlayer from '../VideoPlayer';
import AudioPlayer from '../AudioPlayer';
import { LoadingIcon, SuccessIcon, ErrorIcon } from './styles';

import { Container, Title, StyledH1, CancelIcon, StyledAddButton } from '../ProducerAllPosts';
import { 
  InputBox, 
  StyledInput, Label,
  TextArea, StyledReactQuill1Div, StyledReactQuill,
  FreeAndSellDiv, AccessAndResetDiv, CheckBoxlabel, 
  CheckBoxinput, PriceInput, Select, SelectOption,
 } from './BlogPostForm.Styles';
import { ImageContainer, Thumbnail } from '../PostDetails.Styles';
import styled from 'styled-components';

const ProgressDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px; 10px;
`;

const ProgressBar = styled.div`
  height: 20px;
  width: ${props => props.progress}%;
  background-color: ${props => (props.progress === 100 ? '#4caf50' : '#76c7c0')};
  transition: width 0.4s ease;
`;

const ProgressText = styled.span`
  display: flex;
  width: 25%;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
`;


const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    gap: 8px;
    &:disabled {
        background-color: green;
        cursor: not-allowed;
    }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: ${props => props.theme.colors.background};
`;


const EditPost = () => {
  const { id: urlId } = useParams(); 
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [userData, setUserData] = useState('');

  const [error, setError] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [loadedBytes, setLoadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [previewImage, setPreviewImage] = useState('');

  const [accessType, setAccessType] = useState('reset-date');
  const [accessDuration, setAccessDuration] = useState('lifetime');
  const [downloadable, setDownloadable] = useState('0');

  const [videoId, setVideoId] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);


  const [postData, setPostData] = useState(null);
  const [updatedPostData, setUpdatedPostData] = useState({
    title: '', 
    thumbnail: '', 
    description: '', 
    content: '',
    price: '',
    postType: '', 
    category_id: '',
    videoLink: '',
    accessType: '',
    accessDuration: '',
    downloadLink: '',
    trailerLink: '',
    downloadable: '',
    status: '',
    scheduledAt: '',
    owner_role_id: 0,
    active_status: '',
    message: '',
    post_type: '',
  });
  const [uploadType, setUploadType] = useState('Youtube');

  // Check if online
const isOnline = () => navigator.onLine;

// Add event listeners to detect network changes
window.addEventListener('online', () => {
  console.log('Network connection restored.');
  // Handle reconnection logic here
});

window.addEventListener('offline', () => {
  console.log('Network connection lost.');
  // Handle disconnection logic here
});

// Usage
if (!isOnline()) {
  alert("You are currently offline. Please check your internet connection.");
}

useEffect(() => {
  const videoLink = updatedPostData.videoLink;

  if (videoLink?.includes('youtube') || videoLink?.includes('drive.google.com')) {
    setUploadType('Youtube');
    const match = videoLink.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    if (match) {
      setVideoId(match[1]);
    } else {
      setVideoId('');
    }
  } else {
    setUploadType('Arewa');
    setVideoId('');
  }
}, [updatedPostData.videoLink]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await getValidToken(navigate, true); 
      if (token) {
      try {
        const response = await axios.get(`${API_URL}/User`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response?.data || error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false); 
    }

    };

    fetchData();
  }, [navigate]);

  const handleUploadTypeChange = (e) => {
    const selectedUploadType = e.target.value;
    setUploadType(selectedUploadType);
  
    setUpdatedPostData((prevData) => ({
      ...prevData,
      videoLink: '',
      video: '',
    }));
  
    setVideoId('');
    setIsValidLink(true);
    setErrorMessageVisible(false);
  };

  const handleVideoLinkChange = (e) => {
  const value = e.target.value;
  setUpdatedPostData((prevData) => ({
    ...prevData,
    videoLink: value,
  }));

  if (uploadType === 'Youtube') {
    const match = value.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    if (match) {
      setVideoId(match[1]);
      setIsValidLink(true);
      setErrorMessageVisible(false);
    } else {
      setIsValidLink(false);
      setErrorMessageVisible(true);
    }
  }
};

  useEffect(() => {
    let timer;
    if (!isValidLink) {
      setErrorMessageVisible(true);
      timer = setTimeout(() => {
        setErrorMessageVisible(false);
        setIsValidLink(true); 
      }, 4000);
    }

    return () => clearTimeout(timer); 
  }, [isValidLink]);

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:8080');

    ws.onopen = () => {
      console.log('WebSocket connection established on:', ws);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.progress !== undefined) {
        setUploadProgress(data.progress);
        setLoadedBytes(data.uploadedBytes);
        setTotalBytes(data.totalBytes);
      }
    };    

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => ws.close();
  }, []);

  // Fetch post data based on the ID
  useEffect(() => {
    const fetchPost = async () => {
      const token = await getValidToken(navigate, true); 
      if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true
        };
        const response = await axios.get(`${API_URL}/posts/${urlId}`, config);
        const fetchedPostData = response.data;

        setPostData(fetchedPostData);

        // Determine accessType and accessDuration based on access_type from backend
        let determinedAccessType = 'lifetime';
        let determinedAccessDuration = 'lifetime';

        // Ensure access_type is defined and valid
        if (fetchedPostData.access_type) {
          if (fetchedPostData.access_type.includes('month') || fetchedPostData.access_type.includes('week') || fetchedPostData.access_type.includes('lifetime')) {
            determinedAccessType = 'reset-date';

            switch (fetchedPostData.access_type) {
              case 'lifetime':
                determinedAccessDuration = 'lifetime';
                break;
              case '1-month':
                determinedAccessDuration = '1';
                break;
              case '1-week':
                determinedAccessDuration = '2';
                break;
              case '3-months':
                determinedAccessDuration = '3';
                break;
              default:
                determinedAccessDuration = 'lifetime';
                break;
            }
          }
        }
        
        setUpdatedPostData({
          title: fetchedPostData.title,
          video: fetchedPostData.video,
          audio: fetchedPostData.audio,
          videoLink: fetchedPostData.video_link,
          downloadLink: fetchedPostData.download_link,
          trailerLink: fetchedPostData.trailer_link,
          thumbnail: fetchedPostData.thumbnail,
          description: fetchedPostData.description,
          content: fetchedPostData.content,
          price: fetchedPostData.price,
          postType: fetchedPostData.price > 0 ? 'Sell' : 'Free', 
          category_id: fetchedPostData.category_id,
          accessType: fetchedPostData.access_type,
          accessDuration: determinedAccessDuration,
          downloadable: fetchedPostData.downloadable ? '1' : '0',
          status: fetchedPostData.status,
          scheduledAt: fetchedPostData.scheduled_at,
          owner_role_id: fetchedPostData.owner_role_id,
          active_status: fetchedPostData.active_status,
          message: fetchedPostData.message,
          post_type: fetchedPostData.post_type,
        });

        setDownloadable(fetchedPostData.downloadable ? '1' : '0');
        setAccessType(determinedAccessType);
        setAccessDuration(determinedAccessDuration);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    }
    };
    fetchPost();
  }, [urlId, navigate]); 
  
  if (!postData) {
    return (
      <SpinnerContainer>
        <Spinner /> 
      </SpinnerContainer> 
      );
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner /> 
      </SpinnerContainer> 
      );
  }

  // Function to handle form submission (update post)
const handleUpdatePost = async (e) => {
  setUploadIsLoading(true);
    setLoginSuccess(false);
    setErrorMessage('');
    setUploadProgress(0);

  const token = await getValidToken(navigate, true); 
      if (token) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', 
      },
      onUploadProgress: (progressEvent) => {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total;
        const progress = Math.round((loaded / total) * 100);
        
        setUploadProgress(progress);
        setLoadedBytes(loaded);
        setTotalBytes(total);
      },
    };

    // Create a new FormData object
    const formData = new FormData();
    formData.append('title', updatedPostData.title);
    formData.append('video_link', updatedPostData.videoLink);
    formData.append('download_link', updatedPostData.downloadLink);
    formData.append('trailer_link', updatedPostData.trailerLink);
    formData.append('downloadable', updatedPostData.downloadable);
    formData.append('description', updatedPostData.description);
    formData.append('content', updatedPostData.content);
    formData.append('price', updatedPostData.postType === 'Free' ? 0.00 : updatedPostData.price);
    formData.append('accessType', accessType); // Add accessType to the form data
    formData.append('accessDuration', accessType === 'reset-date' ? accessDuration : '0'); 
    formData.append('postType', updatedPostData.postType);
    formData.append('category_id', updatedPostData.category_id);
    formData.append('status', updatedPostData.status);
    formData.append('scheduled', updatedPostData.scheduledAt);
    formData.append('active_status', updatedPostData.active_status);
    formData.append('message', updatedPostData.message);

    // Append the file data (thumbnailFile) to formData
    formData.append('thumbnail', thumbnailFile);
    formData.append('audio', audio);
    formData.append('video', video);

    await axios.put(`${API_URL}/posts/${urlId}`, formData, config);

    setErrorMessage('');
    setLoginSuccess(true);
    setUploadIsLoading(false);

    setTimeout(() => {
      setLoginSuccess(false);
      navigate('/admin/posts');
      }, 4000); 
  } catch (error) {
    console.error('Error saving post data:', error);
    setErrorMessage(error.response.data.error);
        setUploadProgress(0);
        setUploadIsLoading(false);
        setTimeout(() => {
          setErrorMessage('');
          setLoginSuccess(false);
        }, 4000);
  } finally {
    setUploadIsLoading(false);
  }
}
};

  // Function to handle input changes
  const handleInputChange = (eOrContent) => {
    if (typeof eOrContent === 'string') {
      // Handle case when content is directly passed (from ReactQuill)
      setUpdatedPostData((prevData) => ({
        ...prevData,
        content: eOrContent,
      }));
    } else {
      // Handle case when event object is passed (from other inputs)
      const { name, value, type } = eOrContent.target;
      if (type === 'radio') {
        setUpdatedPostData((prevData) => {
          const newPostType = value;
          return {
            ...prevData,
            postType: newPostType,
            price: newPostType === 'Free' ? 0.00 : prevData.price, 
          };
        });
      } else {
        setUpdatedPostData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };
  


  const handleDelete = async () => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true
        };
        await axios.delete(`${API_URL}/posts/${urlId}`, config);
        navigate('/admin/posts');
      } catch (error) {
        console.error('Error deleting user:', error);
        setError('Error deleting user. Please try again later.');
      }
    }
  }
  };
  

  return (
    <Container>
      <Title>
          <StyledH1>Edit Post</StyledH1>
          <CancelIcon>
            <StyledAddButton onClick={() => navigate('/Admin/posts')}>
              <MdCancel style={{ color: 'black', fontSize: '24px' }} />
            </StyledAddButton>
          </CancelIcon>
        </Title>

        {error && <h1 color="error">{error}</h1>}
      <form onSubmit={handleUpdatePost}>

        
      <InputBox>
        <StyledInput 
          type="text" 
          name="title" 
          value={updatedPostData.title} 
          onChange={(e) => handleInputChange(e)} 
        />
        <Label>Title</Label>
        </InputBox>

      <div style={{ width: '100%', height: '200px', overflow: 'hidden', position: 'relative' }}>

        {/* Display the fetched thumbnail */}
        
        {updatedPostData.thumbnail && !previewImage && (
          <>
          
          <ImageContainer>
          <Thumbnail
            src={`${baseURL}${updatedPostData.thumbnail}`}
            alt="Thumbnail"
            style={{ width: '100%',
            height: '100%',
            objectFit: 'contain',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', }}
          />
          </ImageContainer>
          </>
        )}
        {/* Display the preview image if available */}
        {previewImage && (
          <ImageContainer>
          <Thumbnail
            src={previewImage}
            alt="Thumbnail Preview"
            style={{width: '100%',
            height: '100%',
            objectFit: 'contain',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',}}
          />
          </ImageContainer>
        )}
        
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5px;' }}>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {setThumbnailFile(e.target.files[0]);
            const fileReader = new FileReader();
            fileReader.onload = () => {
              console.log('FileReader result:', fileReader.result);
              setPreviewImage(fileReader.result);
            };
            fileReader.readAsDataURL(e.target.files[0]); 
          }}
          style={{ marginBottom: '10px' }}
        />
      </div>

      {/* Producer */}
    {(updatedPostData.owner_role_id === 1 || updatedPostData.owner_role_id === 3 || updatedPostData.post_type === 'video') && (
      <>
        <Select value={uploadType} onChange={handleUploadTypeChange}>
              <option value="Youtube">Upload by YouTube Link</option>
              <option value="Arewa">Upload by Arewa Link</option>
            </Select>

            {uploadType === 'Youtube' && (
              <>
                <InputBox>
                  <StyledInput
                    type="text"
                    placeholder="Enter YouTube link"
                    name="videoLink"
                    value={updatedPostData.videoLink}
                    onChange={handleVideoLinkChange}
                  />
                  <Label>Video Link</Label>
                </InputBox>
                {!isValidLink && errorMessageVisible && (
                  <div style={{ color: 'red' }}>Invalid YouTube link!</div>
                )}
                {isValidLink && videoId && <CustomYouTubePlayer videoId={videoId} />}
                <InputBox>
                  <StyledInput
                    type="text"
                    placeholder="Download link"
                    name="downloadLink"
                    value={updatedPostData.downloadLink}
                    onChange={handleInputChange}
                  />
                  <Label>Download Link</Label>
                </InputBox>
              </>
            )}

            {uploadType === 'Arewa' && (
              <>
                {updatedPostData.video && (
                  <div>
                    <VideoPlayer videoId={updatedPostData.video} />
                  </div>
                )}
                <InputBox>
                  <StyledInput
                    type="file"
                    accept="video/*"
                    onChange={(e) => setVideo(e.target.files[0])}
                  />
                  <Label>Video</Label>
                </InputBox>
                <InputBox>
                  <StyledInput
                    type="text"
                    placeholder="Download link"
                    name="downloadLink"
                    value={updatedPostData.downloadLink}
                    onChange={handleInputChange}
                  />
                  <Label>Download Link</Label>
                </InputBox>
              </>
            )}
            <InputBox>
    <StyledInput
      type="text"
      placeholder="Trailer Link"
      name="trailerLink"
      value={updatedPostData.trailerLink}
      onChange={(e) => handleInputChange(e)}
    />
    <Label>Trailer Link</Label>
  </InputBox>

  <TextArea 
          name="description"
          label="description"
          placeholder='Description' 
          value={updatedPostData.description} 
          onChange={(e) => handleInputChange(e)} 
        />

<FreeAndSellDiv>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Free" checked={updatedPostData.postType === 'Free'} onChange={(e) => handleInputChange(e)} />
          Free
        </CheckBoxlabel>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Sell" checked={updatedPostData.postType === 'Sell'} onChange={(e) => handleInputChange(e)} />
          Sell
        </CheckBoxlabel>
        {updatedPostData.postType === 'Sell' && (
          <PriceInput type="number" placeholder="Price" value={updatedPostData.price} name="price" onChange={(e) => handleInputChange(e)} />
        )}
      </FreeAndSellDiv>

      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                checked={downloadable}
              />
              Download
            </CheckBoxlabel>

            <select value={updatedPostData.downloadable} onChange={(e) => handleInputChange(e)} name='downloadable'>
              <option value="1">On</option>
              <option value="0">Off</option>
            </select>
          </AccessAndResetDiv>

          <AccessAndResetDiv>  
      
        <CheckBoxlabel>
          <CheckBoxinput
            type="radio"
            name="reset-date"
            checked={accessType}
            onChange={() => setAccessType('reset-date')}
          />
          Reset Purchase
        </CheckBoxlabel>
      
      {accessType === 'reset-date' && (
        <InputBox>
          <select
            value={accessDuration}
            onChange={(e) => setAccessDuration(e.target.value)}
          >
            <option value="lifetime">lifetime</option>
            <option value="1">1-Month</option>
            <option value="2">1-Week</option>
            <option value="3">3-Months</option>
          </select>
        </InputBox>
      )}

      </AccessAndResetDiv>
        <Select value={updatedPostData.category_id} onChange={(e) => handleInputChange(e)} name="category_id">
          <SelectOption value="">Select Category</SelectOption>
          <SelectOption value="1">Fina Finai</SelectOption>
          <SelectOption value="2">Wakoki</SelectOption>
          <SelectOption value="4">Koyarwa</SelectOption>
        </Select>
          </>  
              )}


      {/* Musicians */}
      {(updatedPostData.owner_role_id === 33 || updatedPostData.post_type === 'audio') && (
          <>               
            {updatedPostData.audio && (
              <AudioPlayer audioId={updatedPostData.audio} />
            )}
            <InputBox>
              <StyledInput
                type="file"
                accept="audio/*"
                onChange={(e) => setAudio(e.target.files[0])}
                name="audio"
              />
              <Label>Audio</Label>
            </InputBox>

            <InputBox>
    <StyledInput
      type="text"
      placeholder="Trailer Link"
      name="trailerLink"
      value={updatedPostData.trailerLink}
      onChange={(e) => handleInputChange(e)}
    />
    <Label>Trailer Link</Label>
  </InputBox>

  <TextArea 
          name="description"
          label="description"
          placeholder='Description' 
          value={updatedPostData.description} 
          onChange={(e) => handleInputChange(e)} 
        />

<FreeAndSellDiv>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Free" checked={updatedPostData.postType === 'Free'} onChange={(e) => handleInputChange(e)} />
          Free
        </CheckBoxlabel>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Sell" checked={updatedPostData.postType === 'Sell'} onChange={(e) => handleInputChange(e)} />
          Sell
        </CheckBoxlabel>
        {updatedPostData.postType === 'Sell' && (
          <PriceInput type="number" placeholder="Price" value={updatedPostData.price} name="price" onChange={(e) => handleInputChange(e)} />
        )}
      </FreeAndSellDiv>

      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                checked={downloadable}
              />
              Download
            </CheckBoxlabel>
            <select value={updatedPostData.downloadable} onChange={(e) => handleInputChange(e)} name='downloadable'>
              <option value="1">On</option>
              <option value="0">Off</option>
            </select>
          </AccessAndResetDiv>

          <AccessAndResetDiv>  
      
        <CheckBoxlabel>
          <CheckBoxinput
            type="radio"
            name="reset-date"
            checked={accessType}
            onChange={() => setAccessType('reset-date')}
          />
          Reset Purchase
        </CheckBoxlabel>
      
      {accessType === 'reset-date' && (
        <InputBox>
          <select
            value={accessDuration}
            onChange={(e) => setAccessDuration(e.target.value)}
          >
            <option value="lifetime">lifetime</option>
            <option value="1">1-Month</option>
            <option value="2">1-Week</option>
            <option value="3">3-Months</option>
          </select>
        </InputBox>
      )}

      </AccessAndResetDiv>
        <Select value={updatedPostData.category_id} onChange={(e) => handleInputChange(e)} name="category_id">
          <SelectOption value="">Select Category</SelectOption>
          <SelectOption value="2">Wakoki</SelectOption>
        </Select>
            </>  
              )}          

    {/* Teachers and Writers */}
    {(updatedPostData.owner_role_id === 7 || updatedPostData.post_type === 'book') && (  
        <>
  <StyledReactQuill1Div>    
      <StyledReactQuill 
      name="content"
      value={updatedPostData.content}
      onChange={(content) => handleInputChange(content)} 
      />
  </StyledReactQuill1Div>

  <InputBox>
    <StyledInput
      type="text"
      placeholder="Trailer Link"
      name="trailerLink"
      value={updatedPostData.trailerLink}
      onChange={(e) => handleInputChange(e)}
    />
    <Label>Trailer Link</Label>
  </InputBox>

  <TextArea 
          name="description"
          label="description"
          placeholder='Description' 
          value={updatedPostData.description} 
          onChange={(e) => handleInputChange(e)} 
        />

<FreeAndSellDiv>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Free" checked={updatedPostData.postType === 'Free'} onChange={(e) => handleInputChange(e)} />
          Free
        </CheckBoxlabel>
        <CheckBoxlabel>
          <CheckBoxinput type="radio" value="Sell" checked={updatedPostData.postType === 'Sell'} onChange={(e) => handleInputChange(e)} />
          Sell
        </CheckBoxlabel>
        {updatedPostData.postType === 'Sell' && (
          <PriceInput type="number" placeholder="Price" value={updatedPostData.price} name="price" onChange={(e) => handleInputChange(e)} />
        )}
      </FreeAndSellDiv>

      <AccessAndResetDiv>
            <CheckBoxlabel>
              <CheckBoxinput
                type="radio"
                checked={downloadable}
              />
              Download
            </CheckBoxlabel>
            <select value={updatedPostData.downloadable} onChange={(e) => handleInputChange(e)} name='downloadable'>
              <option value="1">On</option>
              <option value="0">Off</option>
            </select>
          </AccessAndResetDiv>

          <AccessAndResetDiv>  
      
        <CheckBoxlabel>
          <CheckBoxinput
            type="radio"
            name="reset-date"
            checked={accessType}
            onChange={() => setAccessType('reset-date')}
          />
          Reset Purchase
        </CheckBoxlabel>
      
      {accessType === 'reset-date' && (
        <InputBox>
          <select
            value={accessDuration}
            onChange={(e) => setAccessDuration(e.target.value)}
          >
            <option value="lifetime">lifetime</option>
            <option value="1">1-Month</option>
            <option value="2">1-Week</option>
            <option value="3">3-Months</option>
          </select>
        </InputBox>
      )}
      </AccessAndResetDiv>

        <Select value={updatedPostData.category_id} onChange={(e) => handleInputChange(e)} name="category_id">
          <SelectOption value="">Select Category</SelectOption>
          <SelectOption value="4">Koyarwa</SelectOption>
          <SelectOption value="5">Makaranta</SelectOption>
        </Select>
      </>  
      )}

      <Select value={updatedPostData.status} onChange={(e) => handleInputChange(e)} name="status">
          <SelectOption value="">Status</SelectOption>
          <SelectOption value="public">Public</SelectOption>
          <SelectOption value="private">Private</SelectOption>
          <SelectOption value="scheduled">Scheduled</SelectOption>
        </Select>

        <textarea
          name='message'
          value={updatedPostData.message}
          onChange={(e) => handleInputChange(e)}
          rows="3"
        />  

        <Select value={updatedPostData.active_status} onChange={(e) => handleInputChange(e)} name="active_status">
          <SelectOption value="active">Active</SelectOption>
          <SelectOption value="inactive">Inactive</SelectOption>
          <SelectOption value="waiting">waiting</SelectOption>
          <SelectOption value="rejected">Rejected</SelectOption>
          <SelectOption value="deleted">Deleted</SelectOption>
        </Select>  

        {uploadProgress > 0 && (
          <ProgressDiv>
            <ProgressBarContainer>
              <ProgressBar progress={uploadProgress} />
            </ProgressBarContainer>
            <ProgressText>
              {((loadedBytes / 1024 / 1024).toFixed(2))}/{(totalBytes / 1024 / 1024).toFixed(2)} mb
            </ProgressText>
          </ProgressDiv>
        )}  

        <div>
        <Button onClick={handleUpdatePost} disabled={uploadIsLoading || loginSuccess}>
        {loginSuccess ? (
          <SuccessIcon />
        ) : errorMessage ? (
          <ErrorIcon />
        ) : uploadIsLoading ? (
          <>
            Processing
            <LoadingIcon />
            <span>{uploadProgress}%</span>
          </>
        ) : (
          'Update Post'
        )}
      </Button>
          </div>
      </form>
    </Container>
  );
};

export default EditPost;
