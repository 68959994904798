import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';

const useRecommendedPosts = (postIdprops) => {
  const [posts, setPosts] = useState([]);
  const [newPosts, setNewPosts] = useState([]);
  const [mostViewedPosts, setMostViewedPosts] = useState([]);
  const [oldPosts, setOldPosts] = useState([]);
  const [purchasedPosts, setPurchasedPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const postId = Number(postIdprops);

  const fetchPosts = useCallback(async () => {
    if (loading || currentPage > totalPages) return;

    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/public/posts`, {
        params: { currentPage, perPage: 10 },
      });
      const postIds = response.data.posts.map(post => post.id);

      const viewsResponse = await axios.post(`${API_URL}/public/posts/main/views`, { postIds });
      const totalViews = Array.isArray(viewsResponse.data.totalViews) ? viewsResponse.data.totalViews : [];

      const newPostsWithViews = response.data.posts.map(post => {
        const viewData = totalViews.find(view => view.postId === post.id);
        return { ...post, views: viewData ? viewData.totalViews : 0 };
      });

      setPosts(prevPosts => [...prevPosts, ...newPostsWithViews]);
      setTotalPages(response.data.totalPages);
      console.log(`Fetching posts: Page ${currentPage}, Fetched: ${newPostsWithViews.length} posts`);

    } catch (error) {
      console.error('Error fetching posts and views:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, totalPages ]);

  const fetchMorePosts = useCallback(() => {
    if (currentPage <= totalPages && !loading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  useEffect(() => {
    const fetchPurchasedPosts = async () => {
      const token = await getValidToken(navigate, false);
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/all/posts/purchased/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { currentPage, perPage: 10 },
          });
          setPurchasedPosts(response.data.posts);
        } catch (error) {
          console.error('Error fetching purchased posts:', error.response?.data || error.message);
        }
      }
    };
    fetchPurchasedPosts();
  }, [navigate, currentPage]);

  useEffect(() => {
    if (!postId) return;

    const filterPosts = () => {
      const now = new Date();
      const newPostsTemp = [];
      const mostViewedPostsTemp = [];
      const oldPostsTemp = [];
      const postMap = {};

      const addUniquePost = (post, category) => {
        if (!postMap[post.id] && post.id !== postId) {
          postMap[post.id] = true;
          category.push(post);
        }
      };

      posts.forEach(post => {
        const postDate = new Date(post.created_at);
        if (post.views > 30) {
          addUniquePost(post, mostViewedPostsTemp);
        } else if (now - postDate < 7 * 24 * 60 * 60 * 1000) {  // Within the last week
          addUniquePost(post, newPostsTemp);
        } else {
          addUniquePost(post, oldPostsTemp);
        }
      });

      purchasedPosts.forEach(post => {
        addUniquePost(post, newPostsTemp);
      });

      setNewPosts(newPostsTemp);
      setMostViewedPosts(mostViewedPostsTemp);
      setOldPosts(oldPostsTemp);
    };

    filterPosts();
  }, [posts, postId, purchasedPosts]);

  const mergedPosts = [];
  const seenPosts = new Set();
  let index = 0;

  while (newPosts.length > index || mostViewedPosts.length > index || oldPosts.length > index || purchasedPosts.length > index) {
    for (let i = 0; i < 3; i++) {
      if (newPosts[index + i] && !seenPosts.has(newPosts[index + i].id)) {
        mergedPosts.push(newPosts[index + i]);
        seenPosts.add(newPosts[index + i].id);
      }
      if (mostViewedPosts[index + i] && !seenPosts.has(mostViewedPosts[index + i].id)) {
        mergedPosts.push(mostViewedPosts[index + i]);
        seenPosts.add(mostViewedPosts[index + i].id);
      }
      if (oldPosts[index + i] && !seenPosts.has(oldPosts[index + i].id)) {
        mergedPosts.push(oldPosts[index + i]);
        seenPosts.add(oldPosts[index + i].id);
      }
      if (purchasedPosts[index + i] && !seenPosts.has(purchasedPosts[index + i].id)) {
        mergedPosts.push(purchasedPosts[index + i]);
        seenPosts.add(purchasedPosts[index + i].id);
      }
    }
    index += 3;
  }

  return { posts: mergedPosts, fetchMorePosts, loading, currentPage, totalPages };
};

export default useRecommendedPosts;



// import { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import { API_URL } from '../config';
// import { getValidToken } from '../utils/auth';
// import { useNavigate } from 'react-router-dom';

// const useRecommendedPosts = (postIdprops) => {
//   const [posts, setPosts] = useState([]);
//   const [newPosts, setNewPosts] = useState([]);
//   const [mostViewedPosts, setMostViewedPosts] = useState([]);
//   const [oldPosts, setOldPosts] = useState([]);
//   const [purchasedPosts, setPurchasedPosts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const postId = Number(postIdprops);


//   console.log('Ga post Id:', postIdprops);

//   const fetchPosts = useCallback(async () => {
//     if (loading || currentPage > totalPages) return;

//     setLoading(true);
//     try {
//       const response = await axios.get(`${API_URL}/public/posts`, {
//         params: { currentPage, perPage: 10 },
//       });
//       const postIds = response.data.posts.map(post => post.id);
      
//       const viewsResponse = await axios.post(`${API_URL}/public/posts/main/views`, { postIds });

//       const totalViews = Array.isArray(viewsResponse.data.totalViews) ? viewsResponse.data.totalViews : [];

//       const newPostsWithViews = response.data.posts.map(post => {
//         const viewData = totalViews.find(view => view.postId === post.id);
//         return { ...post, views: viewData ? viewData.totalViews : 0 };
//       });

//       setPosts(prevPosts => [...prevPosts, ...newPostsWithViews]);
//       setTotalPages(response.data.totalPages);
//     } catch (error) {
//       console.error('Error fetching posts and views:', error.response?.data || error.message);
//     } finally {
//       setLoading(false);
//     }
//   }, [currentPage, totalPages, loading]);

//   useEffect(() => {
//     fetchPosts();
//   }, [fetchPosts]);

//   useEffect(() => {
//     const fetchPurchasedPosts = async () => {
//       const token = await getValidToken(navigate, false);
//       if (token) {
//         try {
//           const response = await axios.get(`${API_URL}/all/posts/purchased/user`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//             params: { currentPage, perPage: 10 },
//           });
//           setPurchasedPosts(response.data.posts);
//         } catch (error) {
//           console.error('Error fetching purchased posts:', error.response?.data || error.message);
//         }
//       }
//     };
//     fetchPurchasedPosts();
//   }, [navigate, currentPage]);

//   useEffect(() => {
//     if (!postId) {
//       console.log('postId is undefined or null');
//       return; // Ensure postId is available
//     }
//     console.log('postId in filter:', postId);

//     const filterPosts = () => {
//       const now = new Date();
//       const newPostsTemp = [];
//       const mostViewedPostsTemp = [];
//       const oldPostsTemp = [];
//       const postMap = {};

//       const addUniquePost = (post, category) => {
//         console.log('postId in addUniquePost:', postId);
//         if (!postMap[post.id] && post.id !== postId) {
//           postMap[post.id] = true;
//           category.push(post);
//         }
//       };

//       posts.forEach(post => {
//         const postDate = new Date(post.created_at);
//         if (post.views > 30) {
//           addUniquePost(post, mostViewedPostsTemp);
//         } else if (now - postDate < 7 * 24 * 60 * 60 * 1000) { // Within the last week
//           addUniquePost(post, newPostsTemp);
//         } else {
//           addUniquePost(post, oldPostsTemp);
//         }
//       });

//       purchasedPosts.forEach(post => {
//         addUniquePost(post, newPostsTemp); // Adjusted to add purchased posts into newPostsTemp
//       });

//       setNewPosts(newPostsTemp);
//       setMostViewedPosts(mostViewedPostsTemp);
//       setOldPosts(oldPostsTemp);
//     };

//     filterPosts();
//   }, [posts, postId, purchasedPosts]);

//   const fetchMorePosts = () => {
//     setCurrentPage(prevPage => prevPage + 1);
//   };

//   const mergedPosts = [];
//   const seenPosts = new Set();
//   let index = 0;

//   while (newPosts.length > index || mostViewedPosts.length > index || oldPosts.length > index || purchasedPosts.length > index) {
//     for (let i = 0; i < 3; i++) {
//       if (newPosts[index + i] && !seenPosts.has(newPosts[index + i].id)) {
//         mergedPosts.push(newPosts[index + i]);
//         seenPosts.add(newPosts[index + i].id);
//       }
//       if (mostViewedPosts[index + i] && !seenPosts.has(mostViewedPosts[index + i].id)) {
//         mergedPosts.push(mostViewedPosts[index + i]);
//         seenPosts.add(mostViewedPosts[index + i].id);
//       }
//       if (oldPosts[index + i] && !seenPosts.has(oldPosts[index + i].id)) {
//         mergedPosts.push(oldPosts[index + i]);
//         seenPosts.add(oldPosts[index + i].id);
//       }
//       if (purchasedPosts[index + i] && !seenPosts.has(purchasedPosts[index + i].id)) {
//         mergedPosts.push(purchasedPosts[index + i]);
//         seenPosts.add(purchasedPosts[index + i].id);
//       }
//     }
//     index += 3;
//   }

//   return { posts: mergedPosts, fetchMorePosts, loading };
// };

// export default useRecommendedPosts;