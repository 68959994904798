import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { API_URL, baseURL } from '../../config';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import useDebounce from '../../utils/useDebounce';
import ReceiptDetails from './ReceiptDetails'; 

const FormContainer = styled.div`
    position: relative;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    //border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
    overflow: ${(props) => (props.hideScroll ? 'hidden' : 'auto')};
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const FormTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text};
`;

const Tabs = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    border-radius: 10px;
    gap: 10px;
    overflow: hidden;
`;

const Tab = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    gap: 5px;
    border: none;
    border-radius: 15px;
    background: ${props => props.active ? props.theme.colors.primary : '#ddd'};
    color: ${props => props.active ? 'white' : 'black'};
    cursor: pointer;
    &:hover {
        color: white;
    }
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
`;

const RequestList = styled.div`
    margin-bottom: 20px;
`;

const RequestItem = styled.div`
    display: flex;
    padding: 10px;
    border: 1px solid ${props => props.theme.colors.text};
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    margin-bottom: 10px;
    color: ${props => props.theme.colors.text};
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.colors.primary};
    }
`;

const ThumbnailDiv = styled.div`
    width: 100px;
    height: 100px;
`;

const UserDetailsDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px: 0px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const ForeignTransferReceipts = () => {
    const [receipts, setReceipts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedReceipt, setSelectedReceipt] = useState(null); 
    const [showDetails, setShowDetails] = useState(false); 
    const [tab, setTab] = useState('all');
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [counts, setCounts] = useState({ allRequests: 0, approvedRequests: 0, pendingRequests: 0, waitingRequests: 0, rejectedRequests: 0 });
    const ListRef = useRef(null);
    const navigate = useNavigate();

    const debouncedSearch = useDebounce(search, 500);

    const fetchCounts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/receipts/counts`, {
                    params: { search: debouncedSearch, tab },
                    ...config
                });
                setCounts(response.data);
            } catch (error) {
                console.error('Error fetching request counts:', error);
            }
        }
    }, [debouncedSearch, tab, navigate]);

    const fetchReceipts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/receipts`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setReceipts(response.data.receipts || []);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching receipts:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentPage, navigate, debouncedSearch, tab]);

    useEffect(() => {
        fetchCounts();
        fetchReceipts();
    }, [currentPage, debouncedSearch, tab, navigate, fetchCounts, fetchReceipts]);

    

    const handleSelectReceipt = (receipt) => {
        setSelectedReceipt(receipt);
        setShowDetails(true);
    };

    const handleCloseDetails = () => {
        setSelectedReceipt(null);
        setShowDetails(false);
    };

    const handleTabChange = (selectedTab) => {
        setTab(selectedTab);
        setCurrentPage(1); 
        fetchReceipts(); 
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const scrollToTop = () => {
        ListRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handlePageClick = (page) => {
        setCurrentPage(page);
        scrollToTop();
    };
  
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            scrollToTop();
        }
    };
  
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollToTop();
        }
    };
    
    const filteredReceipts = Array.isArray(receipts) 
    ? (tab === 'all' ? receipts : receipts.filter(req => req.status === tab)) 
    : [];

    console.log('Filtered Receipts:', filteredReceipts);
    console.log('Current Tab:', tab);

    const handleSelectChange = (event) => {
        handleTabChange(event.target.value);
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <FormContainer>
        <FormTitle>Receipt Management</FormTitle>
        <Tabs>
            <Tab active={tab === 'pending'} onClick={() => handleTabChange('pending')}>
            <p>Pending</p> <p>({counts.pendingRequests})</p>
            </Tab>
            <Tab active={tab === 'waiting'} onClick={() => handleTabChange('waiting')}>
            <p>Waiting</p> <p>({counts.waitingRequests})</p>
            </Tab>

            <select value={tab} onChange={handleSelectChange} style={{ marginLeft: '10px' }}>
                <option value="all">All ({counts.allRequests})</option>
                <option value="all">All Requests ({counts.allRequests})</option>
                <option value="approved">Approved ({counts.approvedRequests})</option>
                <option value="pending">Pending ({counts.pendingRequests})</option>
                <option value="waiting">Waiting ({counts.waitingRequests})</option>
                <option value="rejected">Rejected ({counts.rejectedRequests})</option>
            </select>
        </Tabs>
        <SearchInput
            type="text"
            placeholder="Search by username or phone"
            value={search}
            onChange={handleSearchChange}
        />
        <RequestList ref={ListRef}>
            {filteredReceipts.map(receipt => (
                <RequestItem key={receipt.id} onClick={() => handleSelectReceipt(receipt)}>
                    <ThumbnailDiv>
                        {<img src={`${baseURL}${receipt.path}`} alt='Receipt' width={'100px'} height={'100px'} />}
                    </ThumbnailDiv>
                    <UserDetailsDiv>
                    <p>Username: {receipt.username}</p>
                    <p>Status: {receipt.status}</p>
                    </UserDetailsDiv>
                </RequestItem>
            ))}
        </RequestList>
        {totalPages > 1 && (
            <PaginationWrapper>
            <Pagination>
                <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                </PaginationButton>
                {Array.from({ length: totalPages }, (_, index) => (
                    <PageNumber
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </PageNumber>
                ))}
                <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                </PaginationButton>
            </Pagination>
        </PaginationWrapper>
        )}
        {showDetails && selectedReceipt && (
            <>
            <ModalBackdrop onClick={handleCloseDetails} />
            <FormModelDiv>
            <ReceiptDetails
                        receipt={selectedReceipt}
                        onClose={handleCloseDetails}
                        refresh={fetchReceipts}
                    />
            </FormModelDiv>        
            </>        
        )}
        </FormContainer>
    );
};

export default ForeignTransferReceipts;

