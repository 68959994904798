import React, { useEffect, useState, } from "react";
import styled from 'styled-components';

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-size: 16px;
min-height: 100vh;

`;

const SabonTsari = () => {
    const [suna, setSuna] = useState('Hausa');
    const [showSuna, setShowSuna] = useState(false);

    // useEffect(() => {
    //     const ShowInitially = () => {
    //         setShowSuna(true);

    //         setTimeout(() => {
    //             setShowSuna(false);
    //         }, 2000);

    //     }
    //     ShowInitially();
    // }, []);

    return (
        <Container>
        {showSuna && (
            <p>{suna}</p>
        ) }
        <button onClick={() => {setShowSuna(!showSuna)}}>Bude Suna</button>
        </Container>
    )
};

export default SabonTsari;