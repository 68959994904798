import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { SpinnerContainer } from './PostDetails.Styles';
import Spinner from './Spinner';
import Button from './Button';

const RenewAdContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const RenewAdsForm = ({ adId }) => {
  const [accessType, setAccessType] = useState('');
  const [amountToSpend, setAmountToSpend] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setSubmitIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleAccessTypeChange = (e) => {
    const selectedType = e.target.value;
    setAccessType(selectedType);

    // Set the price based on access type (You can customize this pricing logic)
    let price = 0;
    switch (selectedType) {
      case '1-week':
        price = 1500;
        break;
      case '2-weeks':
        price = 3000;
        break;
      case '3-weeks':
        price =4500;
        break;
      case '1-month':
        price = 5000;
        break;
      case '2-months':
        price = 10000;
        break;
      case '3-months':
        price = 15000;
        break;
      case '4-months':
        price = 20000;
        break;
      case '5-months':
        price = 25000;
        break;    
      case '6-months':
        price = 30000;
        break;
      case '1-year':
        price = 60000;
        break;
      default:
        price = 0.00;
    }
    setAmountToSpend(price);
  };

  const handleRenewAd = async () => {
    const token = getValidToken(navigate, true);
    if (token) {
        setSubmitIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}/my/ad/renew`, {
        adId,
        access_type: accessType,
        amount_to_spend: amountToSpend,
      });
      setError('');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(true);
        navigate('/manage/ads');
      }, 5000); 
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            setError(error.response.data.error);
          } else {
            setError('An unexpected error occurred.');
          }

          setTimeout(() => {
            setSuccess(false);
            setError('');
          }, 7000);
    } finally {
        setSubmitIsLoading(false);
    }
}
  };

  if (isLoading) {
    return (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    )
  }

  return (
    <RenewAdContainer>
      <h2>Renew Ad</h2>
      <Label htmlFor="accessType">Choose Access Type:</Label>
      <Select id="accessType" value={accessType} onChange={handleAccessTypeChange}>
        <option value="">Select...</option>
        <option value="1-week">1 Week</option>
        <option value="2-weeks">2 Weeks</option>
        <option value="3-weeks">3 Weeks</option>
        <option value="1-month">1 Month</option>
        <option value="2-months">2 Months</option>
        <option value="3-months">3 Months</option>
        <option value="4-months">4 Months</option>
        <option value="5-months">5 Months</option>
        <option value="6-months">6 Months</option>
        <option value="1-year">1 Year</option>
      </Select>
      {error && (
        <p style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red', marginBottom: '10px'}}>{error}</p>
      )}
      <Button 
      onClick={handleRenewAd}
      isLoading={isSubmitLoading}
      loginSuccess={success} 
      errorMessage={error}
      disabled={isSubmitLoading || success}
      isP={true}
      >
        Renew Ad for N{amountToSpend}</Button>
    </RenewAdContainer>
  );
};

export default RenewAdsForm;
