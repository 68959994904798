import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {API_URL} from '../config'
import { useNavigate } from 'react-router-dom';

import { Container, Logo, LoadingIcon, SuccessIcon, ErrorIcon, TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, StyledButton } from './Admin/styles'; // Import Card and FormControl directly
import { FaLock } from 'react-icons/fa';
import logoImg from '../assets/ArewaTv.png';

const UpdatePasswordForm = ({ email, verificationCode }) => {
  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginSuccessfull, setLoginSuccessfull] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    // Simulate page content rendering
    const timeout = setTimeout(() => {
      setScreenLoading(false); // Hide loading spinner after 2 seconds (adjust timing as needed)
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once after component mounts
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(false);

    if (newPassword !== confirmPassword) {
      console.error('Passwords do not match');
      // You can update your frontend UI to display an error message for password mismatch
      setLoading(false);
      setError('Passwords do not match');
    
          // Reset error message after 2 seconds
        setTimeout(() => {
          setError(null);
        }, 2000);

      return;
    }

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long.');
      setLoading(false);

        // Reset error message after 4 seconds
    setTimeout(() => {
      setError(null);
    }, 4000);

      return;
    }

    try {

      const email = localStorage.getItem('email');
      const verificationCode = localStorage.getItem('verificationCode');

      // Check if email and verification code exist in local storage
      if (!email || !verificationCode) {
        throw new Error('Email or verification code not found in local storage');
      }

      const response = await axios.post(`${API_URL}/users/update-password`, { email, verificationCode, newPassword });
      // Optionally, navigate to login page or display success message
      if (response.status === 200) {
        console.log('Password updated successfully');
        setLoading(false);
        setLoginSuccessfull(true);

        setTimeout(() => {
          setLoginSuccessfull(false);
          navigate('/login');
      }, 2000); // Delay navigation for 2 seconds after showing success message
      } else {
        console.error('Error updating password:', response.data || 'Unknown error');
        setLoading(false);
        setError(response.data);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
      }
    } catch (error) {
      console.error('Error updating password:', error.response?.data || error.message);
      setLoading(false);
      setError(error.message);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
    }
  };


  return (
    <Container>
     {screenLoading ? (
                <LoadingIcon />
              ) : (   
      <Card>
      <Logo src={logoImg} alt="Logo" />
      <TitleContainer>
          <Title>Enter New Password</Title>
        </TitleContainer>
        {/* Display error or success message if login fails */}
        {loginSuccessfull && <p style={{ color: 'green', backgroundColor: 'black', padding: '5px' }}>Password Updated successful!</p>}
        {error && <p style={{ color: 'black' }}>{error}</p>}

    <form onSubmit={handleSubmit}>
    <InputBox>
            <Icon>
              <FaLock style={{ color: 'black', fontSize: '24px' }} />
            </Icon>
      <StyledInput
        type="password" value={newPassword} 
        onChange={(e) => setNewPassword(e.target.value)} 
      />
      <Label>New Password</Label>
    </InputBox>

    <InputBox>
            <Icon>
              <FaLock style={{ color: 'black', fontSize: '24px' }} />
            </Icon>
      <StyledInput
       type="password" value={confirmPassword} 
       onChange={(e) => setConfirmPassword(e.target.value)} 
      />
      <Label>Confirm Password</Label>
    </InputBox>
    
    <StyledButton type="submit" disabled={loading || loginSuccessfull}>
          {loginSuccessfull ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Update Password'}
          {loading && <LoadingIcon />}
        </StyledButton>
    </form>
    </Card>
      )} 
    </Container>
  );
};

export default UpdatePasswordForm;
