import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight  } from "react-icons/fa";
import axios from 'axios';
import Button from './Button';
import Spinner from './Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { getValidToken } from '../utils/auth';
import { API_URL, baseURL } from '../config';
import {FormContainer, FormTitle, FormField, Label, Input, Textarea, Select, PreviewContainer, PreviewImage,
    Image, RemoveButton, MoveButton, SubmitButton
} from './CreateAdForm';

import States from '../utils/States.json';
import LocalGovernments from '../utils/LocalGovernments.json';
import Wards from '../utils/Wards.json';
import PopularAreas from '../utils/PopularAreas.json';
import { SpinnerContainer } from './PostDetails.Styles';

const UpdateAdsForm = () => {
  const { id: adId } = useParams(); 
  const [updatedAdFormData, setUpdatedAdFormData] = useState({ 
    title: '',
    description: '',
    phone: '',
    address: '',
    ads_category_id: 1,
    state: '',
    local_government: '',
    ward: '',
    area: '',
    amount_to_spend: 'Free',
    ads_type: 'kasuwaSection',
    access_type: '1-week',
    video_link: '',
    price: 0.00,
    isNegotiable: false,
    condition: '',
    active_status: '',
    end_date: '',
  });

  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [storedPictures, setStoredPictures] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isFirstAd, setIsFirstAd] = useState(true);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [adData, setAdData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  // Fetch Ad data based on the ID
  useEffect(() => {
    const fetchAd = async () => {
      const token = await getValidToken(navigate, true); 
      if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true
        };
        const response = await axios.get(`${API_URL}/my/ad/${adId}`, config);
        const fetchedAdFormData = response.data;

        setAdData(fetchedAdFormData);
        
        setUpdatedAdFormData({
          title: fetchedAdFormData.title,
          video: fetchedAdFormData.video,
          audio: fetchedAdFormData.audio,
          videoLink: fetchedAdFormData.video_link,
          thumbnail: fetchedAdFormData.thumbnail,
          description: fetchedAdFormData.description,
          price: fetchedAdFormData.price,
          ads_category_id: fetchedAdFormData.ads_category_id,
          accessType: fetchedAdFormData.access_type,
          status: fetchedAdFormData.status,
          state: fetchedAdFormData.state,
          local_government: fetchedAdFormData.local_government,
          area: fetchedAdFormData.area,
          ward: fetchedAdFormData.ward,
          isNegotiable: fetchedAdFormData.isNegotiable,
          condition: fetchedAdFormData.condition,
          amount_to_spend: fetchedAdFormData.amount_to_spend,
          active_status: fetchedAdFormData.active_status,
          end_date: fetchedAdFormData.end_date,
        });

        // Set the fetched thumbnail
        setThumbnail(fetchedAdFormData.thumbnail);

        // Set the fetched pictures
        const fetchedPictures = fetchedAdFormData.images || [];
        setPictures(fetchedPictures);
        setPreviewImages(fetchedPictures);

        const disableCondition = (
          (fetchedAdFormData.end_date && new Date(fetchedAdFormData.end_date) > new Date()) ||
          fetchedAdFormData.active_status === "active" //||
          //parseFloat(fetchedAdFormData.amount_to_spend) > 0.00)
        );
        setIsDisabled(disableCondition);

      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setIsLoading(false);
      }
    }
    };
    fetchAd();
  }, [adId, navigate]);

  // useEffect(() => {
  //   const disableCondition = (
  //     (updatedAdFormData?.end_date && new Date(updatedAdFormData.end_date) > new Date()) ||
  //     (updatedAdFormData?.active_status === "inactive" ||
  //     parseFloat(updatedAdFormData?.amount_to_spend) <= 0.00)
  //   );
  //   setIsDisabled(disableCondition);
  // }, [
  //   updatedAdFormData.end_date, 
  //   updatedAdFormData.active_status, 
  //   updatedAdFormData.amount_to_spend
  // ]);



  useEffect(() => {
    const fetchUserData = async () => {
      const token = await getValidToken(navigate, true);
      if (token) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${API_URL}/user/ads/check`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          // Handle the response
          if (response.data) {
            const { ads, hasAds, phone: userPhone, email: userEmail } = response.data;

            if (ads && ads.id) {
              setIsFirstAd(false);
            }

            //setEmail(userEmail);
            //setPhone(userPhone);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUserData();
  }, [navigate]); 

  const calculateAmountToSpend = useCallback (() => {
    const accessPrices = {
      '1-week': 1500,
      '2-weeks': 3000,
      '3-weeks': 4500,
      '1-month': 5000,
      '2-month': 10000,
      '3-month': 15000,
      '6-month': 30000,
      '1-year': 60000,
    };

    const adTypePrices = {
      mainApp: 2000,
      kasuwaSection: 3000,
      player: 5000,
      navigate: 10000,
    };

    const accessPrice = accessPrices[updatedAdFormData.access_type] || 0;
    const adTypePrice = adTypePrices[updatedAdFormData.ads_type] || 0;

    const totalAmount = accessPrice + adTypePrice;
    setUpdatedAdFormData((prev) => ({
      ...prev,
      amount_to_spend: totalAmount.toString(),
    }));
  }, [updatedAdFormData.access_type, updatedAdFormData.ads_type]);

  useEffect(() => {
    if (!isFirstAd) {
      calculateAmountToSpend();
    }
  }, [updatedAdFormData.access_type, updatedAdFormData.ads_type, isFirstAd, calculateAmountToSpend]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAdFormData({ ...updatedAdFormData, [name]: value });
  };

  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;

  //   if (name === 'thumbnail') {
  //     setThumbnail(files[0]);
  //   } else if (name === 'video') {
  //     setVideo(files[0]);
  //   } else if (name === 'pictures') {
  //     const newPictures = Array.from(files);
  //     setPictures((prevPictures) => {
  //       const updatedPictures = [...prevPictures, ...newPictures].slice(0, 10);
  //       const newPreviews = updatedPictures.map((file) =>
  //         typeof file === 'string' ? `${baseURL}${file}` : URL.createObjectURL(file)
  //       );

  //       // Revoke old URLs to avoid memory leaks
  //       previewImages.forEach((url) => {
  //         if (!url.includes(baseURL)) URL.revokeObjectURL(url);
  //       });
  //       setPreviewImages(newPreviews);

  //       return updatedPictures;
  //     });
  //   }
  // };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
  
    if (name === 'thumbnail') {
      setThumbnail(files[0]);
    } else if (name === 'video') {
      setVideo(files[0]);
    } else if (name === 'pictures') {
      const newPictures = Array.from(files);
  
      setPictures((prevPictures) => {
        // Combine old and new pictures, and limit to 10
        const updatedPictures = [...prevPictures, ...newPictures].slice(0, 10);
  
        // Create previews using the renderImageSrc function
        const newPreviews = updatedPictures.map(renderImageSrc);
  
        // Revoke old URLs to avoid memory leaks
        previewImages.forEach((url) => {
          if (!url.includes(baseURL)) URL.revokeObjectURL(url);
        });
  
        setPreviewImages(newPreviews);
        return updatedPictures;
      });
    }
  };
  
  // Function to render images
  const renderImageSrc = (image) => {
    if (typeof image === 'string') {
      return `${baseURL}${image}`;
    } else if (image instanceof File) {
      return URL.createObjectURL(image);
    } else {
      return '';
    }
  };

  useEffect(() => {
    // Initialize preview images with fetched pictures
    if (pictures && pictures.length > 0) {
      const initialPreviews = pictures.map((file) => renderImageSrc(file));
      setPreviewImages(initialPreviews);
      setPictures(pictures);
    }
  }, [pictures]);
  

  const moveImageLeft = (index) => {
    if (index > 0) {
      const reorderedPictures = Array.from(pictures);
      const temp = reorderedPictures[index - 1];
      reorderedPictures[index - 1] = reorderedPictures[index];
      reorderedPictures[index] = temp;
  
      setPictures(reorderedPictures);
      setPreviewImages(reorderedPictures.map(renderImageSrc));
    }
  };
  
  const moveImageRight = (index) => {
    if (index < pictures.length - 1) {
      const reorderedPictures = Array.from(pictures);
      const temp = reorderedPictures[index + 1];
      reorderedPictures[index + 1] = reorderedPictures[index];
      reorderedPictures[index] = temp;
  
      setPictures(reorderedPictures);
      setPreviewImages(reorderedPictures.map(renderImageSrc));
    }
  };
  
  const handleRemoveImage = (index) => {
    setPictures((prevPictures) => {
      const updatedPictures = prevPictures.filter((_, i) => i !== index);
  
      // Revoke the URL of the removed image
      URL.revokeObjectURL(previewImages[index]);
  
      setPreviewImages(updatedPictures.map(renderImageSrc));
      return updatedPictures;
    });
  };
  

  // const handlePriceOptionChange = (e) => {
  //   const { value } = e.target;
  //   setUpdatedAdFormData((prev) => ({
  //     ...prev,
  //     isNegotiable: value === 'negotiable',
  //     price: value === 'negotiable' ? '' : prev.price,
  //   }));
  // };
  const handlePriceOptionChange = (event) => {
    const { value } = event.target;
    setUpdatedAdFormData((prevData) => ({
      ...prevData,
      isNegotiable: value === "negotiable"
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const token = await getValidToken(navigate, true);
    
    if (token) {
      setSubmitIsLoading(true);
    try {
      const formDataObj = new FormData();

       // Conditionally append fields to FormData
       for (const [key, value] of Object.entries(updatedAdFormData)) {
        // Skip appending 'amount_to_spend' if the condition is true
        if (key === 'amount_to_spend' && isDisabled) {
          continue; // Skip appending this field
        }
        formDataObj.append(key, value);
      }
  
      // Append files
      if (thumbnail) formDataObj.append('thumbnail', thumbnail);
      if (video) formDataObj.append('video', video);

      // if (pictures.length > 0) {
      //   pictures.forEach((picture) => formDataObj.append('pictures', picture));
      // }

      // Append files in the order they appear in the pictures array
      if (pictures.length > 0) {
        pictures.forEach((picture) => formDataObj.append('pictures', picture));
    }
  
       await axios.put(`${API_URL}/ads/${adId}`, formDataObj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setError('');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(true);
        navigate('/manage/ads');
      }, 5000); 
    } catch (error) {
      console.error('Error creating ad:', error);
      
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An unexpected error occurred.');
      }
  
      setTimeout(() => {
        setSuccess(false);
        setError('');
      }, 5000);
    } finally {
      setSubmitIsLoading(false);
    }
    }
  };

  useEffect(() => {
    console.log("updatedAdFormData changed:", updatedAdFormData);
  }, [updatedAdFormData]);

  console.log('disabled values', updatedAdFormData.end_date, updatedAdFormData.active_status, updatedAdFormData.amount_to_spend)
  
  // const isDisabled = (
  //   (updatedAdFormData.end_date && new Date(updatedAdFormData.end_date) > new Date()) ||
  //   updatedAdFormData.active_status === "active" ||
  //   updatedAdFormData.amount_to_spend > 0.00
  // );

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  

  return (
    <FormContainer>
      <FormTitle>Update Ad</FormTitle>
      <form onSubmit={handleSubmit}>
      <FormField>
          <Label>Thumbnail</Label>

      {thumbnail && (
        <FormField>
        <PreviewImage>
          <Image src={renderImageSrc(thumbnail)} alt="Thumbnail" />
        </PreviewImage>
        </FormField>
      )}

          <Input
            type="file"
            name="thumbnail"
            accept="image/*"
            onChange={handleFileChange}
          />
        </FormField>
        
        <FormField>
          <Label>Pictures (Up to 10)</Label>
          <PreviewContainer>
        {previewImages.map((image, index) => (
          <PreviewImage key={`${image}-${index}`}>
            <Image src={image} alt={`Preview ${index}`} />

            {index > 0 && (
              <MoveButton
                style={{ left: '-25px' }}
                onClick={() => moveImageLeft(index)}
              >
                <FaArrowAltCircleRight  color={'black'}/>
              </MoveButton>
            )}
            {index < previewImages.length - 1 && (
              <MoveButton
                style={{ right: '-25px' }}
                onClick={() => moveImageRight(index)}
              >
                <FaArrowAltCircleLeft color={'black'} />
              </MoveButton>
            )}
            <RemoveButton onClick={() => handleRemoveImage(index)}>
              <IoMdCloseCircle color='white' />
            </RemoveButton>
          </PreviewImage>
        ))}
      </PreviewContainer>

          <Input
            type="file"
            name="pictures"
            accept="image/*"
            multiple
            onChange={handleFileChange}
          />
        </FormField>
        <FormField>
          <Label>Video Link (Optional)</Label>
          <Input
            type="url"
            name="video_link"
            value={updatedAdFormData.video_link}
            onChange={handleInputChange}
          />
        </FormField>
        <FormField>
          <Label>Title</Label>
          <Input
            type="text"
            name="title"
            value={updatedAdFormData.title}
            onChange={handleInputChange}
            required
          />
        </FormField>
        <FormField>
          <Label>Ads Category</Label>
          <Select
            name="ads_category_id"
            value={updatedAdFormData.ads_category_id}
            onChange={handleInputChange}
            required
          >
            <option value="1">Electronics</option>
            <option value="2">Vehicles</option>
            <option value="3">Real Estate</option>
            <option value="4">Services</option>
            <option value="5">Jobs</option>
            <option value="6">Furniture</option>
            <option value="7">Fashion</option>
            <option value="8">Health & Beauty</option>
            <option value="9">Sports & Outdoors</option>
            <option value="10">Pets</option>
            <option value="11">Books & Media</option>
            <option value="12">Home & Garden</option>
            <option value="13">Toys & Games</option>
            <option value="14">Food & Beverages</option>
            <option value="15">Automotive</option>
          </Select>
        </FormField>
        <FormField>
          <label htmlFor="condition">Condition</label>
          <div style={{ gap: '20px', display: 'flex', margin: '10px' }}>
            <div>
              <input
                type="radio"
                id="condition-new"
                name="condition"
                value="new"
                checked={updatedAdFormData.condition === 'new'}
                onChange={handleInputChange}
              />
              <label htmlFor="condition-new">New</label>
            </div>

            <div>
              <input
                type="radio"
                id="condition-used"
                name="condition"
                value="used"
                checked={updatedAdFormData.condition === 'used'}
                onChange={handleInputChange}
              />
              <label htmlFor="condition-used">Used</label>
            </div>
          </div>
        </FormField>
        <FormField>
          <Label>Description</Label>
          <Textarea
            name="description"
            value={updatedAdFormData.description}
            onChange={handleInputChange}
            required
            rows="4"
          />
        </FormField>
        <FormField>
          <Label>Price</Label>
          <div style={{ gap: '20px', display: 'flex', margin: '10px' }}>
          <div>
            <input
              type="radio"
              name="priceOption"
              value="price"
              checked={!updatedAdFormData.isNegotiable}
              onChange={handlePriceOptionChange}
            />
            <label>Price</label>
            </div>
            <div>
            <input
              type="radio"
              name="priceOption"
              value="negotiable"
              checked={updatedAdFormData.isNegotiable}
              onChange={handlePriceOptionChange}
            />
            <label>Negotiable</label>
          </div>
          </div>
          {!updatedAdFormData.isNegotiable && (
            <Input
              type="number"
              name="price"
              value={updatedAdFormData.price}
              onChange={handleInputChange}
              required={!updatedAdFormData.isNegotiable}
              step="0.01"
            />
          )}
        </FormField>
        <FormField>
          <Label>State</Label>
          <Select 
            id="state"
            name="state" 
            value={updatedAdFormData.state} 
            onChange={handleInputChange}
            >
          <option value="">--Choose State--</option>
          {States.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </Select>
        </FormField>
        {updatedAdFormData.state && PopularAreas[updatedAdFormData.state] && (
          <>
          <FormField>
            <Label>Choose Nearest to your location</Label>
            <Select 
              id="area" 
              name="area"
              value={updatedAdFormData.area} 
              onChange={handleInputChange}

              >
              <option value="">--Choose Area--</option>
              {PopularAreas[updatedAdFormData.state].map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
              <option value="Whole">Whole {updatedAdFormData.state}</option>
            </Select>
            </FormField>
          </>
        )}
        {updatedAdFormData.state && LocalGovernments[updatedAdFormData.state] && (
          <>
        <FormField>
          <Label>Local Government</Label>
          <Select 
            id="lga" 
            name="local_government"
            value={updatedAdFormData.local_government} 
            onChange={handleInputChange}>
              <option value="">--Choose Local Government--</option>
              {LocalGovernments[updatedAdFormData.state].map((lga, index) => (
                <option key={index} value={lga}>
                  {lga}
                </option>
              ))}
            </Select>
        </FormField>
        </>
      )}

{updatedAdFormData.local_government && Wards[updatedAdFormData.state] && Wards[updatedAdFormData.state][updatedAdFormData.local_government] && (
          <>
        <FormField>
          <Label>Select Ward</Label>
          <Select 
            id="ward" 
            name="ward"
            value={updatedAdFormData.ward} 
            onChange={handleInputChange}>
              <option value="">--Choose Ward--</option>
              {Wards[updatedAdFormData.state][updatedAdFormData.local_government].map((ward, index) => (
                <option key={index} value={ward}>
                  {ward}
                </option>
              ))}
            </Select>
        </FormField>
        </>
      )}
        <FormField>
          <Label>Address</Label>
          <Input
            type="text"
            name="address"
            value={updatedAdFormData.address}
            onChange={handleInputChange}
            required
          />
        </FormField>
        <FormField>
          <Label>Contact Phone</Label>
          <Input
            type="tel"
            name="phone"
            value={updatedAdFormData.phone}
            onChange={handleInputChange}
            required
            disabled
          />
        </FormField>
        <FormField>
          <Label>Access Type</Label>
          <Select
            name="access_type"
            value={updatedAdFormData.access_type}
            onChange={handleInputChange}
            required
            disabled={isDisabled}
          >
            <option value="1-week">1 Week {!isFirstAd && '- ₦1500'}</option>
            <option value="2-weeks">2 Weeks {!isFirstAd && '- ₦3000'}</option>
            <option value="3-weeks">3 Weeks {!isFirstAd && '- ₦4500'}</option>
            <option value="1-month">1 Month {!isFirstAd && '- ₦5000'}</option>
            <option value="2-months">2 Months {!isFirstAd && '- ₦10000'}</option>
            <option value="3-months">3 Months {!isFirstAd && '- ₦15000'}</option>
            <option value="4-months">4 Months {!isFirstAd && '- ₦20000'}</option>
            <option value="5-months">5 Months {!isFirstAd && '- ₦25000'}</option>
            <option value="6-months">6 Months {!isFirstAd && '- ₦30000'}</option>
            <option value="1-year">1 Year - {!isFirstAd && '- ₦60000'}</option>
          </Select>
        </FormField>
        <FormField>
          <Label>Ads Type</Label>
          <Select
            name="ads_type"
            value={updatedAdFormData.ads_type}
            onChange={handleInputChange}
            required
            disabled={isDisabled}
          >
            <option value="mainApp">Main App {!isFirstAd && '- ₦2000'}</option>
            <option value="kasuwaSection">Kasuwa Section {!isFirstAd && '- ₦3000'}</option>
            <option value="player">Player {!isFirstAd && '- ₦5000'}</option>
            <option value="navigate">Navigate {!isFirstAd && '- ₦10000'}</option>
          </Select>
        </FormField>
        {updatedAdFormData.ads_type === 'player' && (
          <FormField>
            <Label>Video (Optional)</Label>
            <Input
              type="file"
              name="video"
              accept="video/*"
              onChange={handleFileChange}
              style={{ display: updatedAdFormData.ads_type === 'player' ? 'block' : 'none' }}
            />
          </FormField>
        )}
        <FormField>
          <Label>Amount to Spend</Label>
          <Input
            type="text"
            name="amount_to_spend"
            value={isFirstAd ? 'Free' : isDisabled  ? 'Already paid for this plan and is active' : updatedAdFormData.amount_to_spend}
            readOnly
          />
        </FormField>
        
        {error && (
          <FormField><b><p style={{color: 'red'}}>{error}</p></b></FormField>
        )}
        
        <Button 
        onClick={handleSubmit}
        isLoading={submitIsLoading} 
        loginSuccess={success} 
        errorMessage={error}  
        disabled={submitIsLoading || success}
        isP={true}
        >
          Update Ad</Button>
      </form>
    </FormContainer>
  );
};

export default UpdateAdsForm;
