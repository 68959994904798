import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import styled, { keyframes, css } from 'styled-components';
import { FaPlay, FaPause, FaExpand, FaCompress, FaCog } from 'react-icons/fa';
import Logo from '../assets/ArewaTv.png';

const VideoPlayerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 233px;
  background-color: black;
  border-radius: 7px;
  border: 2px solid ${props => props.theme.colors.text};

  ${props => props.isFullScreen && css`
    border: 2px solid black;
  `}

  @media (min-width: 768px) {
    height: 320px;
  }

  @media (min-width: 1242px) {
    height: 420px;
  }
`;

const ControlsDiv = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px;
  transition: opacity 0.5s;
  opacity: ${props => props.hidden ? '0' : '1'};
  pointer-events: ${props => props.hidden ? 'none' : 'auto'};
`;

const Slider = styled.input`
  width: 100%;
  margin: 0 10px;
  appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-moz-range-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-ms-track {
    height: 5px;
    background: linear-gradient(to right, green ${props => props.value * 100}%, #ddd 0%);
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid green;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const SettingsDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px;
  display: ${props => props.visible ? 'block' : 'none'};
`;

const TimeDisplay = styled.span`
  color: white;
  margin-left: 10px;
  margin-right: 10px;
`;

const LogoDiv = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 20; // Ensure it is above the video

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TopRightLogo = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 10;
  //opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.5s;
  

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    animation: ${rotate} 2s linear infinite;
  }
`;

const StyledP = styled.p`
    color: ${props => props.theme.colors.danger};
    margin: 5px 0px;
    background-color: ${props => props.theme.colors.background};
`;

const VideoPlayer = ({ videoUrl, thumbnailUrl }) => {
  const playerRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [logoVisible, setLogoVisible] = useState(false);
  const logoTimeoutRef = useRef(null);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const resetHideControlsTimeout = () => {
    clearTimeout(controlsTimeoutRef.current);

    if (playing) {
      controlsTimeoutRef.current = setTimeout(() => {
        setControlsVisible(false);
        setSettingsVisible(false); // Hide settings when controls are hidden
      }, 5000);
    } else {
      setControlsVisible(true);
    }
  };

  useEffect(() => {
    resetHideControlsTimeout(); // Set timeout to hide controls when player state changes

    return () => clearTimeout(controlsTimeoutRef.current);
  }, [playing]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreenNow = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
      setIsFullScreen(!!isFullScreenNow);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);

  const handlePlayPause = () => {
    if (isIOS) {
      const videoElement = playerRef.current;
      if (videoElement) {
        // Check if the video is ready to play
        if (videoElement.readyState >= 3) { // HAVE_FUTURE_DATA or HAVE_ENOUGH_DATA
          if (videoElement.paused) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        } else {
          console.log("Video is not ready to be played.");
        }
      }
    } else {
      // For non-iOS platforms
      setPlaying(prev => !prev);
    }
  
    // Show controls after play/pause toggle
    setControlsVisible(true);
  };  
  

  const handleSeekChange = (e) => {
    const newProgress = parseFloat(e.target.value);
    setProgress(newProgress);
    const newTime = newProgress * duration;
    
    if (isIOS) {
      const videoElement = playerRef.current;
      videoElement.currentTime = newTime;
      videoElement.play(); 
    } else {
      playerRef.current.seekTo(newProgress, 'fraction');
      setPlaying(true);
    }
  };

  const showLogo = () => {
    setLogoVisible(true);
    clearTimeout(logoTimeoutRef.current);
    logoTimeoutRef.current = setTimeout(() => {
      setLogoVisible(false);
    }, 5000);
  };

  const handleProgress = (state) => {
    setProgress(state.played);
    const playedSeconds = state.playedSeconds;

    if (playedSeconds > 0 && playedSeconds < 5) {
      showLogo();
    }
    if (playedSeconds > duration * 0.5 && playedSeconds < duration * 0.5 + 5) {
      showLogo();
    }
    if (playedSeconds > duration * 0.8 && playedSeconds < duration * 0.8 + 5) {
      showLogo();
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };
  
  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const toggleFullScreen = () => {
    if (isIOS) {
      const videoElement = playerRef.current; // Get the video element reference
  
      if (!isFullScreen) {
        videoElement.webkitEnterFullscreen(); // Enter full-screen mode
        videoElement.play(); // Ensure the video starts playing when entering full-screen mode
        setIsFullScreen(true);
      } else {
        videoElement.webkitExitFullscreen(); // Exit full-screen mode
        videoElement.play(); // Ensure the video continues playing after exiting full-screen mode
        setIsFullScreen(false);
      }
    } else {
      const playerElement = playerRef.current.wrapper.parentNode;
  
      if (!isFullScreen) {
        if (playerElement.requestFullscreen) {
          playerElement.requestFullscreen();
        } else if (playerElement.mozRequestFullScreen) { /* Firefox */
          playerElement.mozRequestFullScreen();
        } else if (playerElement.webkitRequestFullscreen) { /* Chrome, Safari, and Opera */
          playerElement.webkitRequestFullscreen();
        } else if (playerElement.msRequestFullscreen) { /* IE/Edge */
          playerElement.msRequestFullscreen();
        }
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari, and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
        setIsFullScreen(false);
      }
    }
  };
  

  const handleError = (error) => {
    console.error('Video playback error:', error);
    setLoading(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  if (isIOS) {
    return (
      <VideoPlayerDiv onClick={handlePlayPause}>
        <video
          ref={playerRef}
          src={videoUrl}
          controls
          width="100%"
          height="100%"
          playsInline
          poster={thumbnailUrl}  // Display a preview image on mount
          onPlay={handlePlay}
          onPause={handlePause}
          onWaiting={() => setLoading(true)}  // Show loading state when buffering
          onPlaying={() => setLoading(false)}  // Hide loading state when video is playing
          onError={handleError}
        />
        {!playing && !loading && (
          <LogoDiv onClick={(e) => { e.stopPropagation(); handlePlayPause(); }}>
            <img src={Logo} alt="Play Logo" />
          </LogoDiv>
        )}
        {loading && (
          <LoadingLogo visible={loading}>
            <img src={Logo} alt="Loading" />
          </LoadingLogo>
        )}
        {/* <p onClick={toggleFullScreen}>FullScreen</p> */}
      </VideoPlayerDiv>
    );
  }
  

  return (
    <VideoPlayerDiv
      onMouseMove={() => resetHideControlsTimeout()}
      onClick={handlePlayPause}
    >
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        playing={playing}
        controls={false} // Disable default controls for custom player
        width="100%"
        height="100%"
        className={isFullScreen ? 'fullscreen-video' : ''}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onPlay={handlePlay}
        onPause={handlePause}
        onError={handleError}
        onBuffer={() => setLoading(true)}
        onBufferEnd={() => setLoading(false)}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              disablepictureinpicture: true,
            },
          },
        }}
        playsinline // This prevents iOS from forcing fullscreen
      />
      <TopRightLogo visible={logoVisible}>
        <img src={Logo} alt="Logo" />
      </TopRightLogo>
      {!playing && (
        <LogoDiv onClick={(e) => { e.stopPropagation(); handlePlayPause(); }}>
          <img src={Logo} alt="Play Logo" />
        </LogoDiv>
      )}
      {loading && (
        <LoadingLogo visible={loading}>
          <img src={Logo} alt="Loading" />
        </LoadingLogo>
      )}
      <ControlsDiv hidden={!controlsVisible} onClick={(e) => e.stopPropagation()}>
        <button onClick={(e) => { e.stopPropagation(); handlePlayPause(); }}>
          {playing ? <FaPause /> : <FaPlay />}
        </button>
        <TimeDisplay>{formatTime(progress * duration)}</TimeDisplay>
        <Slider 
          type="range" 
          min={0} 
          max={1} 
          step="any" 
          value={progress} 
          onChange={handleSeekChange} 
        />
        <TimeDisplay>{formatTime(duration)}</TimeDisplay>
        <button onClick={(e) => { e.stopPropagation(); toggleFullScreen(); }} style={{marginRight: '8px'}}>
          {isFullScreen ? <FaCompress /> : <FaExpand />}
        </button>
        <button onClick={(e) => { e.stopPropagation(); setSettingsVisible(prev => !prev); }}>
          <FaCog />
        </button>
      </ControlsDiv>
      <SettingsDiv visible={settingsVisible}>
        <p>Settings placeholder</p>
      </SettingsDiv>
    </VideoPlayerDiv>
  );
};

export default VideoPlayer;
