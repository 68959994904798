import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  z-index: 29;
`;

const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f8f8;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const Iframe = styled.iframe`
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
`;

// WebViewModal Component
const WebViewModal = ({ visible, url, onClose }) => {
  const [loading, setLoading] = useState(true);

  return (
    visible && (
      <ModalContainer>
        <Header>
          <Title>WebView</Title>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>
        <div style={{ position: 'relative', flex: 1 }}>
          <Iframe
            src={url}
            onLoad={() => setLoading(false)}
            onError={() => setLoading(false)}
          />
          {loading && <LoadingIndicator>Loading...</LoadingIndicator>}
        </div>
      </ModalContainer>
    )
  );
};

export default WebViewModal;
