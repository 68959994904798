import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { API_URL, baseURL } from '../config';
import { getValidToken, getUserIdFromToken } from '../utils/auth';

const ChatListContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ChatListHeader = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
  
  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

const ChatItemList = styled.ul`
  padding: 0;
  list-style: none;
`;

const ChatItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.background2};
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid ${(props) => props.theme.colors.border};

  &:hover {
    background-color: ${(props) => props.theme.colors.hover};
  }
`;

const ChatDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;

  strong {
    font-size: 18px;
    color: ${(props) => props.theme.colors.text};
  }

  p {
    margin: 4px 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.textSecondary};
  }
`;

const TimeText = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.textSecondary};
`;

const UnseenDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  margin-left: 10px;
`;

const LoadMoreButton = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
  }
`;

// Utility function to format time
const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours % 12 || 12}:${minutes < 10 ? `0${minutes}` : minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
};

const ChatList = () => {
  const [userId, setUserId] = useState(null);
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserId = async () => {
      const id = await getUserIdFromToken(navigate);
      setUserId(id);
    };
    fetchUserId();
  }, [navigate]);

  const fetchChats = useCallback(async (reset = false) => {
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/chat/list`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
          params: {
            search,
            page: reset ? 1 : page,
            limit: 20,
          },
        });

        const fetchedChats = response.data.chats;
        setChats(reset ? fetchedChats : [...chats, ...fetchedChats]);
        setHasMore(fetchedChats.length === 20); // If fetched less than 20, assume no more data
        if (reset) setPage(1);
        else setPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    }
  }, [navigate, search, page]);

  useEffect(() => {
    if (userId) {
      fetchChats(true); // Fetch with reset on initial load or search change
    }
  }, [userId, fetchChats]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    fetchChats(true); // Refetch chats with the new search value
  };

  const handleChatClick = (chat) => {
    navigate(`/chat/${chat.ad_id}`);
  };

  return (
    <ChatListContainer>
      <ChatListHeader>Your Chats</ChatListHeader>
      <SearchInput
        type="text"
        value={search}
        onChange={handleSearch}
        placeholder="Search by title or username..."
      />
      <ChatItemList>
        {chats.map((chat, index) => (
          <ChatItem key={index} onClick={() => handleChatClick(chat)}>
            <img
              src={`${baseURL}${chat.ad_thumbnail}`}
              alt=""
              width="50px"
              height="50px"
              style={{ borderRadius: '5px' }}
            />
            <ChatDetails>
              <strong>{chat.sender_id === userId ? 'You' : chat.sender_username}</strong>
              <p>{chat.ad_title}</p>
              <p>{chat.last_message}</p>
            </ChatDetails>
            <TimeText>{formatTime(chat.last_message_time)}</TimeText>
            {chat.seen === 0 && <UnseenDot />}
          </ChatItem>
        ))}
      </ChatItemList>
      {hasMore && <LoadMoreButton onClick={() => fetchChats()}>Load More</LoadMoreButton>}
    </ChatListContainer>
  );
};

export default ChatList;

