import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ErrorMessageModal from './ErrorMessageModal';

const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${props => props.theme.colors.background};
  border-top: 1px solid ${props => props.theme.colors.text};
  border-radius: 6px;
  padding: 20px;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.text};
  font-size: 24px;
  margin-bottom: 20px;
`;

// Styled Components
const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
  option {
    color: ${props => props.theme.colors.text};
  }
`;

const Option = styled.option`
  color: ${props => props.theme.colors.text};
`;

const Button = styled.button`
  background-color: ${props => props.type === 'primary' ? '#4caf50' : '#f44336'};
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;

  &:hover {
    background-color: ${props => props.type === 'primary' ? '#45a049' : '#d32f2f'};
  }
`;

// const ErrorMessage = styled.p`
//   color: red;
//   margin-top: 10px;
// `;

const CommentsModalDiv = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const SubscriptionModal = ({ authorId, authorUsername, onClose }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${API_URL}/public/subscriptions/plans/${authorId}`);
        setPlans(response.data);
        setError('');
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
        setError('Error fetching subscription plans');
        setTimeout(() => {
          setError('');
        }, 8000);
      }
    };
    fetchPlans();

    // if (authorId) {
    //   fetchPlans();
    // }
  }, [authorId]);

  const handleSubscription = async () => {
    if (!selectedPlan) {
      setError('Please select a subscription plan');
      setTimeout(() => {
        setError('');
      }, 4000);
      return;
    }

    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const response = await axios.post(`${API_URL}/subscribe`, {
          authorId,
          duration: selectedPlan
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          onClose();
        } else {
          setError(response.data.error || 'Subscription failed');
        }
      } catch (error) {
        console.error('Error subscribing:', error.response?.data || error.message);
        setError(error.response?.data?.error || 'Subscription failed. Please try again later.');
        setTimeout(() => {
            setError('');
          }, 7000);
      }
    }
  };

  const handleCloseModal = () => {
    setError('');
  };

  // This function will take the plan name and return the corresponding translated string and duration.
const getTranslatedPlanDuration = (planDuration, t) => {
  if (planDuration === 'weekly') {
    return t('1 week');
  } else if (planDuration === 'monthly') {
    return t('1 month');
  } else if (planDuration === 'yearly') {
    return t('1 year');
  } else {
    return planDuration;  // Fallback to the original plan name if no match
  }
};

// Format earnings with commas
  const formatPrice = (earnings) => {
    // Check if earnings is a valid number or string that can be converted to a number
    if (isNaN(parseFloat(earnings)) || !isFinite(earnings)) {
      return '......'; //'Invalid earnings';
    }
  
    // Convert earnings to number and ensure it's a valid number
    earnings = parseFloat(earnings);
    if (isNaN(earnings) || !isFinite(earnings)) {
      return 'Invalid earnings';
    }
  
    // Convert earnings to string with 2 decimal places
    let earningsStr = earnings.toFixed(2);
  
    // Split earnings into whole and decimal parts
    const parts = earningsStr.split('.');
    let wholePart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Add commas for thousands, millions, etc.
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    wholePart = wholePart.replace(regex, ',');
  
    // Return formatted earnings
    return wholePart + decimalPart;
  };  


  return (
    <ModalContainer>
      <Title>Subscribe to {authorUsername}</Title>
      <div>
        <h3>Select Subscription Plan</h3>
        {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
        <Select value={selectedPlan} onChange={e => setSelectedPlan(e.target.value)}>
        <Option disabled>Select a plan</Option>
        {plans.map(plan => (
          <Option key={plan.id} value={plan.duration}>
            {plan.name} - ₦{formatPrice(plan?.price)} ({getTranslatedPlanDuration(plan.duration, t)})
          </Option>
        ))}
      </Select>
      </div>

      <Button onClick={onClose}>Cancel</Button>
      <Button type="primary" onClick={handleSubscription}>Subscribe</Button>

      {error && (
      <ErrorMessageModal errorMessage={error} onClose={handleCloseModal}/>
    )}  
    </ModalContainer>
  );
};

export default SubscriptionModal;



