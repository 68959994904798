import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BsCart4 } from "react-icons/bs";
import { PiTelevisionBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';

const SliderContainer = styled.div`
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 10px 0;
  margin: 5px;
  max-width: 100vw; /* Ensure the container doesn't exceed the screen width */
  white-space: nowrap; /* Prevent line breaks */

  /* Optional: Add a scrollbar for aesthetics */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.border} ${(props) =>
  props.theme.colors.background};
`;

const CategoryButton = styled.button`
  padding: 8px 16px;
  font-size: 15px;
  margin-right: 10px;
  background-color: ${(props) => (props.active ? '#333' : '#ccc')};
  color: ${(props) => (props.active ? '#fff' : '#000')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#333' : '#aaa')};
  }

  @media (max-width: 768px) {
    padding: 6px 14px;
  }

  @media (max-width: 480px) {
    padding: 6px 14px;
  }

  @media (max-width: 290px) {
    padding: 6px 14px;
  }
`;

const CategoriesSliderKasuwa = ({ handleSectionToggle, categories, activeSection, isFromHome, showFilter, setActiveCategory, setActivePrice, handleFetchAds, setCurrentPage }) => {
  const [activeCategory, setActiveCategoryInternal] = useState(null);
  const [activePrice, setActivePriceInternal] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    handleFetchAds(activeCategory, activePrice);
  }, [activeCategory, activePrice, handleFetchAds, setCurrentPage]);

   // Determine the active section based on isFromHome
  const effectiveActiveSection = !isFromHome ? 'kasuwa' : activeSection;

  
console.log('received categories props', categories);

const handleClick = (id, type) => {
  if (type === 'section') {
    if (id === 'main' && !isFromHome) {
      navigate('/'); // Navigate to the home page if not from the home page
    } else if (id === 'kasuwa' && !isFromHome) {
      navigate('/kasuwa/section'); // Stay on the current page
    } else {
      handleSectionToggle(id); // Use the current behavior to toggle sections
    }
  } else if (type === 'category') {
    setActiveCategory(id);
    setActivePriceInternal(null);
    setCurrentPage(1);
    setActiveCategoryInternal(id);
  } else if (type === 'price') {
    setActivePrice(id);
    setActiveCategoryInternal(null);
    setCurrentPage(1);
    setActivePriceInternal(id);
  } else {
    setActiveCategoryInternal(null);
    setActivePriceInternal(null);
    setCurrentPage(1);
  }
};
  

  return (
    <SliderContainer>
      <CategoryButton onClick={() => handleClick('main', 'section')} 
        style={{
            backgroundColor: effectiveActiveSection === 'main' ? 'green' : '#ccc', // Change color if active
            color: effectiveActiveSection === 'main' ? '#fff' : '#000',
          }}>
        Main <PiTelevisionBold />
      </CategoryButton>
      <CategoryButton 
      onClick={() => handleClick('kasuwa', 'section')} 
      style={{
        backgroundColor: effectiveActiveSection === 'kasuwa' ? 'green' : '#ccc', 
        color: effectiveActiveSection === 'kasuwa' ? '#fff' : '#000',
      }}
      >
        Kasuwa <BsCart4 />
      </CategoryButton>  
      <CategoryButton
        active={activeCategory === 'all'}
        onClick={() => handleClick('all', 'category')}
      >
        All
      </CategoryButton>
      {categories?.map((category) => (
        <CategoryButton
          key={category.id}
          $active={activeCategory === category.id}
          onClick={() => handleClick(category.id, 'category')}
        >
          {category.name}
        </CategoryButton>
      ))}
      <CategoryButton
        $active={activePrice === 'price'}
        onClick={() => handleClick('price', 'price')}
      >
        Price
      </CategoryButton>
      <CategoryButton
        $active={activePrice === 'free'}
        onClick={showFilter}
      >
        Filter
      </CategoryButton>
    </SliderContainer>
  );
};

export default CategoriesSliderKasuwa;
