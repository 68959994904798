// import React, { useEffect, useRef } from 'react';
// import PostCard from './PostCard';
// import styled from 'styled-components';
// import {PostsContainer} from './PostCard.styles';
// import useRecommendedPosts  from './RecommendedPosts';

// const RecommendedPostsList = ({ postIdprops }) => {
//     const loadMoreRef = useRef(null);
//     const {posts, fetchMorePosts, loading } = useRecommendedPosts(postIdprops);

//     useEffect(() => {
//       const loadMoreElement = loadMoreRef.current;
  
//       const observer = new IntersectionObserver((entries) => {
//         if (entries[0].isIntersecting && !loading) {
//           fetchMorePosts();
//         }
//       }, { threshold: 1.0 });
  
//       if (loadMoreElement) {
//         observer.observe(loadMoreElement);
//       }
  
//       return () => {
//         if (loadMoreElement) {
//           observer.unobserve(loadMoreElement);
//         }
//       };
//     }, [loading, fetchMorePosts]);
  
//     return (
//       <div>
//         <PostsContainer>
//       {posts.map((post, index) => (
//         <PostCard key={post.id} post={post} />
//       ))}
//       </PostsContainer>
//       {loading && <p>Loading...</p>}
//       {!loading && (
//         <Button onClick={fetchMorePosts}>Load More</Button>
//       )}
//       <div ref={loadMoreRef} style={{ height: '20px' }} />
//     </div>
//     );
//   };
  
//   export default RecommendedPostsList;

//   const Button = styled.button`
//     padding: 10px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 16px;
//     background: ${props => props.theme.colors.primary};
//     color: ${props => props.theme.colors.text};
//     border: none;
//     border-radius: 10px;
//     cursor: pointer;
//     width: 100%;
//     &:disabled {
//         background: #aaa;
//         cursor: not-allowed;
//     }
// `;

import React, { useEffect, useRef, useCallback } from 'react';
import PostCard from './PostCard';
import Spinner from './Spinner';
import styled from 'styled-components';
import { PostsContainer } from './PostCard.styles';
import useRecommendedPosts from './RecommendedPosts';

const RecommendedPostsList = ({ postIdprops }) => {
  const loadMoreRef = useRef(null);
  const { posts, fetchMorePosts, loading, currentPage, totalPages } = useRecommendedPosts(postIdprops);

  const fetchMorePostsCallback = useCallback(() => {
    if (!loading) {
      fetchMorePosts();
    }
  }, [loading, fetchMorePosts]);

  useEffect(() => {
    const loadMoreElement = loadMoreRef.current;
    if (!loadMoreElement) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchMorePostsCallback();
      }
    }, { threshold: 1.0 });

    observer.observe(loadMoreElement);

    return () => {
      observer.unobserve(loadMoreElement);
    };
  }, [fetchMorePostsCallback]);

  return (
    <div>
      <PostsContainer>
        {posts.map((post) => (
          <PostCard key={post.id} post={post} />
        ))}
      </PostsContainer>
      {loading && (
        <LoadingDiv>
        <p>Loading...</p>
        <Spinner />
        </LoadingDiv>
        )}
      {!loading && currentPage < totalPages && <Button onClick={fetchMorePostsCallback}>Load More</Button>}
      <div ref={loadMoreRef} style={{ height: '20px' }} />
    </div>
  );
};

export default RecommendedPostsList;

const Button = styled.button`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  &:disabled {
    background: #aaa;
    cursor: not-allowed;
  }
`;

const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.colors.text};
`;