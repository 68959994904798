import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {API_URL} from '../config';
import Header from './Header';
import axios from 'axios';
import incrementViewCount from '../utils/incrementView';
import { getValidToken } from '../utils/auth';
import PostCard from './PostCard';
import SkeletonLoader from './SkeletonLoader';
import { SkeletonWrapper } from './SkeletonWrapper';
import {PostsContainer} from './PostCard.styles';
import Menu from './Menu';
import { IoMdCloseCircle } from "react-icons/io";
import { BiSolidDashboard } from "react-icons/bi";
import CategoriesSlider from './CategoriesSlider';
import KasuwaSection from './KasuwaSection';
import { useTranslation } from 'react-i18next';

import { Container, Aside, Top, Logo, Close, Sidebar, Main, HeaderContainer,
  CategoriesContainer, InputContainer,
  SearchInput, SearchIcon,
  MenuIcon, PaginationWrapper,
  Pagination,
  PageNumber,
  PaginationButton } from './Home.styles';

import logo from '../assets/ArewaVip.png';


const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [shuffled, setShuffled] = useState(false);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activePrice, setActivePrice] = useState(null);
  //const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed
  const [isOpen, setIsOpen] = useState(true);
  const sidebarRef = useRef(null);
  const prevShuffled = useRef(shuffled);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [iconType, setIconType] = useState('search');
  const loadMoreRef = useRef(null);
  const postsListRef = useRef(null);
  const [shouldIncrement, setShouldIncrement] = useState(false);
  const effectRan = useRef(false);

  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState('main');


  const handlePagination = useCallback((direction) => {
    setCurrentPage((prevPage) => {
      if (totalPages > 1) {
        if (!shuffled && (direction === 'next' || direction === 'prev')) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        return direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1);
      }
      return prevPage;
    });
  }, [totalPages, shuffled]); 

  useEffect(() => {

    if (activeSection === 'main') return;

    const fetchCategories = async () => {
      const token = await getValidToken(navigate, false);
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/public/ads/categories`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            withCredentials: true
          });
          setCategories(response.data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        } finally {
          setCategoriesLoading(false);
        }
      } else {
        console.error('No valid token found');
      }
    };

    fetchCategories();
  }, [navigate, activeSection]);


  useEffect(() => {
    if (effectRan.current) return; // If the effect has already run or should not increment, do nothing

    const incrementView = async () => {
      try {
        await incrementViewCount('homepage', 'homepage', navigate);
        console.log('View incremented and session storage updated');
      } catch (error) {
        console.error('Error incrementing view count:', error);
      }
    };

    incrementView();
    effectRan.current = true; // Mark the effect as run

    // Clean-up function
    return () => {
      console.log('Cleaning up');
      // Any necessary cleanup logic can go here
    };
  }, [navigate]);

  // handle click outside to open or close menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768; 

      if (isMobile && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false); // Close sidebar if click is outside the sidebar and it's a mobile device
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Check screen size on mount and update isOpen accordingly
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsOpen(window.innerWidth > 768); // Show sidebar on desktop if screen size is greater 768
    };

    // Set isOpen to false if screen width is less than or equal to 768px (mobile)
    setIsOpen(window.innerWidth > 768);

    // Add event listener for screen resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);

  useEffect(() => {
    if (activeSection === 'kasuwa') return;
    const fetchCategories = async () => {
      const token = getValidToken(navigate, false);
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/public/categories`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            withCredentials: true
          });
          setCategories(response.data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        } finally {
          setCategoriesLoading(false);
        }
      } else {
        console.error('No valid token found');
      }
    };

    fetchCategories();
  }, [navigate, activeSection]);
  

  const fetchPosts = useCallback(async (category, price) => {
    setLoading(true);
    const queryParams = { searchQuery, currentPage, perPage };

    if (category) {
      queryParams.category = category;
    }
    if (price) {
      queryParams.price = price;
    }

    try {
        const response = await axios.get(`${API_URL}/public/posts`, {
          params: queryParams,
        });

        if (shuffled) {
          if (currentPage === 1) {
            setPosts(response.data.posts);
          } else {
            setPosts((prevPosts) => [...prevPosts, ...response.data.posts]);
          }
        } else {
          if (currentPage === 1) {
            setPosts(response.data.posts);
            setTotalPages(response.data.totalPages);
          } else {
            setPosts(response.data.posts);
          }
        }
    } catch (error) {
      console.error('Error fetching posts:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  }, [searchQuery, currentPage, perPage, shuffled]);

  useEffect(() => {
    
    fetchPosts(); 

  }, [fetchPosts, currentPage, searchQuery, perPage, shuffled]);

  useEffect(() => {
    if (prevShuffled.current !== shuffled) {
      fetchPosts(); // Fetch posts again if 'shuffled' changes
      prevShuffled.current = shuffled; // Update the previous value of 'shuffled'
    }
  }, [shuffled, fetchPosts]);

  // check if is shuffled or not in adminSettings.js route
  useEffect(() => {
    const fetchShuffledSetting = async () => {
      try {
        const response = await axios.get(`${API_URL}/public/check/shuffled/settings`, null);
        const { shuffled_posts: initialShuffled } = response.data;
        setShuffled(initialShuffled);
      } catch (error) {
        console.error('Error fetching shuffled setting:', error.response?.data || error.message);
      }
    };

    fetchShuffledSetting()
  }, []);


  useEffect(() => {
    const loadMoreElement = loadMoreRef.current;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading && shuffled && currentPage < totalPages) {
        handlePagination('next');
      }
    }, { threshold: 1.0 });

    if (loadMoreElement) {
      observer.observe(loadMoreElement);
    }

    return () => {
      if (loadMoreElement) {
        observer.unobserve(loadMoreElement);
      }
    };
  }, [loading, shuffled, currentPage, totalPages, handlePagination]);


  const toggleSidebar = () => {
    console.log('Before state update - isOpen:', isOpen);
  setIsOpen((prevOpen) => !prevOpen);
  console.log('After state update - isOpen:', !isOpen);
    //console.log('isOpen:', isOpen);
  };

  const handleToggleSearchInput = () => {
    setShowSearchInput(!showSearchInput);
    setIconType(iconType === 'search' ? 'close' : 'search');
    console.log('Toggled search input', !showSearchInput);

    if (showSearchInput) {
      setSearchQuery('');
      setCurrentPage(1);
      fetchPosts(); // Reset to show main posts when closing search input
    }
  }; 

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value); 
    console.log('Search query changed:', e.target.value);
  };
  
  const handleSearch = (e) => {
    if (e) e.preventDefault();
    console.log('Search submitted:', searchQuery);
    setCurrentPage(1);
  };


  

  const handlePageClick = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handleKeyDown = (event, submitFunction, textSetter) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      submitFunction();
      textSetter('');
    }
  };

  const scrollToTop = () => {
    postsListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSectionToggle = (section) => {
    setActiveSection(section);
    navigate('kasuwa/section');
  };


  return (
    <Container>
    <>
    
    <MenuIcon onClick={toggleSidebar}>
      <BiSolidDashboard style={{ fontSize: '24px', fontWeight: '500' }} />
    </MenuIcon>

    <Aside>
    <Sidebar style={{ display: isOpen ? 'flex' : 'none' }} ref={sidebarRef}>
    <Top>
          <Logo>
          <img src={logo} alt="Logo" />
          </Logo>
  
          <Close id="close-btn" onClick={() => setIsOpen(false)}>
          <IoMdCloseCircle style={{ fontSize: '34px', fontWeight: '500' }} />
          </Close>
          
        </Top>
    <Menu />
    </Sidebar>
    </Aside>

    <Main>      

    {activeSection === 'main' && (

    <HeaderContainer ref={postsListRef}>
    <Header style={styles.header} handleSearch={handleSearch} toggleSearchInput={handleToggleSearchInput} iconType={iconType} />
    </HeaderContainer> 

    )} 


{activeSection === 'main' && (
  <>
<div>
{showSearchInput ? (
      <InputContainer>
        <form onSubmit={handleSearch} style={{ display: 'flex', width: '100%' }}>
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder={t('search...')}
            onKeyDown={(e) => handleKeyDown(e, handleSearch, setSearchQuery)}
          />
        </form>
        <SearchIcon onClick={handleSearch} />
      </InputContainer>
    ) : (
      categoriesLoading ? (
        <SkeletonWrapper height="30px" width="80%" />
      ) : (
        <CategoriesContainer>
          <CategoriesSlider
            categories={categories}
            setActiveCategory={setActiveCategory}
            setActivePrice={setActivePrice}
            handleFetchPosts={fetchPosts}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handleSectionToggle={handleSectionToggle}
            activeSection={activeSection}
            isFromHome={true}
          />
        </CategoriesContainer>
      )
    )}

  </div>

      
    <PostsContainer style={{ position: 'relative' }}>
    {loading && currentPage === 1 ? ( <SkeletonLoader count={8} /> ) : ( posts.map((post, index) => (
          <PostCard key={`${post.id}-${index}`} post={post} />
        ))
      )}
      
      {loading && currentPage > 1 && <p>Loading...</p>}

    </PostsContainer>

    {!shuffled && (
        
        <PaginationWrapper>
        <Pagination>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <PageNumber 
            key={index + 1} 
            onClick={() => handlePageClick(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}>
              {index + 1}
            </PageNumber>
          ))}
          <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </PaginationButton>
        </Pagination>
        </PaginationWrapper>

      )}
    <div ref={loadMoreRef} style={{ height: '20px' }} />
    </>
  )}

    {/* {activeSection === 'kasuwa' && (
          <KasuwaSection
            categories={categories}
            categoriesLoading={categoriesLoading}
            handleSectionToggle={handleSectionToggle}
            activeSection={activeSection}
            isFromHome={true}
          />
        )}  */}
    </Main>
    </>
    </Container>
    );
};

const styles = {
  header: {
    marginBottom: '20px',
    width: '100%',
    overflow: 'hidden',
  }
};

export default Home;
