import React, { useState, useEffect, useCallback,useRef } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { API_URL, baseURL } from '../config';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Avatar from './Avatar';
import { SkeletonWrapper } from './SkeletonWrapper';
import styled from 'styled-components';


import { 
  CardContainer, 
  StyledLink, 
  ImageContainer, 
  Thumbnail, 
  TextContainer, 
  Title, 
  AuthorLink, 
  PriceAndBuy, 
  StyledPrice,
  StyledBuy,
  StyledPlay,
  AuthorDetailsDiv,
  AuthorInfo 
} from './PostCard.styles';

const StyledPlayIsExpired = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
  cursor: pointer;
  color: black;
  background-color: ${(props) => {
    if (props.isExpired === true) return 'yellow';
    return '#f0f0f0';
  }};
  
  h2 {
    color: black;
  }
  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 0.8rem;
  }

  @media (max-width: 290px) {
    padding: 4px;
    font-size: 0.7rem;
  }
`; 

const ViewsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: right;
  font-size: 17px;
  margin-right: 10px;

  div {
    margin-right: 10px;
    color: ${props => props.theme.colors.text};
  }
`;


function PostCard({ post }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [authorDetails, setAuthorDetails] = useState('');
  const [trailerViews, setTrailerViews] = useState(null);
  const [mainViews, setMainViews] = useState(null);
  const [isPurchased, setIsPurchased] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const src = `${baseURL}${post.thumbnail}`;

  useEffect(() => {
    if (!isLoading) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        if (containerRef.current) { // Add null check here
          if (aspectRatio > 1.5) {
            containerRef.current.classList.add('landscape');
          } else if (aspectRatio < 0.67) {
            containerRef.current.classList.add('portrait');
          } else {
            containerRef.current.classList.add('square');
          }
        }
      };
    }
  }, [isLoading, src, isContentLoaded]);

  useEffect(() => {
    if (post.thumbnail) {
      setIsLoading(false);
    }
  }, [post.thumbnail]);

  

  useEffect(() => {
    const token = getValidToken(navigate, false);
    if (token) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false);
    }
  }, [navigate])

  
    const fetchAuthorDetails = useCallback(async () => {
      
      try {
        const response = await axios.get(`${API_URL}/public/author/${post.author_id}/details`);
        setAuthorDetails(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching author details:', error.response?.data || error.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false); 
      }

    }, [post.author_id]);

  
    const fetchTrailerViews = useCallback(async () => {
        try {
          const response = await axios.get(`${API_URL}/public/post/${post.id}/trailer`);
          setTrailerViews(response.data.totalViews);
        } catch (error) {
          console.error('Error fetching trailer views:', error);
        } finally {
          setIsLoading(false);
        }
    }, [post.id]);

    const fetchMainViews = useCallback(async () => {
      try {
        const response = await axios.get(`${API_URL}/public/post/${post.id}/main`);
        setMainViews(response.data.totalViews);
      } catch (error) {
        console.error('Error fetching trailer views:', error);
      } finally {
        setIsLoading(false);
      }
  }, [post.id]);


    const checkPostPurchase = useCallback(async () => {
      const token = await getValidToken(navigate, false); 
      if (token) {
        setIsLoggedIn(true);
      try {
        const response = await axios.get(`${API_URL}/purchase/check/${post.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        setIsPurchased(response.data.purchased);
        setIsLoading(false); 
      } catch (error) {
        console.error('Error checking post purchase:', error.response?.data || error.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false); 
      }
    } else {
      setIsLoading(false); 
    } 
    }, [navigate, post.id]);

  

  const checkPostPurchaseExpire = useCallback(async () => {
    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/purchase/check/expire/${post.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });

        console.log('Response data:', response.data);

        const { purchasedResult, resetDateValid } = response.data;
        const isPostExpired = purchasedResult && !resetDateValid;
        console.log('Is post expired?', isPostExpired);

        setIsExpired(isPostExpired);
        setIsLoading(false);
      } catch (error) {
        console.error('Error checking post purchase:', error.response?.data || error.message);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [navigate, post.id]);




  // Check if the user is subscribed

  const checkSubscription = useCallback(async () => {
    if (isLoading || !post.author_id) return;

    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const subscriptionCheckResponse = await axios.get(`${API_URL}/subscription/check/${post.author_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });

        const { subscribed } = subscriptionCheckResponse.data;
        setIsSubscribed(subscribed);
        console.log('Subscription check successful:', subscriptionCheckResponse.data);
      } catch (error) {
        console.error('Error checking subscription:', error.response?.data || error.message);
        setErrorMessage(error.response?.data || error.message);
      }
    }
  }, [isLoading, navigate, post.author_id]);

  useEffect(() => {
    if (!post) {
      return;
    }

    const fetchData = async () => {
      await Promise.all([
        fetchAuthorDetails(),
        fetchTrailerViews(),
        fetchMainViews(),
        checkPostPurchase(),
        checkPostPurchaseExpire(),
        checkSubscription(),
      ]);

      setIsContentLoaded(true);
    };

    fetchData();
  }, [post, isLoggedIn, navigate, fetchAuthorDetails, fetchTrailerViews, fetchMainViews, checkPostPurchase, checkPostPurchaseExpire, checkSubscription]);


  // Time format 

  const createdDate = new Date(post.created_at);
  const currentDate = new Date();

  const timeDifference = currentDate - createdDate;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const monthsDifference = Math.floor(daysDifference / 30);
  const yearsDifference = Math.floor(monthsDifference / 12);

  let formattedTimeAgo = '';

  if (yearsDifference > 0) {
    formattedTimeAgo = `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
  } else if (monthsDifference > 0) {
    formattedTimeAgo = `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
  } else if (daysDifference > 0) {
    formattedTimeAgo = `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
  } else if (hoursDifference > 0) {
    formattedTimeAgo = `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
  } else if (minutesDifference > 0) {
    formattedTimeAgo = `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
  } else {
    formattedTimeAgo = `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
  }
  

  return (
    <CardContainer>
      <Link to={`/post/${post.id}`}>
        <ImageContainer ref={containerRef}>
          {isLoading || !isContentLoaded ? (
            <SkeletonWrapper style={{ '--width': '100%', '--height': '100%' }} />
          ) : (
            <Thumbnail src={src} alt="Photo" />
          )}
        </ImageContainer>
      </Link>
      {/* {isLoading || !isContentLoaded ? (
        <ViewsDiv style={{ width: '100%', marginTop: '5px' }}>
        <SkeletonWrapper style={{ width: '100%', height:'1rem' }} />
        </ViewsDiv>
        ) : (
        <ViewsDiv> 
          <div>{mainViews} Main Views</div>
          <div>{trailerViews} Trailer Views</div>
        </ViewsDiv>
        )} */}

        <ViewsDiv> 
          <div>{mainViews} Main Views</div>
          <div>{trailerViews} Trailer Views</div>
        </ViewsDiv>
      
      <TextContainer>
        {errorMessage && (
          <p>{typeof errorMessage === 'string' ? errorMessage : errorMessage.msg}</p>
        )}
        <StyledLink to={`/post/${post.id}`}>
          {isLoading || !isContentLoaded ? (
            <Title>
            <SkeletonWrapper style={{ '--width': '60%', '--height': '1.6rem' }} />
            </Title>
          ) : (
            <Title>{post.title}</Title>
          )}
        </StyledLink>

        <AuthorDetailsDiv> 
          <div>
            {isLoading || !isContentLoaded ? (
              <SkeletonWrapper style={{ '--border-radius': '50%', '--width': '40px', '--height':'40px' }} />
            ) : (
              <Link to={`/author/${post.author_id}`}>
                <Avatar 
                username={authorDetails?.username}
                profilePicture={authorDetails.profile_picture ? `${baseURL}${authorDetails.profile_picture}` : null} 
                avatarColor={authorDetails.avatar_color}
                />
              </Link>
            )}
            <AuthorInfo>
              {isLoading || !isContentLoaded ? (
                <SkeletonWrapper style={{ width: '100px', height:'0.7rem' }} />
              ) : (
                <AuthorLink to={`/author/${post.author_id}`}><h4>by {authorDetails?.username}</h4></AuthorLink>
              )}
            </AuthorInfo>
          </div>
          
          <div>
            {isLoading || !isContentLoaded ? (
              <SkeletonWrapper style={{ '--width': '80px', '--height':'20px' }} />
            ) : (
              <time>{formattedTimeAgo}</time>
            )}
          </div>
        </AuthorDetailsDiv>

        {isLoading || !isContentLoaded ? (
            <PriceAndBuy>
              <SkeletonWrapper style={{ width: '150px', height: '1.6rem' }} />
              <SkeletonWrapper style={{ width: '50px', height: '1.6rem' }} />
            </PriceAndBuy>
          ) : post.price === '0.00' ? (
            <PriceAndBuy>
              <StyledPrice><h2>{t('free')}</h2></StyledPrice>
              <StyledPlay><StyledLink to={`/post/${post.id}`}><h2>{t('play')}</h2></StyledLink></StyledPlay>
            </PriceAndBuy>
          ) : isExpired && !isSubscribed ? (
            <PriceAndBuy>
              <StyledPrice><h2>{t('price')}: ₦{post.price}</h2></StyledPrice>
              <StyledPlayIsExpired isExpired={isExpired}><StyledLink to={`/post/${post.id}`}><h2>{t('expired, renew')}</h2></StyledLink></StyledPlayIsExpired>
            </PriceAndBuy>
          ) : isPurchased || isSubscribed ? (
            <Link to={`/post/${post.id}`}>
              <PriceAndBuy>
                <StyledPlay><StyledLink to={`/post/${post.id}`}><h2>{t('play')}</h2></StyledLink></StyledPlay>
              </PriceAndBuy>
            </Link>
          ) : (  
            <PriceAndBuy>
              <StyledPrice><h2>{t('price')}: ₦{post.price}</h2></StyledPrice>
              <StyledBuy><StyledLink to={`/post/${post.id}`}><h2>{t('buy')}</h2></StyledLink></StyledBuy>
            </PriceAndBuy>
          )}

      </TextContainer>
    </CardContainer>
  );
};

export default PostCard;
