import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import html2canvas from 'html2canvas';
import logo from '../assets/ArewaVip.png';
import { FaEye, FaEyeSlash, FaTimes, FaSave, FaSearch } from 'react-icons/fa';
import { API_URL, baseURL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { LoadingIcon, SuccessIcon, ErrorIcon } from '../components/Admin/styles';
import { FormField, Label, Input, ButtonsDiv, Button } from '../components/Admin/RequestForm';
import { FormModelDiv, ModalBackdrop } from '../components/Admin/BecomeCreatorFormRequests';

const CartContainer = styled.div`
  padding: 50px 0;
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 5px;
`;

const TotalPrice = styled.h3`
  text-align: right;
  margin-top: 20px;
`;

const ReceiptContainer = styled.div`
  padding: 20px;
`;

const Receipt = ({ receipt, onClose }) => {
  // Ensure receipt and receipt.items are defined
  if (!receipt || !receipt.length) return <div>No receipt available</div>;

  const handleSaveAsImage = () => {
    const transactionDetails = document.getElementById('transaction-details');

    // Hide the Save Button and Close Icon
    transactionDetails.classList.add('hide-save-and-close');

    html2canvas(transactionDetails).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'transaction-details.png';
      link.click();

      // Show the Save Button and Close Icon
      transactionDetails.classList.remove('hide-save-and-close');
    });
  };

  return (
    <>
    <ModalBackdrop onClick={onClose} />
    <FormModelDiv>
      <ReceiptContainer id="transaction-details">
        <h2>Purchase Receipt</h2>
        <h3>Items Purchased:</h3>
        {receipt.map((item) => (
          <CartItem key={item.id}>
            <span>{item.post_title}</span>
            <span>{item.reference_code}</span>
            <span>{item.type_of_use}</span>
          </CartItem>
        ))}
        <Button className="save-button" onClick={handleSaveAsImage}>
              <FaSave /> Save as Image
        </Button>
        <Button onClick={onClose} className="close-modal-button">Close</Button>
      </ReceiptContainer>
    </FormModelDiv>
    </>
  );
};

const Cart = ({ cart, onClose, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [shopNumber, setShopNumber] = useState('');
  const [state, setState] = useState('');
  const [error, setError] = useState('');
  const [receipt, setReceipt] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getValidToken(navigate, true);
  
    if (token) {
      setIsLoading(true);
      try {
        const response = await axios.post(`${API_URL}/offline/purchase/cart`, {
          items: cart.map(item => ({
            postId: item.id,
            postTitle: item.title,
            price: item.price
          })),
          username,
          address,
          phone,
          shopNumber,
          state
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
  
        // Assuming backend returns purchasedPosts data
        const receiptData = response.data.purchasedPosts;
        setReceipt(receiptData);
        setError('');
        setLoginSuccess(true);
        setTimeout(() => {
          setLoginSuccess(false);
          //onClose();
          //refresh();
        }, 2000);
      } catch (error) {
        console.error('Error purchasing items:', error);
        setError(error.response?.data?.error || error.message);
        setIsLoading(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  return (
    <>
      {receipt ? (
        <Receipt receipt={receipt} onClose={onClose} />
      ) : (
        <>
          <ModalBackdrop onClick={onClose} />
          <FormModelDiv>
            <CartContainer>
              <h2>Your Cart</h2>
              <h3 style={{ marginBottom: '10px' }}>Purchase Items</h3>
              {cart.length > 0 ? (
                <>
                  {cart.map((item) => (
                    <CartItem key={item.id}>
                      <img src={`${baseURL}${item.thumbnail}`} alt={item.title} width={'50px'} height={'50px'}/>
                      <span>{item.title}</span>
                      <span>N{(item.price * 0.5).toFixed(2)}</span>
                    </CartItem>
                  ))}
                  <TotalPrice>Total: N{cart.reduce((acc, item) => acc + (item.price * 0.5), 0).toFixed(2)}</TotalPrice>
                </>
              ) : (
                <p>Your cart is empty</p>
              )}

              <div>
                <FormField>
                  <Label>Username</Label>
                  <Input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Phone</Label>
                  <Input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Address</Label>
                  <Input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>State</Label>
                  <Input
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Shop Number</Label>
                  <Input
                    type="text"
                    value={shopNumber}
                    onChange={(e) => setShopNumber(e.target.value)}
                    required
                  />
                </FormField>

                {error && <FormField><p style={{ color: 'red' }}><b>{error}</b></p></FormField>}

                <ButtonsDiv>
                  <Button onClick={handleSubmit} disabled={isLoading || loginSuccess}>
                    {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Proceed to Checkout'}
                    {isLoading && <LoadingIcon />}
                  </Button>
                  <Button onClick={onClose} style={{ marginTop: '10px', background: 'red' }}>Close</Button>
                </ButtonsDiv>
              </div>
            </CartContainer>
          </FormModelDiv>
        </>
      )}
    </>
  );
};

export default Cart;

