import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Spinner from './Spinner';
import PostTicketPurchaseForm from './PostTicketPurchaseForm';  
import { API_URL, baseURL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../utils/useDebounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import html2canvas from 'html2canvas';
import { MdDateRange, MdClose, MdFilterList } from "react-icons/md";
import { FaEye, FaEyeSlash, FaTimes, FaDownload, FaSearch } from 'react-icons/fa';
import Cart from './Cart';
import logo from '../assets/ArewaVip.png';
import {
  PaginationWrapper,
  Pagination,
  PageNumber,
  PaginationButton,
  FormContainer,
  FormTitle,
  Tabs,
  Tab,
  SearchInput,
  RequestList,
  //RequestItem,
  FormModelDiv,
  ModalBackdrop,
} from '../components/Admin/BecomeCreatorFormRequests';
//import { ButtonsDiv, Styledhr } from './Admin/RequestForm';
import { SpinnerContainer } from './PostDetails.Styles';
import { FormField, Label, Input, ButtonsDiv } from '../components/Admin/RequestForm';

const SearchAndFilter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }
  button {
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.text};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const SearchAndFilterIconDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text};

  input {
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }

`;

const Icon = styled.div`

`;

// Container for the date picker and quick select options
const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%; // Ensure container takes full width
`;

// Wrapper for the date picker inputs
const DatePickerWrapper = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  margin-bottom: 16px;
  width: 100%; // Ensure wrapper takes full width
`;

// Label for the date inputs
const DateLabel = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
`;

// Custom styled DatePicker component
const StyledDatePicker = styled(DatePicker)`
  width: 100%; // Take full width of the container
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-bottom: 16px;
  box-sizing: border-box; // Ensure padding is included in width

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  input {
    width: 100%; // Ensure input inside DatePicker takes full width
    border: none; // Remove border from input
    padding: 0; // Reset padding
    &:focus {
      outline: none; // Remove default input focus outline
    }
  }
`;

// Container for quick select options (Today, Yesterday, Last Month)
const QuickSelectOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%; // Ensure quick select options take full width
`;

// Individual quick select option button
const QuickSelect = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const CartContainer = styled.div`
  padding: 20px;
`;

const CartItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: ${(props) => (props.hideScroll ? 'hidden' : 'auto')};
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  img {
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 15px;
  }

  span {
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    margin: 5px 0;
  }

  hr {
    width: 100%;
    color: ${(props) => props.theme.colors.text};
    margin: 10px 0;
  }  

  @media (min-width: 768px) {
    img {
      margin-right: 20px;
    }

    span {
      font-size: 16px;
    }
  }
`;

const RequestItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: ${(props) => (props.hideScroll ? 'hidden' : 'auto')};
  margin-bottom: 20px;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  img {
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.text};
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    margin: 5px 0;
  }

  button {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.text};
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;

    &:hover {
      background-color: #0056b3;
    }
  }

  @media (min-width: 768px) {
    

    img {
      margin-right: 20px;
    }

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }

`;

const ContentDiv = styled.div`
  width: 100%;
`;


const TotalPrice = styled.h3`
  text-align: right;
  margin-top: 20px;
`;

const ReceiptContainer = styled.div`
  //padding: 10px;
`;

const Button = styled.button`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  max-width: 200px; /* Set a max-width for better responsiveness */
  margin: 10px 0;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: ${props => props.theme.colors.primaryHover};
    transform: translateY(-2px);
  }

  &:active {
    background: ${props => props.theme.colors.primaryActive};
    transform: translateY(0);
  }

  &:disabled {
    background: #aaa;
    cursor: not-allowed;
  }

  svg {
    margin-right: 8px;
  }

  .hide-save-and-close .save-button, 
.hide-save-and-close .close-button {
  display: none;
}
`;

const CartButtonDiv = styled.div`
  position: fixed;
  right: 0;
  margin-right: 10px;
  top: 10px;
  display: flex;
  gap: 10px;
  z-index: 1100;

  button {
    padding: 10px;
    font-size: 15px;
    color: ${props => props.theme.colors.white};
    background-color: green;
  }

  span {
    padding: 5px;
    background-color: ${props => props.theme.colors.danger};
    color: ${props => props.theme.colors.white};
    border-radius: 10px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

`


const Receipt = ({ receipt, onClose }) => {
  if (!receipt || (Array.isArray(receipt) && receipt.length === 0)) {
    return <div>No receipt available</div>;
  }

  const handleSaveAsImage = () => {
    const transactionDetails = document.getElementById('transaction-details');
    transactionDetails.classList.add('hide-save-and-close');
  
    // Ensure the DOM is updated before capturing the screenshot
    setTimeout(() => {
      html2canvas(transactionDetails).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'Arewa_Gida_transaction-details.png';
        link.click();
        
        // Remove the class after capturing
        transactionDetails.classList.remove('hide-save-and-close');
      });
    }, 100);  // Adjust delay as necessary
  };
  

  const renderReceiptItems = (items) => {
    if (Array.isArray(items)) {
      return items.map((item) => (
        <CartItem key={item.id} id="transaction-details">
          <img src={logo} alt="Company Logo" width={'100px'} height={'100px'}/>
          <h3>Arewa Gida Transaction Details</h3>
          <hr />
          {/* <img src={`${baseURL}${item.thumbnail}`} alt={item.title} width={'100px'} height={'100px'}/> */}
          <span>Title: {item.title}</span>
          <span>Reference: {item.reference_code}</span>
          <span>Type Of Use: {item.type_of_use}</span>
          <span>Purchased On: {item.purchase_date}</span>
          <span>Validity: {item.access_type}</span>
          <span>Channel: {item.author_username}</span>
          <span>Buyer: {item.username}</span>
        </CartItem>
      ));
    } else {
      return (
        <CartItem key={items.id} id="transaction-details">
          <img src={logo} alt="Company Logo" width={'100px'} height={'100px'} />
          <h3>Arewa Gida Transaction Details</h3>
          <hr />
          {/* <img src={`${baseURL}${items.thumbnail}`} alt={items.title} width={'100px'} height={'100px'}/> */}
          <span>Title: {items.title}</span>
          <span>Reference: <b>{items.reference_code}</b></span>
          <span>Type Of Use: {items.type_of_use}</span>
          <span>Purchased On: {items.purchase_date}</span>
          <span>Validity: {items.access_type}</span>
          <span>Channel: {items.author_username}</span>
          <span>Buyer: {items.username}</span>
        </CartItem>
      );
    }
  };

  return (
    <>
      <ModalBackdrop onClick={onClose} />
      <FormModelDiv>
        <ReceiptContainer >
          {renderReceiptItems(receipt)}
          <ButtonsDiv>
          <Button className="save-button" onClick={handleSaveAsImage}>
            <FaDownload color='#fff'/>
          </Button>
          <Button className="close-button" onClick={onClose} style={{backgroundColor: 'red', color: '#fff'}}>Close</Button>
          </ButtonsDiv>
        </ReceiptContainer>
      </FormModelDiv>
    </>
  );
};

const PostTicketPurchase = () => {
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [purchasedPosts, setPurchasedPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const debouncedSearch = useDebounce(search, 800);
  const ListRef = useRef(null);
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [quickSelect, setQuickSelect] = useState('');

  useEffect(() => {
    if (showCart) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showCart]);


  const scrollToTop = () => {
    ListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (newTab) => {
    setTab(newTab);
    setFilterVisible(false);
    setCurrentPage(1);
    setStartDate('');
    setEndDate('');
  };

  const handleNextPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(totalPages / perPage)));
  };

  const handlePreviousPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    scrollToTop();
    setCurrentPage(pageNumber);
  };


  const handleSelectPost = (post) => {
    setSelectedPost(post);
  };

  const handleSelectPurchasedPost = (post) => {
    setReceipt(post);
  };

  const handleSelectPurchasedPostClose = (post) => {
    setReceipt(null);
  };


  const handleAddToCart = (post) => {
    setCart((prevCart) => {
      const alreadyInCart = prevCart.find((item) => item.id === post.id);
      if (alreadyInCart) {
        return prevCart.filter((item) => item.id !== post.id); // Remove from cart if already added
      } else {
        return [...prevCart, post]; // Add to cart
      }
    });
  };

  const handleCartToggleOpen = () => {
    setShowCart(true);
  };

  const handleCartToggleClose = () => {
    setShowCart(false);
  };

  const handleCartReset = () => {
    setCart([]);
  }

  const handleCheckout = () => {
    setSelectedPost(cart); // Pass cart items to the purchase form
    setShowCart(false);
  };

  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  const handleQuickSelect = (option) => {
    setQuickSelect(option);
    setStartDate(null);
    setEndDate(null);
    if (option === 'today') {
      const today = new Date();
      setStartDate(today);
      setEndDate(today);
    } else if (option === 'yesterday') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setStartDate(yesterday);
      setEndDate(yesterday);
    } else if (option === 'lastmonth') {
      const lastMonthStart = new Date(new Date().setMonth(new Date().getMonth() - 1));
      const lastMonthEnd = new Date(new Date().setDate(0));
      setStartDate(lastMonthStart);
      setEndDate(lastMonthEnd);
    }
  };

  const fetchPosts = useCallback(async () => {
    const token = await getValidToken(navigate, true);
    if (token) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/public/posts/tickets`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { 
            searchQuery: debouncedSearch, 
            currentPage, 
            perPage: 20 
          }
        });
        const fetchedPosts = response.data.posts;
        if (fetchedPosts.length === 0) {
          setPosts([]);
          console.log("No posts found for the search criteria.");
        } else {
          setPosts(fetchedPosts);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [debouncedSearch, currentPage, navigate]);
  

  const fetchPurchasedPosts = useCallback(async () => {
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/public/purchased-posts`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { 
            searchQuery: debouncedSearch,
            currentPage, 
            perPage: perPage,
            startDate: startDate ? startDate.toISOString().split('T')[0] : null,
            endDate: endDate ? endDate.toISOString().split('T')[0] : null
          }
        });
        const fetchedPosts = response.data.purchasedPosts;
        if (fetchedPosts.length === 0) {
          // Handle no purchased posts found
          setPurchasedPosts([]);
          console.log("No purchased posts found.");
        } else {
          setPurchasedPosts(fetchedPosts);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        console.error('Error fetching purchased posts:', error);
      }
    }
  }, [debouncedSearch, currentPage, perPage, navigate, startDate, endDate]);
  

  useEffect(() => {
    if (tab === 'all') {
      fetchPosts();
    } else if (tab === 'purchased') {
      fetchPurchasedPosts();
    }
  }, [fetchPosts, fetchPurchasedPosts, tab, debouncedSearch]); 

  const calculateDiscountedPrice = (price) => {
    return price * 0.5;
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
      <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <FormContainer hideScroll={true}>
      {cart.length > 0 && (
        <CartButtonDiv>
        <button onClick={handleCartToggleOpen}>
          View Cart ({cart.length})
        </button>
        <span onClick={handleCartReset}>Reset</span>
        </CartButtonDiv>
      )}
      <FormTitle>Purchase Tickets</FormTitle>
      <Tabs>
        <Tab active={tab === 'all'} onClick={() => handleTabChange('all')}>
          All Posts
        </Tab>
        <Tab active={tab === 'purchased'} onClick={() => handleTabChange('purchased')}>
          Purchased Posts
        </Tab>
      </Tabs>

      {posts.length === 0 && !isLoading && (
      <div>No posts found for your search.</div>
    )}


      <SearchAndFilter>
      <SearchAndFilterIconDiv>
        <input
          type="text"
          placeholder="Search by title or channel"
          value={search}
          onChange={handleSearchChange}
        />  
        {tab === 'purchased' && (
          <div onClick={toggleFilterVisibility}>{filterVisible ? <MdClose size={30} width={100} height={100} color='red'/> : <MdFilterList size={30} width={100} height={100}/>}</div>
        )}
        </SearchAndFilterIconDiv>
        {
        filterVisible && (
          <DatePickerContainer>
            <DatePickerWrapper visible={filterVisible}>
              <FormField>
                <DateLabel>Start Date:</DateLabel>
                <StyledDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  isClearable
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select start date"
                />
              </FormField>

              <FormField>
                <DateLabel>End Date:</DateLabel>
                <StyledDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  isClearable
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select end date"
                />
              </FormField>
            </DatePickerWrapper>
            <QuickSelectOptions>
              <QuickSelect onClick={() => handleQuickSelect('today')}>Today</QuickSelect>
              <QuickSelect onClick={() => handleQuickSelect('yesterday')}>Yesterday</QuickSelect>
              <QuickSelect onClick={() => handleQuickSelect('lastmonth')}>Last Month</QuickSelect>
            </QuickSelectOptions>
          </DatePickerContainer>
        )
      }
          </SearchAndFilter>
       
      {tab === 'purchased' && (
        <>
        {receipt && (
          <Receipt receipt={receipt} onClose={handleSelectPurchasedPostClose}/>
        )}
         </>
      )}
  
      <RequestList ref={ListRef}>
        {tab === 'all' &&
          posts.map((post) => (
            <RequestItem key={`post-${post.id}`}>
              <ContentDiv onClick={() => handleSelectPost(post)}>
              <img src={`${baseURL}${post.thumbnail}`} alt={post.title} width={'100px'} height={'100px'}/>
              <h3>{post.title}</h3>
              <p>Price: N{calculateDiscountedPrice(post.price)}</p>
              <p>Validity: {post.access_type}</p>
              <p>Type Of Use: Offline</p>
              <p>Channel: {post.author_username}</p>
              </ContentDiv>
              <button onClick={() => handleAddToCart(post)}>
                {cart.find((item) => item.id === post.id) ? 'Remove from Cart' : 'Add to Cart'}
              </button>
            </RequestItem>
          ))
        }

        {tab === 'purchased' &&
          purchasedPosts.map((post) => (
            <RequestItem key={`purchased-${post.id}`} onClick={() => handleSelectPurchasedPost(post)}>
              <img src={`${baseURL}${post.thumbnail}`} alt={post.title} width={'100px'} height={'100px'}/>
              <h3>{post.title}</h3>
              <p>Price: N{calculateDiscountedPrice(post.price)}</p>
              <p>Purchased On: {post.purchase_date}</p>
              <p>Validity: {post.access_type}</p>
              <p>Channel: {post.author_username}</p>
              <p>Buyer: {post.username}</p>
            </RequestItem>
          ))
        }
      </RequestList>

      {(tab === 'all' || tab === 'purchased') && (
        <PaginationWrapper>
        <Pagination>
          <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </PaginationButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <PageNumber
              key={index + 1}
              onClick={() => handlePageClick(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </PageNumber>
          ))}
          <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </PaginationButton>
        </Pagination>
      </PaginationWrapper>
      )}

      {selectedPost && (
        <ModalBackdrop>
          <FormModelDiv>
            <PostTicketPurchaseForm 
              //selectedPost={selectedPost}
              post={selectedPost}
              onClose={() => setSelectedPost(null)}
            />
          </FormModelDiv>
        </ModalBackdrop>
      )}

      {showCart && (
        <Cart 
          cart={cart} 
          onClose={handleCartToggleClose} 
          onCheckout={handleCheckout} 
        />
      )}
    </FormContainer>
  );
};

export default PostTicketPurchase;
