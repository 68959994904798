import React from 'react';

function Transactions(props) {
    return (
        <div>
            <h1> All Transactions</h1>
        </div>
    );
}

export default Transactions;