import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import styled from "styled-components";
import PostCard from "./PostCard";
import { SkeletonWrapper } from './SkeletonWrapper';
import SubscriptionModal from './SubscriptionModal';
import { PostsContainer } from "./PostCard.styles";
import { AuthorDetailsDiv, ProfileImage, AuthorInfo, StyledButton, PriceAndPurchaseDiv} from './PurchaseModel'; 
import { Header,  ImageContainer, BrandLogo, Span, StyledHandshakeIcon, StyledFaRegThumbsUp } from './PostDetails.Styles';
import { PaginationWrapper, Pagination, PaginationButton, PageNumber } from './ProducerAllPosts';
import { API_URL, baseURL } from "../config";
import { Title } from "./PublicStyles";
import { getValidToken } from "../utils/auth";
import Spinner from "./Spinner";
import { FaSearch, FaTimes } from 'react-icons/fa'; 
import { FaUserCheck } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
import { getUserIdFromToken } from '../utils/auth';
import useDebounce from '../utils/useDebounce';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  padding: 20px;
`;

const SpinnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  min-height: 100vh;
`;

const AuthorDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #add;
  border-radius: 15px;
  margin-bottom: 20px;
`;

const StyledPdiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.danger};
  padding: 5px;

  p {
    color: #fff;
    text-align: center;
  }
`;

const SearchAndTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const SearchContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  margin-top: 10px;
  position: relative;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchIcon = styled(FaSearch)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.text};
  font-size: 20px;
  margin-right: 10px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const CloseIcon = styled(FaTimes)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.danger};
  font-size: 20px;
  margin-right: 10px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const SearchInput = styled.input`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 16px;
  transition: width 0.3s ease;

  &:focus {
    border-color: #ccc;
    outline: none;
  }
`;

const SearchButton = styled.button`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const AuthorDetails = () => {
  const { authorId } = useParams();
  const [author, setAuthor] = useState(null);
  const [userId, setUserId] = useState(null);
  const [authorPosts, setAuthorPosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [followersCount, setFollowersCount] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [expiredOn, setExpiredOn] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showHandshake, setShowHandshake] = useState(false);
  const [showThumbsUp, setShowThumbsUp] = useState(false);
  const [showSubscriptionsModel, setShowSubscriptionsModel] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const isOwner = Number(authorId) === Number(userId);
  const debouncedSearch = useDebounce(searchTerm, 800);

  console.log('totalPages', totalPages);

  useEffect(() => {
    const fetchUserId = async () => {
      const id = await getUserIdFromToken(navigate, false);
      setUserId(id);
    };
    fetchUserId();
  }, [navigate]);

const fetchAuthorData = useCallback(async () => {
  try {
    const authorResponse = await axios.get(`${API_URL}/public/author/${authorId}/details`);
    setAuthor(authorResponse.data);
    setFollowersCount(authorResponse.data.followersCount || 0);
  } catch (error) {
    console.error('Error fetching author data:', error.response?.data || error.message);
  }
}, []);

const fetchAuthorPosts = useCallback(async (page = 1, searchQuery = '') => {
  try {
    setIsFetchingMore(true);
    const response = await axios.get(`${API_URL}/public/author/posts/${authorId}/details`, {
      params: { currentPage: page, perPage, searchQuery },
    });

    // Directly set the posts without appending
    setAuthorPosts(response.data.posts);
    setTotalPages(response.data.totalPages);
    setCurrentPage(page);
  } catch (error) {
    console.error('Error fetching author posts:', error.response?.data || error.message);
  } finally {
    setIsFetchingMore(false);
  }
}, []);

const fetchFollowerCount = useCallback(async () => {
  try {
    const response = await axios.get(`${API_URL}/public/users/${authorId}/followers/count`);
    setFollowersCount(response.data.followerCount);
  } catch (error) {
    console.error('Error fetching follower count:', error);
  }
}, []);

const checkSubscription = useCallback(async () => {
  if (!authorId) return;

  const token = await getValidToken(navigate, false);
  if (token) {
    try {
      const subscriptionCheckResponse = await axios.get(`${API_URL}/subscription/check/${authorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      const { subscribed, endDate } = subscriptionCheckResponse.data;
      setIsSubscribed(subscribed);
      setExpiredOn(endDate);
    } catch (error) {
      console.error('Error checking subscription:', error.response?.data || error.message);
      setErrorMessage(error.response?.data || error.message);
    }
  }
}, [navigate]);

const fetchFollowStatus = useCallback(async () => {
  const token = await getValidToken(navigate, false);
  if (token) {
    try {
      const response = await axios.get(`${API_URL}/users/${authorId}/follow_status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      setIsFollowing(response.data.isFollowing);
    } catch (error) {
      console.error('Error fetching follow status:', error);
    }
  }
}, [navigate]);

useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    await fetchAuthorData();
    await fetchFollowerCount();
    await checkSubscription();
    await fetchFollowStatus();
    await fetchAuthorPosts(currentPage, debouncedSearch);
    setIsLoading(false);
  };

  if (authorId) {
    fetchData();
  }
}, [authorId, debouncedSearch, currentPage, perPage, fetchAuthorData, fetchAuthorPosts, fetchFollowerCount, checkSubscription, fetchFollowStatus]);

  const handleSearch = () => {
    setAuthorPosts([]); 
    //fetchAuthorPosts(1, debouncedSearch);
  };

  const loadMorePosts = () => {
    if (currentPage < totalPages) {
      fetchAuthorPosts(currentPage + 1, debouncedSearch);
    }
  };

  const handleToggleSearch = () => {
    setShowSearchInput(prevState => !prevState);
    setSearchTerm('');
  };

  const formatTime = (date) => {
    const now = new Date();
    const expiryDate = new Date(date);
    const differenceInTime = expiryDate.getTime() - now.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); 
    return differenceInDays > 0 ? differenceInDays : 0;
  };

  const handleShowSubscriptions = () => {
    setShowSubscriptionsModel(true);
  }

  const handleCloseSubscriptionsModel = () => {
    setShowSubscriptionsModel(false);
  }

  const handleFollow = async () => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    try {
      await axios.post(`${API_URL}/users/${authorId}/follow`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
      setIsFollowing(true);
      setFollowersCount(prevLikes => prevLikes + 1);

      setShowHandshake(true);
      setTimeout(() => {
        setShowHandshake(false);
        setShowThumbsUp(true);
      }, 2000);
      
      setTimeout(() => {
        setShowThumbsUp(false);
      }, 4000);

    } catch (error) {
      console.error('Error following user:', error);
    } 
  }
  };

  const handleUnfollow = async () => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    try {
      await axios.delete(`${API_URL}/users/${authorId}/unfollow`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
      setIsFollowing(false);
      setFollowersCount(prevLikes => prevLikes - 1);
    } catch (error) {
      console.error('Error unfollowing user:', error);
    } 
  }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNextPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(totalPages / perPage)));
  };

  const handlePreviousPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    scrollToTop();
    setCurrentPage(pageNumber);
  };

  if (isLoading) {
    return (
      <SpinnerDiv>
        <Spinner />
      </SpinnerDiv>
    );
  }

  return (
    <Container>
      {author && (
        <AuthorDetailsSection>
          <AuthorDetailsDiv>
            <div>
              <ProfileImage src={`${baseURL}${author.profile_picture}`} alt="Profile" />
              <AuthorInfo>
                <h4>{author.username} <MdVerified color="#add" /></h4>
                <p>{followersCount} Followers</p>
              </AuthorInfo>
              <div>{showThumbsUp && <StyledFaRegThumbsUp />}</div>
            </div>
          </AuthorDetailsDiv>
  
          <PriceAndPurchaseDiv style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            gap: '10px',
            color: '#fff',
            }}>

            <div>
              {isLoading ? (
                <>
                  <SkeletonWrapper style={{ width: '70px', height: '30px' }} />
                  <SkeletonWrapper style={{ width: '70px', height: '30px' }} />
                </>
              ) : (
                <>
                  {isSubscribed ? (
                    <StyledPdiv>
                      <p>
                        Subscription remains <b>{formatTime(expiredOn)}</b> days to expire
                      </p>
                    </StyledPdiv>
                  ) : (
                    <StyledButton onClick={handleShowSubscriptions}>Subscribe</StyledButton>
                  )}
                </>
              )}
            </div>
  
            <div>{showHandshake && <StyledHandshakeIcon />}</div>
  
            {!isOwner && (
              <div>
                {isFollowing ? (
                  <StyledButton onClick={handleUnfollow}>
                    <FaUserCheck color="green" size={15} />
                  </StyledButton>
                ) : (
                  <StyledButton onClick={handleFollow}>
                    <h4>
                      <b>+</b>Follow
                    </h4>
                  </StyledButton>
                )}
              </div>
            )}
          </PriceAndPurchaseDiv>
        </AuthorDetailsSection>
      )}
  
      <SearchAndTitleDiv>
        <TopRow>
          <Title>All Videos</Title>
          <IconsContainer>
            {showSearchInput ? (
              <CloseIcon onClick={handleToggleSearch} />
            ) : (
              <SearchIcon onClick={handleToggleSearch} />
            )}
          </IconsContainer>
        </TopRow>
        <SearchContainer>
        <SearchInput
          type="text"
          placeholder="Search..."
          visible={showSearchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchButton onClick={handleSearch} visible={showSearchInput}>
          Search
        </SearchButton>
        </SearchContainer>
      </SearchAndTitleDiv>
  
      <PostsContainer>
        {authorPosts.map((post) => (
          <PostCard key={post.id} post={post} />
        ))}
      </PostsContainer>
  
      {showSubscriptionsModel && (
        <SubscriptionModal
          authorId={authorId}
          onClose={handleCloseSubscriptionsModel}
          authorUsername={author.username}
        />
      )}
  
      {(currentPage < totalPages || totalPages > 0) && !isFetchingMore && (
      <PaginationWrapper>
        <Pagination>
          <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </PaginationButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <PageNumber
              key={index + 1}
              onClick={() => handlePageClick(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </PageNumber>
          ))}
          <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </PaginationButton>
        </Pagination>
      </PaginationWrapper>
    )}

      {isFetchingMore && (
        <SpinnerDiv>
          <Spinner />
        </SpinnerDiv>
      )}
    </Container>
  );
};  
  
export default AuthorDetails;
