import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { API_URL, baseURL } from '../config';
import axios from 'axios';
import { getValidToken, getUserIdFromToken } from '../utils/auth';
import DOMPurify from 'dompurify';
import ConfirmModal from './ConfirmModal';
import Avatar from './Avatar';
import Spinner from './Spinner';
import { IoMdCloseCircle } from 'react-icons/io';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FcLike } from "react-icons/fc";
import { FaRegHeart } from "react-icons/fa";
import { FaShare } from "react-icons/fa6";


const Container = styled.div` 
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  margin: 0;
  padding: 5px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.background};
  position: relative;
`;

const Header = styled.div`
  display: flex;
  padding: 5px
  height: 30px;
  gap: 10px;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.text};

  h2 {
    color: ${(props) => props.theme.colors.text};
  }

  span {
    color: ${(props) => props.theme.colors.text};
  }
`;

const Close = styled.div`
  display: block;
  position: absolute;
  top: 3px;
  right: 10px;
  color: ${props => props.theme.colors.danger};
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
  `;

const Content = styled.div`
  position: relative;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.background};
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow */
  width: 100%;
  height: calc(100vh - 60px); /* Adjust height based on header and input box */

   /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #0004;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
`;

const TextArea = styled.textarea`
  width: calc(100% - 20px); /* Adjust width to fit inside container with margins */
  margin: 0 10px; /* Add margins on the left and right */
  padding: 10px;
  border-radius: 30px;
  font-size: 1rem;
  line-height: 1.5; /* Ensure consistent line spacing */
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.background};
  resize: none; /* Prevent resizing by the user */
  overflow-y: auto; /* Allow vertical scrolling */
  
  ::placeholder {
    white-space: nowrap; /* Keep placeholder text on one line */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  }

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #0004;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
`;

const InputBoxComment = styled.div`
  display: flex;
  margin-top: 5px;
  width: 100%; /* Adjust width */
  height: auto; /* Let the container adapt to its content */
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 6px;
  padding: 10px; /* Add padding for better appearance */
`;

const InputBoxCommentLeftDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 5px;
  width: 50px;
  height: 100%;
`;

const InputBoxCommentRightDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 35px;
  height: 35;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
`;

const SubmitDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.text};
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust font size as needed */
`;


const CommentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const CommentDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CommentText = styled.p`
  color: ${(props) => props.theme.colors.text};
  margin: 0.5rem 0;
  // white-space: pre-wrap; /* Ensures text wraps and respects whitespace */
  // word-wrap: break-word; /* Ensures long words break and wrap */
  // overflow-wrap: break-word; /* Ensures text wraps within the container */
  width: 100%;
`;

const CreatorLabel = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;



const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const LikeDiv = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const RepliesContainer = styled.div`
  margin-top: 1rem;
  padding-left: 2rem; 
`;

const ReplyContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.text};
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

const ReplyDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const CommentButtonP = styled.p`
  background-color: none;
  color: ${(props) => props.theme.colors.text};
  border: none;
  border-radius: 30px;
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;


const CommentsModal = ({ CloseFunction, postId, authorUsername, setTotalCommentsProps, isFromPurchaseModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [comments, setComments] = useState([]);
  const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [replyToUserId ,setReplyToUserId] = useState(null);
  const [totalComments, setTotalComments] = useState(0);
  const [likes, setLikes] = useState({}); 
  const [replyLikes, setReplyLikes] = useState({}); 
  const [likeStatus, setLikeStatus] = useState({}); 
  const [userData, setUserData] = useState('');
  const [commentText, setCommentText] = useState('');
  const [replyText, setReplyText] = useState('');
  const [characterLimitAlert, setCharacterLimitAlert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyMode, setReplyMode] = useState(false);
  const [replytoReplyMode, setReplytoReplyMode] = useState(false);
  const [userId, setUserId] = useState(null);
  const [page, setPage] = useState(1); 
  const [limit] = useState(10); 
  const loadMoreRef = useRef(null);
  const contentRef = useRef(null);
  const textAreaRef = useRef(null);
  const navigate = useNavigate();

useEffect(() => {
  const fetchUserId = async () => {
    const id = await getUserIdFromToken(navigate, false);
    setUserId(id);
  };
  fetchUserId();
}, [navigate]);

  // Function to scroll to the top of the container
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' for immediate scroll
      });
    }
  };
  
  // fetch comments and replies from backend in postRoutes.js line =
  const fetchCommentsAndReplies = useCallback(async () => {
    try {
      setIsLoading(true); // Set loading state
      const commentsResponse = await axios.get(`${API_URL}/public/posts/${postId}/comments`, {
        params: { page, limit },
      });
      const commentsData = commentsResponse.data.comments.map(comment => ({
        ...comment,
        isOwner: comment.user.id === userId
      }));
  
      setTotalComments(commentsResponse.data.totalComments);
      
  
      // Append new comments to existing ones or replace if on the first page
      setComments(prevComments => {
        if (page === 1) return commentsData;
        return [...prevComments, ...commentsData];
      });
      setErrorMessage(null);
      setIsLoading(false);
      setIsInitialLoading(false);
    } catch (error) {
      console.error('Error fetching comments and replies:', error.response?.data || error.message);
      setErrorMessage(error.response?.data?.error || 'Error fetching comments. Please try again later.');
      setIsLoading(false);
      setIsInitialLoading(false);
    }
  }, [postId, page, limit, userId]);
  
  useEffect(() => {
    if (postId) {
      fetchCommentsAndReplies();
    }
  }, [postId, page, fetchCommentsAndReplies]);
  

  // Fetch like status and counts for comments and replies
  useEffect(() => {
    const fetchLikeData = async () => {
      const token = await getValidToken(navigate, false);
      
      try {
        const commentLikesData = {};
        const likeStatusData = {};
        const replyLikesData = {};
  
        const commentLikesPromises = comments.map(async (comment) => {
          const commentLikeResponse = await axios.get(`${API_URL}/public/comments/${comment.id}/commentlikes/count`);
          commentLikesData[comment.id] = commentLikeResponse.data.commentLikesCount;

          if (token) {
            const commentLikeStatusResponse = await axios.get(`${API_URL}/comments/${comment.id}/commentLike_status`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            }); 
            likeStatusData[comment.id] = commentLikeStatusResponse.data.isCommentLiked;
          };
          
  
          // Fetch replies' like data
          const replyLikesPromises = comment.replies.map(async (reply) => {
            const replyLikeResponse = await axios.get(`${API_URL}/public/replies/${reply.id}/replylikes/count`);
            replyLikesData[reply.id] = replyLikeResponse.data.replyLikesCount;

            if (token) {
            const replyLikeStatusResponse = await axios.get(`${API_URL}/replies/${reply.id}/replylike_status`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            }); 
            likeStatusData[reply.id] = replyLikeStatusResponse.data.isReplyLiked;
          };
            
          });
  
          // Wait for all reply like data to be fetched
          await Promise.all(replyLikesPromises);
        });
  
        // Wait for all comment like data to be fetched
        await Promise.all(commentLikesPromises);
  
        // Batch state updates
        setLikes(prev => ({ ...prev, ...commentLikesData }));
        setReplyLikes(prev => ({ ...prev, ...replyLikesData }));
        setLikeStatus(prev => ({ ...prev, ...likeStatusData }));
      } catch (error) {
        console.error('Error fetching likes data:', error);
      }
    };
  
    if (comments.length > 0) {
      fetchLikeData();
    }
  }, [comments, setLikes, setReplyLikes, setLikeStatus, navigate]);
  
  

  // Intersection Observer to load more comments when reaching the end
  useEffect(() => {
    const loadMoreElement = loadMoreRef.current;
  
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading && comments.length < totalComments) {
        setPage((prevPage) => prevPage + 1);
      }
    }, { threshold: 1.0 });
  
    if (loadMoreElement) {
      observer.observe(loadMoreElement);
    }
  
    console.log('observer created', observer);
  
    return () => {
      if (loadMoreElement) {
        observer.unobserve(loadMoreElement);
      }
    };
  }, [comments, isLoading, totalComments, loadMoreRef, setPage]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await getValidToken(navigate, false);
      if (token) {
      try {
        const response = await axios.get(`${API_URL}/User`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response?.data || error.message);
      }
    }
    };

    fetchData();
  }, [navigate]);

  const handleReply = (commentId, commentUserId, username) => {
    console.log("commentId:", commentId);
    console.log("username:", username);
    setReplyToCommentId(commentId); 
    setReplyToUserId(commentUserId); 
    setReplyingTo(username);
    setReplytoReplyMode(false);
    setReplyMode(true); 
  };
  
  const handleReplyToReply = (commentId, replyUserId, username) => {
    console.log("replyUserId:", replyUserId);
    setReplyToCommentId(commentId); 
    setReplyToUserId(replyUserId); 
    setReplyingTo(username); 
    setReplyMode(true);
    setReplytoReplyMode(true); 
  };

  const handleCommentSubmit = async (e) => {
    if (isFromPurchaseModal) {
      setErrorMessage('Zaka Fara Siya Kafin Kayi Comment');
      setTimeout(() => {
        setErrorMessage('');
      }, 7000);
      return;
    }

    if (e) {
      e.preventDefault();
    }
  
    const token = await getValidToken(navigate, true);
  
    if (token) {
      try {
        const response = await axios.post(
          `${API_URL}/posts/${postId}/comments`,
          { text: commentText }, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
  
        const newComment = response.data.comment;
        setCommentText('');
  
        const textArea = document.getElementById('commentTextArea'); // Reset textarea height
        if (textArea) {
          textArea.style.height = 'auto';
        }
  
        setComments((prevComments) => [
          {
            ...newComment,
            isOwner: newComment.user?.id === userId
          },
          ...prevComments
        ]);        

        setTotalComments((prevTotal) => prevTotal + 1);
        setTotalCommentsProps((prevTotal) => prevTotal + 1);
        scrollToTop();
  
      } catch (error) {
        console.error('Error posting comment:', error);
        setErrorMessage(error.response?.data?.error || 'Error posting comment. Please try again later.');
      }
    }
  };
  

  const handlePostReply = async (e) => {
    if (isFromPurchaseModal) {
      setErrorMessage('Zaka Fara Siya Kafin Kayi Comment');
      setTimeout(() => {
        setErrorMessage('');
      }, 7000);
      return;
    }

    if (e) {
      e.preventDefault();
    }
  
    const token = await getValidToken(navigate, true);
  
    if (token) {
      try {
        if (!replyToCommentId) {
          console.error('No comment ID specified for reply.');
          return;
        }
  
        const response = await axios.post(
          `${API_URL}/comments/${replyToCommentId}/replies`,
          { text: replyText, replyToUserId }, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
  
        const newReply = response.data.reply; 
        setReplyText('');
  
        const textArea = document.getElementById('replyTextArea'); // Reset textarea height
        if (textArea) {
          textArea.style.height = 'auto';
        }

        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === replyToCommentId
              ? {
                  ...comment,
                  replies: [
                    ...comment.replies,
                    {
                      ...newReply,
                      isOwner: newReply.user?.id === userId
                    }
                  ]
                }
              : comment
          )
        );        
  
        setReplyingTo(null);
        setReplyMode(false);
        setReplytoReplyMode(false);
        setReplyToCommentId(null);
        setReplyToUserId(null);
  
      } catch (error) {
        console.error('Error posting reply:', error);
        alert('Error posting reply. Please try again later.');
      }
    }
  };
  
 // comment like
 const handleLike = async (commentId) => {
  const token = await getValidToken(navigate, true);
  
  if (token) {

  try {
    await axios.post(`${API_URL}/comments/${commentId}/like`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    setLikeStatus(prev => ({ ...prev, [commentId]: true }));
    setLikes(prev => ({ ...prev, [commentId]: (prev[commentId] || 0) + 1 }));
  } catch (error) {
    console.error('Error liking comment:', error.response?.data || error.message);
  }
}
};


  // comment unlike  
  const handleUnLike = async (commentId) => {

    const token = await getValidToken(navigate, true);
  
  if (token) {
  
    try {
      await axios.delete(`${API_URL}/comments/${commentId}/unlike`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
  
      setLikeStatus(prev => ({ ...prev, [commentId]: false }));
      setLikes(prev => ({
        ...prev,
        [commentId]: Math.max((prev[commentId] || 1) - 1, 0) 
      }));
    } catch (error) {
      console.error('Error unliking comment:', error.response?.data || error.message);
    }
  }
  };
  

  // reply like
  const handleReplyLike = async (replyId) => {
    const token = await getValidToken(navigate, true);
  
  if (token) {
  
    try {
      await axios.post(`${API_URL}/comments/${replyId}/replylike`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
  
      setLikeStatus(prev => ({ ...prev, [replyId]: true }));
      setReplyLikes(prev => ({ ...prev, [replyId]: (prev[replyId] || 0) + 1 })); 
    } catch (error) {
      console.error('Error liking reply:', error.response?.data || error.message);
    }
  }
  };

  // reply unlike
  const handleReplyUnLike = async (replyId) => {
    const token = await getValidToken(navigate, true);
  
  if (token) {
  
    try {
      await axios.delete(`${API_URL}/comments/${replyId}/replyunlike`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
  
      setLikeStatus(prev => ({ ...prev, [replyId]: false }));
      setReplyLikes(prev => ({
        ...prev,
        [replyId]: Math.max((prev[replyId] || 1) - 1, 0) 
      }));
      // Fetch updated comments after unliking or update state manually
    } catch (error) {
      console.error('Error unliking reply:', error.response?.data || error.message);
    }
  }
  };

  const handleCommentDelete = async (commentId) => {
    setItemToDelete(commentId);
    setDeleteType('comment');
    setIsModalOpen(true);
  };

  const handleReplyDelete = async (replyId) => {
    setItemToDelete(replyId);
    setDeleteType('reply');
    setIsModalOpen(true);
  };

  const cancelDelete = () => {
       setItemToDelete(null);
       setIsModalOpen(false);
     };

     const confirmDelete = async () => {
      const token = await getValidToken(navigate, true);
    
      if (token) {
        try {
          if (deleteType === 'comment') {
            await axios.delete(
              `${API_URL}/comments/${itemToDelete}/delete`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
              }
            );
    
            // Update the UI by removing the deleted comment
            setComments((prevComments) => prevComments.filter((comment) => comment.id !== itemToDelete));
    
            // Optionally, update the total comments count after deletion
            setTotalComments((prevTotal) => Math.max(prevTotal - 1, 0)); 
            setTotalCommentsProps((prevTotal) => Math.max(prevTotal - 1, 0));
    
          } else if (deleteType === 'reply') {
            await axios.delete(
              `${API_URL}/replies/${itemToDelete}/delete`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
              }
            );
    
            // Update the UI by removing the deleted reply
            setComments((prevComments) =>
              prevComments.map((comment) =>
                comment.replies
                  ? { ...comment, replies: comment.replies.filter((reply) => reply.id !== itemToDelete) }
                  : comment
              )
            );
          }
        } catch (error) {
          console.error('Error deleting item:', error);
        }
      }
    
      // Close the modal after the deletion process
      setIsModalOpen(false);
    };
  
  const handleShowReplies = (commentId) => {
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === commentId ? { ...comment, showReplies: true } : comment
      )
    );
  };

  const handleHideReplies = (commentId) => {
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === commentId ? { ...comment, showReplies: false } : comment
      )
    );
  };

  // Time format 
  const formatTimeAgo = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();

    const timeDifference = currentDate - createdDate;
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(monthsDifference / 12);

    let formattedTimeAgo = '';

    if (yearsDifference > 0) {
      formattedTimeAgo = `${yearsDifference} ${yearsDifference === 1 ? 'y' : 'yrs'} ago`;
    } else if (monthsDifference > 0) {
      formattedTimeAgo = `${monthsDifference} ${monthsDifference === 1 ? 'm' : 'mns'} ago`;
    } else if (daysDifference > 0) {
      formattedTimeAgo = `${daysDifference} ${daysDifference === 1 ? 'd' : 'dys'} ago`;
    } else if (hoursDifference > 0) {
      formattedTimeAgo = `${hoursDifference} ${hoursDifference === 1 ? 'h' : 'hrs'} ago`;
    } else if (minutesDifference > 0) {
      formattedTimeAgo = `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      formattedTimeAgo = `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
    }

    return formattedTimeAgo;
  };

  const handleKeyDown = (event, submitFunction, textSetter) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      submitFunction();
      textSetter('');
    }
  };

  const CharacterLimit = 200;

  const handleCommentTextChange = (e) => {
    const textArea = e.target;
    const text = textArea.value;

    // Check if the text exceeds the character limit
    if (text.length > CharacterLimit) {
        // Alert the user
        setCharacterLimitAlert(`Character limit of ${CharacterLimit} exceeded!`);
        setTimeout(() => setCharacterLimitAlert(null), 6000);
        // Set the text to the allowed limit
        setCommentText(text.slice(0, CharacterLimit));
    } else {
        // Otherwise, update the state with the new text
        setCommentText(text);
        setCharacterLimitAlert(null);
    }
    
    // Reset height to auto to get the correct scrollHeight
    textArea.style.height = 'auto';
    // Set the height based on the scrollHeight, with a maximum of 3 lines
    const lineHeight = parseFloat(getComputedStyle(textArea).lineHeight);
    textArea.style.height = `${Math.min(textArea.scrollHeight, 3 * lineHeight)}px`;
};

  const handleReplyTextChange = (e) => {
    const textArea = e.target;
    const text = textArea.value;

    // Check if the text exceeds the character limit
    if (text.length > CharacterLimit) {
        // Alert the user
        setCharacterLimitAlert(`Character limit of ${CharacterLimit} exceeded!`);
        setTimeout(() => setCharacterLimitAlert(null), 6000);
        // Set the text to the allowed limit
        setReplyText(text.slice(0, CharacterLimit));
    } else {
        // Otherwise, update the state with the new text
        setReplyText(text);
        setCharacterLimitAlert(null);
    }
    
    // Reset height to auto to get the correct scrollHeight
    textArea.style.height = 'auto';
    // Set the height based on the scrollHeight, with a maximum of 3 lines
    const lineHeight = parseFloat(getComputedStyle(textArea).lineHeight);
    textArea.style.height = `${Math.min(textArea.scrollHeight, 3 * lineHeight)}px`;
  };

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `${Math.min(textArea.scrollHeight, 3 * parseFloat(getComputedStyle(textArea).lineHeight))}px`;
    }
  }, [commentText, replyText]);
  

  return (
    <Container>

      <Header>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
          <h2>Comments</h2>
          <span>{totalComments}</span> 
        </div>
        <div>
          <Close onClick={CloseFunction}>
            <IoMdCloseCircle style={{ fontSize: '25px' }} />
          </Close>
        </div>
      </Header>

      <Content ref={contentRef}>
        <div>
        {errorMessage ? (
          <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'red', width: '100%', height: '100%' }}>
              <h4>{errorMessage}</h4>
              <button onClick={fetchCommentsAndReplies}>Reload</button>
          </div>
      ) : isInitialLoading ? (
          <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', height: '100%' }}>
              <Spinner />
          </div>
      ) : comments.length === 0 ? (
          <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', height: '100%' }}>
              <h4>No comments available</h4>
              <p>Start the conversation below.</p>
          </div>
      ) : (
          comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((commentItem) => (
            <CommentContainer key={commentItem.id}>
              <div style={{marginRight: '0.7rem'}}>
              <Avatar 
              username={commentItem.user?.username}
              profilePicture={commentItem.user.profilePicture ? `${baseURL}${commentItem.user.profilePicture}` : null}
              width='30px'
              height='30px'
              />
              </div>
              <CommentDetails>
                <CommentText><strong>{commentItem.user.username}{commentItem.user.username === authorUsername && <CreatorLabel>~ (Creator)</CreatorLabel>}</strong></CommentText>
                <CommentText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(commentItem.text) }} />
                <ActionButtons>
                  <CommentText><span>{formatTimeAgo(commentItem.createdAt)}</span></CommentText>
                  <CommentButtonP onClick={() => handleReply(commentItem.id, commentItem.user.id, commentItem.user.username)}>Reply</CommentButtonP>
                  <div style={{display: 'flex'}}>
                  {likeStatus[commentItem.id] ? (
                    <LikeDiv onClick={() => handleUnLike(commentItem.id)}><FcLike /></LikeDiv>
                  ) : (
                    <LikeDiv onClick={() => handleLike(commentItem.id)}><FaRegHeart /></LikeDiv>
                  )}
                  {likes[commentItem.id]}
                  </div>
                  {commentItem.isOwner && <LikeDiv onClick={() => handleCommentDelete(commentItem.id)}><RiDeleteBin6Fill /></LikeDiv>}
                </ActionButtons>
                {isModalOpen && deleteType === 'comment' && (
                  <ConfirmModal
                    message="Are you sure you want to delete this comment?"
                    onConfirm={confirmDelete}
                    onCancel={cancelDelete}
                  />
                )}
                {commentItem.replies.length > 0 && (
                <RepliesContainer>
                  {commentItem.showReplies && (
                    <>
                      {commentItem.replies.map((reply) => (
                        <ReplyContainer key={reply.id}>
                          <Avatar 
                          username={reply.user?.username}
                          profilePicture={reply.user.profilePicture ? `${baseURL}${reply.user.profilePicture}` : null}
                          width='15px'
                          height='15px'
                          />
                          <ReplyDetails>
                            <CommentText>
                                    <strong>{reply.user.username}{reply.user.username === authorUsername && <CreatorLabel>~ (Creator)</CreatorLabel>}, replied to {reply.replyTo.username}:</strong>
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reply.text) }} />
                            </CommentText>
                            <ActionButtons>
                              <CommentButtonP onClick={() => handleReplyToReply(commentItem.id, reply.user.id, reply.user.username)}>Reply</CommentButtonP>
                              <div style={{display: 'flex'}}>
                                {likeStatus[reply.id] ? (
                                  <LikeDiv onClick={() => handleReplyUnLike(reply.id)}><FcLike /></LikeDiv>
                                ) : (
                                  <LikeDiv onClick={() => handleReplyLike(reply.id)}><FaRegHeart /></LikeDiv>
                                )}
                                {replyLikes[reply.id]}
                              </div>
                              {commentItem.isOwner && <LikeDiv onClick={() => handleReplyDelete(reply.id)}><RiDeleteBin6Fill /></LikeDiv>}
                            </ActionButtons>
                            {isModalOpen && deleteType === 'reply' && (
                              <ConfirmModal
                                message="Are you sure you want to delete this reply?"
                                onConfirm={confirmDelete}
                                onCancel={cancelDelete}
                              />
                            )}
                          </ReplyDetails>
                        </ReplyContainer>
                      ))}
                      <CommentButtonP onClick={() => handleHideReplies(commentItem.id)}>
                        Hide
                      </CommentButtonP>
                    </>
                  )}
                  {!commentItem.showReplies && (
                    <CommentButtonP onClick={() => handleShowReplies(commentItem.id)}>
                      <span>__Show {commentItem.replies.length} reply</span>
                    </CommentButtonP>
                  )}
                </RepliesContainer>
              )}

              </CommentDetails>
            </CommentContainer>
            ))
          )}

      <div ref={loadMoreRef} />    

      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      {isLoading ? (<div><h4>Loading...</h4></div>) : null}
      </div>
        </div>
      </Content>

      {!replyMode && !replytoReplyMode && (
        <>
        {characterLimitAlert && (
            <p style={{ color: 'red', textAlign: 'center' }}>{characterLimitAlert}</p>
        )}
        <InputBoxComment>
          <InputBoxCommentLeftDiv>
          <Avatar 
            username={userData?.username}
            profilePicture={userData.profile_picture ? `${baseURL}${userData.profile_picture}` : null}
            width='30px'
            height='30px'
          />
          </InputBoxCommentLeftDiv>
          <TextArea
            id="commentTextArea"
            type='text'
            placeholder='Write comment...'
            value={commentText}
            onChange={handleCommentTextChange}
            onKeyDown={(e) => handleKeyDown(e, handleCommentSubmit, setCommentText)}
            rows={1}
          />
          <InputBoxCommentRightDiv onClick={handleCommentSubmit}>
          <SubmitDiv><FaShare /></SubmitDiv>
          </InputBoxCommentRightDiv>
        </InputBoxComment>
        </>
      )}

      {(replyMode || replytoReplyMode) && (
        <>
        {characterLimitAlert && (
            <p style={{ color: 'red', textAlign: 'center' }}>{characterLimitAlert}</p>
        )}
        <InputBoxComment>
          <InputBoxCommentLeftDiv>
          <Avatar 
            username={userData?.username}
            profilePicture={userData.profile_picture ? `${baseURL}${userData.profile_picture}` : null}
            width='30px'
            height='30px'
          />
          </InputBoxCommentLeftDiv>
          <TextArea
            id="replyTextArea"
            value={replyText}
            onChange={handleReplyTextChange}
            placeholder={`Replying to ${replyingTo}`}
            onKeyDown={(e) => handleKeyDown(e, handlePostReply, setReplyText)}
            rows={1}
          />
          <InputBoxCommentRightDiv onClick={handlePostReply}>
          <SubmitDiv><FaShare /></SubmitDiv>
          </InputBoxCommentRightDiv>
        </InputBoxComment>
        </>
      )}
    </Container>
  );
};

export default CommentsModal;
