import React, { useState } from 'react';
import axios from 'axios';
import { API_URL, baseURL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { LoadingIcon, SuccessIcon, ErrorIcon } from '../components/Admin/styles';

import {FormField, Label, Input, TextArea, Select, ButtonsDiv, Styledhr, Button} from '../components/Admin/RequestForm';

const PostTicketPurchaseForm = ({ post, onClose, refresh }) => {
  const [isloading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [shopNumber, setShopNumber] = useState(''); 
  const [state, setState] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getValidToken(navigate, true);

    if (token) {
      setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}/offline/purchase`, {
        postId: post.id,
        postTitle: post.title,
        username,
        address,
        phone,
        shopNumber,
        state,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });

      
      setError('');
      setLoginSuccess(true);
      setIsLoading(false);

      setTimeout(() => {
        setLoginSuccess(false);
          onClose();
          refresh();
      }, 2000); 
    } catch (error) {
      console.error('Error purchasing post:', error);
      setError(error.message);
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
    } finally {
      setIsLoading(false)
    }
    }
  };

  return (
    <div>
      <FormField style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img src={`${baseURL}${post.thumbnail}`} alt={post.title} width={'80px'} height={'80px'}/>
      </FormField>
      <h3 style={{marginBottom: '10px'}}>Purchase - {post.title}, For {post.access_type}</h3>
      <Styledhr />
      <FormField>
        <Label>Username</Label>
        <Input 
          type="text"
          placeholder='Sunan Mai Siya' 
          value={username}
          onChange={(e) => setUsername(e.target.value)} 
          required 
        />
      </FormField>
      <FormField>
        <Label>Phone</Label>
        <Input 
          type="tel"
          placeholder='Lambar Wayar Mai Siya'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </FormField>
      <FormField>
        <Label>Address</Label>
        <Input 
          type="text"
          placeholder='Adireshin Mai Siya'
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
      </FormField>
      <FormField>
        <Label>State</Label>
        <Input 
          type="text"
          placeholder='Jahar Mai Siya'
          value={state}
          onChange={(e) => setState(e.target.value)}
          required
        />
      </FormField>
      <FormField>
        <Label>Shop Number</Label>
        <Input 
          type="text"
          placeholder='Lambar Shagon Mai Siya'
          value={shopNumber}
          onChange={(e) => setShopNumber(e.target.value)}
          required
        />
      </FormField>

      {error && <FormField><p style={{color: 'red'}}><b>{error}</b></p></FormField>}
      
      <ButtonsDiv>
        <Button onClick={handleSubmit} disabled={isloading || loginSuccess}>  
                {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Purchase Post'}
                {isloading && <LoadingIcon />}
        </Button>
        <Button onClick={onClose} style={{ marginTop: '10px', background: 'red' }}>Close</Button>
      </ButtonsDiv>
    </div>
  );
};

export default PostTicketPurchaseForm;
