import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; 
import { API_URL } from '../../config'; 
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import RequestForm from './RequestForm'; 
import useDebounce from '../../utils/useDebounce';
import Spinner from '../Spinner';

const FormContainer = styled.div`
    position: relative;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    //border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
    overflow: ${(props) => (props.hideScroll ? 'hidden' : 'auto')};
`;

const SpinnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const FormTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text};
`;

const Tabs = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    border-radius: 10px;
    gap: 10px;
    overflow: hidden;
`;

const Tab = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    gap: 5px;
    border: none;
    border-radius: 15px;
    background: ${props => props.active ? props.theme.colors.primary : '#ddd'};
    color: ${props => props.active ? 'white' : 'black'};
    cursor: pointer;
    &:hover {
        color: white;
    }
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
`;

const RequestList = styled.div`
    margin-bottom: 20px;
`;

const RequestItem = styled.div`
    padding: 10px;
    border: 1px solid ${props => props.theme.colors.text};
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    margin-bottom: 10px;
    color: ${props => props.theme.colors.text};
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.colors.primary};
    }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

export {PaginationWrapper, Pagination, PageNumber, PaginationButton, FormContainer, FormTitle, Tabs, Tab, SearchInput, RequestList, RequestItem, FormModelDiv, ModalBackdrop};

const BecomeCreatorFormRequests = () => {
    const [isloading, setIsLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [tab, setTab] = useState('all');
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [counts, setCounts] = useState({ allRequests: 0, approvedRequests: 0, pendingRequests: 0, rejectedRequests: 0 });
    const ListRef = useRef(null);
    const navigate = useNavigate();

    const debouncedSearch = useDebounce(search, 500); // 500ms debounce delay

    const fetchCounts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/author/request/counts`, {
                    params: { search: debouncedSearch, tab },
                    ...config
                });
                setCounts(response.data);
            } catch (error) {
                console.error('Error fetching request counts:', error);
            }
        }
    }, [debouncedSearch, tab, navigate]);

    const fetchRequests = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/author/request/getAll`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setRequests(response.data.requests || []);
                setTotalPages(response.data.totalPages); 
            } catch (error) {
                console.error('Error fetching requests:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentPage, navigate, debouncedSearch,tab]);

    useEffect(() => {
        fetchCounts();
        fetchRequests();
    }, [currentPage, debouncedSearch, tab, navigate, fetchCounts, fetchRequests]);

    const handleSelectRequest = (request) => {
        if (selectedRequest && selectedRequest.id === request.id) {
            setSelectedRequest(null);
        } else {
            setSelectedRequest(request);
        }
    };

    const handleTabChange = (selectedTab) => {
        setTab(selectedTab);
        setCurrentPage(1); // Reset to page 1 on tab change
        fetchRequests(); // Fetch requests for the new tab
    };

    const handleCloseRequest = () => {
        setSelectedRequest(null);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const scrollToTop = () => {
        ListRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        scrollToTop();
    };
  
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            scrollToTop();
        }
    };
  
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollToTop();
        }
    };

    const handleSelectChange = (event) => {
        handleTabChange(event.target.value);
    };

    //const filteredRequests = requests.filter(req => req.status === tab);
    const filteredRequests = Array.isArray(requests) 
    ? (tab === 'all' ? requests : requests.filter(req => req.status === tab)) 
    : [];

    if (isloading) {
        return (
            <SpinnerContainer>
            <Spinner />
            </SpinnerContainer>
        )
    }

    return (
        <FormContainer>
            <FormTitle>Become a Creator Requests</FormTitle>
            <Tabs>

                <Tab active={tab === 'pending'} onClick={() => handleTabChange('pending')}>
                    Pending ({counts.pendingRequests})
                </Tab>
                <Tab active={tab === 'waiting'} onClick={() => handleTabChange('waiting')}>
                    Approved ({counts.approvedRequests})
                </Tab>
    
                <select value={tab} onChange={handleSelectChange} style={{ marginLeft: '10px' }}>
                    <option value="all">All ({counts.allRequests})</option>
                    <option value="approved">Approved ({counts.approvedRequests})</option>
                    <option value="pending">Pending ({counts.pendingRequests})</option>
                    <option value="waiting">Waiting ({counts.waitingRequests})</option>
                    <option value="rejected">Rejected ({counts.rejectedRequests})</option>
                </select>
            </Tabs>
            <SearchInput
                type="text"
                placeholder="Search by username or phone"
                value={search}
                onChange={handleSearchChange}
            />
            <RequestList ref={ListRef}>
                {filteredRequests.map(request => (
                    <RequestItem key={request.id} onClick={() => handleSelectRequest(request)}>
                        {request.username} - {request.author_type} - {request.status}
                    </RequestItem>
                ))}
            </RequestList>
            {totalPages > 1 && (
                <PaginationWrapper>
                <Pagination>
                    <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                        Previous
                    </PaginationButton>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <PageNumber
                            key={index + 1}
                            onClick={() => handlePageClick(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </PageNumber>
                    ))}
                    <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </PaginationButton>
                </Pagination>
            </PaginationWrapper>
            )}
            {selectedRequest && (
                <>
                    <ModalBackdrop onClick={handleCloseRequest} />
                    <FormModelDiv> 
                        <RequestForm
                            request={selectedRequest}
                            onClose={handleCloseRequest}
                            refresh={fetchRequests}
                            refreshCounts={fetchCounts}
                        />
                    </FormModelDiv>
                </>
            )}
        </FormContainer>
    );
};

export default BecomeCreatorFormRequests; 
