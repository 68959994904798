import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaPhone, FaComments, FaFlag, FaTimes } from 'react-icons/fa';
import { API_URL, baseURL } from '../config';
import { useNavigate, useParams } from 'react-router-dom';
import { getValidToken } from '../utils/auth';
import { SpinnerContainer } from './PostDetails.Styles';
import Spinner from './Spinner';
import Chat from './Chat';
import { getUserIdFromToken } from '../utils/auth';

// Styled Components
const Container = styled.div`
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background2};
  color: ${(props) => props.theme.colors.text};
`;

const AdDetailsContainer = styled.div`
  max-width: 800px;
  min-height: 100vh;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ImagesContainer = styled.div`
  margin-bottom: 20px;
  height: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;

  .carousel .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .carousel .slide img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    cursor: pointer;
  }
`;

const ThumbnailDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any overflow */
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  background: black;
  padding: 20px;
  border-radius: 10px;

  .carousel .slide img {
    width: 100%;
    height: auto;
    max-height: 100vh;
    object-fit: contain;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;

  &:hover {
    color: #ccc;
  }
`;

const AdTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const AdLocation = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
`;

const AdPrice = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #1c8cdb;
  margin-bottom: 10px;
`;

const AdDescription = styled.p`
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  background-color: ${({ bgColor }) => bgColor || '#1c8cdb'};
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${({ bgColor }) => bgColor || '#106ba3'};
  }

  svg {
    margin-right: 10px;
  }
`;

const ReportButton = styled.button`
  background: none;
  border: none;
  color: #ff4d4d;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #d42e2e;
  }
`;

const AdDetails = () => {
  const [ad, setAd] = useState(null);
  const [chats, setChats] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const { id: adId } = useParams();
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserId = async () => {
      const id = await getUserIdFromToken(navigate);
      setUserId(id);
    };
    fetchUserId();
  }, [navigate]);

  useEffect(() => {
    const fetchAd = async () => {
      const token = await getValidToken(navigate, false);
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/public/ad/details/${adId}`, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          });
          setAd(response.data);
        } catch (error) {
          console.error('Error fetching ad details:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('No valid token found');
        setIsLoading(false);
      }
    };

    fetchAd();
  }, [navigate, adId]);

  // In adDetails component
  const fetchAdChats = useCallback(async () => {
    if (!ad) return;
    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/chat/all`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
          params: { adId: adId, authorId: ad.authorId },
        });
        setChats(response.data.chats);
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    }
  }, [adId, navigate, ad]);

  useEffect(() => {
    fetchAdChats();
  }, [adId, navigate, fetchAdChats]);

  const handleImageClick = (index) => {
    setModalImageIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    //navigate('/kasuwa/section');
  };

  const handleCall = () => {
    if (ad?.phone) {
      window.location.href = `tel:${ad.phone}`;
    }
  };

  const handleChatClick = () => {
    // Either navigate to a chat page or open a modal
    setIsChatOpen(!isChatOpen);
  };

  const handleReport = () => {
    alert('Are you sure you want to report this ad?');
    // Logic to report the ad
  };

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  if (!ad) {
    return (
      <AdDetailsContainer>
        <AdTitle>No ad found</AdTitle>
        <AdDescription>
          The ad you're looking for may have been removed or does not exist.
        </AdDescription>
      </AdDetailsContainer>
    );
  }

  return (
    <Container>
    <AdDetailsContainer>
      {/* Image Carousel */}
      <ImagesContainer>
        <ThumbnailDiv>
        <Carousel showThumbs={false} infiniteLoop autoPlay>
          {ad.images.map((image, index) => (
            <div key={index} onClick={() => handleImageClick(index)}>
              <img
                src={`${baseURL}${image}`}
                alt="Ad"
                
              />
            </div>
          ))}
        </Carousel>
        </ThumbnailDiv>
      </ImagesContainer>

      {/* Modal for Full-Screen Image View */}
      {modalOpen && (
        <ModalBackdrop onClick={handleCloseModal}>
          <AdDetailsContainer>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={handleCloseModal}>
              <FaTimes />
            </CloseButton>
            <Carousel showThumbs={false} selectedItem={modalImageIndex} infiniteLoop>
              {ad.images.map((image, index) => (
                <div key={index}>
                  <img src={`${baseURL}${image}`} alt="Ad" />
                </div>
              ))}
            </Carousel>
          </ModalContent>
          </AdDetailsContainer>
        </ModalBackdrop>
      )}
      {/* Ad Details */}
      <AdTitle>{ad.title}</AdTitle>
      <AdLocation>
        {ad.state}, {ad.local_government}, {ad.area}
      </AdLocation>
      <AdPrice>N{ad.price}</AdPrice>
      <AdDescription>{ad.description}</AdDescription>

      {/* Action Buttons */}
      <ButtonContainer>
        <ActionButton bgColor="#4CAF50" onClick={handleCall}>
          <FaPhone /> Call
        </ActionButton>
        {ad.authorId !== userId ? (
          <ActionButton bgColor="#2196F3" onClick={() => {navigate(`/chat/${adId}`)}}>
          <FaComments /> Chat
        </ActionButton>
        ) : (
          <ActionButton bgColor="#2196F3" onClick={() => {navigate('/chat/lists')}}>
          <FaComments /> Chat
        </ActionButton>
        )}
      </ButtonContainer>

      {/* Report Button */}
      <ReportButton onClick={handleReport}>
        <FaFlag /> Report this ad
      </ReportButton>

      {isChatOpen && (
        <Chat
          ad={ad}
          onClose={handleChatClick}
          chats={chats}
          fetchMessages={fetchAdChats}
        />
      )}
    </AdDetailsContainer>
    </Container>
  );
};

export default AdDetails;
