import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center; /* Adjust alignment to start */
  width: 100%;
  position: relative;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px;
  gap: 1.8rem;
  //grid-template-columns: 300px 1fr; /* Adjusted for desktop view */
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};

  // @media (max-width: 768px) {
  //   grid-template-columns: 1fr; /* Adjusted for mobile view */
  // }
`;

const Card = styled.div`
  //background: rgba(32, 99, 135, 0.9);
  background-color: ${(props) => props.theme.colors.background};
  margin: auto;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  align-items: center;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%; /* Add the desired height value here */
  color: white;
  //color: ${({ theme }) => theme.text};
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.6rem;
  //flex-direction: column;
  justify-content: center;
  
  h1 {
    color: black;
  }
`;

const Close = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.colors.danger};

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
  `;

const InputBox = styled.div`
display: flex;
justify-content: center;
position: relative;
width: 100%;
height: 50px;
border: 1px solid ${props => props.theme.colors.text};
border-radius: 6px;
margin-bottom: 25px;

&:nth-child input:valid ~ span,
&:nth-child input:focus ~ span {
  background: #00dfc4;
  color: black;
  border-radius: 2px;
}

input:valid ~ span,
input:focus ~ span {
  color: black;
  transform: translateX(10px) translateY(-14px);
  font-size: 15px;
  padding: 0 10px;
  background: #32a852;
  border-left: 1px solid #00dfc4;
  border-right: 1px solid #00dfc4;
  border-radius: 5px;
}
`;


const Label = styled.span`
position: absolute;
left: 0;
padding: 10px;
pointer-events: none;
font-size: 20px;
font-weight: 400;
color: ${props => props.theme.colors.text};
transition: 0.3s ease;
`;

// FormControl component styled with theme variables
const StyledInput = styled.input`
width: 100%;
padding: 10px;
background: transparent;
border: 1px solid ${props => props.theme.colors.text};
border-radius: 6px;
outline: none;
color: ${props => props.theme.colors.text};
font-size: 20px;
transition: 0.3s;

&:valid,
&:focus {
  border: 2px solid #00dfc4;
}
`;

const ModalContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
gap: 1.6rem;

> div {
  padding: ${props => props.theme.padding.card};
  border-radius: ${props => props.theme.borderRadius.card};
  margin-top: 1rem;
  box-shadow: ${props => props.theme.boxShadow};
  transition: all 300ms ease;

  &:hover {
    box-shadow: none;
    border: 2px solid green;
  }

  &:active {
    box-shadow: none;
    border: 6px solid green;
  }

  span {
    background-color: ${props => props.theme.colors.primary};
    padding: 0.5rem;
    border-radius: 50%;
    color: ${props => props.theme.colors.white};
    font-size: 2rem; /* Adjusted icon size */
  }

  .middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    margin: 1rem 0 0.6rem;
    font-size: 1.5rem;
    color: ${props => props.theme.colors.text};
  }

  h1 {
    color: ${props => props.theme.colors.text};
  }

  small {
    display: block;
    margin-top: 1.3rem;
  }
}

@media (max-width: 768px) {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Two items side by side on mobile */

  h3 {
    font-size: .8rem;
  }

  h1 {
    font-size: 1rem;
  }
}

`;

const StyledButton = styled.button`
width: 100%;
background-color: ${(props) => props.theme.colors.primary};
border-radius: 8px;
color: ${(props) => props.theme.colors.white};
margin-top: 20px;
border: none;
outline: none;
padding: 10px 20px;
cursor: pointer;
font-size: 1em;
font-weight: 800;
text-align: center;
`;

export { Container, Close, Card, ModalContainer, InputBox, StyledInput, Label, StyledButton };