import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL, baseURL } from '../config';
import { getValidToken } from '../utils/auth';
import { SkeletonWrapper } from './SkeletonWrapper';
import CategoriesSliderKasuwa from './CategoriesSliderKasuwa';
import AdDetails from './AdDetails';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdCampaign } from "react-icons/md";
import incrementViewCountForAd from '../utils/incrementViewForAd';
import { IoMdOptions } from "react-icons/io";
import { FaEye, FaLocationDot } from "react-icons/fa6";

const KasuwaSection = ({categories: propCategories, categoriesLoading: propCategoriesLoading, handleSectionToggle, activeSection, isFromHome}) => {
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [ads, setAds] = useState([]);
  const [phoneVisibility, setPhoneVisibility] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams, setSearchParams] = useState('');
  const [filterParams, setFilterParams] = useState({ location: '', minPrice: '', maxPrice: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAds, setTotalAds] = useState(0);
  const [selectedAd, setSelectedAd] = useState(null); 
  const [activeCategory, setActiveCategory] = useState(null);
  const [activePrice, setActivePrice] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const effectRan = useRef(false);

  const adsPerPage = 20;
  

  useEffect(() => {
    // Restore the scroll position
    const scrollPosition = localStorage.getItem('kasuwaScrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
      localStorage.removeItem('kasuwaScrollPosition'); // Remove the stored scroll position
    }
  }, [location]);


  useEffect(() => {
    if (effectRan.current) return; // If the effect has already run or should not increment, do nothing

    const incrementView = async () => {
      try {
        await incrementViewCountForAd('kasuwa_section', 'kasuwa_section', navigate);
        console.log('View incremented and session storage updated');
      } catch (error) {
        console.error('Error incrementing view count:', error);
      }
    };

    incrementView();
    effectRan.current = true; // Mark the effect as run

    // Clean-up function
    return () => {
      console.log('Cleaning up');
      // Any necessary cleanup logic can go here
    };
  }, [navigate]);

  // Check if the user is logged in
  useEffect(() => {
    const checkLogin = async () => {
      const token = await getValidToken(navigate, false);
      setIsLoggedIn(!!token);
    };
    checkLogin();
  }, [navigate]);

  // Fetch categories if not from Home
  useEffect(() => {
    if (isFromHome) {
      setCategories(propCategories);
      setCategoriesLoading(propCategoriesLoading);
    } else {
      const fetchCategories = async () => {
        const token = await getValidToken(navigate, false);
        if (token) {
          try {
            const response = await axios.get(`${API_URL}/public/ads/categories`, {
              headers: { Authorization: `Bearer ${token}` },
              withCredentials: true
            });
            setCategories(response.data);
          } catch (error) {
            console.error('Error fetching categories:', error);
          } finally {
            setCategoriesLoading(false);
          }
        } else {
          console.error('No valid token found');
        }
      };

      fetchCategories(); 
    }
  }, [navigate, activeSection, isFromHome, propCategories, propCategoriesLoading]);

  // Fetch advertisements with pagination, search, and filter
const fetchAds = useCallback(async (category) => {
  const queryParams = {
      perPage: adsPerPage,
      currentPage,
      searchQuery: searchParams,
      location: filterParams.location,
      minPrice: filterParams.minPrice,
      maxPrice: filterParams.maxPrice,
      category: category || filterParams.category,
  };

  try {
      const response = await axios.get(`${API_URL}/public/ads/public`, {
          params: queryParams,
      });
      setAds(response.data.ads);
      setTotalAds(response.data.totalPages);
  } catch (error) {
      console.error('Error fetching advertisements:', error);
  }
}, [adsPerPage, currentPage, searchParams, filterParams]);

useEffect(() => {
  fetchAds(); // Fetch ads with current parameters
}, [currentPage, searchParams, filterParams, fetchAds]);


  // Handle showing and hiding phone numbers
  const handleShowPhone = async (id) => {
    const token = await getValidToken(navigate, true);
    if (token) {
      setPhoneVisibility((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
  
      // Increment view count for contact view when showing phone
      try {
        await incrementViewCountForAd('contact_view', id, navigate);
        console.log('Contact view incremented');
      } catch (error) {
        console.error('Error incrementing contact view count:', error);
      }
    }
  };
  

  // Handle Ad details display
  const handleAdClick = (adId) => {
    // Store the current scroll position
  localStorage.setItem('kasuwaScrollPosition', window.scrollY);
    //setSelectedAd(ad);
    navigate(`/ad/details/${adId}`)
  };

  const handleCloseAdDetails = () => {
    setSelectedAd(null);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Container>
      <Title>Sashen Talla <MdCampaign color='green'/></Title>

      {categoriesLoading ? (
        <SkeletonWrapper height="30px" width="80%" />
      ) : (
        <CategoriesContainer>
          <CategoriesSliderKasuwa
            categories={categories}
            setActiveCategory={setActiveCategory}
            setActivePrice={setActivePrice}
            handleFetchAds={fetchAds}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handleSectionToggle={handleSectionToggle}
            activeSection={isFromHome ? 'kasuwa' : 'main'}
            showFilter={handleShowFilter}
            isFromHome={isFromHome}
          />
        </CategoriesContainer>
      )
    }

      <SearchAndFilter>
        <SearchAndFilterIconDiv>
        <input
          type="text"
          placeholder="Search Ads..."
          value={searchParams}
          onChange={(e) => setSearchParams(e.target.value)}
        />
        <div onClick={handleShowFilter}><IoMdOptions size={30} width={100} height={100} /></div>
        </SearchAndFilterIconDiv>
        {showFilter && (
          <>
          <input
          type="text"
          placeholder="Location"
          value={filterParams.location}
          onChange={(e) =>
            setFilterParams((prev) => ({ ...prev, location: e.target.value }))
          }
        />
        <input
          type="tel"
          placeholder="Min Price"
          value={filterParams.minPrice}
          onChange={(e) =>
            setFilterParams((prev) => ({ ...prev, minPrice: e.target.value }))
          }
        />
        <input
          type="tel"
          placeholder="Max Price"
          value={filterParams.maxPrice}
          onChange={(e) =>
            setFilterParams((prev) => ({ ...prev, maxPrice: e.target.value }))
          }
        />
        <button onClick={() => setCurrentPage(1)}>Search</button>
        </>
        )}
      </SearchAndFilter>

      {ads.length > 0 ? (
        ads.map((ad) => (
          <AdCard key={ad.id}>
            <AdImagesSliderDiv>
            <ThumbnailDiv>
              {ad.thumbnail && (
                <img src={`${baseURL}${ad.thumbnail}`} alt="Thumbnail" onClick={() => handleAdClick(ad.id)}/>
              )}
            </ThumbnailDiv>
              {ad.images.slice(0, 4).map((image, index) => (
                <img key={index} src={`${baseURL}${image}`} alt="Ad" onClick={() => handleAdClick(ad.id)}/>
              ))}
              {ad.images.length > 4 && (
                <SeeMoreButton onClick={() => handleAdClick(ad)}>
                  See More
                </SeeMoreButton>
              )}
            </AdImagesSliderDiv>
            <Info>
              <AdTitle>{ad.title}</AdTitle>
              <Description>{ad.description}</Description>
              <Address><FaEye /> {ad.views_count}</Address>
              <Address><FaLocationDot /> {ad.state}, {ad.local_government}, {ad.address}</Address>
              {phoneVisibility[ad.id] && (
                <ShowCallButton>
                  <PhoneNumber href={`tel:${ad.phone}`}>Call {ad.phone}</PhoneNumber>
                </ShowCallButton>
              )}
              <ShowPhoneButton onClick={() => handleShowPhone(ad.id)}>
                {phoneVisibility[ad.id] ? 'Hide Phone' : 'Show Phone'}
              </ShowPhoneButton>
            </Info>
          </AdCard>
        ))
      ) : (
        <NoAdsMessage>No advertisements available.</NoAdsMessage>
      )}

      {/* {selectedAd && (
        <>
        <ModalBackdrop onClick={handleCloseAdDetails} />
        <FormModelDiv>
        <AdDetails ad={selectedAd} onClose={handleCloseAdDetails} />
        </FormModelDiv>
        </>
      )} */}

      {totalAds > adsPerPage && (
        <Pagination>
          <PaginationButton
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            Previous
          </PaginationButton>
          <PaginationButton
            disabled={ads.length < adsPerPage}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            Next
          </PaginationButton>
        </Pagination>
      )}
    </Container>
  );
};

export default KasuwaSection;

// Styled Components
const Container = styled.div`
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background2};
  color: ${(props) => props.theme.colors.text};
`;

const CategoriesContainer = styled.div`

`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const SearchAndFilter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }
  button {
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.text};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const SearchAndFilterIconDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text};

  input {
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }

`;

const AdCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 15px;
  //background-color: #ffffff;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ThumbnailDiv = styled.div`
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
`;

const AdImagesSliderDiv = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  max-width: 100%;
  img {
    width: 150px;
    height: 150px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const SeeMoreButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.text};
`;

const AdTitle = styled.h3`
  //margin: 0 0 10px 0;
  //color: #333;
  margin: 5px 0px;
  color: ${(props) => props.theme.colors.text};
`;

const Description = styled.p`
  margin: 0 0 10px 0;
  //color: #666;
  color: ${(props) => props.theme.colors.text};
`;

const Address = styled.p`
  margin: 0 0 10px 0;
  font-size: 14px;
  //color: #888;
  color: ${(props) => props.theme.colors.text};
`;

const ShowPhoneButton = styled.button`
  padding: 10px 15px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
`;

const ShowCallButton = styled.button`
  padding: 10px 15px;
  font-size: 16px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #218838;
  }
`;

const PhoneNumber = styled.a`
  color: white;
  text-decoration: none;
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  font-size: 16px;
  color: white;
  background-color: ${(props) => (props.disabled ? '#6c757d' : '#007bff')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => !props.disabled && '#0056b3'};
  }
`;

const NoAdsMessage = styled.p`
  text-align: center;
  font-size: 18px;
  color: #888;
`;
