import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { API_URL, baseUrl } from '../config'; 
import { useTranslation } from 'react-i18next';
import { Container } from './Register.styles';
import { 
  PlayAudioButton, AudioIndicator, Close, CloseIcon, Logo, 
  LoadingIcon, SuccessIcon, ErrorIcon, SuccessMessage, ErrorMessage, 
  TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, 
  StyledButton, StyledLink, PhoneIcon, LockIcon } from './PublicStyles';
import { BiMoviePlay } from "react-icons/bi";
import logoImg from '../assets/ArewaTv.png';
import TutorialVideoModel from '../components/TutorialVideoModel';
import VideoPlayer from './VideoPlayer';
import DOMPurify from 'dompurify';


import { useDispatch } from 'react-redux';
import { loginSuccess } from '../reducers/actions';
import Spinner from './Spinner';
import { getValidToken } from '../utils/auth';



const Login = () => {
  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loginSuccessfull, setLoginSuccessfull] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    phone: '',
    password: '',
  });

  const { phone, password } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [showVideoModal, setShowVideoModal] = useState(false);

  useEffect(() => {
    const checkIfIsLoggedIn = async () => {
      const token = await getValidToken(navigate, false);
      if (token) {
        setIsLoggedIn(true);
        navigate('/')
      } else {
        setIsLoggedIn(false);
      }
    }
    checkIfIsLoggedIn();
  }, [navigate]);

  useEffect(() => {
    const savedPhone = Cookies.get('userLamba');
    if (savedPhone) {
      // Update the formData's phone field only, preserving other fields (e.g., password)
      setFormData((prevData) => ({
        ...prevData,  // Preserve the existing fields like password
        phone: savedPhone,  // Set the phone value from the cookie
      }));
    }
  }, [navigate]);

  const handleShowModal = () => {
    setShowVideoModal(true);
  };

  const handleModalClose = () => {
    setShowVideoModal(false);
    navigate('/login'); 
  };

  useEffect(() => {
    // Simulate page content rendering
    const timeout = setTimeout(() => {
      setScreenLoading(false); 
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once after component mounts


  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
  
    const sanitizedPhone = DOMPurify.sanitize(phone);
    const sanitizedPassword = DOMPurify.sanitize(password);
    console.log('Sanitized values:', { sanitizedPhone, sanitizedPassword });
    setLoading(true);
    setLoginSuccessfull(false);
    setError(false);
  
    // Validate input before sending the request
    if (!sanitizedPhone || !sanitizedPassword) {
      const errorMessage = t('phone Number and password are required.');
      setError(errorMessage);
      setLoading(false);
  
      // Reset error message after 2 seconds
      setTimeout(() => {
        setError(null);
      }, 2000);
  
      return;
    }
  
    if (sanitizedPassword.length < 8) {
      const errorMessage = t('password must be at least 8 characters long.');
      setError(errorMessage);
      setLoading(false);
  
      // Reset error message after 4 seconds
      setTimeout(() => {
        setError(null);
      }, 4000);
  
      return;
    }
  
    try {
      const { data } = await axios.post(`${API_URL}/public/users/login`, { 
        phone: sanitizedPhone,
        password: sanitizedPassword,
      }, { withCredentials: true });
  
      if (data.token && data.refreshToken) {
        Cookies.set('jwtToken', data.token, { secure: false, sameSite: 'Strict' });
        Cookies.set('refreshToken', data.refreshToken, { secure: false, sameSite: 'Strict' });

      if (formData.phone) {  
        Cookies.set('userLamba', formData.phone, { secure: false, sameSite: 'Strict' });
      }
  
        const { userId, token } = data;
        dispatch(loginSuccess(token, userId));
        setLoginSuccessfull(true);
        setLoading(false);
  
        setTimeout(() => {
          setLoginSuccessfull(false);
          navigate('/');
        }, 2000);
      } else if (data.status === 201 && data.data.email) {
        localStorage.setItem('userEmail', data.data.email);
        const errorMessage = t('errors.please Verify Your Email.');
        setError(errorMessage);
        setTimeout(() => {
          setError(null);
          navigate('/verify-email');
        }, 4000);
      } else if (data.status.msg === 'Invalid credentials') {
        const errorMessage = t('errors.invalid phone or password');
        setError(errorMessage);
        setLoading(false);
        setTimeout(() => {
          setError(null);
        }, 4000);
      } else if (data.status.msg === 'user not found') {
        const errorMessage = t('errors.user not found');
        setError(errorMessage);
        setLoading(false);
        setTimeout(() => {
          setError(null);
        }, 4000);
      } else {
        const errorMessage = t('errors.server Error');
        setError(errorMessage);
        setLoading(false);
        setTimeout(() => {
          setError(null);
        }, 4000);
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  };

  if (isLoggedIn) {
    return;
  }
        

  return (
    <Container>
     {screenLoading ? (
                <Spinner />
              ) : (   
      <Card>

      <Close onClick={() => navigate('/')}>
      <CloseIcon />
      </Close>

      <Logo src={logoImg} alt="Logo" />

      <TitleContainer>
      <Title>{t('login to arewa kallo')}</Title>
        </TitleContainer>

        {!error && (
          <>
          <PlayAudioButton onClick={handleShowModal} style={{ marginBottom: '20px' }}>
          {t('play tutorial')} <AudioIndicator showVideoModal={showVideoModal}></AudioIndicator> 
          <BiMoviePlay style={{ color: 'red', fontSize: '18px' }} />
          </PlayAudioButton> 
        
{/*         {showVideoModal && (
          <TutorialVideoModel
            videoId="ZbyfD0FC3oE"
            onClose={handleModalClose}
          />
        )}
        </>
        )} */}
        {showVideoModal && (
          <VideoPlayer videoUrl="https://storage.googleapis.com/arewa-kallo.appspot.com/Koyarwa/Yadda Ake Kallon Fina Finai A Shafin Arewakallo - Full Koyarwa.mp4" />
        )}
        </>
        )}    
         
        {loginSuccessfull && <SuccessMessage>Login successful!</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}       
       
      <form onSubmit={onSubmit}>
      <InputBox>
            <Icon>
              <PhoneIcon />
            </Icon>
          <StyledInput
            type="text"
            name="phone"
            value={phone}
            required
            onChange={onChange}
          />
            <Label>{t('phone number')}</Label>
          </InputBox>

        <InputBox>
            <Icon>
              <LockIcon />
            </Icon>
          <StyledInput
            type="password"
            name="password"
            value={password}
            required
            onChange={onChange}
          />
            <Label>Password</Label>
        </InputBox>

        <StyledButton type="submit" disabled={loading || loginSuccessfull}>
          {loginSuccessfull ? <SuccessIcon /> : error ? <ErrorIcon /> : `${t('login button')}`}
          {loading && <LoadingIcon />} 
        </StyledButton>
          </form>

      <div style={{ marginTop: '10px', marginBottom: '8px' }}>
      <StyledLink to="/forgot-password" >{t('forgot password?')}</StyledLink>
      </div>
      <div style={{ marginTop: '0' }}>
      <StyledLink to="/register">{t('create new account')}</StyledLink>
      </div>

    </Card>
      )} 
    </Container>
  );
};

export default Login;
