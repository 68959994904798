import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {
    PaginationWrapper,
    Pagination,
    PageNumber,
    PaginationButton,
    FormContainer,
    FormTitle,
    Tabs,
    Tab,
    SearchInput,
    RequestList,
    RequestItem,
    FormModelDiv,
    ModalBackdrop
  } from '../components/Admin/BecomeCreatorFormRequests';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';

// Container for the entire card
const CardContainer = styled.div`
  padding: 20px;
  margin-bottom: 15px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.text};
`;

// Div for the status section
const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

// Div to show active/expired status with dynamic background color
const ActiveOrExpiredDiv = styled.div`
  background-color: ${props => props.status === 'Expired' ? '#ff6b6b' : '#4caf50'};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: bold;
`;

const TicketsSearch = () => {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const ListRef = useRef(null);
  const navigate = useNavigate();

  const fetchTickets = async (searchQuery, page = 1) => {
    const token = await getValidToken(navigate, true);
    if (token) {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/tickets/search`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          search: searchQuery,
          page,
          limit: 10
        }
      });
      setResults(response.data.data);
      setTotalPages(response.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    } finally {
      setLoading(false);
    }
    }
  };

  useEffect(() => {
    if (search.length > 2) { // Trigger search when user types at least 3 characters
      fetchTickets(search, currentPage);
    } else if (search.length === 0) {
      fetchTickets('', currentPage);
    }
  }, [search, currentPage, navigate]);

  const scrollToTop = () => {
    ListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  

//   function status(ticket) {
//     const currentDate = new Date();
//     const purchaseDate = new Date(ticket.purchase_date);
//     let validityPeriod;
  
//     switch (ticket.access_type) {
//       case '1-week':
//         validityPeriod = 7; // 7 days
//         break;
//       case '2-month':
//         validityPeriod = 60; // 60 days (approx 2 months)
//         break;
//       case '3-months':
//         validityPeriod = 90; // 90 days (approx 3 months)
//         break;
//       case 'lifetime':
//         return 'Active';
//       default:
//         return 'Invalid access type';
//     }
  
//     // Calculate expiration date
//     const expirationDate = new Date(purchaseDate);
//     expirationDate.setDate(purchaseDate.getDate() + validityPeriod);
  
//     // Compare expiration date with the current date
//     return expirationDate > currentDate ? 'Active' : 'Expired';
//   }

function formatDate(dateString) {
    if (dateString === 'Never') return dateString;
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

function getStatus(ticket) {
    const currentDate = new Date();
    const purchaseDate = new Date(ticket.purchase_date);
    let validityPeriod;

    switch (ticket.access_type) {
      case '1-week':
        validityPeriod = 7; // 7 days
        break;
      case '2-month':
        validityPeriod = 60; // 60 days (approx 2 months)
        break;
      case '3-months':
        validityPeriod = 90; // 90 days (approx 3 months)
        break;
      case 'lifetime':
        return { status: 'Active', expirationDate: 'Never' }; // Lifetime access
      default:
        return { status: 'Invalid access type', expirationDate: null };
    }

    // Calculate expiration date
    const expirationDate = new Date(purchaseDate);
    expirationDate.setDate(purchaseDate.getDate() + validityPeriod);

    // Compare expiration date with the current date
    const status = expirationDate > currentDate ? 'Active' : 'Expired';

    return { status, expirationDate };
}


  return (
    <FormContainer>
        <Title>
            <h3>Search Tickets For Offline Users</h3>
        </Title>
      <SearchInput
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search tickets..."
      />
      {loading && <p>Loading...</p>}
      <RequestList ref={ListRef}>
      {results.map(ticket => {
        const { status, expirationDate } = getStatus(ticket);

        return (
          <RequestItem key={ticket.id}>
            <CardContainer>
              <p>Post Name: {ticket.post_title}</p>
              <p>Username: {ticket.username}</p>
              <p>Validity: {ticket.access_type}</p>
              <p>Purchased On: {formatDate(ticket.purchase_date)}</p>
              <p>Expires On: {formatDate(expirationDate)}</p>
              <p>Reference: {ticket.reference_code}</p>
              <StatusDiv>
                <p>Status: </p>
                <ActiveOrExpiredDiv status={status}>
                  {status}
                </ActiveOrExpiredDiv>
              </StatusDiv>
            </CardContainer>
          </RequestItem>
        );
      })}
    </RequestList>
      <div>
      {totalPages > 1 && (
        <PaginationWrapper>
          <Pagination>
            <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </PaginationButton>
            {Array.from({ length: totalPages }, (_, i) => (
              <PageNumber key={i} onClick={() => handlePageClick(i + 1)} active={currentPage === i + 1}>
                {i + 1}
              </PageNumber>
            ))}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </PaginationButton>
          </Pagination>
        </PaginationWrapper>
      )}
      </div>
    </FormContainer>
  );
};

export default TicketsSearch;
