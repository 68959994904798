import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
//import styled from 'styled-components';
import PostCard from './PostCard'; // Assuming you have a PostCard component
import { API_URL } from '../config';
import Spinner from './Spinner';
import { useNavigate } from 'react-router-dom';
import {PostsContainer} from './PostCard.styles'; 
import { useTranslation } from 'react-i18next';
import { TbTrident } from "react-icons/tb";
import { MdCancel } from 'react-icons/md';
import { 
  PaginationWrapper,
  Pagination,
  PageNumber,
  PaginationButton } from './Home.styles'; 
  import {MainContainer, NoPostsMessage, Title, StyledH2, CancelIcon, StyledAddButton, SpinnerContainer} from './UserPurchasedContentLists';   

const VipSectionNew = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();
  const postsListRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${API_URL}/public/vip/section/posts`, {
          params: {
            currentPage,
            perPage: 10,
          },
        });
        setPosts(response.data.posts);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [currentPage]);

  const scrollToTop = () => {
    postsListRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const handlePageClick = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handleGoBack = () => {
    navigate('/');
  };

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }


  return (
    <MainContainer ref={postsListRef}>
    <Title>
      <StyledH2>
        <TbTrident size={34} color='yellow' /> 
        Vip Section New Movies
        </StyledH2>
        <CancelIcon>
          <StyledAddButton onClick={handleGoBack}>
            <MdCancel style={{ color: 'red', fontSize: '24px' }} />
          </StyledAddButton>
        </CancelIcon>
        </Title>
    <PostsContainer>
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <PostCard key={`${post.id}-${index}`} post={post} />
          ))
        ) : (
          <NoPostsMessage>{t('No posts available. You can go and buy a new one!')}</NoPostsMessage>
        )}
      </PostsContainer>
      <PaginationWrapper>
        <Pagination>
        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </PaginationButton>
          {Array.from({ length: totalPages }, (_, index) => (
            <PageNumber 
            key={index + 1} 
            onClick={() => handlePageClick(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}>
              {index + 1}
            </PageNumber>
          ))}
          <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </PaginationButton>
        </Pagination>
        </PaginationWrapper>
  </MainContainer>
  );
};

export default VipSectionNew;
