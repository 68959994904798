import React from 'react';

function Sales(props) {
    return (
        <div>
          <h1>Sales</h1>  
        </div>
    );
}

export default Sales;