import React, {useState} from 'react';
import { ModalBackdrop, ModalContent, CloseButton, Button, ShowModelContainer} from './ShowStatusModel.Styles';
import {Close, CloseIcon} from './PublicStyles';
import AddFundModel from './AddFundModel';

const ShowStatusModel = ({ message, onClose }) => {
  const [addFundModel1, setAddFundModel1] = useState(false);
  
  const handleAddFund = () => {
    setAddFundModel1(true);
    //onClose();
  }
    return (
      <ShowModelContainer>
      {/* <ModalBackdrop show> */}
        <ModalContent>
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
          {/* <CloseButton onClick={onClose}>&times;</CloseButton> */}
          <p style={{color: 'red'}}>{message}</p>
          {message === 'Insufficient funds' && (
            <>
            <p>Your Wallet Balance is: <b>N0.00</b></p>
            <p>Click Here To Fund Your Wallet <button onClick={handleAddFund}>+</button></p>
            </>
          )}
        </ModalContent>
      {/* </ModalBackdrop> */}
      {addFundModel1 && (<AddFundModel />)}
      </ShowModelContainer>
      
    );
  };

export default ShowStatusModel;
